import styled, { css } from "styled-components";

const skeleton = css`
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
`;

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
  background: #f1f1f1;
`;

export const Main = styled.div`
  justify-content: center;
  align-items: center;

  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 5px #00000014;

  min-width: 664px;
  margin: auto;

  @media (max-width: 768px) {
    min-width: 327px;
    width: 80%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 515px;
  min-height: 512px;
  margin: auto;

  @media (max-width: 768px) {
    width: 279px;
    min-height: 638px;
  }
  @media (max-width: 640px) {
    min-height: 600px;
  }

  @media (max-height: 430px) {
    min-height: 330px;
  }
`;

export const Title = styled.h4`
  margin-bottom: 40px;
  color: #212121;
  font-weight: 800;

  ${(props) => props?.loading && skeleton}
  ${(props) =>
    props?.loading &&
    css`
      height: 20px;
      width: 240px;
    `}
    
    @media (max-width: 768px) {
    margin-bottom: 50px;
  }

  @media (max-height: 430px) {
    margin-bottom: 0px;
  }
`;

export const SubTitle = styled.p`
  margin: 10px 0 20px;

  text-align: center;
  line-height: 1.7;
  color: #707070;

  margin-top: 60px;
  margin-bottom: 40px;

  ${(props) => props?.loading && skeleton}
  ${(props) =>
    props?.loading &&
    css`
      height: 55px;
      width: inherit;
    `}

    @media (max-width: 768px) {
    margin-top: 80px;
  }

  @media (max-height: 430px) {
    margin: 20px;
  }
`;

export const DivImage = styled.div`
  @media (max-height: 430px) {
    display: none;
  }
`;

export const DivButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;

  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  font-weight: 600;
  color: ${(props) => (props.textColor ? props.textColor : "white")};

  cursor: pointer;
  background: ${(props) => props.backgroundColor};
  border: 1px solid
    ${(props) =>
      props.borderColor ? props.borderColor : props.backgroundColor};
  border-radius: 30px;

  ${(props) => props?.loading && skeleton}

  ${(props) =>
    props?.loading &&
    css`
      border-radius: 30px;
      border: none;
    `}

  width: 100%;
  height: 40px;
`;
