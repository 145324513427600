import { SET_DEPENDENTS, SET_DEPENDENTS_RULES } from "../actions/action-types";

const initialState = {
  dependents: [],
  dependentsRules: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DEPENDENTS:
      return {
        ...state,
        dependents: action.payload,
      };
    case SET_DEPENDENTS_RULES:
      return {
        ...state,
        dependentsRules: action.payload,
      };
    default:
      return state;
  }
};
