import React, { useEffect } from "react";
import Routes from "./routes";
import Loading from "./components/loading";
import GlobalStyles from "./global-styles";

import {
  Container,
  Options,
  OptionItem,
  User,
  ImageUser,
  Message,
  ModalConfirm,
  Buttons,
  Button,
} from "./global-styles";

import { useAlert } from "react-alert";
import { setAlert } from "./services/alert";
import { useSelector, useDispatch } from "react-redux";
import { openModalUser } from "./redux/actions/user.actions";
import {
  setVisibleOptions,
  modalPlanB2B,
  generateBuyToken,
} from "./redux/actions/utils.actions";
import { changePlan } from "./redux/actions/buy.actions";
import { push } from "./services/navigate";
import Modal from "./components/modal";

import { ThemeProvider } from "styled-components";
import theme from "./assets/theme";
import { clearCredentials, getCredentials } from "./utils/credentials";
import { LOGIN_ROUTE } from "./routes/routes.contants";
import { setScheduleModal } from "./redux/actions/schedule.actions";
import { SchedulerItem } from "@einstein/schedule-platform";
import { fetchAndActivate } from "firebase/remote-config";
import { remoteConfig, getRemoteConfigValue } from "./hooks/RemoteConfig";
import { getHomeStore } from "./core/Home/actions";
import { getScheduleStore } from "./core/Scheduling/actions";

export default function App() {
  const dispatch = useDispatch();
  //flag home
  const moduleHomeMicrofrontendEnabled = getRemoteConfigValue(
    "module_home_microfrontend_enabled"
  );
  const changeUpdateHomeStore = React.useCallback(async () => {
    const patient = JSON.parse(
      localStorage.getItem("persist:conecta:home-microfrontend/patient")
    );

    if (patient !== null) {
      const limitedInterval = await setInterval(() => {
        dispatch(getHomeStore());
        dispatch(getScheduleStore());
      }, 3500);
      async () => {
        await clearInterval(limitedInterval);
      };
    } 
    return () => {
      clearInterval(limitedInterval);
    }
  }, [dispatch, setInterval, clearInterval]);

  React.useEffect(() => {
    changeUpdateHomeStore();
  }, [changeUpdateHomeStore]);

  const loading = useSelector((state) => state.utilsReducer.loading);
  const whiteLabelOptions = useSelector(
    (state) => state.utilsReducer.whiteLabelOptions
  );
  const changePlanUserB2B = useSelector(
    (state) => state.utilsReducer.changePlanUserB2B
  );
  const menuVisible = useSelector((state) => state.utilsReducer.menuVisible);
  const user = useSelector((state) => state.userReducer.user);
  const openScheduleModal = useSelector(
    (state) => state.schedule.openScheduleModal
  );
  const hasTelemedicina = useSelector(
    (state) => state.userReducer.telemedicina
  );
  const optionsVisible = useSelector(
    (state) => state.utilsReducer.optionsVisible
  );

  const credentials = getCredentials();

  React.useEffect(() => {
    fetchAndActivate(remoteConfig)
      .then((value) => {
        console.log("updateRemoteConfig", value);
      })
      .catch((err) => {
        console.log("Error Remote Config", err);
      });
  }, [remoteConfig]);

  let provider = localStorage.getItem("@conecta:selected-provider");

  provider = JSON.parse(provider);

  const alert = useAlert();

  const options = [
    {
      id: "HOME",
      label: "Meu perfil",
      action: () => goToPerfil(),
    },
    {
      id: "DEPENDENTES",
      label: "Dependentes",
      action: () => goToDependents(),
    },
    {
      id: "ALTERAR_CONTRATO",
      label: "Alterar contrato",
      action: () => goToChangePlan(),
    },
    {
      id: "COMO_CANCELAR",
      label: "Como cancelar",
      action: () => goToCancel(),
    },
    {
      id: "TROCAR_PROVEDOR",
      label: "Trocar provedor",
      action: () => goToProvider(),
    },
    // {
    //   id: "TERMO_PRIVACIDADE",
    //   label: "Privacidade",
    //   action: () => goToPrivacy(),
    // },
    {
      id: "TROCAR_CARTAO_DE_CREDITO",
      label: "Trocar cartão de crédito",
      action: () => goToChangeCreditCard(),
    },
    {
      id: "SAIR",
      label: "Sair",
      action: () => logout(),
    },
  ];

  useEffect(() => {
    setAlert(alert);
  }, [alert]);

  function goToChangePlan() {
    if (provider) {
      let idConvenio = provider.id_convenio;
      dispatch(changePlan(idConvenio));
      dispatch(setVisibleOptions(!optionsVisible));
    } else {
      dispatch(changePlan());
      dispatch(setVisibleOptions(!optionsVisible));
    }
  }

  function goToCancel() {
    push("/cancelar");
    dispatch(setVisibleOptions(!optionsVisible));
  }

  function goToPerfil() {
    if (hasTelemedicina) {
      if (!moduleHomeMicrofrontendEnabled) {
        push("/home");
      }
    } else {
      push("/telepsicologia");
    }

    if (moduleHomeMicrofrontendEnabled) {
      localStorage.setItem("@home:open-profile-side", "true");
    } else {
      dispatch(openModalUser(true));
      dispatch(setVisibleOptions(!optionsVisible));
    }
  }

  function goToDependents() {
    push("/dependentes");
    dispatch(setVisibleOptions(!optionsVisible));
  }

  function goToChangeCreditCard() {
    push("/trocar-cartao");
    dispatch(setVisibleOptions(!optionsVisible));
  }

  function goToBuy() {
    push("/comprar");
    dispatch(setVisibleOptions(!optionsVisible));
  }

  function goToPrivacy() {
    push("/privacidade");
    dispatch(setVisibleOptions(!optionsVisible));
  }

  function goToProvider() {
    push("/provedores");
    localStorage.removeItem("@conecta:selected-provider");
    localStorage.removeItem("@selected_provider_number_card");
    dispatch(setVisibleOptions(!optionsVisible));
  }

  function logout() {
    clearCredentials();
    window.location.href = LOGIN_ROUTE;
    dispatch(setVisibleOptions(!optionsVisible));
  }

  function getMessage() {
    const dateNow = new Date();
    const hour = dateNow.getHours();

    if (hour < 5) {
      return "Boa noite";
    }

    if (hour < 12) {
      return "Bom dia";
    }

    if (hour < 18) {
      return "Boa tarde";
    }

    return "Bem-vindo";
  }

  function toggleOptions() {
    dispatch(setVisibleOptions(!optionsVisible));
  }

  function closeModalConfirm() {
    dispatch(modalPlanB2B(false));
  }

  function acceptedModalConfirm() {
    dispatch(modalPlanB2B(false));
    dispatch(generateBuyToken());
  }

  function getCurrentOptions() {
    const hasPrivatePlan =
      provider.id_convenio == process.env.REACT_APP_ID_AGREEMENT_PARTICULAR;

    const isPlanAvulso = provider.subgrupo === "PLANO AVULSO B2C";

    if (isPlanAvulso)
      return options.filter((elem) => elem.id !== "TROCAR_CARTAO_DE_CREDITO");

    if (!hasPrivatePlan)
      return options.filter(
        (elem) =>
          elem.id !== "COMO_CANCELAR" && elem.id !== "TROCAR_CARTAO_DE_CREDITO"
      );

    return options;
  }

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <GlobalStyles />
        <Routes />

        {changePlanUserB2B && (
          <Modal isOpen={true}>
            <ModalConfirm>
              <h1>Deseja continuar ?</h1>
              <p>
                Você esta logado com um contrato corporativo que não permite
                alterações. Deseja adquirir um contrato particular?
              </p>
              <Buttons>
                <Button outline onClick={closeModalConfirm}>
                  Cancelar
                </Button>
                <Button contained onClick={acceptedModalConfirm}>
                  Continuar
                </Button>
              </Buttons>
            </ModalConfirm>
          </Modal>
        )}

        {!moduleHomeMicrofrontendEnabled && menuVisible && (
          <User>
            <Message white={whiteLabelOptions}>
              Olá, {getMessage()}. <br />{" "}
              <b>{`${user.name || ""} ${user.lastName || ""}`}</b>
            </Message>
            <ImageUser
              onClick={toggleOptions}
              src={`${user.image || "/img/default.png"}`}
              onError={(e) => (e.target.src = "/img/default.png")}
            />
          </User>
        )}

        {optionsVisible && (
          <Options>
            {getCurrentOptions().map((option, key) => (
              <OptionItem key={key} onClick={() => option.action()}>
                {option.label}
              </OptionItem>
            ))}
          </Options>
        )}
        {user.empresas && (
          <SchedulerItem
            onClose={() => dispatch(setScheduleModal(false))}
            patient={{
              documentType: user.documentType,
              document: user.document,
              citizenship:
                user.documentType === "CPF" ? "brasileiro" : "estrangeiro",
              gender: user.sex,
              pregnant: false,
              name: `${user.name} ${user.lastName}`,
              birthDate: user.birthDate,
              phone: user.phone,
              email: user.email,
            }}
            eligibility={{
              id_convenio: user.empresas[0]?.id_convenio,
              id_cliente: user.empresas[0]?.id_cliente,
              cod_convenio: user.empresas[0]?.cod_convenio,
              cod_plano: user.empresas[0]?.cod_plano,
              cod_escritorio: user.empresas[0]?.cod_escritorio,
              nome: user.empresas[0]?.nome_cliente,
              image: "",
            }}
            visible={openScheduleModal}
            authToken={credentials.token}
            patientToken={credentials.patientToken}
          />
        )}
        {loading && <Loading />}
      </Container>
    </ThemeProvider>
  );
}
