import {
  SET_HISTORY_LIST
} from "../actions/action-types";

const initialState = {
  historyList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_HISTORY_LIST:
      return {
        ...state,
        historyList: action.payload,
      };
    default:
      return state;
  }
};
