import styled from "styled-components";
import { Title } from "./styles";

const TitleLogin = styled(Title)`
    text-align: center;
`;

const TitleRegister = styled(Title)`
    text-align: left;
    color: #004f92;
`;

export { TitleLogin, TitleRegister };
