import styled, { css } from "styled-components";
import Mask from "react-input-mask";

export const Container = styled.div`
  width: 700px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const LabelLoading = styled.p`
  margin: auto !important;
`;

export const LabelDiscount = styled.div`
  color: ${(props) => (props.color ? props.color : "#000")};
  font-size: ${(props) => (props.size ? props.size : "14px")};
  font-weight: ${(props) => (props.size ? "normal" : "bold")};
`;

export const Descount = styled.div`
  display: flex;
  flex-direction: column;

  button {
    width: 50%;
    height: 30px;
    background-color: #e0832c;
    color: white;
    border-radius: 25px;
    font-weight: bolder;
    cursor: pointer;
    margin: 10px 0 20px;
  }

  label {
    text-transform: uppercase;
    color: #787878;
    margin: 0 0 5px;
  }

  input {
    width: 50%;
    padding: 10px 10px;
    border: 1px solid #d6d6d6;
    border-radius: 4px;
  }
`;

export const Titles = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 20px 0;

  @media (max-width: 1024px) {
    width: 100%;
    align-items: center;
    text-align: center;
  }
`;

export const Title = styled.h2`
  margin-bottom: 5px;
  font-size: 18px;
`;

export const SubTitle = styled.p`
  color: #707070;
`;

export const Main = styled.div`
  @media (max-width: 1024px) {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

export const DivButtonPayment = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonPayment = styled.button`
  width: 200px;
  height: 38px;
  background-color: #e0832c;
  color: white;
  border-radius: 25px;
  font-weight: bolder;
  cursor: pointer;
`;

export const InformationPlan = styled.div`
  box-shadow: 0px 0px 9px 0px rgba(150, 150, 150, 1);
  display: flex;
  flex-direction: row;
  width: 700px;
  min-height: 500px;
  margin: 20px 0;
  background: #fff;

  @media (max-width: 1024px) {
    width: 80%;
    display: flex;
    flex-direction: column;
    min-height: auto;
  }
`;

export const Plan = styled.div`
  position: relative;
  width: 325px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  border-right: 1px solid #c7c7c7;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const TitlePlan = styled.h2`
  font-size: 25px;
`;

export const SubTitlePlan = styled.p`
  font-size: 14px;
  color: #707070;
`;

export const OldPrice = styled.div`
  margin: 25px 0 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  color: #707070;

  b {
    text-decoration: line-through red;

    margin-left: 5px;
    font-size: 18px;
    color: #707070;
  }
`;

export const NewPrice = styled.div`
  display: flex;
  justify-content: center;
`;

export const Price = styled.p`
  font-weight: bold;
  font-size: 35px;
  color: #000;
`;

export const DetailsPrice = styled.div`
  margin: 7px 5px 0;
  width: 30px;
  display: flex;
  flex-direction: column;
  p {
    font-weight: bold;
  }
  span {
    font-weight: lighter;
    color: #707070;
    margin-left: 5px;
    font-size: 12px;
    width: 80px;
  }
`;

export const ListAdvantages = styled.div`
  margin: 10px 0;
  width: 80%;
`;

export const Advantage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 28px 0;
`;

export const IconAdvantage = styled.img`
  width: 15px;
`;

export const LabelAdvantage = styled.p`
  color: #707070;
  margin-left: 5px;
  font-size: 15px;
`;

export const ButtonPlan = styled.button`
  width: 220px;
  height: 48px;
  background-color: #e0832c;
  color: white;
  border-radius: 25px;
  font-weight: bolder;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
`;

export const DetailsPlan = styled.div`
  width: 55%;
  padding: 20px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const TitleDetails = styled.div`
  font-weight: bold;
  margin: 10px 0 0;
  color: #2196f3;
  font-size: 23px;
`;

export const SubTitlesDetails = styled.div`
  margin: 20px 0;

  h4 {
    margin: 5px 0 0;
    font-size: 18px;
    color: #707070;
  }

  p {
    margin: 5px 0 0;
    font-size: 16px;
    color: #707070;
  }
`;

export const ChoiceDependentes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  p {
    width: 70%;
    font-size: 14px;
    color: #707070;
  }
`;

export const TitleFinanceOfficer = styled.div`
  font-size: 14px;
  color: #707070;
`;
export const FinanceOfficerError = styled.div`
  font-size: 14px;
  color: #F53748;
`;

export const SelectFinanceOfficer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
`;

export const CountDependents = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
    font-weight: bolder;
    font-size: 14px;
  }
`;

export const AddDependentCount = styled.button`
  border: 1px solid #999;
  border-radius: 3px;
  cursor: pointer;
  padding: 3px 7px;
  margin: 0 10px;
`;

export const PricePlan = styled.div`
  background: #f2f5f5;
  padding: 20px 10px;
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const LabelPrice = styled.div`
  font-size: 16px;
  color: #333;
  font-weight: bolder;
`;

export const ValuePrice = styled.div`
  font-size: 16px;
  color: #333;
  font-weight: bolder;

  span {
    font-size: 12px;
    color: #707070;
  }
`;

export const ChangePlan = styled.div`
  width: 325px;
  height: 500px;
  box-shadow: 0px 0px 2px 2px rgba(230, 230, 230, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const DivChangePlan = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 90%;
  min-height: 150px;
  border: 1px solid #e0832c;
  border-radius: 3px;

  ${(props) => props.contained && contained}
  ${(props) => props.outline && outline}

  h3 {
    width: 100%;
    text-align: center;
    padding: 5px 0px 5px 0px;
    background-color: #e0832c;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 0px;
  }
`;

const contained = css`
  background: #f79b45;
  color: #fff;
`;

const outline = css`
  border-bottom: 1px solid #e0832c;
  color: #000;
`;

export const IconDiv = styled.div`
  margin: 10px 0;
`;

export const InfoChangePlan = styled.div`
  padding: 0px 10px;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  text-align: left;

  h4 {
    font-size: 18px;
    margin: 0;
  }
`;

export const LabelDisclaimer = styled.p`
  color: #707070;
  margin: 50px 20px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
`;
