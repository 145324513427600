/* eslint-disable */
var ESITEF_URL =
    "https://esitef-homologacao.softwareexpress.com.br/e-sitef/api/v1/store/",
  CARD_NUMBER = "esitef-cardnumber",
  CARD_EXPIRY_DATE = "esitef-cardexpirydate",
  CARD_EXPIRY_MONTH = "esitef-cardexpirymonth",
  CARD_EXPIRY_YEAR = "esitef-cardexpiryyear";
function esitefGetLocale(e) {
  return e &&
    e.locale &&
    ("pt" === e.locale || "en" === e.locale || "es" === e.locale)
    ? e.locale
    : "pt";
}
function esitefGetMessage(e, t, a, i) {
  return "es" === e ? a : "en" === e ? i : t;
}
function esitefGetField(e) {
  var t = document.getElementsByClassName(e);
  return 1 === t.length && t[0] && t[0].value ? t[0].value : null;
}
function esitefValidateRequired(e, t) {
  if (!e) return esitefGetMessage(t, "ausente", "ausente", "missing");
}
function esitefValidateIsFunction(e, t) {
  if ("function" != typeof e)
    return esitefGetMessage(
      t,
      "deve ser uma function",
      "debe ser una function",
      "must be a function"
    );
}
function esitefValidateNumeric(e, t) {
  if (!e.match(/[0-9]+/i))
    return esitefGetMessage(
      t,
      "deve conter apenas nÃºmeros",
      "debe contener sÃ³lo nÃºmeros",
      "must contain only numbers"
    );
}
function esitefValidateMonth(e, t) {
  if (!e.match(/^0[1-9]|1[0-2]$/))
    return esitefGetMessage(
      t,
      "deve respeitar o formato MM",
      "debe respetar el formato MM",
      "must respect the format MM"
    );
}
function esitefValidateYear(e, t) {
  if (!e.match(/^[0-9][0-9]$/))
    return esitefGetMessage(
      t,
      "deve respeitar o formato AA",
      "debe respetar el formato AA",
      "must respect the format YY"
    );
}
function esitefValidateDate(e, t) {
  if (!e.match(/^(0[1-9]|1[0-2])[0-9][0-9]$/))
    return esitefGetMessage(
      t,
      "deve respeitar o formato MMAA",
      "debe respetar el formato MMAA",
      "must respect the format MMYY"
    );
}
function esitefValidateLengthEquals(a) {
  return function (e, t) {
    if (e.length != a)
      return esitefGetMessage(
        t,
        "deve conter " + a + " caracteres",
        "debe contener " + a + " caracteres",
        "must contain " + a + " characters"
      );
  };
}
function esitefValidateMaxLength(a) {
  return function (e, t) {
    if (e.length > a)
      return esitefGetMessage(
        t,
        "deve conter um mÃ¡ximo de " + a + " caracteres",
        "debe contener un mÃ¡ximo de " + a + " caracteres",
        "must contain a maximum of " + a + " characters"
      );
  };
}
function esitefValidateMinLength(a) {
  return function (e, t) {
    if (e.length < a)
      return esitefGetMessage(
        t,
        "deve conter um mÃ­nimo de " + a + " caracteres",
        "debe contener un mÃ­nimo de " + a + " caracteres",
        "must contain a minimum of " + a + " characters"
      );
  };
}
function esitefValidateParam(e, t, a, i, s) {
  for (var r = 0; r < s.length; r++) {
    var n = s[r](e, i);
    if (n)
      return a.push({ field: t, cause: n }), void console.error(t + " " + n);
  }
}
export function esitefValidate(e) {
  var t = [],
    a = esitefGetLocale(e);
  esitefValidateParam(e, "request", t, a, [esitefValidateRequired]);
  var i = e || {};
  esitefValidateParam(i.onSuccess, "onSuccess", t, a, [
    esitefValidateRequired,
    esitefValidateIsFunction,
  ]),
    esitefValidateParam(i.onFailure, "onFailure", t, a, [
      esitefValidateRequired,
      esitefValidateIsFunction,
    ]),
    esitefValidateParam(i.onInvalid, "onInvalid", t, a, [
      esitefValidateRequired,
      esitefValidateIsFunction,
    ]),
    esitefValidateParam(i.nita, "nita", t, a, [
      esitefValidateRequired,
      esitefValidateLengthEquals(65),
    ]),
    esitefValidateParam(i.merchantId, "merchantId", t, a, [
      esitefValidateRequired,
      esitefValidateMaxLength(15),
    ]),
    esitefValidateParam(i.storeToken, "storeToken", t, a, [
      esitefValidateRequired,
      esitefValidateLengthEquals(66),
    ]),
    esitefValidateParam(i.authorizerId, "authorizerId", t, a, [
      esitefValidateRequired,
      esitefValidateNumeric,
      esitefValidateMaxLength(4),
    ]),
    esitefValidateParam(esitefGetField(CARD_NUMBER), CARD_NUMBER, t, a, [
      esitefValidateRequired,
      esitefValidateNumeric,
      esitefValidateMinLength(13),
      esitefValidateMaxLength(19),
    ]);
  var s = esitefGetField(CARD_EXPIRY_DATE),
    r = esitefGetField(CARD_EXPIRY_MONTH),
    n = esitefGetField(CARD_EXPIRY_YEAR);
  return (
    r || n
      ? (esitefValidateParam(r, CARD_EXPIRY_MONTH, t, a, [
          esitefValidateRequired,
          esitefValidateMonth,
        ]),
        esitefValidateParam(n, CARD_EXPIRY_YEAR, t, a, [
          esitefValidateRequired,
          esitefValidateYear,
        ]))
      : esitefValidateParam(s, CARD_EXPIRY_DATE, t, a, [
          esitefValidateRequired,
          esitefValidateDate,
        ]),
    t
  );
}
export function esitefStore(e) {
  var t = esitefValidate(e);
  if (0 < t.length)
    e && e.onInvalid && "function" == typeof e.onInvalid && e.onInvalid(t);
  else {
    var a = new XMLHttpRequest();
    a.onreadystatechange = function () {
      if (4 === this.readyState) {
        var t = {
          code: "2",
          message: esitefGetMessage(
            esitefGetLocale(e),
            "Erro de comunicaÃ§Ã£o JS",
            "Error de comunicaciÃ³n JS",
            "JS communication error"
          ),
        };
        try {
          t = JSON.parse(this.responseText);
        } catch (e) {
          console.error(t.message);
        }
        200 === this.status ? e.onSuccess(t) : e.onFailure(t);
      }
    };
    var i = esitefGetField(CARD_EXPIRY_MONTH),
      s = esitefGetField(CARD_EXPIRY_YEAR),
      r = esitefGetField(CARD_EXPIRY_DATE);
    i && s && (r = i + s);
    var n = {
      authorizer_id: e.authorizerId,
      card: { number: esitefGetField(CARD_NUMBER), expiry_date: r },
    };
    a.open("PUT", ESITEF_URL + e.nita, !0),
      a.setRequestHeader("Content-Type", "application/json"),
      a.setRequestHeader("Accept", "application/json"),
      a.setRequestHeader("merchant_id", e.merchantId),
      a.setRequestHeader("store_token", e.storeToken),
      a.send(JSON.stringify(n));
  }
}