import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import Loading from "../components/loading";
import { verifyAuth, setLogged, setMenuVisible } from "../redux/actions/utils.actions";
import { useDispatch, useSelector } from "react-redux";
import { clearCredentials } from "../utils/credentials";

export default function PublicRoute(props) {
  const isLoaded = useSelector((state) => state.utilsReducer.loaded);
  const isLogged = useSelector((state) => state.utilsReducer.logged);
  const homePage = useSelector((state) => state.userReducer.homePage);
  const dispatch = useDispatch();
  const isPaymentConfirmed = props.location.pathname == "/login/payment_confirmed";

  useEffect(() => {
    dispatch(verifyAuth());
  }, []);

  function returnPublicRoute() {
    if (isPaymentConfirmed) {
      localStorage.removeItem("@conecta:selected-provider");
      localStorage.removeItem("@selected_provider_number_card")
      clearCredentials();
      dispatch(setLogged(false))
      dispatch(setMenuVisible(false))
      return <Redirect to="/login" />;
    }
    if (isLogged) {
      return <Redirect to={homePage} />;
    }

    return <Route {...props} />;
  }

  return !isLoaded ? <Loading /> : returnPublicRoute();
}
