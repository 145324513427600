import React, { useState, useEffect } from "react"
import moment from "moment"

import {
  Container,
  ButtonBack,
  DivButtons,
  LabelConfirm,
  ButtonCancel,
  ButtonConfirm,
  LabelBack,
  IconBack,
} from "./styles"

import { useSelector, useDispatch } from "react-redux"
import Modal from "../../../components/modal-confirm"

export default function App(props) {
  const appointmentOld = useSelector((state) => state.schedule.appointmentOld)

  const dispatch = useDispatch()

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      handleClose={props.handleClose}
    >
      <Container>
        <ButtonBack type="button" onClick={() => props.onClose()}>
          <IconBack src="img/icons/back-icon.png"></IconBack>
          <LabelBack>Voltar</LabelBack>
        </ButtonBack>

        <LabelConfirm>
          Você possui uma consulta agendada para o dia{" "}
          {moment(appointmentOld.startAt).format("DD/MM")} às{" "}
          {moment(appointmentOld.startAt).format("HH:mm")}. Este agendamento
          será substituído pela opção atual.
        </LabelConfirm>

        <DivButtons>
          <ButtonCancel onClick={() => props.handleClose(false)}>
            Cancelar
          </ButtonCancel>
          <ButtonConfirm
            onClick={() => {
              props.handleClose(true)
            }}
          >
            Substituir
          </ButtonConfirm>
        </DivButtons>
      </Container>
    </Modal>
  )
}
