import React, { useState } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";

import CardsTerms from "../../components/cards-terms";
import ContentTerms from "../../components/content-terms";
import Container from "../../container";
import { getRemoteConfigValue } from '../../hooks/RemoteConfig';
import { getPatientTerms } from "../../redux/actions/termsProvider.actions";
import * as S from "./styles";

export default function Privacy() {
  const dispatch = useDispatch();
  const hasTerm = !!useParams().term;
  const term_menu = getRemoteConfigValue("terms_menu");
  const term = JSON.parse(localStorage.getItem("termSelected"));
  const setTerm = (term) => localStorage.setItem("termSelected", JSON.stringify(term));

  const getTerms = React.useCallback(() => {
    dispatch(getPatientTerms());
  }, [dispatch]);

  React.useEffect(() => {
    if (!term) {
      getTerms();
    }
  }, [term]);

  React.useEffect(() => {
    setTerm({});
  }, [])

  return (
    <Container>
      <S.Container>
        {hasTerm ? <ContentTerms term={term} /> : <CardsTerms onSelectTerm={(v) => setTerm(v)} terms={term_menu} />}
      </S.Container>
    </Container>
  );
}
