import React from "react"

import { useDispatch, useSelector } from "react-redux";

import {
  modalConfirmAccepted,
  modalConfirmDismissed,
} from "../../../../redux/actions/utils.actions";

import {
  Container,
  DivButtons,
  LabelConfirm,
  ButtonCancel,
  ButtonConfirm,
  OverlayContainer
} from "./styles"

import Modal from "../../../../components/modal-confirm"

export default function ModalConfirm() {
  const dispatch = useDispatch();
  const { text, cancelText, visible, confirmText } = useSelector(
    (state) => state.utilsReducer.modalConfirm
  );

  const onDismiss = () => {
    dispatch(modalConfirmDismissed());
  };

  const onAccept = () => {
    dispatch(modalConfirmAccepted());
  };

  return (
    <Modal isOpen={visible}>
      <OverlayContainer>
        <Container>
          <LabelConfirm>{text}</LabelConfirm>

          <DivButtons>
            <ButtonCancel onClick={onDismiss}>{cancelText}</ButtonCancel>
            <ButtonConfirm onClick={onAccept}>{confirmText}</ButtonConfirm>
          </DivButtons>
        </Container>
      </OverlayContainer>
    </Modal>
  );
}
