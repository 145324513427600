export default {
  1: {
    description: "Contrato Individual",
    namePlan: "Individual",
    typePlan: "Contrato anual",
    oldPrice: 120,
    newPrice: 59,
    advantages: [
      "Acesso imediato",
      "Seu atendimento de qualquer lugar",
      "Atendimento 24h por dia",
      "Para uso exclusivo de 1 pessoa",
      "Consultas ilimitadas durante o período da pandemia"
    ],
    labelTypePlan: "mensais",
    single: true,
  },
  2: {
    description: "Contrato Familiar 1 dependente",
    namePlan: "Familiar",
    typePlan: "Contrato anual",
    oldPrice: 100,
    newPrice: 39,
    advantages: [
      "Acesso imediato",
      "Seu atendimento de qualquer lugar",
      "Atendimento 24h por dia",
      "Adicione até 9 membros",
      "Consultas ilimitadas durante o período da pandemia"
    ],
    labelTypePlan: "mensais \n por membro",
  },
  3: {
    description: "Consulta Avulsa",
    namePlan: "Consulta Avulsa",
    typePlan: "Consulta Avulsa",
    newPrice: 270,
    advantages: [
      "Acesso imediato",
      "Seu atendimento de qualquer lugar",
      "Atendimento 24h por dia",
    ],
    labelTypePlan: "pagamento único",
    disclaimer: "* Após contratação, você terá 24 horas para realizar a consulta.",
    single: true,
    detached: true
  },
};
