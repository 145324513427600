import { call, put } from "redux-saga/effects";
import { setLoading } from "../actions/utils.actions";
import { show } from "../../services/alert";
import { apiCatalog as api } from "../../services/api";
import { setSpecialist, setSpecialties } from "../actions/specialist.actions";

export function* getAllDoctors({ payload }) {
  yield put(setLoading(true));

  try {
    const url = `${process.env.REACT_APP_API_CATALOGO}/v2/specialists/public?active=1&specialty=${payload.specialty}&health_insurance=${process.env.REACT_APP_HEALTH_INSURANCE_PLAN_SPECIALIST}&limit=12&page=${payload.page}`;

    const headers = {
      "X-Tenant-Id": "einstein",
    };

    const request = () => api.get(url, { headers });

    const specialist = yield call(request);

    if (specialist) {
      yield put(setSpecialist(specialist.data.data));
    }
  } catch (error) {
    show(error.response.data.message);
  } finally {
    yield put(setLoading(false));
  }
}

export function* getAllSpecialties() {
  yield put(setLoading(true));

  try {
    const headers = {
      "X-Tenant-Id": "einstein",
    };

    const request = () =>
      api.get("/specialties?active=true&limit=0", { headers });
    const specialties = yield call(request);

    if (specialties && specialties.data) {
      yield put(setSpecialties(specialties.data));
    }
  } catch (error) {
    show(error.response.data.message);
  } finally {
    yield put(setLoading(false));
  }
}
