export const formatPhone = (number) => {
  if (!number) return "";
  const cleanNumber = number.replace(/[^\d]/g, "");
  return cleanNumber.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3"); // (12) 1234-1234
};

export const formatTitle = (text) => {
  if (!text) return "";
  return text.replace(/#/g, "");
};

export function formatDescription(text) {
  if (!text) return "";

  const startTag = "<b>";
  const endTag = "</b>";
  let count = 0;

  // Exemplo de uso
  // Entrada 'Entre em #contato# com o nosso #suporte técnico pelos canais de atendimento abaixo:'
  // Saída: 'Entre em <b>contato</b> com o nosso <b>suporte técnico pelos canais de atendimento abaixo:</b>'

  // Loop pela string procurando o padrão "#"
  for (let i = 0; i <= text.length - 1; i++) {
    if (text[i] === "#") {
      // Se encontrou o padrão, adiciona a tag de negrito
      text = `${text.slice(0, i)}${
        count % 2 === 0 ? startTag : endTag
      }${text.slice(i + 1)}`;
      count++;
      i += startTag.length - 1; // pula a tag adicionada
    }
  }

  // adiciona a tag de fechamento de negrito se necessário
  if (count % 2 !== 0) text += endTag;

  return text;
}
