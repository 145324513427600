import { call, put } from "redux-saga/effects";
import {
  setLoading
} from "../actions/utils.actions";

import { show } from "../../services/alert";
import { setCorporateCardData } from "../actions/corporateCard.actions";
import api from "../../services/apiPatient";
import { push } from "../../services/navigate";

export function* getCorporateCardRequest() {
  yield put(setLoading(true));

  try {
    const request = () => api.get(`/corporate/card-info`);
    const { data } = yield call(request);

    yield put(setCorporateCardData(data));

  } catch (error) {
    if (error?.response?.data?.message) show(error.response.data.message);
    else show('Não foi possível obter os dados do cartão, por favor tente novamente mais tarde.');

    yield push("/home");
  } finally {
    yield put(setLoading(false));
  }
}