import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Texts = styled.div`
  margin: 50px 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    padding: 0 40px;
    font-size: 35px;
  }

  p {
    margin: 30px 0 0;
    padding: 0 150px;
    font-size: 18px;
    line-height: 1.8;
    color: #707070;
    text-align: justify;
  }

  @media (max-width: 1267px) {
    h1 {
      width: 100vw;
      text-align: center;
    }
    p {
      width: 100vw;
      text-align: justify;
      padding: 0 40px;
    }
  };

  @media (max-width: 833px) {
    h1 {
      font-size: 25px;
    }

    p {
      font-size: 16px;
    }
  };
`;

export const Advantages = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0 150px;
  margin: 0 0 40px;
  width: 100vw;

  @media (max-width: 1267px) {
    width: 100vw;
    padding: 0 40px;
  }

  @media (max-width: 1032px) {
    flex-direction: column;
  }
`;

export const ListAdvantages = styled.ul`
  display: grid;
  margin: 0 60px 0 0;
  padding-left: 10px;
  
  li {
    margin: 0 0 30px;
    font-size: 18px;
    color: #707070;
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  @media (max-width: 1032px) {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin: 0;
    margin-bottom: 30px;

    li {
      margin: 0;
    }
  }

  @media (max-width: 833px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin: 0;
    margin-bottom: 30px;
    
    li {
      margin: 0;
      font-size: 16px;
    }
  }

  @media (max-width: 531px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Circle = styled.div`
  width: 7px;
  height: 7px;
  background: #303030;
  border-radius: 50px;
  margin-right: 10px;
`;

export const ImageAdvantage = styled.img`
  width: 734px;

  @media (max-width: 1032px) {
    width: 100%;
  }
`;
