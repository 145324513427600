import styled, { css } from "styled-components";
import Mask from "react-input-mask";

export const DivInput = styled.div`
  margin: ${(props) => (props.margin ? props.margin : "5px 0")};
  height: ${(props) => (props.height ? props.height : "50px")};
  position: relative;
`;

export const Input = styled(Mask)`
  background: transparent;
  border: 0;
  border-bottom: 1px solid #000;
  outline: none;

  min-width: ${(props) => props.width};

  ${(props) => props.isCreditCardDate && css`
    width: 55px;
  `}

  ${(props) => props.isCreditCardNumber && css`
    width: 160px;
  `}

  font-size: 12px;
  border-radius: 0;
  padding: 5px 0;
  color: ${(props) => (props.readOnlyColor ? "#707070" : "#000")};
  &:focus {
    border-bottom: 1px solid #000;
  }

  &::placeholder {
    color: transparent;
  }

  &:focus + label,
  &:not(:placeholder-shown) + label {
    font-size: 10px;
    margin-top: 0;
    color: #000;
  }

  @media (max-width: 1024px) {
    min-width: 270px;
    width: 350px;
    ${(props) => props.isCreditCardDate && css`
      min-width: 55px;
      width: 55px;
    `}
    ${(props) => props.isCreditCardNumber && css`
      min-width: 160px;
      width: 160px;
    `}
  }
  @media (max-width: 425px) {
    min-width: 270px;
    width: 270px;
    ${(props) => props.isCreditCardDate && css`
      min-width: 55px;
      width: 55px;
    `}
    ${(props) => props.isCreditCardNumber && css`
      min-width: 160px;
      width: 160px;
    `}
  }
`;

export const Label = styled.label`
  font-size: 14px;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 13px;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
`;

export const LabelFloat = styled.div`
  position: relative;
  padding-top: 13px;
`;

export const ErrorMessage = styled.p`
  color: ${(props) => (props.label ? "#444" : "red")};
  margin: 1px 0 0;
  font-size: 10px;
  max-width: 260px;
  width: ${props => props.width};
`;

export const ShowPassword = styled.div`
  position: absolute;
  top: 15px;
  right: 5px;
  cursor: pointer;
`;
