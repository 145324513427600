import styled from "styled-components";

export const Header = styled.header`
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #d0dade;

  & > h1 {
    font-size: 32px;
  }

  @media (max-width: 960px) {
    height: 45px;

    & > h1 {
      font-size: 20px;
    }
  }
`;

export const ContainerContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 20px auto 0 15px;

  &.title {
    margin: 40px auto 0 15px;
  }

  &.read-term {
    padding-right: 15px;
  }

`;

export const ContainerTerm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 650px;

  @media (max-width: 1050px) {
    max-width: 100%;
  }
`;

export const Link = styled.a`
  color: #1774de;
  text-decoration: underline;
  font-weight: 600;
  cursor: hand;
`;

export const Text = styled.p`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 22px;

  & > a, button {
    color: #1774de;
    text-decoration: underline;
    font-weight: 600;
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const Column = styled.div`
  flex: 1;
`;

export const ColumnWidthCardUser = styled.div`
  width: 380px;
  @media (max-width: 1050px) {
    display: none;
  }
`;

export const CardUserInfo = styled.aside`
  width: 336px;
  max-width: 100%;
  background: #f8fafa;
  border-radius: 16px;
  padding: 16px 21px;
  margin: 5px 15px 35px 15px;

  @media (min-width: 1050px) {
    position: absolute;
    right: 35px;
    margin-top: -50px;
  }
  @media (max-width: 1050px) {
    width: 95%;
  }
`;

export const UserData = styled.div`
  & > span {
    color: #7f99a6;
    font-weight: 700;
    font-size: 14px;
  }

  & > h3 {
    font-weight: 600;
    font-size: 16px;
    color: #415b68;
  }
`;

export const UserTimeViewed = styled.p`
  margin-top: 24px;
  font-weight: 700;
  font-size: 14px;
  color: #415b68;

  & > span {
    font-weight: 500;
    font-size: 14px;
    color: #415b68;
  }
`;

export const CommunicationTermWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
`;

export const Info = styled.div`
  flex: 1;
`;

export const SwitchContainer = styled.div`
  flex: 0 0 auto;
`;

export const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
`;

export const SliderBefore = styled.span`
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
`;

export const Input = styled.input`
  display: none;

  &:checked + ${Slider} {
    background-color: #2196f3;
  }

  &:checked + ${Slider} ${SliderBefore} {
    transform: translateX(26px);
  }
`;

export const CommunicationContainer = styled.div`
  padding: 24px;
  max-width: 100%;
  background-color: #ffffff;
  height: 100%;

  @media (max-width: 960px) {
    margin-top: 55px;
  }
`;

export const ModalTitle = styled.h1`
  font-size: 24px;
  margin-bottom: 10px;
`;

export const ModalSubtitle = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
`;

export const ModalImage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
`;

export const ModalButton = styled.button`
  min-width: 120px;
  background-color: ${({ color }) => color || '#007bff'};
  color: ${({ outlineColor }) => outlineColor || '#ffffff'};
  border: ${({ outlineColor }) => `solid ${outlineColor} 1px` || '#ffffff'};
  min-height: 45px;
  border-radius: 5px;
  cursor: pointer;
`;

export const ModalContainer = styled.div`
  width: 500px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  justify-content: flex-start;
`;

export const ModalCloseButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  width: 100%;
  text-align: right;
  cursor: pointer;
  color: #0076DA;
  * {
    color: #0076DA;
  }
`;
