import React from "react";
import {
  Container,
  ButtonBack,
  DivButtons,
  LabelConfirm,
  ButtonCancel,
  ButtonConfirm,
  LabelBack,
  IconBack,
} from "./styles";

import Modal from "../../../components/modal-confirm";

export default function App(props) {

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      handleClose={props.handleClose}
      dateSlot={props.dateSlot}
      hourSlot={props.hourSlot}
    >
      <Container>
        <ButtonBack type="button" onClick={() => props.onClose()}>
          <IconBack src="img/icons/back-icon.png"></IconBack>
          <LabelBack>Voltar</LabelBack>
        </ButtonBack>

        <LabelConfirm black>
         Você possui uma agendamento para:
        </LabelConfirm>

        <LabelConfirm>
          Data: <b>&nbsp;{props.dateSlot ? props.dateSlot : null}</b>
        </LabelConfirm>

        <LabelConfirm>
         Hora: <b>&nbsp;{props.hourSlot ? props.hourSlot : null}</b>
        </LabelConfirm>

        <LabelConfirm>
         Tem certeza que deseja <b>&nbsp;cancelar&nbsp;</b> o agendamento?
        </LabelConfirm>

        <DivButtons>
          <ButtonCancel onClick={() => props.handleClose(false)}>
            Não
          </ButtonCancel>
          <ButtonConfirm
            onClick={() => {
              props.handleClose(true);
            }}
          >
            Sim
          </ButtonConfirm>
        </DivButtons>
      </Container>
    </Modal>
  );
}
