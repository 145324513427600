import styled from "styled-components";

export const Container = styled.div``;

export const Main = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin: 70px 0 0 70px;

  @media (max-width: 1024px) {
    align-items: center;
    margin: 70px 0 0;
  }
`;

export const Titles = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

export const Title = styled.h4`
  margin-bottom: 5px;
`;

export const SubTitle = styled.p`
  color: #707070;
  width: 443px;
  margin: 10px 0 20px;
  line-height: 1.7;

  @media (max-width: 1024px) {
    width: 250px;
  }
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin: 30px 0 0;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 1024px) {
    margin: 10px 0;
  }
`;

export const LabelInfo = styled.div`
  color: #707070;
  margin: 0 25px 0 0;
`;

export const DivImageInfo = styled.div`
  width: 39px;
  height: 39px;
  background: #2196f3;
  border-radius: 45px;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageInfo = styled.div``;

export const Form = styled.form`
  margin: 20px 0 0;
  width: 450px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: 1024px) {
    width: 100%;
    align-items: center;
  }
`;

export const DivTextArea = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 15px 0;
  width: 100%;

  @media (max-width: 1024px) {
    width: 270px;
    align-items: center;
  }
`;

export const LabelTextArea = styled.p`
  color: #707070;
  font-size: 12px;
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  height: 135px;
  border: 0;
  border: 1px solid #707070;
  margin: 5px 0;
  background-color: transparent;
  resize: none;
`;

export const ErrorMessage = styled.p`
  color: red;
  width: 100%;
  padding: 5px 0;
  font-size: 10px;
`;

export const DivButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 1024px) {
    width: 270px;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  color: white;
  font-weight: bold;
  width: 100%;
  height: 40px;
  background: #2196f3;
  border-radius: 30px;

  @media (max-width: 1024px) {
    margin: 0 0 120px;
  }
`;
