const alert = {};

export function setAlert(provider) {
  if (provider) {
    alert.show = provider.show;
  }
}

export function show(message, button = false, buttonAction = false) {
  if(typeof message === "string"){
  if (button && buttonAction) {
    return alert.show(message, { button, buttonAction });
  }
  return alert.show(message);
}
}
