import React, { useEffect } from "react";

import { parseISO, format } from "date-fns";

import { AvailableHours, HourButton } from "./styles";

import { useSelector, useDispatch } from "react-redux";

import {
  setSelectedSlot,
  slotByMonth,
} from "../../redux/actions/schedule.actions";

function HoursAvailable() {
  const dispatch = useDispatch();
  const schedule = useSelector((state) => state.schedule.scheduleResult);
  const selectedSlot = useSelector((state) => state.schedule.selectedSlot);

  let date = useSelector((state) => state.schedule.daySelected);

  const _setSelectedSlot = (slot) => {
    dispatch(setSelectedSlot(slot));
  };

  date = format(date, "yyyy-MM-dd");

  const distinctSlots = [];
  schedule.map((slot) => {
    const exists = distinctSlots.some((item) => item.startAt == slot.startAt);
    if (!exists) {
      distinctSlots.push(slot);
    }
  });

  return (
    <AvailableHours>
      {distinctSlots &&
        distinctSlots.length > 0 &&
        distinctSlots.map((slot) => (
          <HourButton
            active={selectedSlot == slot}
            onClick={() => _setSelectedSlot(slot)}
          >
            {format(parseISO(slot.startAt), "HH:mm")}
          </HourButton>
        ))}
    </AvailableHours>
  );
}
export default HoursAvailable;
