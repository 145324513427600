import React, { useState, useEffect } from "react";
import {
  Container,
  Form,
  DivLogo,
  Logo,
  Inputs,
  DivInput,
  CustomInput,
  Input,
  Label,
  Button,
  ImageForm,
  ErrorMessage,
} from "./styles";

import { useHistory } from "react-router-dom";
import { FiMail } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { sendLinkRecovery } from "../../redux/actions/user.actions";

import useYup from "@usereact/use-yup";
import validationSchema from "./validate";
import { push } from "../../services/navigate";

export default function App() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [touched, setTouched] = useState(false);
  const [values, setValues] = useState({
    email: "",
  });

  let { errors } = useYup(values, validationSchema, {
    validateOnChange: true,
  });

  function handleSubmit(e) {
    e.preventDefault();

    if (!errors.email) {
      const data = {
        username: values.email,
      };

      localStorage.setItem("@email", values.email);

      dispatch(sendLinkRecovery(data));
    }
  }

  function handleChange(e) {
    const { name, value } = e.currentTarget;
    setTouched({
      ...touched,
      [name]: true,
    });
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function goToHome() {
    push("/home");
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <DivLogo onClick={goToHome}>
          <Logo src="img/logo-blue.png" />
        </DivLogo>

        <Inputs>
          <Label>
            Insira seu e-mail para enviarmos um link de redefinição de senha
          </Label>
          <DivInput>
            <CustomInput>
              <FiMail color="#707070" />
              <Input
                name="email"
                value={values.email}
                onChange={handleChange}
                placeholder="E-mail"
                type="text"
              />
            </CustomInput>
            <ErrorMessage>
              {touched["email"] && errors && errors.email}
            </ErrorMessage>
          </DivInput>
        </Inputs>

        <Button type="submit">Recuperar senha</Button>
      </Form>

      <ImageForm src="img/bg-provedores.png" />
    </Container>
  );
}
