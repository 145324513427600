import styled from "styled-components";

export const BottomSection = styled.div`
  width: 100%;

  & > div {
    border-top: 1px solid #dce1e5;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
    padding-top: 20px;
  }
  & > div > p {
    margin-top: 20px;
    text-align: left;
  }

`;

export const LoginSection = styled.div`
  max-width: 450px;
  min-width: 350px;
  display: flex;
  align-items: center;
`