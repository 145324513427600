import styled, { css } from "styled-components";

export const Container = styled.div`
  padding: 30px 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 1024px) {
    padding: 120px 20px 0;
  }
`;

export const Title = styled.h4`
    margin-bottom: 10px;
    text-align: center;
`;

export const SubTitle = styled.p`
    color: #707070;
    margin: 0 0 10px;
    line-height: 1.7;
    text-align: justify;
    text-align: center;
`;

export const InputContainer = styled.div`
  margin: 50px 0 50px;
`;

export const CardNumberContainer = styled.div`
  display: flex;
  align-items: center;

  position: relative;
`;

export const Image = styled.img`  
  margin-left: 10px;
  position: absolute;
  right: -60px;
`;

export const ExpirtationDateContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Slash = styled.strong`
  font-size: 20px;
  margin: 0 20px 0;
`;

export const Button = styled.button`
    cursor: pointer;
    color: white;
    font-weight: bold;
    height: 40px;
    background: #2196f3;
    border-radius: 30px;
    margin: 0 0 20px;
    width: 270px;

    ${props => props.disabled && css`
      background: #d3d3d3;
      pointer-events: none;
    `}

    @media (max-width: 1024px) {
      width: 200px;
    }
`;

export const ButtonBackHome = styled.button`
    cursor: pointer;
    color: #2196f3;
    width: 140px;
    height: 40px;
    font-weight: bold;
    background: #fff;

    border-radius: 30px;
    border: 2px solid #2196f3;

    width: 270px;
    margin: 0;
    
    @media (max-width: 1024px) {
      width: 200px;
    }
`;