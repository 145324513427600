import { all, call, put } from 'redux-saga/effects';
import {
  setLoading,
  setActiveStep,
  generateBuyToken,
  modalPlanB2B,
} from '../actions/utils.actions';
import { show } from '../../services/alert';
import { setPlans, setPlansCategory, setUrlPayment, setVoucher } from '../actions/buy.actions';

import api from '../../services/catalogoApi';

export function* getPlansApi() {
  try {
    const request = () => api.get(`/services/${process.env.REACT_APP_SERVICE_ID}`);
    const { data } = yield call(request);
    yield put(setPlans(data.plans));
  } catch (error) {
    if (error && error.response && error.response.data && error.response.data.message) {
      show(error.response.data.message);
    }
  }
}

export function* getPlansCategoryApi({ payload }) {
  try {
    const request = () => api.get(`/plans/category/${payload}`);
    const { data } = yield call(request);
    yield put(setPlansCategory(data));
  } catch (error) {
    if (error && error.response && error.response.data && error.response.data.message) {
      show(error.response.data.message);
    }
  }
}

export function* getCurrentPlan({ payload }) {
  const idConvenio = payload;
  yield put(setLoading(true));

  try {
    const request = () => api.post(`/plans/getCurrentPlan/`, { idConvenio: idConvenio });
    const { data } = yield call(request);

    if (data && data.userB2BBuy) {
      return yield put(modalPlanB2B(true));
    }

    yield localStorage.setItem('@change_plan', JSON.stringify(data));
    yield put(generateBuyToken());
  } catch (error) {
    if (error && error.response && error.response.data && error.response.data.message) {
      return show(error.response.data.message);
    }
  } finally {
    yield put(setLoading(false));
  }
}

export function* registerPayment({ payload }) {
  yield put(setLoading(true));

  delete payload.activeStep;

  try {
    const request = () => api.post('/payment/register', payload);
    const { data } = yield call(request);

    if (data && data.paymentUrl) {
      yield all([put(setUrlPayment(data.paymentUrl)), put(setActiveStep(4))]);
    }
  } catch (error) {
    if (error && error.response && error.response.data && error.response.data.message) {
      show(error.response.data.message);
    }
  } finally {
    yield put(setLoading(false));
  }
}

export function* verifyVoucher({ payload }) {
  try {
    yield put(setLoading(true));

    const request = () => api.post('/payment/voucher', payload);

    const { data } = yield call(request);

    yield put(setVoucher(data));
  } catch (error) {
    yield put(setVoucher({}));
    if (error && error.response && error.response.data && error.response.data.message) {
      show(error.response.data.message);
    }
  } finally {
    yield put(setLoading(false));
  }
}
