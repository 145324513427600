import React, { useState } from "react";
import {
  Form,
  Title,
  TitlesRecovery,
  SubTitle,
  UploadMain,
  UploadArea,
  Uploaded,
  Files,
  ItemFile,
  ButtonUpload,
  ButtonSaveUpload,
  DivImageUpload,
  FirstFile
} from "../styles";

export default function Profile(props) {

  const [files, setFiles] = useState([]);

  function handleFiles(newFiles) {
    let auxFiles = [...files]

    Array.from(newFiles.files).forEach(file => {
      auxFiles.push({
        file,
        name: file.name,
        size: file.size,
        preview: URL.createObjectURL(file),
        url: null
      });
    });

    setFiles([...auxFiles]);
  }

  function handleSubmit(e) {
    e.preventDefault();
  }

  function removeItem(key) {
    let auxFiles = [...files]

    auxFiles.splice(key, 1);

    setFiles([...auxFiles]);
  }

  return (
    <Form onSubmit={handleSubmit} enctype="multipart/form-data">
      <TitlesRecovery>
        <Title>Meus arquivos</Title>
        <SubTitle>Faça upload de seus arquivos.</SubTitle>
      </TitlesRecovery>

      <UploadMain>

        <UploadArea htmlFor="inputFile">

          <DivImageUpload>
            <img src="img/upload-cloud.svg" />
          </DivImageUpload>

          <input multiple={true} onChange={(e) => handleFiles(e.target)} id="inputFile" type="file" />

          <ButtonUpload htmlFor="inputFile" type="buttton">Upload</ButtonUpload>

        </UploadArea>

        <Uploaded>
          <Files>
            {!files || files.length === 0 && (
              <div>
                <h4>Faça upload de arquivos</h4>
                <p>Clique no campo ao lado para enviar arquivos</p>
              </div>
            )}
            {(files || []).map((item, key) => (
              <ItemFile>
                <FirstFile>
                  <img src="img/file.svg" />
                  <label>{item.name}</label>
                </FirstFile>

                <img onClick={() => removeItem(key)} src="img/close.png" />
              </ItemFile>
            ))}
          </Files >

          {files && files.length !== 0 && (<ButtonSaveUpload type="buttton">Enviar</ButtonSaveUpload>)}
        </Uploaded>

      </UploadMain>

    </Form>
  );
}
