import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Form as SemanticForm, Radio } from "semantic-ui-react";

import {
  Container,
  Main,
  Titles,
  Title,
  DivLogo,
  Logo,
  Infos,
  Texts,
  Buttons,
  Button,
  Form,
  UploadArea,
  ButtonUpload,
  SubTitle,
  PreviewImg,
  UploadIcon,
  Caption,
  Checkbox,
  FileInput,
  PreviewTip,
} from "./styles";

import { Steps } from "./steps";
import { goBack, push } from "../../services/navigate";
import {
  nextDocsUploadStep,
  undoDocsUploadStep,
  skipDocUploadStep,
  setSelectedDocType,
  setCurrentDocumentStepFile,
  ignoreUploadDocs,
  resetUploadDocs,
} from "../../redux/actions/utils.actions";
import { BsPerson, BsCardHeading } from "react-icons/bs";
import { AiOutlineIdcard } from "react-icons/ai";
import { VscMultipleWindows } from "react-icons/vsc";

export const initialUploadDocsSteps = [
  {
    id: "PROFILE_PIC",
    title: "Foto de Perfil",
    subtitle: (
      <div style={{ cursor: "inherit" }}>
        Adicione uma foto do seu rosto para o perfil da sua conta
        <br />
        <Caption>*Foto opcional e visível somente para você</Caption>
      </div>
    ),
    icon: BsPerson,
  },
  {
    id: "SELECT_DOCUMENT",
    title: "Tipo de documento",
    subtitle: `Para iniciar adicione uma foto do seu rosto para o perfil do Einstein Conecta`,
    icon: VscMultipleWindows,
  },
  {
    id: "ID_FRONT",
    title: "Frente do documento",
    subtitle: "Inclua uma foto da parte frontal do documento selecionado",
    icon: AiOutlineIdcard,
  },
];

const documentTypeOptions = [
  { key: "RG", value: "RG", text: "RG - Registro Geral" },
  { key: "CNH", value: "CNH", text: "CNH - Carteira Nacional de Habilitação" },
  { key: "RNE", value: "RNE", text: "RNE - Registro Nacional de Estrangeiros" },
  { key: "PASSPORT", value: "PASSPORT", text: "Passaporte" },
];

const StepView = () => {
  const dispatch = useDispatch();
  const { steps, selectedDocumentType, currentStep, skipProfilePic } =
    useSelector((state) => state.utilsReducer.documentsUpload);

  const stepConfig = steps[currentStep];

  const handleSelectDocumentType = (e, { value }) =>
    dispatch(setSelectedDocType(value));

  function handleFiles({ files }) {
    const newObjectFile = {
      lastModified: files[0].lastModified,
      lastModifiedDate: files[0].lastModifiedDate,
      name: files[0].name,
      size: files[0].size,
      type: files[0].type,
      webkitRelativePath: files[0].webkitRelativePath,
    };
    const response = {
      files,
      file: {
        id: stepConfig.id,
        ...newObjectFile,
      },
    };
    dispatch(setCurrentDocumentStepFile(response));
  }

  if (stepConfig.id === "PROFILE_PIC")
    return (
      <>
        <UploadArea htmlFor="inputFile" disabled={skipProfilePic}>
          <>
            <FileInput
              onChange={(e) => handleFiles(e.target)}
              id="inputFile"
              type="file"
              accept="image/x-png,image/gif,image/jpeg,image/png"
              disabled={skipProfilePic}
            />
            <Texts>
              <UploadIcon src="img/file-plus.png" />
              <ButtonUpload htmlFor="inputFile" type="buttton">
                {stepConfig.subtitle}
                {stepConfig.captions &&
                  stepConfig.captions.map((text) => (
                    <caption>
                      <br />*{text}
                    </caption>
                  ))}
              </ButtonUpload>
            </Texts>
          </>
        </UploadArea>
        <Checkbox
          onChange={() => dispatch(skipDocUploadStep())}
          checked={skipProfilePic}
          label="Desejo pular essa etapa"
        ></Checkbox>
      </>
    );

  if (stepConfig.id === "SELECT_DOCUMENT")
    return (
      <div>
        <SemanticForm>
          {documentTypeOptions.map((option, key) => (
            <SemanticForm.Field
              key={key}
              control={Radio}
              label={option.text}
              value={option.value}
              checked={option.value === selectedDocumentType}
              onChange={handleSelectDocumentType}
            />
          ))}
        </SemanticForm>
      </div>
    );

  return (
    <UploadArea htmlFor="inputFile">
      <>
        <input
          onChange={(e) => handleFiles(e.target)}
          id="inputFile"
          type="file"
          accept="image/x-png, image/jpeg,image/png"
        />
        <Texts>
          <UploadIcon src="img/file-plus.png" />
          <ButtonUpload htmlFor="inputFile" type="buttton">
            {stepConfig.subtitle}
            {stepConfig.captions &&
              stepConfig.captions.map((text) => (
                <caption>
                  <br />*{text}
                </caption>
              ))}
          </ButtonUpload>
        </Texts>
      </>
    </UploadArea>
  );
};

const Preview = (props) => {
  return (
    <UploadArea htmlFor="inputFile">
      <input
        onChange={(e) => props.handleUpdateFile(e.target)}
        id="inputFile"
        type="file"
        accept="image/x-png,image/gif,image/jpeg,image/png"
      />
      <div htmlFor="inputFile" type="buttton">
        <PreviewImg centered src={props.src} />
      </div>
    </UploadArea>
  );
};

export default function Covid(props) {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { steps, files, currentStep, selectedDocumentType, skipProfilePic } =
    useSelector((state) => state.utilsReducer.documentsUpload);

  const stepConfig = steps[currentStep];

  useEffect(() => {
    dispatch(resetUploadDocs());
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
  }

  function getCurrentPreview() {
    const stepId = steps[currentStep].id;
    const file = files.find(
      (file) => file.originalName.split("-")[0] == stepId
    );
    return file && file.preview;
  }

  function ignoreTemporarely() {
    dispatch(ignoreUploadDocs());
  }

  function isNextButtonDisabled() {
    const stepId = steps[currentStep].id;
    const stepsWithFile = new Set(
      files.map((elem) => elem.originalName.split("-")[0])
    );

    if (stepId === "PROFILE_PIC" && skipProfilePic) return false;

    if (stepId === "SELECT_DOCUMENT" && selectedDocumentType !== "")
      return false;

    if (getCurrentPreview() !== undefined) return false;

    return true;
  }

  function getDependent() {
    const params = new URLSearchParams(search);
    const dependent = params.get("dependent");

    if (dependent === undefined) {
      return undefined;
    }

    return JSON.parse(dependent);
  }

  function handleUpdateFile({ files }) {
    const newObjectFile = {
      lastModified: files[0].lastModified,
      lastModifiedDate: files[0].lastModifiedDate,
      name: files[0].name,
      size: files[0].size,
      type: files[0].type,
      webkitRelativePath: files[0].webkitRelativePath,
    };
    const response = {
      files,
      file: {
        id: stepConfig.id,
        ...newObjectFile,
      },
    };
    dispatch(setCurrentDocumentStepFile(response));
  }

  const currentPreview = getCurrentPreview();

  function goToHome() {
    push("/home");
  }

  return (
    <Container>
      <DivLogo onClick={() => goToHome()}>
        <Logo src="img/logo-blue.png" />
      </DivLogo>

      <Main>
        <Titles>
          <Title>Verificação de Identidade</Title>
          <SubTitle>
            Precisamos de algumas fotos para confirmar sua identidade
            <br />
          </SubTitle>
        </Titles>

        <Form onSubmit={handleSubmit} enctype="multipart/form-data">
          <Infos>
            <Steps currentStep={currentStep} steps={steps} />

            {currentPreview === undefined ? (
              <StepView />
            ) : (
              <>
                <Preview
                  src={getCurrentPreview()}
                  handleUpdateFile={handleUpdateFile}
                ></Preview>
                <PreviewTip>
                  Lembre-se de verificar a qualidade da imagem antes de
                  continuar
                </PreviewTip>
              </>
            )}
            <Buttons>
              {currentStep === 0 && (
                <Button outline onClick={ignoreTemporarely}>
                  Agora não
                </Button>
              )}
              {currentStep > 0 && (
                <Button outline onClick={() => dispatch(undoDocsUploadStep())}>
                  Voltar
                </Button>
              )}
              <Button
                contained
                width="250px"
                onClick={() =>
                  dispatch(nextDocsUploadStep({ dependent: getDependent() }))
                }
                disabled={isNextButtonDisabled()}
              >
                Próximo
              </Button>
            </Buttons>
          </Infos>
        </Form>
      </Main>
    </Container>
  );
}
