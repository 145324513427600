import styled from "styled-components";

export const Body = styled.div`
    padding: 15px;
    max-width: 210px;
    width: 100%;
    height: 380px;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
    margin: 1rem 20px 0px 5px;

    border: ${(props) => props.isFocus ? '2px solid #2196F3' : 'none'};

    @media(max-width: 768px) {
        min-width: 275px;
        margin: 0px 45px 0px 5px;
    }

`;

export const Title = styled.div`
    font-size: 16px;
    font-weight: 700;
    flex: 1;
    display: ${(props) => props.showMore ? 'none' : 'flex'};
    justify-content: center;
    text-align: center;
    color: #464646;
    padding: 35px 20px 0px 20px;
`;

export const ProgramIcon = styled.img`
    width:  ${(props) => props.showMore ? '40px' : '80px'};
    height: ${(props) => props.showMore ? '40px' : '80px'};
    margin: ${(props) => props.showMore ? '0px 0px 34px 0px' : '34px 0px 50px 0px'};
`;

export const ProgramPeriod = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: #464646;
    display: ${(props) => props.showMore ? 'none' : 'flex'};
    flex: 1;
    justify-content: center;
`;

export const PeriodDescription = styled.div`
    font-size: 12px;
    font-weight: 400;
    color: #464646;
    width: 100%;
    display: ${(props) => props.showMore ? 'flex' : 'none'};
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
    padding: 0px 0px 15px 5px;
`;
export const DivisionDiv = styled.div`
    width: 100%;
    height: 20px;
    justify-content: flex-start;
    padding: 0px 0px 15px 5px;
    display: ${(props) => props.showMore ? 'flex' : 'none'};
`;
export const ShowMoreDivision = styled.div`
    width: 10px;
    border-bottom: 1px solid #707070;
`;

export const ShowMore = styled.div`
    width: 100%;
    text-align: right;
    display: ${(props) => props.showMore ? 'none' : 'flex'};
    justify-content: flex-end;
`;

export const Icon = styled.text`
    font-size: 25px;
    display: flex;
    align-items: center;
    &:hover {
        color: #fff;
        border-radius: 50%;
        background-color: #2196F3;
        cursor: pointer;
      }
`;

export const BackDiv = styled.div`
    width: 100%;
    display: ${(props) => props.showMore ? 'flex' : 'none'};
    cursor: pointer;
`;

export const Description = styled.div`
    width: 100%;
    display: ${(props) => props.showMore ? 'flex' : 'none'};
    text-align: justify;
    font-size: 12px;
    color: #464646;
    padding: 0px 0px 0px 5px;
`;

export const ButtonDiv = styled.div`
    width: 100%;
    flex: 1;
    display: ${(props) => props.showMore ? 'flex' : 'none'};
    justify-content: center;
    align-items: flex-end;
`;
export const Button = styled.button`
    background-color: #2196f3;
    color: #fff;
    height: 35px;
    width: 80%;
    font-weight: 600;
    border-radius: 30px;
`;