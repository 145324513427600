import React, { useEffect } from "react"
import Loading from "../../components/loading"
import { push } from "../../services/navigate"

const Logout = () => {

    useEffect(() => {
        localStorage.clear()
        sessionStorage.clear()
        setTimeout(() => {
            push('/')
        },1000)
    },[])
    return (
        <>
            <Loading />
        </>
    )
}

export default Logout