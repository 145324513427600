import { GET_TELEPSICO_PROGRAMS,GET_AVAILABLE_DATE_DOCTOR, TELEPSICO_PROGRAMS_SUCCESS, SET_SELECTED_PROGRAM, GET_AVAILABLE_DATE, GET_AVAILABLE_DATE_SUCCESS, SET_TRIADE } from "./action-types";

export const getTelepsicoPrograms = (payload) => ({
  type: GET_TELEPSICO_PROGRAMS,
  payload,
});

export const telepsicoProgramsSuccess = (payload) => ({
  type: TELEPSICO_PROGRAMS_SUCCESS,
  payload,
});

export const setSelectedProgram = (payload) => ({
  type: SET_SELECTED_PROGRAM,
  payload,
});

export const getAvailableDates = (payload) => ({
  type: GET_AVAILABLE_DATE,
  payload,
});
export const getAvailableDatesByDoctor = (payload) => ({
  type: GET_AVAILABLE_DATE_DOCTOR,
  payload,
});

export const getAvailableDatesSuccess = (payload) => ({
  type: GET_AVAILABLE_DATE_SUCCESS,
  payload,
});

export const setTriade = (payload) => ({
  type: SET_TRIADE,
  payload,
})


