export default function GetCatalogoPlanId(mockId) {
	const mockCatalogo = {
		1: process.env.REACT_APP_INDIVIDUAL_PLAN_ID,
		2: process.env.REACT_APP_AVULSA_PLAN_ID,
		3: process.env.REACT_APP_FAMILIAR_PLAN_ID,
	};

	const exists = Object.keys(mockCatalogo).includes(mockId);

	if (!exists) return mockCatalogo[1];

	return mockCatalogo[mockId];
}
