import {
    GET_HISTORY,
    SET_HISTORY_LIST
} from "./action-types";

export const getHistory = (payload) => ({
    type: GET_HISTORY,
    payload,
});

export const setHistoryList = (payload) => ({
    type: SET_HISTORY_LIST,
    payload,
});
