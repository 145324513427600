import React, { useState } from "react";
import {
  Container,
  Header,
  Options,
  OptionsButton,
  ButtonBack,
  IconBack,
  LabelBack,
} from "./styles";

import Modal from "../../../components/modal-right";

import RecoveryPassword from "./recovery-password";
import UserProfile from "./user-profile";
import UploadFiles from "./upload-files";

import { useDispatch } from "react-redux";

export default function Profile(props) {
  const dispatch = useDispatch();
  const [option, setOption] = useState(1);

  return (
    <Modal isOpen={props.isOpen} onClose={() => dispatch(props.onClose(false))}>
      <Container>
        <Header>
          <ButtonBack
            type="button"
            onClick={() => dispatch(props.onClose(false))}
          >
            <IconBack src="img/icons/back-icon.png"></IconBack>
            <LabelBack>Voltar</LabelBack>
          </ButtonBack>

          <Options>
            <OptionsButton
              active={option === 1}
              left
              onClick={() => setOption(1)}
            >
              Meu Perfil
            </OptionsButton>
            {/* <OptionsButton
              center
              active={option === 2}
              onClick={() => setOption(2)}
            >
              Meus arquivos
            </OptionsButton> */}
            {/* <OptionsButton
              active={option === 3}
              right
              onClick={() => setOption(3)}
            >
              Trocar senha
            </OptionsButton> */}
          </Options>

          <div />
        </Header>

        {option === 1 && <UserProfile />}

        {option === 2 && <UploadFiles />}

        {option === 3 && <RecoveryPassword />}

      </Container>
    </Modal>
  );
}
