import styled from "styled-components";
export const InputStyle = styled.input`
  border: 1px solid #8e959a;
  border-radius: 8px;
  min-width: 200px;
  width: ${(props) => props.width};
  padding: 15px 20px;
  font-size: 16px;
  outline: none;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  :focus {
    border: 1px solid #3951b2;
  }
`;

export const InputContent = styled.div`
  position: relative;
  padding-top: 13px;
  width: 100%;
  
  label {
    position: absolute;
    top: 0;
    font-size: 13px;
    left: 15px;
    color: #8e959a;
    transition: all 0.1s linear;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    background-color: white;
    padding: 5px;
    box-sizing: border-box;
  }

  img {
    position: absolute;
    right: 5%;
    top: 47%;
    cursor: pointer;
  }
  input:focus + label,
  input:not(:placeholder-shown) + label {
    color: #3951b2;
  }
`;
