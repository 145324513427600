import React from "react";

import { push } from "../../services/navigate";

import {
  Container,
  Main,
  Steps,
  Step,
  NumberStep,
  Button,
  DivLogo,
  DivLogoWhite,
  Logo,
} from "./styles";
import { useDispatch } from "react-redux";
import { generateBuyToken } from "../../redux/actions/utils.actions";
import { hasToken } from "../../utils/credentials";

export default function App() {
  const dispatch = useDispatch();

  function goToPayments() {
    localStorage.setItem("discount", "JD912D1H2DUSA");
    localStorage.setItem("buy-flag", "true");

    const hasValidToken = hasToken();

    if (!hasValidToken) {
      push("/register");
    }

    dispatch(generateBuyToken());
  }

  return (
    <Container>
      <DivLogo onClick={() => (window.location.href = "/home")}>
        <Logo src="img/logo-blue.png" />
      </DivLogo>

      <DivLogoWhite onClick={() => (window.location.href = "/home")}>
        <Logo src="img/logo-einstein-white.svg" />
      </DivLogoWhite>

      <Main>
        <h1>Samsung te conecta</h1>

        <p>
          Assine o plano anual do <b>Einstein Conecta</b> e receba um mês
          adicional
        </p>

        <b>Veja como é facil</b>

        <Steps>
          <Step>
            <NumberStep>1</NumberStep>
            <p>Utilize o código "SAMSUNG" na confirmação do seu pedido.</p>
          </Step>

          <Step>
            <NumberStep>2</NumberStep>
            <p>
              Pronto! Agora você pode usufruir do atendimento médico Einstein
              sem sair de casa com bônus de um mês adicional.
            </p>
          </Step>
        </Steps>

        <Button onClick={goToPayments}>Eu quero!</Button>
      </Main>
    </Container>
  );
}
