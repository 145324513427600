import { getValue } from "firebase/remote-config";
import remoteConfig from "./remoteConfig";
import parseString from "../../utils/parseString";

const getRemoteConfigValue = (remoteParamName) => {
  const { _value } = getValue(remoteConfig, remoteParamName);
  try {
    return parseString(_value);
  } catch (error) {
    return _value;
  }
};

export default getRemoteConfigValue;
