import styled from "styled-components";

export const Container = styled.div``;

export const Iframe = styled.iframe`
  width: 780px;
  height: 900px;

  @media (max-width: 1024px) {
    width: 700px;
  }

  @media (max-width: 425px) {
    width: 100%;
  }
`;
