import styled from "styled-components";

export const Sections = styled.div`
  padding: 60px 50px;
`;

export const WaitOpacity = styled.div`
  ${(props) => ({
    opacity: props.wait ? 0.1 : 1,
  })}
`;

export const Container = styled.div`
  max-width: 700px;
  padding: 30px 0 10px 40px;
  line-height: 1.3rem;
  font-size: 13px;
  color: #777;

  h1 {
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #111;
  }

  h2 {
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #777;
  }

  p {
  }

  a {
    color: #999;
  }
`;

export const Columns = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;

  ul {
    flex: 1;
    padding: 0;
    padding-left: 15px;
  }

  ul > li {
  }

  ul.divider-line {
    border-left: 1px solid #ddd;
    padding-left: 25px;
  }

  @media (max-width: 1024px) {
    gap: 0px;
    flex-direction: column;

    ul.divider-line {
      border-left: none;
      padding-left: 15px;
    }
  }

`;

export const ToggleSwitch = styled.div`

  input, label {
    ${(props) => ({
      opacity: props.wait ? 0.1 : 1,
    })}
  }

  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 50px;
    height: 25px;
    background: grey;
    display: block;
    border-radius: 25px;
    position: relative;
  }

  label:after {
    content: "";
    position: absolute;
    top: 2.5px;
    left: 2.5px;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 20px;
    transition: 0.3s;
  }

  input:checked + label {
    background-color: #2196f3;
  }

  input:checked + label:after {
    left: calc(100% - 2.5px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 40px;
  }
`;

export const WaitSpan = styled.span`
  min-height: 20px;
  font-size: 10px;
`

export const PaginationGroup = styled.div`
  display: flex;
  align-content: flex-end;
  align-items: center;
  margin-top: 20px;

  & ul {
    list-style: none;
    float: right;
  }
  & ul > li {
    float: left;
    margin-left: -1px;
    text-align: center;
    min-width: 30px;
    height: 30px;
    border: 1px solid #ddd;
    cursor: pointer;
  }
  & ul > li > a {
    display: block;
    padding-top: 3px;
    font-size: 14px;
    color: #2196f3;
  }
  li.rc-pagination-prev,
  li.rc-pagination-next,
  li.rc-pagination-jump-next {
    background-color: #eee;
    min-width: 30px;
    height: 30px;
  }

  /* 
  content: '‹';
  content: '›'; 
  */

  li.rc-pagination-prev > button.rc-pagination-item-link:after {
    content: '‹';
  }
  li.rc-pagination-jump-next > button.rc-pagination-item-link:after {
    content: '...';
  }
  li.rc-pagination-jump-next {
    cursor: default;
    border: none;
    background-color: transparent;
  }
  li.rc-pagination-next > button.rc-pagination-item-link:after {
    content: '›';
  }
  li.rc-pagination-options {
    display: none;
  }
  li.rc-pagination-item-active {
    background-color: #2196f3;
  }
  li.rc-pagination-item-active a {
    color: #fff;
  }
`;

export const IconBack = () => (
  <svg
    width="24px"
    height="24px"
    version="1.1"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polygon points="352,115.4 331.3,96 160,256 331.3,416 352,396.7 201.5,256 " />
  </svg>
);

export const BackButtonContainer = styled.div`
  margin-bottom: 20px;
  font-size: 0.9rem;
  color: #777;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const CardShareChoice = styled.div`
  background-color: white;
  padding: 15px 20px;
  margin: 30px 0 10px 0;
  border-radius: 10px;
  box-shadow: 1px 1px 5px #ddd;
  border: 1px solid #ddd;
  align-items: flex-start;

  h3 {
    padding-bottom: 7px;
    font-size: 15px;
    font-weight: 500;
    color: #444;
  }
`;

export const CardShareChoiceColumns = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const CardShareChoiceRows = styled(CardShareChoiceColumns)`
  flex: 1;
  flex-direction: column;
`;

export const TermsHistoryRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 7px;
  padding: 20px 0;
  border-bottom: 1px solid #DDD;

  .header {
	  font-weight: 700;
  }
`;

export const TermsHistoryRowHeader = styled(TermsHistoryRow)`
  font-weight: 700;
`;

export const TermsHistoryColumn = styled.div`

  ${(props) => ({
    flex: props.width ? 'none' : '1',
    width: props.width || 'auto',
  })}

  .right {
	text-align: right;
  }
`;