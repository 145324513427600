const theme = {
  colors: {
    primary: "#059CD9",
    secondary: "#2196F329",
    primaryText: "#00000A",
    secondaryText: "#707070",
    background: "#EFEFEF",
  },
};

export default theme;
