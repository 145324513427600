import styled, { css } from "styled-components";

import { motion } from "framer-motion";
import animations from "../../utils/animation";

const active = css`
  background-color: #2196f3;
  color: #fff;
`;
export const Container = styled.div``;

export const BodySchedule = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 70px 0 0 70px;
  width: 100%;

  @media (max-width: 500px) {
    padding: 0;
    margin: 70px 0 0 0;
  }
`;
export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
export const ContainerFilters = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
  }
`;
export const DivFilters = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  /* padding: 20px 50px; */

  @media (max-width: 500px) {
    padding: 10px;
  }
`;

export const HeaderScheduled = styled.div`
  display: flex;
  width: 70%;
  height: 80%;
  margin-top: 30px;
  flex-direction: column;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const BodyScheduled = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 15px;
  flex-direction: column;
  background-color: #fff;
  align-items: center;

  @media (max-width: 500px) {
    width: 100%;
    height: 100%;
  }
`;
export const AlertSchedule = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;

  @media (max-width: 500px) {
    width: 100%;
    height: 100%;
  }
`;

export const DivPatient = styled.div`
  display: flex;
  width: 440px;
  height: 100px;
  margin-top: 15px;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
`;

export const TitleScheduled = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 16px;
  margin: 10px 0 5px;
`;

export const TitleMessage = styled.div`
  width: 100%;
  font-size: 14px;
  color: #909090;
  margin-top: 5px;
  margin-bottom: 10px;
`;

export const SubTitleScheduled = styled.div`
  display: flex;
  font-size: 14px;
  color: #909090;
`;

export const ButtonOk = styled.div`
  width: 120px;
  height: 30px;
  border-radius: 15px;
  padding: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: #2196f3;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  margin: 20px 0 10px 0;
`;

export const GridFilterSpecialty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
  margin-right: 50px;
  @media (max-width: 500px) {
    margin: 0;
    width: 100%;
  }
`;
export const GridFilterDoctor = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const DivScheduledAppointments = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 50px;
  padding-left: 50px;
  height: 150px;
`;

export const DivAppointments = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  border-radius: 4px;
  width: 93%;
  margin-top: 15px;
  padding: 10px;
  height: 50px;
  background-color: #fff;
`;

export const AppointmentsText = styled.div`
  width: 20px;
  height: 30px;
  background-color: red;
`;

export const AppointmentsButtonEnter = styled.div`
  width: 100px;
  height: 30px;
  border-radius: 7px;
  padding: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: #2196f3;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
`;

export const AppointmentsButtonCancel = styled.div`
  width: 100px;
  height: 30px;
  margin-left: 10px;
  border-radius: 7px;
  padding: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: #d1d1d1;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
`;

export const ScheduleGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const ScheduleDay = styled.form`
  height: 450px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 70px;
  font-size: 12px;
  @media (max-width: 500px) {
    width: 100%;
    align-items: center;
    padding-left: 10px;
    margin: 0;
  }
`;
export const ScheduleHour = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  @media (max-width: 500px) {
    width: 100%;
    align-items: center;
    padding: 10px 20px;
  }
`;

export const DateCalendarDisableClick = styled.div`
  position: absolute;
  width: 255px;
  height: 60px;
  margin: 0px 32px 0px 65px;
  @media (max-width: 1024px) {
    width: 150px;
    align-items: center;
  }
`;

export const DateInputDiv = styled.div`
  width: 360px;
  margin: 20px;
  @media (max-width: 500px) {
    width: 80%;
  }
`;
export const AvailableHours = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 450px;
  height: auto;
  max-height: 270px;
  overflow: auto;
  padding: 7px;
  margin: 5px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;
export const HourButton = styled.div`
  width: 80px;
  height: 30px;
  margin-top: 13px;
  margin-left: 5%;
  border-radius: 20px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  ${(props) => props.active && active}
  &:hover {
    background-color: #2196f3;
    color: #fff;
    transition: 0.2s;
  }
`;

export const TitleSchedule = styled.h4`
  width: 250px;
  font-weight: bold;

  font-size: 16px;
  margin-bottom: 5px;
`;

export const SubTitleSchedule = styled.p`
  width: 100%;
  font-size: 14px;
  color: #909090;
`;
export const CircleSchedule = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2196f3;
  color: #fff;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  @media (max-width: 500px) {
    width: 50px;
  }
`;
export const LabelSchedule = styled.div`
  width: 100%;
  font-weight: bold;
  font-size: 16px;
`;
export const SubLabelSchedule = styled.div`
  width: 100%;
  font-size: 14px;
  color: #909090;
  margin-top: 5px;
  margin-bottom: 10px;
`;
export const GridContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 10px 0 0;

  @media (max-width: 500px) {
    width: 100%;
    justify-content: center;
  }
`;
export const GridScheduled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
  @media (max-width: 500px) {
    width: 100%;
    flex-direction: column;
    margin-left: 30px;
  }
`;
export const DivLabels = styled.div`
  margin: 5px 10px 0;

  @media (max-width: 500px) {
    width: 100%;
  }
`;
export const DivScheduled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 20px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;
export const DivTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 20px 0 20px;
`;
export const ButtonAdvance = styled.div`
  width: 35%;
  height: 40px;
  border-radius: 20px;
  background-color: #2196f3;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-left: 30%;
  margin-bottom: 15px;

  cursor: pointer;
  @media (max-width: 500px) {
    width: 50%;
    margin-left: 25%;
    margin-bottom: 10px;
    margin-top: 20px;
  }
`;

export const LabelSelect = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 5px;
  margin-left: 3px;
`;
export const DivSelect = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 0 40px;
  @media (max-width: 500px) {
    flex-direction: column;
    margin: 0;
  }
`;
export const SelectSpecialtyDoctor = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 10px;
  margin-left: 10px;
  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

export const Img = styled.img`
  width: 100px;
  margin-top: 5px;
`;
export const Icon = styled.img`
  width: 50px;
  @media (max-width: 425px) {
    width: 25px;
  }
`;

export const ChoicePatient = styled.div`
  border-radius: 10px;
  width: 430px;
  height: 90px;
  background: #fff;
  padding: 10px 20px;
  margin-top: 15px;
  margin-bottom: 20px;
  max-height: 100%;
  @media (max-width: 425px) {
    width: 100%;
    height: auto;
    margin: 20px 0 10px 0;
    background: #fff;
  }
`;

export const PatientSelected = styled(motion.div).attrs(animations)`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const ImagePatientSelected = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 36px;
`;

export const InfoPatientSelected = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 80%;
`;

export const ChangePatientButton = styled.button`
  min-width: 156px;
  width: 156px;
  height: 32px;
  background: transparent;
  color: #2196f3;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 5px 0 10px;

  span {
    border: 1px solid #2196f3;
    border-radius: 4px;
    padding: 3px 5px;
  }
  p {
    margin-left: 10px;
    color: #000;
  }
`;

export const NamePatientSelected = styled.p`
  color: #000;
  font-size: 16px;
  font-weight: bold;
  margin: 0 15px;
`;

export const NivelPatientSelected = styled.p`
  color: #303030;
  font-size: 12px;
  font-weight: light;
  margin: 0 15px;
`;

export const ListPatient = styled(motion.div).attrs(animations)`
  display: flex;
  flex-direction: row;
  overflow: auto;

  @media (max-width: 425px) {
    flex-direction: column;
    height: 300px;
  }
`;

export const PatientSection = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  width: 90px;
  min-width: 90px;

  @media (max-width: 425px) {
    margin: 10px 0;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  }
`;

export const ButtoAddPatient = styled.button`
  min-width: 72px;
  width: 72px;
  height: 72px;
  border-radius: 36px;
  background: #2196f3;
`;

export const ImgButtonAddPatient = styled.img`
  width: 22px;
  height: 22px;
`;

export const ImagePatient = styled.img`
  min-width: 72px;
  width: 72px;
  height: 72px;
  border-radius: 36px;
`;

export const NamePatient = styled.div`
  color: #000;
  font-size: 12px;
  margin: 5px 0;
`;
