import styled, { css } from "styled-components";

export const Footer = styled.div`
  height: 250px;

  @media (max-width: 1024px) {
    height: auto;
  }
`;

export const Div = styled.div`
  width: 30%;

  p{
    font-size: 12px;
    color: #707070;
  }

  @media (max-width: 1024px) {
    width: 100%;
    text-align: center;

    img{
      width: 70%;
      margin: 20px 0;
    }
  }
`;

export const FooterHeader = styled.div`
  height: 75%;
  background: #F1F1F1;;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 1024px) {
    height: auto;
    padding: 10px 0;
  }
`

export const FooterBody = styled.div`
  height: 25%;
  background: #fff;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: #707070;

  @media (max-width: 1024px) {
    height: auto;
    padding: 10px 0;

    font-size: 10px;
  }
`;
