import {
  SET_PATIENT,
  PATIENT_SELECTED,
  PATIENT_SENT_DOCUMENTS,
} from "../actions/action-types";

const initialState = {
  patient: {},
  patientSelected: false,
  patientSentDocuments: false,
};

export default (state = initialState, action) => {
  if (action?.payload?.document?.length > 0) {
    localStorage.setItem("@conecta:patient-selected", JSON.stringify(action?.payload));
  }
  switch (action.type) {
    case SET_PATIENT:
      localStorage.setItem("@conecta:patient-selected", JSON.stringify(action?.payload));
      return {
        ...state,
        patient: action.payload,
      };
    case PATIENT_SELECTED:
      return {
        ...state,
        patientSelected: action.payload,
      };
    case PATIENT_SENT_DOCUMENTS:
      return {
        ...state,
        patientSentDocuments: action.payload,
      };
    default:
      return state;
  }
};
