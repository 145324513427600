import styled from "styled-components";

export const Container = styled.div`
height: 100vh;
width: 100vw;
max-width: 1366px;
background: #fff;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding: 5vh 5vw;
`;

export const Wrapp = styled.div`
height: 80vh;
width: 100vw;
max-width: 1366px;
padding: 5vh 5vw;
`;

export const ButtonBack = styled.button`
  cursor: pointer;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background: transparent;
  margin-top: 15px;
`;

export const LabelBack = styled.p`
  margin-left: 10px;
  color: #707070;
`;

export const IconBack = styled.img`
  width: 20px;
  height: 20px;
`;

export const Button = styled.button`
  border: 0;
  border-radius: 200px;
  font-weight: bolder;
  color: #005a9c;
  margin: 15px auto 0 auto;
  width: 305px;
  height: 37px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
`;

export const DivLogo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 10px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`

export const Logo = styled.img`
  max-width: 40px;
  margin-left: 20px;
`
