import crypto from "crypto-js";
import { takeEvery, put } from "redux-saga/effects";
import { getHomeStore, setDependents, setPatient } from "./actions";
import { REACT_APP_CRYPTO_STORE_HOME_MICROFRONTEND } from "../../configuration/env";

function* homeFulfilledSaga() {
  const { dependents } = JSON.parse(
    localStorage.getItem("persist:conecta:home-microfrontend/dependents")
  );
  const { patient } = JSON.parse(
    localStorage.getItem("persist:conecta:home-microfrontend/patient")
  );
  if (dependents !== null) {
    const storeDependents = JSON.parse(
      crypto.AES.decrypt(
        JSON.parse(dependents),
        REACT_APP_CRYPTO_STORE_HOME_MICROFRONTEND
      ).toString(crypto.enc.Utf8)
    );
    yield put(setDependents({ dependents: storeDependents }));
  }
  if (patient !== null) {
    const storePatient = JSON.parse(
      crypto.AES.decrypt(
        JSON.parse(patient),
        REACT_APP_CRYPTO_STORE_HOME_MICROFRONTEND
      ).toString(crypto.enc.Utf8)
    );
    yield put(setPatient({ patient: storePatient }));
  }
}

const homeSagas = [takeEvery(getHomeStore.match, homeFulfilledSaga)];

export default homeSagas;
