import React, { useState } from "react";
import {
  Container,
  Main,
  Titles,
  Title,
  SubTitle,
  DivLogo,
  Logo,
  Infos,
  Texts,
  Buttons,
  Button,
  AlertImage,
} from "./styles";

import { push } from "../../../services/navigate";

export default function Covid({ disease }) {
  function goToHome() {
    push("/sintomas");
  }

  return (
    <Container>
      <DivLogo>
        <Logo src="img/logo-blue.png" />
      </DivLogo>

      <Main>
        <Titles>
          <Title>{disease.titleDisease}</Title>
          <SubTitle>{disease.subTitleDisease}</SubTitle>
        </Titles>

        <Infos>
          <AlertImage src="img/alert-message.svg" />

          <Texts>
            <p>{disease.textDisease}</p>
          </Texts>

          <Buttons>
            <Button outline onClick={goToHome}>
              Voltar
            </Button>
          </Buttons>
        </Infos>
      </Main>
    </Container>
  );
}
