import React, { useState } from "react";
import VerificationInput from "react-verification-input";
import {
  Container,
  ButtonBack,
  IconBack,
  DivInput,
  DivInputCustom,
  Title,
  SubTitle,
  DivButtons,
  ButtonConfirm,
} from "./styles";

import "./styles.css";

import { useDispatch } from "react-redux";
import Modal from "../../../../../components/modal-provider";

import {
  findPhoneNumber,
  openModalVerification,
} from "../../../../../redux/actions/user.actions";

export default function App(props) {
  const dispatch = useDispatch();

  const [ref, setRef] = useState(null);

  function checkPhoneNumber(phoneNumber) {
    const user = {};
    const provider = JSON.parse(localStorage.getItem("@conecta:selected-provider"));
    user.empresas = [provider];
    user.phoneNumber = phoneNumber;
    dispatch(findPhoneNumber(user));
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <Container>
        <ButtonBack
          type="button"
          onClick={() => dispatch(openModalVerification(false))}
        >
          <IconBack src="img/close-icon.png"></IconBack>
        </ButtonBack>

        <Title>Insira seu número</Title>
        <SubTitle>
          Para acessar o serviço de Telemedicina Einstein, por favor, informe o
          número do seu celular:
        </SubTitle>

        <DivInput>
          <DivInputCustom>
            <VerificationInput
              autoFocus
              length={11}
              removeDefaultStyles={false}
              container={{
                className: "container",
              }}
              characters={{
                className: "characters",
              }}
              validChars="\d"
              character={{
                className: "character",
                classNameInactive: "character--inactive",
                classNameSelected: "character--selected",
              }}
              getInputRef={(inputRef) => setRef(inputRef)}
              inputField={{
                onChange() {},
              }}
            ></VerificationInput>
          </DivInputCustom>
        </DivInput>

        <DivButtons>
          <ButtonConfirm
            onClick={() => {
              checkPhoneNumber(ref.value);
            }}
          >
            CONTINUAR
          </ButtonConfirm>
        </DivButtons>
      </Container>
    </Modal>
  );
}
