import React, { useState, useEffect } from "react";
import {
  Container,
  Inputs,
  Form,
  Titles,
  Title,
  SubTitle,
  DivButton,
  Button,
  CheckResponsible,
  Label,
  DivInput,
  ErrorMessage,
  Select,
} from "./styles";

import useYup from "@usereact/use-yup";
import {
  brasileiroSchema,
  estrangeiroSchema,
  addressEstrangeiroSchema,
  addressBrasileiroSchema,
} from "./validate";
import axios from "axios";
import statesLong from "./states-long";

import { differenceInYears, parseISO } from "date-fns";

import { useSelector, useDispatch } from "react-redux";
import { setDataUser as setDataUserProps } from "../../../redux/actions/buy.actions";
import { buyAuth, setActiveStep } from "../../../redux/actions/utils.actions";
import Input from "../../../components/input-custom";
import { getPhoneMaskRule } from "../../../utils/phoneMask";

export default function App(props) {
  const dispatch = useDispatch();
  const propsDataUser = useSelector((state) => state.buyReducer.dataUser);
  const user = useSelector((state) => state.userReducer.user);
  const changePlan = JSON.parse(localStorage.getItem("@change_plan"));
  const [touched, setTouched] = useState(false);
  const [dataUser, setDataUser] = useState({});

  useEffect(() => {
    dispatch(buyAuth());
  }, []);

  useEffect(() => {
    if (propsDataUser) {
      setDataUser({
        cep: propsDataUser.cep,
        address: propsDataUser.address,
        cityAddress: propsDataUser.cityAddress,
        stateAddress: propsDataUser.stateAddress,
        numberAddress: propsDataUser.numberAddress,
        complementAddress: propsDataUser.complementAddress,
      });
    }
  }, [propsDataUser]);

  let { errors } = useYup(
    dataUser,
    user.documentType === "PASSAPORTE"
      ? addressEstrangeiroSchema
      : addressBrasileiroSchema,
    {
      validateOnChange: true,
    }
  );

  function isEmpty(obj) {
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }

    return true;
  }

  function handleSubmit(e) {
    e.preventDefault();
    setTouched({
      address: true,
      stateAddress: true,
      numberAddress: true,
      cityAddress: true,
    });

    if (isEmpty(errors)) {
      const newCep = dataUser.cep.replace(/\D/g, "");
      const newUser = {
        ...dataUser,
        cep: newCep,
      };
      setDataUser((prevState) => ({
        ...prevState,
        cep: newCep,
      }));
      dispatch(setDataUserProps({...propsDataUser, ...newUser}));
      if (changePlan) {
        dispatch(setActiveStep(3));
      } else {
        props.handleNext();
      }
    }
  }

  async function getCep(e) {
    const { name, value } = e.currentTarget;
    setTouched({
      ...touched,
      [name]: true,
    });
    setDataUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (value && value.length === 9) {
      const cep = value.replace(/[^0-9]+/g, "");

      try {
        const { data } = await axios.get(
          `https://viacep.com.br/ws/${cep}/json/`
        );

        setDataUser((prevState) => ({
          ...prevState,
          address: data.logradouro,
          cityAddress: data.localidade,
          stateAddress: statesLong[data.uf] || "",
        }));
      } catch (err) {
      } finally {
      }
    }
  }

  function dontBlockEmoji(value) {
    return (
      value === "email" ||
      value === "confirmEmail" ||
      value === "password" ||
      value === "confirmPassword" ||
      value === "birthDate" ||
      (value === "document" && user.nationality === "B")
    );
  }

  function handleChange(e) {
    const { name, value } = e.currentTarget;
    setTouched({
      ...touched,
      [name]: true,
    });
    setDataUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    //Block all non (alpha or numeric caracters)
    const reg = /^([a-zA-ZÀ-ú\s\d]+)$/gi;
    if (!reg.test(value) && !dontBlockEmoji(name) && value.length > 0) {
      return;
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Inputs>
          <Titles>
            <Title>
              Complete seus dados de cadastro
                </Title>
            <SubTitle>
              Precisamos dos dados a seguir para continuar com a sua
              aquisição.
                </SubTitle>
          </Titles>

          <Input
            width="315px"
            autoComplete="off"
            mask={
              dataUser.documentType === "PASSAPORTE"
                ? "9999999999999"
                : "99999-999"
            }
            maskChar={user.documentType === "PASSAPORTE" ? "" : "_"}
            name="cep"
            value={dataUser.cep}
            onChange={getCep}
            placeholder="CEP"
            errorMessage={touched["cep"] && errors && errors.cep}
          />

          <Input
            width="315px"
            name="address"
            value={dataUser.address}
            onChange={handleChange}
            placeholder="Endereço"
            errorMessage={touched["address"] && errors && errors.address}
          />

          <Input
            width="315px"
            name="numberAddress"
            value={dataUser.numberAddress}
            onChange={handleChange}
            placeholder="Número"
            errorMessage={
              touched["numberAddress"] && errors && errors.numberAddress
            }
          />

          <Input
            width="315px"
            name="complementAddress"
            value={dataUser.complementAddress}
            onChange={handleChange}
            placeholder="Complemento"
            errorMessage={
              touched["complementAddress"] &&
              errors &&
              errors.complementAddress
            }
          />

          <Input
            width="315px"
            name="stateAddress"
            value={dataUser.stateAddress}
            onChange={handleChange}
            placeholder="Estado"
            errorMessage={
              touched["stateAddress"] && errors && errors.stateAddress
            }
          />

          <Input
            width="315px"
            name="cityAddress"
            value={dataUser.cityAddress}
            onChange={handleChange}
            placeholder="Cidade"
            errorMessage={
              touched["cityAddress"] && errors && errors.cityAddress
            }
          />
        </Inputs>

        <DivButton>
          <Button type="submit">Continuar</Button>
        </DivButton>
      </Form>
    </Container>
  );
}
