import React, { useEffect } from "react";
import {
  Card,
  Infos,
  Icon,
  Text,
  Title,
  TitleSchedule,
  Form,
  SubTitle,
} from "./styles";

import { GiBrain } from "react-icons/gi";
import { useSelector } from "react-redux";
import pt from "date-fns/locale/pt-BR";
import { parseISO, format } from "date-fns";

function ConfirmInfos() {
  const patient = useSelector((state) => state.patientReducer.patient);
  const selectedSlot = useSelector((state) => state.schedule.selectedSlot);
  const program = useSelector((state) => state.telepsico.selectedProgram);

  let formattedDate;
  if (selectedSlot) {
    formattedDate = format(parseISO(selectedSlot.startAt), "EEEE - dd/MM", {
      locale: pt,
    });

    formattedDate = `${formattedDate} às ${format(
      parseISO(selectedSlot.startAt),
      "HH:mm"
    )}`;
  }

  return (
    <div>
      <Card>
        <Form>
          <TitleSchedule>Confirme as informações</TitleSchedule>
        </Form>

        <Infos>
          <Icon>
            <GiBrain />
          </Icon>
          <Text>
            <Title>Paciente selecionado</Title>
            <SubTitle>{`${patient.name || patient.firstName} ${
              patient.lastName
            }`}</SubTitle>
          </Text>
          <Text>
            <Title>Programa selecionado</Title>
            <SubTitle>{program.title ? program.title : program.programId.title}</SubTitle>
          </Text>
          <Text>
            <Title>Profissional</Title>
            <SubTitle>{selectedSlot.doctorRefName}</SubTitle>
          </Text>
          <Text>
            <Title>Consulta</Title>
            <SubTitle>{program.sessionCount ? `${program.sessionCount+1}ª consulta` : `1ª consulta`}</SubTitle>
          </Text>
          <Text hidden>
            <Title>Data</Title>
            <SubTitle>{formattedDate}</SubTitle>
          </Text>
        </Infos>
      </Card>
    </div>
  );
}
export default ConfirmInfos;
