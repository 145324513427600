import React, { useState, useEffect } from 'react';
import {
  Container,
  ButtonClose,
  Content,
  SvgCloseIcon,
  ButtonCloseContainer,
  ContentCentralize,
  ContentParagraph,
  ButtonsGrpup,
  Image,
} from "./styles";

import Modal from "../../../../../components/modal"
import Button from "../../../../../components/UI/2.0/Button";
import iconTermsProvider from './../../../../../assets/terms-provider/icon-modal-terms-provider.png';
import { TERMS_PROVIDER_STATUS } from "../constants";
import { useSelector } from 'react-redux';

export default function ModalTermsProvider(props) {
  const termsProviderUrl = useSelector((state) => state.termsProvider.meta.termUrl);
  function closeAndSendMyTermnsChoice (termsChoice) {
    props.onClose && props.onClose(termsChoice);
  }

  return (
    <Modal 
      isOpen={props.isOpen} 
      onClose={() => closeAndSendMyTermnsChoice(TERMS_PROVIDER_STATUS.INITIAL)} 
      className="centralize-dialog"
    >
      <Container style={{ display: 'block' }}>
        <Content>
          <ButtonCloseContainer>
            <ButtonClose
              type="button"
              onClick={() => closeAndSendMyTermnsChoice(TERMS_PROVIDER_STATUS.INITIAL)}
            >
              <SvgCloseIcon />
            </ButtonClose>
          </ButtonCloseContainer>
          <ContentCentralize>
            <ContentParagraph>
              <h3>Deseja compartilhar seus dados com a empresa Natura S/A?</h3>
              <Image src={iconTermsProvider} />
              <p>
                Eu concordo que o Hospital Israelita Albert Einstein armazene, processe e 
                compartilhe meus dados pessoais e de saúde com a empresa Natura S/A.
                <a href={termsProviderUrl} target="_blank">
                  Termos de compartilhamento
                </a>
                .
              </p>
            </ContentParagraph>
          </ContentCentralize>
          <ButtonsGrpup>
            <Button 
              variation={'outline'}
              onClick={() => closeAndSendMyTermnsChoice(TERMS_PROVIDER_STATUS.REJECTED)}
            >
              Não desejo compartilhar
            </Button>            
            <Button
              onClick={() => closeAndSendMyTermnsChoice(TERMS_PROVIDER_STATUS.ACCEPTED)}
            >
              Sim, quero compartilhar
            </Button>
          </ButtonsGrpup>
        </Content>
      </Container>
    </Modal>
  );
}
