import styled from "styled-components";

import { motion } from "framer-motion";
import animations from "../../utils/animation";

export const Container = styled(motion.div).attrs(animations)`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;

  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

export const ImageForm = styled.div`
    background-image: url('${(props) => props.src}');
    background-size: cover;
    background-repeat: no-repeat;
    /* position: absolute; */
    background-position: right; 
    flex: 1;
    height: 100%;

    @media (max-width: 1024px) {
      display: none;
    }

`;

export const BackIos = styled.div`
  padding: 15px;
  padding-top: 35%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;
export const BackIosButton = styled.div`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 20px;
  padding: 15px;
  background-color: #e0832c;
  color: white;
  text-align: center;
  border-radius: 25px;
`;

export const Form = styled.form`
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding: 50px 50px 0 50px;
  z-index: 1;
  background: #fff;
  box-sizing: border-box;
  width: 100%;
`;

export const DivLogo = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 0 20px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const Logo = styled.img`
  max-width: 40px;
`;

export const Inputs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 70px;
  clear: both;

`;

export const Icon = styled.img``;

export const LabelPassword = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 20px 0 0 0;
  padding-left: 5px;

  p {
    color: #005a9c;
    font-size: 15px;
  }

  @media (max-width: 1024px) {
  }
`;

export const Button = styled.button`
  border: 0;
  border-radius: 25px;
  font-weight: bolder;
  color: #ffffff;
  margin: 0 auto;
  width: 345px;
  height: 50px;
  cursor: pointer;
  background-color: #004F92;
  padding: 15px 7px;

  &:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
`;

export const Register = styled.p`
  cursor: pointer;
  color: #707070;
  margin: 32px auto 0;
  b {
    color: #005a9c;
  }
`;
