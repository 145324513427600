import React, { useState } from "react";
import {
  Container,
  ListPatient,
  ButtonBack,
  LabelBack,
  IconBack,
  Title,
  SubTitle,
  Inputs,
  DivInput,
  Label,
  ErrorMessage,
  Select
} from "./styles";

import {
  DivButtonPayment,
  ButtonPayment
} from "../styles";

import useYup from "@usereact/use-yup";
import {
  brasileiroSchema,
  estrangeiroSchema,
  addressEstrangeiroSchema,
  addressBrasileiroSchema,
} from "./validate";
import { getPhoneMaskRule } from "../../../../utils/phoneMask";
import { useSelector, useDispatch } from "react-redux"
import Modal from "../../../../components/modal"
import Input from "../../../../components/input-custom";
import axios from "axios";
import statesLong from "./states-long";

export default function App(props) {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.userReducer.user)

  const [dataUser, setDataUser] = useState({
    name: "",
    lastName: "",
    birthDate: "",
    sex: "",
    nationality: "",
    documentType: "",
    phone: "",
  });
  const [selectedNationality, setSelectedNationality] = useState(false);
  const [phoneMaskRule, setPhoneMaskRule] = useState(getPhoneMaskRule(""));
  const [touched, setTouched] = useState(false);

  let { errors } = useYup(
    dataUser,
    dataUser.documentType === "PASSAPORTE"
      ? estrangeiroSchema
      : brasileiroSchema,
    {
      validateOnChange: true,
    }
  );

  function dontBlockEmoji(value) {
    return (
      value === "email" ||
      value === "confirmEmail" ||
      value === "password" ||
      value === "confirmPassword" ||
      value === "birthDate" ||
      value === "document"
    );
  }

  function handleSubmit(e) {
    e.preventDefault();
    setTouched({
      name: true,
      lastName: true,
      birthDate: true,
      sex: true,
      email: true,
      document: true,
      documentType: true,
      phone: true,
      nationality: true,
      cep: true,
      rg: true,
      address: true,
      stateAddress: true,
      numberAddress: true,
      cityAddress: true,
    });

    if (isEmpty(errors)) {
      props.onSubmit(dataUser)
    }
  }

  function isEmpty(obj) {
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }

    return true;
  }

  function handlePhone(e) {
    const { name, value } = e.currentTarget;
    setTouched({
      ...touched,
      [name]: true,
    });

    setDataUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setPhoneMaskRule(getPhoneMaskRule(value));
  }

  function handleChange(e) {
    let { name, value } = e.currentTarget;
    setTouched({
      ...touched,
      [name]: true,
    });
    //Block all non (alpha or numeric caracters)
    const reg = /^([a-zA-ZÀ-ú\s\d]+)$/gi;
    if (!reg.test(value) && !dontBlockEmoji(name) && value.length > 0) {
      return;
    }

    if(name == 'document' && dataUser.nationality === 'E'){
      value = value.replace(/[^a-zA-Z0-9]+/g, "");
    }

    setDataUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));


    //Resets phone input on nationality change
    if (name === "nationality") {
      setDataUser((prevState) => ({
        ...prevState,
        phone: "",
        document: "",
        documentType: value === "B" ? "CPF" : "PASSAPORTE",
      }));
      setSelectedNationality(true);
    }
  }

  async function getCep(e) {
    const { name, value } = e.currentTarget;
    setTouched({
      ...touched,
      [name]: true,
    });
    setDataUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (value && value.length === 9) {
      const cep = value.replace(/[^0-9]+/g, "");

      try {
        const { data } = await axios.get(
          `https://viacep.com.br/ws/${cep}/json/`
        );

        setDataUser((prevState) => ({
          ...prevState,
          address: data.logradouro,
          cityAddress: data.localidade,
          stateAddress: statesLong[data.uf] || "",
        }));
      } catch (err) {
      } finally {
      }
    }
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <Container>
        <ButtonBack
          type="button"
          onClick={props.onClose}
        >
          <IconBack src="/img/icons/back-icon.png"></IconBack>
          <LabelBack>Voltar</LabelBack>
        </ButtonBack>

        <Title>Insira os dados do responsável pelo pagamento</Title>
        <SubTitle>Os dados são necessários para a emissão da nota fiscal</SubTitle>
        <ListPatient>
          <Inputs>
            <Input
              width="315px"
              onChange={handleChange}
              name="name"
              placeholder="Nome"
              maxLength={128}
              type="text"
              readOnly={props.readOnly ? true : false}
              readOnlyColor="#707070"
              value={dataUser.name}
              errorMessage={touched["name"] && errors && errors.name}
            />

            <Input
              width="315px"
              onChange={handleChange}
              name="lastName"
              noEmoji={true}
              placeholder="Sobrenome"
              maxLength={128}
              type="text"
              readOnly={props.readOnly ? true : false}
              readOnlyColor="#707070"
              value={dataUser.lastName}
              errorMessage={
                touched["lastName"] && errors && errors.lastName
              }
            />

            <Input
              width="315px"
              mask="99/99/9999"
              name="birthDate"
              value={dataUser.birthDate}
              onChange={handleChange}
              placeholder="Data de Nascimento"
              readOnly={props.readOnly ? true : false}
              readOnlyColor="#707070"
              errorMessage={
                touched["birthDate"] && errors && errors.birthDate
              }
            />

            <DivInput width="315px">
              <label>{dataUser.nationality && "Nacionalidade"}</label>

              <Select name="nationality" onChange={handleChange} disabled={props.readOnly ? true : false}
                readOnlyColor="#707070">
                <option disabled value selected={!selectedNationality}>
                  Nacionalidade
                        </option>
                <option value="B">Brasileira</option>
                <option value="E">Estrangeira</option>
              </Select>

              <ErrorMessage>
                {touched["nationality"] && errors && errors.nationality}
              </ErrorMessage>
            </DivInput>

            <DivInput width="315px">
              <label>{dataUser.sex && "Sexo"}</label>

              <Select name="sex" onChange={handleChange} disabled={props.readOnly ? true : false}
                readOnlyColor="#707070">
                <option disabled selected={dataUser.sex != '' ? false : true} value>
                  Sexo
                        </option>
                <option value="M">Masculino</option>
                <option value="F">Feminino</option>
              </Select>

              <ErrorMessage>
                {touched["sex"] && errors && errors.sex}
              </ErrorMessage>
            </DivInput>

            {selectedNationality ? (
              dataUser.nationality === "E" ? (
                <Input
                  width="315px"
                  onChange={handleChange}
                  value={dataUser.document}
                  name="document"
                  placeholder="Passaporte"
                  type="text"
                  readOnly={props.readOnly ? true : false}
                  readOnlyColor="#707070"
                  maxLength={128}
                  maskChar={""}
                  errorMessage={
                    touched["document"] && errors && errors.document
                  }
                />
              ) : (
                <>
                  <Input
                    width="315px"
                    onChange={handleChange}
                    value={dataUser.document}
                    name="document"
                    readOnly={props.readOnly ? true : false}
                    readOnlyColor="#707070"
                    placeholder="CPF"
                    type="text"
                    mask={"999.999.999-99"}
                    errorMessage={
                      touched["document"] && errors && errors.document
                    }
                  />

                  <Input
                    width="315px"
                    onChange={handleChange}
                    name="rg"
                    readOnly={props.readOnly ? true : false}
                    readOnlyColor="#707070"
                    placeholder="RG"
                    maxLength={128}
                    type="text"
                    value={dataUser.rg}
                    errorMessage={touched["rg"] && errors && errors.rg}
                  />
                </>
              )
            ) : null}

            <Input
              width="315px"
              onChange={handlePhone}
              name="phone"
              readOnly={props.readOnly ? true : false}
              readOnlyColor="#707070"
              placeholder={
                dataUser.nationality === "E"
                  ? "Código do País + DDD + Telefone"
                  : "DDD + Telefone"
              }
              type="text"
              mask={
                dataUser.nationality === "E"
                  ? "+999999999999999"
                  : phoneMaskRule.mask
              }
              value={dataUser.phone}
              errorMessage={touched["phone"] && errors && errors.phone}
              maskChar={phoneMaskRule.maskChar}
            />
            <Input
              width="315px"
              onChange={handleChange}
              name="email"
              placeholder="E-mail"
              maxLength={128}
              type="text"
              readOnly={props.readOnly ? true : false}
              readOnlyColor="#707070"
              value={dataUser.email}
              errorMessage={touched["email"] && errors && errors.email}
            />
          </Inputs>
          <Inputs>
            <Input
              width="315px"
              autoComplete="off"
              mask={
                dataUser.documentType === "PASSAPORTE"
                  ? "9999999999999"
                  : "99999-999"
              }
              maskChar={dataUser.documentType === "PASSAPORTE" ? "" : "_"}
              name="cep"
              value={dataUser.cep}
              onChange={getCep}
              placeholder="CEP"
              errorMessage={touched["cep"] && errors && errors.cep}
            />

            <Input
              width="315px"
              name="cityAddress"
              value={dataUser.cityAddress}
              onChange={handleChange}
              placeholder="Cidade"
              errorMessage={
                touched["cityAddress"] && errors && errors.cityAddress
              }
            />

            <Input
              width="315px"
              name="stateAddress"
              value={dataUser.stateAddress}
              onChange={handleChange}
              placeholder="Estado"
              errorMessage={
                touched["stateAddress"] && errors && errors.stateAddress
              }
            />

            <Input
              width="315px"
              name="address"
              value={dataUser.address}
              onChange={handleChange}
              placeholder="Endereço"
              errorMessage={touched["address"] && errors && errors.address}
            />

            <Input
              width="315px"
              name="numberAddress"
              value={dataUser.numberAddress}
              onChange={handleChange}
              placeholder="Número"
              errorMessage={
                touched["numberAddress"] && errors && errors.numberAddress
              }
            />

            <Input
              width="315px"
              name="complementAddress"
              value={dataUser.complementAddress}
              onChange={handleChange}
              placeholder="Complemento"
              errorMessage={
                touched["complementAddress"] &&
                errors &&
                errors.complementAddress
              }
            />

          </Inputs>
          <DivButtonPayment onClick={(e) => handleSubmit(e)}>
            <ButtonPayment>Confirmar</ButtonPayment>
          </DivButtonPayment>
        </ListPatient>
      </Container>
    </Modal>
  );
}
