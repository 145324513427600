import styled, { css } from "styled-components";

const active = css`
  background-color: #2196f3;
  color: #fff;
`;

export const Card = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Infos = styled.div`
  width: 333px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  @media (max-width: 600px) {
    width: 85%;
  }
`;

export const Text = styled.div`
  width: 90%;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 0.5px solid #808080;
`;

export const Title = styled.div`
  display: flex;
  width: 100%;
  font-size: 14px;
  color: #909090;
  margin-bottom: 5px;
`;

export const Icon = styled.div`
  font-size: 47px;
  font-weight: bold;
  margin-bottom: 20px;
`;

export const DivButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin-top: 40px;
  @media (max-width: 1024px) {
    width: 100%;
    position: fixed;
    height: 10%;
    bottom: 0;
  }
`;
export const Button = styled.button`
  width: 221px;
  height: 47px;
  border-radius: 36px;
  margin: 0 5px;

  ${(props) => props.blue && blue}
  ${(props) => props.gray && gray}

  @media (max-width: 1024px) {
    margin: 10px;
    width: 60%;
    height: 30px;
  }
`;

const blue = css`
  background-color: #2196f3;
  color: #ffffff;
`;

const gray = css`
  border: 1px solid #2196f3;
  color: #2196f3;
`;

export const DivTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  margin-left: 5%;
  @media (max-width: 1024px) {
    margin-top: 95px;
  }
`;
export const Form = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

export const TitleSchedule = styled.div`
  width: ${(props) => props.title && "250px"};
  font-weight: ${(props) => (props.title ? "bold" : "normal")};
  font-size: ${(props) => (props.title ? "16px" : "14px")};
  margin: 0 0 5px;
  text-align: center;
`;

export const Img = styled.img`
  width: 100px;
  margin-top: 5px;
`;
export const IconImg = styled.img`
  width: 50px;
`;

export const DivScheduled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 20px;
  @media (max-width: 500px) {
    width: 100%;
    margin:0;
  }
`;

export const GridScheduled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const DivLabels = styled.div`
  margin: 5px 10px 0;

  @media (max-width: 500px) {
    width: 100%;
    margin:0;
  }
`;

export const LabelSchedule = styled.div`
  width: 100%;
  font-weight: bold;
  font-size: 14px;
`;
export const SubLabelSchedule = styled.div`
  width: 100%;
  font-size: 14px;
  color: #909090;
  margin-top: 5px;
  margin-bottom: 10px;
`;
