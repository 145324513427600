import * as yup from "yup";
import moment from "moment";
import { cpf as cpfTest } from "cpf-cnpj-validator";


export const brasileiroSchema = yup.object().shape({
  terms: yup.bool().oneOf([true], 'Aceite os termos para continuar'),
  planTerm: yup.bool().oneOf([true], 'Aceite os termos para continuar'),
  name: yup
    .string()
    .trim()
    .required("Nome é obrigatório")
    .min(3, "Nome mínimo 3 caracteres"),
  lastName: yup
    .string()
    .trim()
    .required("Sobrenome é obrigatório")
    .min(3, "Sobrenome mínimo 3 caracteres"),
  birthDate: yup
    .string()
    .required("Data de nascimento é obrigatória")
    .test("date-test", "Data inválida", function (value) {
      if (value) {
        let date = value.replace(/_/g, "");
        if (date.length < 10) {
          return false;
        }
        date = moment(value, "DD/MM/YYYY");
        if (!date.isValid()) {
          return false;
        }

        function calcAge(birth) {
          const auxBirth = new Date(birth);
          const diference = Date.now() - auxBirth.getTime();
          const age = new Date(diference);

          return age.getUTCFullYear() - 1970;
        }

        const age = calcAge(date);

        if (age < 0 || age > 120) {
          return false;
        }

        return date;
      }
    }),
  sex: yup.string().required("Sexo é obrigatório"),
  nationality: yup.string().required("Nacionalidade é obrigatório"),
  document: yup
  .string()
  .trim()
  .test("cpf-test", "Documento inválido", function (value) {
    if (!value) return false;
  
    const cpf = value.replace(/[^0-9]+/g, "");
    if (!cpfTest.isValid(cpf)) {
      return false;
    } else {
      return true;
    }
  }),
  rg: yup.string().required("RG é obrigatório"),
  email: yup
    .string()
    .trim()
    .required("E-mail é obrigatório")
    .test("email-test", "E-mail inválido", function (value) {
      const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

      if (!emailRegexp.test(value)) {
        return false;
      } else {
        return true;
      }
    }),
  confirmEmail: yup
    .string()
    .trim()
    .required("Confirme seu e-mail")
    .test("confirm-email", "E-mails divergentes", function (value) {
      if (this.parent && this.parent.email !== value) {
        return false;
      } else {
        return true;
      }
    }),
  password: yup
    .string()
    .trim()
    .required("Senha é obrigatória")
    .min(8, "Mínimo 8 caracteres")
    .test("password-text", "Senha fraca", function (value) {
      return value.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+/:;<=>?@[\]^_`{|}~])[0-9a-zA-Z!"#$%&'()*+/:;<=>?@[\]^_`{|}~]{8,}$/
      );
    }),
  confirmPassword: yup
    .string()
    .trim()
    .required("Confirme sua senha")
    .test("confirm-password", "Senhas divergentes", function (value) {
      if (this.parent && this.parent.password !== value) {
        return false;
      } else {
        return true;
      }
    }),
  phone: yup
    .string()
    .trim()
    .required("Telefone é obrigatório")
    .test("phone-test", "Telefone inválido", function (value) {
      if (value) {
        let phone = value.replace(/_/g, "");
        if (phone.length < 10) {
          return false;
        } else {
          return true;
        }
      }
    }),
  cep: yup
    .string()
    .trim()
    .required("Cep é obrigatório")
    .test("Cep-test", "CEP inválido", function (value) {
      if (value) {
        const cep = value.replace(/[^0-9]+/g, "");
        if (cep.length < 8) {
          return false;
        } else {
          return true;
        }
      }
    }),
  address: yup.string().trim().required("Endereço é obrigatório"),
  stateAddress: yup.string().trim().required("Estado é obrigatório"),
  numberAddress: yup.string().trim().required("Informe um número"),
  cityAddress: yup.string().trim().required("Cidade é obrigatória"),
});



// ESTRANGEIRO



export const estrangeiroSchema = yup.object().shape({
  terms: yup.bool().oneOf([true], 'Aceite os termos para continuar'),
  planTerm: yup.bool().oneOf([true], 'Aceite os termos para continuar'),
  name: yup
    .string()
    .trim()
    .required("Nome é obrigatório")
    .min(3, "Nome mínimo 3 caracteres"),
  lastName: yup
    .string()
    .trim()
    .required("Sobrenome é obrigatório")
    .min(3, "Sobrenome mínimo 3 caracteres"),
  birthDate: yup
    .string()
    .required("Data de nascimento é obrigatória")
    .test("date-test", "Data inválida", function (value) {
      if (value) {
        let date = value.replace(/_/g, "");
        if (date.length < 10) {
          return false;
        }
        date = moment(value, "DD/MM/YYYY");
        if (!date.isValid()) {
          return false;
        }

        function calcAge(birth) {
          const auxBirth = new Date(birth);
          const diference = Date.now() - auxBirth.getTime();
          const age = new Date(diference);

          return age.getUTCFullYear() - 1970;
        }

        const age = calcAge(date);

        if (age < 0 || age > 120) {
          return false;
        }

        return date;
      }
    }),
  sex: yup.string().required("Sexo é obrigatório"),
  nationality: yup.string().required("Nacionalidade é obrigatório"),
  document: yup
  .string()
  .required('Documento é obrigatório'),
  email: yup
    .string()
    .trim()
    .required("E-mail é obrigatório")
    .test("email-test", "E-mail inválido", function (value) {
      const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

      if (!emailRegexp.test(value)) {
        return false;
      } else {
        return true;
      }
    }),
  confirmEmail: yup
    .string()
    .trim()
    .required("Confirme seu e-mail")
    .test("confirm-email", "E-mails divergentes", function (value) {
      if (this.parent && this.parent.email !== value) {
        return false;
      } else {
        return true;
      }
    }),
  password: yup
    .string()
    .trim()
    .required("Senha é obrigatória")
    .min(8, "Mínimo 8 caracteres")
    .test("password-text", "Senha fraca", function (value) {
      return value.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'ˆ()*+/:;<=>?@_`{|}~])[0-9a-zA-Z!"#$ˆ%&'()*+/:;<=>?@_`{|}~]{8,}$/
      );
    }),
  confirmPassword: yup
    .string()
    .trim()
    .required("Confirme sua senha")
    .test("confirm-password", "Senhas divergentes", function (value) {
      if (this.parent && this.parent.password !== value) {
        return false;
      } else {
        return true;
      }
    }),
  phone: yup
    .string()
    .trim()
    .required("Telefone é obrigatório")
    .test("phone-test", "Telefone inválido", function (value) {
      if (value) {
        let phone = value.replace(/_/g, "");
        if (phone.length < 10) {
          return false;
        } else {
          return true;
        }
      }
    }),
  cep: yup
    .string()
    .trim()
    .required("Cep é obrigatório")
    .test("Cep-test", "CEP inválido", function (value) {
      if (value) {
        const cep = value.replace(/[^0-9]+/g, "");
        if (cep.length < 3) {
          return false;
        } else {
          return true;
        }
      }
    }),
  address: yup.string().trim().required("Endereço é obrigatório"),
  stateAddress: yup.string().trim().required("Estado é obrigatório"),
  numberAddress: yup.string().trim().required("Informe um número"),
  cityAddress: yup.string().trim().required("Cidade é obrigatória"),
});

