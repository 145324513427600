import visa from '../assets/icons/payment-icons/visa.svg';
import mastercard from '../assets/icons/payment-icons/mastercard.svg';
import amex from '../assets/icons/payment-icons/amex.svg';
import diners from '../assets/icons/payment-icons/diners.svg';
import elo from '../assets/icons/payment-icons/elo.svg';

import logoVisa from '../assets/icons/payment-icons/logo/visa.svg';
import logoMastercard from '../assets/icons/payment-icons/logo/mastercard.svg';
import logoAmex from '../assets/icons/payment-icons/amex.svg';
import logoDiners from '../assets/icons/payment-icons/logo/diners.svg';
import logoElo from '../assets/icons/payment-icons/logo/elo.svg';

export default [
  {
    id: 1,
    image: visa,
    icon: logoVisa,
    label: "visa",
  },
  {
    id: 2,
    image: mastercard,
    icon: logoMastercard,
    label: "mastercard",
  },
  {
    id: 3,
    image: amex,
    icon: logoAmex,
    label: "american express",
  },
  {
    id: 33,
    image: diners,
    icon: logoDiners,
    label: "diners",
  },
  {
    id: 41,
    image: elo,
    icon: logoElo,
    label: "elo",
  },
];
