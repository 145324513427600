import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/modal";
import * as S from "../../components/cards-terms/styles";
import CommunicationsImage from "../../../public/img/communications.png";
import moment from "moment";
import { postPatientLeadTerms } from "../../redux/actions/termsProvider.actions";
import { push } from "../../services/navigate";
moment.locale("pt-br");

const TERMS_STATUS = {
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
  INITIAL: "INITIAL",
};

export default function CommunicationsModals() {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalExitOpen, setModalExitOpen] = React.useState(false);
  const leadTerms = useSelector(
    (state) => state.termsProvider.patientLeadTermsFiltered
  );
  const [leadTermsDates, setLeadTermsDates] = React.useState({
    healthOrientation: null,
    productsAndServices: null,
    searchSatisfaction: null,
  });
  const [leadTermsStatus, setLeadTermsStatus] = React.useState({
    healthOrientation: null,
    productsAndServices: null,
    searchSatisfaction: null,
  });
  const [leadTermsIds, setLeadTermsIds] = React.useState({
    healthOrientation: 0,
    productsAndServices: 0,
    searchSatisfaction: 0,
  });

  const filterLeadDate = (propertyName) => {
    return leadTerms[propertyName]?.current?.interactionDate || null;
  };

  const filterLeadStatus = (propertyName) => {
    return leadTerms[propertyName]?.current?.status || null;
  };

  const filterLeadId = (propertyName) => {
    return leadTerms[propertyName]?.id || 0;
  };

  const mostRecentDate = async () => {
    const healthOrientationDate = filterLeadDate("healthOrientation");
    const productsAndServicesDate = filterLeadDate("productsAndServices");
    const searchSatisfactionDate = filterLeadDate("searchSatisfaction");

    const healthOrientationStatus = filterLeadStatus("healthOrientation");
    const productsAndServicesStatus = filterLeadStatus("productsAndServices");
    const searchSatisfactionStatus = filterLeadStatus("searchSatisfaction");

    const healthOrientationId = filterLeadId("healthOrientation");
    const productsAndServicesId = filterLeadId("productsAndServices");
    const searchSatisfactionId = filterLeadId("searchSatisfaction");

    const dates = {
      healthOrientation: healthOrientationDate,
      productsAndServices: productsAndServicesDate,
      searchSatisfaction: searchSatisfactionDate,
    };
    const status = {
      healthOrientation: healthOrientationStatus,
      productsAndServices: productsAndServicesStatus,
      searchSatisfaction: searchSatisfactionStatus,
    };
    const ids = {
      healthOrientation: healthOrientationId,
      productsAndServices: productsAndServicesId,
      searchSatisfaction: searchSatisfactionId,
    };

    setLeadTermsDates(dates);
    setLeadTermsStatus(status);
    setLeadTermsIds(ids);

    const ifHasNoIds = Object.keys(leadTermsIds).find(
      (key) => leadTermsIds[key] === 0
    );

    if (ifHasNoIds) return;


    const showBannerIntervalRule = () => {
      const terms = [
        {
          current: {
            status: healthOrientationStatus,
            interactionDate: healthOrientationDate
          }
        },
        {
          current: {
            status: productsAndServicesStatus,
            interactionDate: productsAndServicesDate
          }
        },
        {
          current: {
            status: searchSatisfactionStatus,
            interactionDate: searchSatisfactionDate
          }
        }];

      const filteredTerms = terms?.filter((term) => term?.current?.status);

      if (!filteredTerms?.length) return false;

      const interactionDates = filteredTerms
        .filter((term) => term?.current?.interactionDate)
        .map((term) => new Date(term.current.interactionDate))
        .filter((date) => !isNaN(date.getTime()));

      if (!interactionDates.length) return true;

      const mostRecentDate = new Date(Math.max.apply(null, interactionDates));

      const today = new Date();
      const diffTime = Math.abs(today.getTime() - mostRecentDate.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      const statuses = new Set(filteredTerms.map((term) => term.current.status));

      return (
        (statuses.has('ACCEPTED') && diffDays >= 360) || diffDays >= 180
      );
    }

    setModalOpen(showBannerIntervalRule());
  };


  const openCommunications = () => {
    setModalExitOpen(false);
    setModalOpen(false);
    dispatchNoInteraction();
    push("/comunicacoes");
  };

  const dispatchNoInteraction = () => {
    if (
      leadTermsDates.healthOrientation === null &&
      leadTermsIds.healthOrientation &&
      leadTermsStatus.healthOrientation === "INITIAL"
    ) {
      dispatch(
        postPatientLeadTerms({
          termId: leadTermsIds.healthOrientation || 12,
          status: TERMS_STATUS.INITIAL,
        })
      );
    }
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    (async () => await mostRecentDate())();
  }, [leadTerms]);

  return (
    <>
      <Modal isOpen={modalOpen}>
        <S.ModalContainer>
          <S.ModalCloseButton onClick={() => dispatchNoInteraction()}>X</S.ModalCloseButton>
          <S.ModalTitle>Consentimento para comunicações</S.ModalTitle>
          <S.ModalSubtitle>
            Escolha quais tipos de informações deseja que o Einstein envie
            para você por meio de canais físicos e digitais.
          </S.ModalSubtitle>
          <S.ModalImage src={CommunicationsImage} alt="Modal Image" />
          <S.ModalButton onClick={openCommunications}>Ver permissões</S.ModalButton>
        </S.ModalContainer>
      </Modal>
      {/* <Modal isOpen={modalExitOpen}>
        <S.ModalContainer>
          <S.ModalTitle>Você tem certeza?</S.ModalTitle>
          <S.ModalSubtitle>
            Ao sair, você não acessará a configurações de comunicações. Mas caso
            queira, você poderá acessar depois clicando no Menu e em Privacidade
            e Comunicações.
          </S.ModalSubtitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            <S.ModalButton
              onClick={() => setModalExitOpen(!modalExitOpen)}
              color="transparent"
              outlineColor="red"
            >
              Cancelar
            </S.ModalButton>
            <S.ModalButton
              onClick={() => {
                setModalExitOpen(!modalExitOpen);
              }}
              color="red"
            >
              Sair
            </S.ModalButton>
          </div>
        </S.ModalContainer>
      </Modal> */}
    </>
  );
}
