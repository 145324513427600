// import {version} from '../../package.json';

export const getDesktopData = () => {
  let typeBrowser;
  let browserInfo = navigator.userAgent;
  if (browserInfo.indexOf("Firefox") > -1) {
    typeBrowser = "Mozilla Firefox";
  } else if (browserInfo.indexOf("SamsungBrowser") > -1) {
    typeBrowser = "Samsung Internet";
  } else if (
    browserInfo.indexOf("Opera") > -1 ||
    browserInfo.indexOf("OPR") > -1
  ) {
    typeBrowser = "Opera";
  } else if (browserInfo.indexOf("Trident") > -1) {
    typeBrowser = "Microsoft Internet Explorer";
  } else if (browserInfo.indexOf("Edge") > -1) {
    typeBrowser = "Microsoft Edge";
  } else if (browserInfo.indexOf("Chrome") > -1) {
    typeBrowser = "Google Chrome";
  } else if (browserInfo.indexOf("Safari") > -1) {
    typeBrowser = "Apple Safari";
  } else {
    typeBrowser = "unknown";
  }

  const osMobile =
    navigator.userAgent.split(/\s*[;)(]\s*/)[1] === "iPhone"
      ? "iOS"
      : "Android";

  const isMobile =
    navigator.userAgent.split(/\s*[;)(]\s*/)[1].toLocaleLowerCase() ===
      "iphone" ||
    navigator.userAgent.split(/\s*[;)(]\s*/)[1].toLocaleLowerCase() ===
      "android"
      ? true
      : false;

  const brand = isMobile
    ? osMobile === "iOS"
      ? "Apple"
      : navigator.userAgent.split(/\s*[;)(]\s*/)[3].split("-")[0]
    : navigator?.userAgentData?.platform === "macOS"
    ? "Apple"
    : "Desktop";

  return {
    os: isMobile ? osMobile : navigator?.userAgentData?.platform,
    appVersion: "0.1.0",
    deviceBrand: brand,
    deviceType: isMobile ? "Mobile" : "Desktop",
    navigator: typeBrowser,
  };
};
