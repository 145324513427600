import styled from "styled-components"

export const Background = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  padding: 48px;
  border-radius: 8px;
  width: 80vw;
  gap: 16px;
`

export const Title = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 24px;
`

export const Icon = styled.img`
  width: 104px;
  height: 104px;
`

export const Text = styled.p`
  font-family: 'Montserrat', sans-serif;
  color: #707070;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  line-height: 34px;
`

export const Button = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 315px;
  height: 50px;
  background-color: #004F92;
  color: #FFFFFF;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 600;
  border-radius: 25px;
	border: 0;

  :hover {
    opacity: 0.8;
  }
`

export const Link = styled.a`
  font-family: 'Montserrat', sans-serif;
  color: #059DF7;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`