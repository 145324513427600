import apiPatient from "../../services/apiPatient";
import { show } from "../../services/alert";

const consultHealthPlan = async (user) => {
  let { empresasExterno, empresas } = user;
  const idsCompanies = new Set();
  const idsCompaniesExternal = new Set();

  empresas.map((clients) => idsCompanies.add(clients.id_convenio));
  empresasExterno.map((clients) =>
    idsCompaniesExternal.add(clients.id_convenio)
  );

  const formattedIdsCompanies = Array.from(idsCompanies).join(",");
  const formattedIdsCompaniesExternal =
    Array.from(idsCompaniesExternal).join(",");

  const [idsCompaniesImages, idsCompaniesImagesExternal] = await Promise.all([
    formattedIdsCompanies.length > 0
      ? await apiPatient
          .get(`/elegibility/healthPlan/images/${formattedIdsCompanies}`)
          .then((response) => response.data.healthPlanImages)
          .catch((error) => {
            return empresas;
          })
      : empresas,
    formattedIdsCompaniesExternal.length > 0
      ? await apiPatient
          .get(
            `/elegibility/healthPlan/images/${formattedIdsCompaniesExternal}`
          )
          .then((response) => response.data.healthPlanImages)
          .catch((error) => {
            show("Erro ao buscar imagens.");
            return empresasExterno;
          })
      : empresasExterno,
  ]);

  let newCompanies = [];
  let newCompaniesExternal = [];

  await Promise.all([
    await empresas.map((company) => {
      const result = idsCompaniesImages.find(
        (companyImage) =>
          Number(companyImage.id) === Number(company.id_convenio)
      );
      if (result?.image && result?.image !== null && result?.image?.length > 0) {
        company.imagem = result.image;
        newCompanies.push(company);
      } else {
        newCompanies.push(company);
      }
    }),
    await empresasExterno.map((company) => {
      const result = idsCompaniesImagesExternal.find(
        (companyImageExternal) =>
          Number(companyImageExternal?.id) === Number(company.id_convenio)
      );
      if (result?.image && result?.image !== null && result?.image?.length > 0) {
        company.imagem = result.image;
        newCompaniesExternal.push(company);
      } else {
        newCompaniesExternal.push(company);
      }
    }),
  ]);

  return {
    companies: newCompanies,
    companiesExterno: newCompaniesExternal,
  };
};

export { consultHealthPlan };
