import React, { useState } from "react";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import {
  Container,
  ListPatient,
  PatientSection,
  ImagePatient,
  NamePatient,
  ButtoAddPatient,
  ImgButtonAddPatient,
  ButtonBack,
  LabelBack,
  IconBack,
  DivArrow,
} from "./styles";

import { useSelector, useDispatch } from "react-redux"
import Modal from "../../../../components/modal"
import {
  setPatient,
  setSelectedPatient,
} from "../../../../redux/actions/patient.actions"

import {
  openModalVerification,
  openModalPatient,
} from "../../../../redux/actions/user.actions"

import { push } from "../../../../services/navigate"
import apiPatient from "../../../../services/apiPatient";

export default function App(props) {
  const dispatch = useDispatch()

  const user = useSelector((state) => state.userReducer.user)
  const patient = useSelector((state) => state.patientReducer.patient);
  const dependentsProps = useSelector(
    (state) => state.dependentsReducer.dependents
  )

  let provider = localStorage.getItem("@conecta:selected-provider")

  provider = JSON.parse(provider)

  const [hiddenArrow, setHiddenArrow] = useState(true);

  async function setPatientFC(patient) {
    dispatch(setPatient(patient));

    if (user.checkElegibility && Number(provider.validar_tipo_elegibilidade) === 0) {
      dispatch(openModalPatient(false))
      validateDocumentsPatient();
    }
    if (provider && Number(provider.validar_tipo_elegibilidade) === 1) {
      dispatch(openModalPatient(false))
    }
    // accenture
    if (provider && Number(provider.validar_tipo_elegibilidade) === 2 && provider?.produto !== '5da4d2b958527a17184f95a7') {
      dispatch(openModalPatient(false))
      dispatch(openModalVerification(true))
    }
    //accenture (?)
    if (provider && Number(provider.validar_tipo_elegibilidade) === 2 && provider?.produto === '5da4d2b958527a17184f95a7') {
      dispatch(openModalPatient(false))
      validateDocumentsPatient();
    }
  }

  const validateDocumentsPatient = React.useCallback(async () => {
    const patientSelected = JSON.parse(localStorage.getItem("@conecta:patient-selected"));
    const titularId = user.id;
    const isDependent = patientSelected._id !== titularId;
    const idPatient = isDependent ? patientSelected._id : patientSelected.idPatientConecta;

    const responseGetDocuments = await apiPatient
      .post(`/uploads/documents`, {
        id: idPatient,
        document: patientSelected?.document,
      })
      .then((response) => {
        if (response.data.patient.fileDocuments.length > 0) {
          return {
            documents: response.data.patient.fileDocuments,
            existsDocuments: true,
          };
        } else {
          return {
            documents: null,
            existsDocuments: false,
          };
        }
      })
      .catch(() => {
        return {
          documents: null,
          existsDocuments: false,
        };
      });

    if (!responseGetDocuments.existsDocuments) {
      return push("/documentos");
    }

    push("/sintomas");
    dispatch(setSelectedPatient(true))
  }, []);


  function getScrollPosition(el) {
    setHiddenArrow(false);
    const elemento = el.target;
    if (elemento.scrollHeight - elemento.scrollTop === elemento.clientHeight) {
      setHiddenArrow(true);
    }
  }

  function goToDependents() {
    props.onClose()
    push("/dependentes")
  }

  function cutName(fisrtName, lastName) {
    let fullName = `${fisrtName} ${lastName}`

    // if (fullName && fullName.length > 15) {
    //   return fisrtName;
    // }
    return fullName
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <Container>
        <ButtonBack
          type="button"
          onClick={() => dispatch(openModalPatient(false))}
        >
          <IconBack src="img/icons/back-icon.png"></IconBack>
          <LabelBack>Voltar</LabelBack>
        </ButtonBack>

        <h3>Selecione para quem é o atendimento</h3>
        <ListPatient onScroll={getScrollPosition}>
          <PatientSection onClick={() => setPatientFC(user)}>
            <ImagePatient
              src={user?.image || "img/default.png"}
            />
            <NamePatient>{user.firstName ? `${cutName(user.fisrtName, user.lastName)}` : `${user.name} ${user.lastName}`}</NamePatient>
          </PatientSection>
          {dependentsProps && dependentsProps.length > 0 ? dependentsProps.map((patientItem, index) => {
            if (patientItem.document !== user.document.replace(/([^0-9a-zA-Z])/gi, '')) return (
              <PatientSection key={index} onClick={() => setPatientFC(patientItem)}>
                <ImagePatient
                  src={
                    (patientItem?.image &&
                      patientItem?.image) ||
                    "img/default.png"
                  }
                  onError={(e) => e.target.src = "img/default.png"}
                />
                <NamePatient>{patientItem?.name || 'Dependente'}</NamePatient>
              </PatientSection>
            )
          }) : null}

        </ListPatient>
        {!hiddenArrow && (
          <DivArrow>
            <ArrowDownwardIcon />
          </DivArrow>
        )}
      </Container>
    </Modal>
  );
}
