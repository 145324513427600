export function patientObjHasDocuments(patient) {
    const docs = patient && patient.signUpDocuments;
    const selectedPatientHasDocs =
        (docs && docs.length > 0) || // Holder's docs
        (docs && docs.signUpDocuments && docs.signUpDocuments.length > 0); // Dependent's doc

    return !!selectedPatientHasDocs
}

export function stripPrefix (str) {
    return str.split("/")[str.split("/").length - 1]
} 