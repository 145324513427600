import { all, call, put, select, take } from "redux-saga/effects";
import { jwtDecode } from 'jwt-decode';
import { setLoading } from "../actions/utils.actions";
import {
  setDependents,
  getAllDependents,
  setDependentsRules,
} from "../actions/depedents.actions";
import { openModalDependent, setModalConfirm } from "../actions/utils.actions";
import { show } from "../../services/alert";
import api from "../../services/api";
import apiPatient from "../../services/apiPatient";
import { generateDate } from "../../utils/date";
import { stripPrefix } from "../../utils/documentHelpers";

function sortAscendingList(a, b) {
  if (a.createdAt && b.createdAt) {
    return a.createdAt > b.createdAt;
  } else {
    return false;
  }
}

export function* getAll() {
  yield put(setLoading(true));
  try {
    const request = () => apiPatient.get("/dependentes");
    const { data } = yield call(request);
    const { addDependentAllowed, message, origin } = data;
    yield put(setDependentsRules({ addDependentAllowed, message, origin }));

    let formattedDependents = [];

    const dependents = data?.dependents.length > 1 ? data?.dependents.filter((dependent) => {
      const currentDate = new Date();
      const patientBirthDate = new Date(dependent?.birthDate);

      const ageInMillis = currentDate.getTime() - patientBirthDate.getTime();

      const ageInYears = ageInMillis / (1000 * 60 * 60 * 24 * 365.25);
      if (!dependent?.isHolder && !dependent?.hasSpecialCondition && ageInYears >= 18) {
        return false;
      }
      return true;
    }) : data?.dependents;

    yield Promise.all(
      dependents.map(async (dependent) => {
        formattedDependents.push({
          id: dependent._id,
          document: dependent.document,
        });
      })
    );
    const requestImageProfile = () =>
      apiPatient
        .post("/uploads/search-image-profile-dependents", {
          dependents: formattedDependents,
        })
        .then((response) => response)
        .catch((error) => error.response);

    const responseImageProfile = yield call(requestImageProfile);
    if (responseImageProfile.data.dependents.length > 0) {
      let formattedDependentsImages = [];
      yield Promise.all(
        dependents.map((value) => {
          const response = responseImageProfile.data.dependents.filter(
            (image) =>
              image.id === value._id ||
              (image?.document !== undefined &&
                value?.document !== undefined &&
                image?.document?.toLowerCase() ===
                value?.document?.toLowerCase())
          )[0];
          if (response !== undefined && response !== null) {
            const image =
              response.image?.length > 0 && response.image !== null
                ? response.image
                : "";
            formattedDependentsImages.push({ ...value, image: image });
          } else {
            formattedDependentsImages.push({ ...value, image: "" });
          }
        })
      );
      const newListSortDependents =
        formattedDependentsImages.sort(sortAscendingList);
      yield put(setDependents(newListSortDependents));
    } else {
      yield put(setDependents(dependents));
    }
  } catch (error) {
    console.log({ error })
    show(error?.response?.data?.message || 'Algo deu errado, tente novamente');
  } finally {
    yield put(setLoading(false));
  }
}

export function* getDocumentsDependent(documentNumber) {
  try {
    const response = yield api.get(`/dependent/documents/${documentNumber}`);
    if (response.data.signUpDocuments.length > 0) {
      return {
        documents: response,
        existsDocuments: true,
      };
    } else {
      return {
        documents: response,
        existsDocuments: false,
      };
    }
  } catch {
    return {
      documents: null,
      existsDocuments: false,
    };
  }
}

export const getDependentEligibility = async (patientDependentId) => {
  const TELEMEDICINA_PRODUCT_ID = process.env.REACT_APP_TELEMEDICINA_PRODUCT_ID;
 
  const patientToken = await apiPatient
    .post("/profile/token", {
      patientId: patientDependentId,
    })
    .then(async ({ data }) => {
      const { patientToken } = data;
      return patientToken;
    })
    .catch(() => {
      return "";
    });
    
  if (!patientToken) {
    return { hasEligibility: false, message: 'Não foi possível validar a elegibilidade do paciente' };
  }

  try {
    const decodedToken = jwtDecode(patientToken);
    const { scopes } = decodedToken;

    if (!scopes) {
      return { hasEligibility: false, message: 'Paciente não elegível'};
    }

    const allowedScopes = [`product:${TELEMEDICINA_PRODUCT_ID}`];
    const isAllowed = allowedScopes.some(allowedScope => scopes.includes(allowedScope));

    if (!isAllowed) {
      return { hasEligibility: false, message: 'Paciente não elegível'};
    }

    return { hasEligibility: true, message: 'Paciente elegível'};
  } catch (error) {
    console.log('Error decoding JWT:', error);
    return { hasEligibility: false, message: 'Ocorreu um erro ao validar a elegibilidade, tenta novamente mais tarde' };
  }
};
