import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 0 30px;
`;

export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Titles = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Title = styled.h4`
  margin: 10px 0;
  font-size: 28px;
`;

export const SubTitle = styled.p`
  color: #707070;
  width: 450px;
  text-align: center;
  margin: 10px 0 20px;
  line-height: 1.7;

  @media (max-width: 1024px) {
    width: 80%;
  }
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    justify-content: center;
    align-items: flex-start;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
`;

export const LabelInfo = styled.a`
  color: #707070;
  margin: 0 25px 0 0;
`;

export const DivImageInfo = styled.div`
  width: 39px;
  height: 39px;
  background: #23ba8d;
  border-radius: 45px;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageInfo = styled.div``;

export const Form = styled.form`
  margin: 20px 0 0;
  width: 450px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1024px) {
    width: 85%;
  }
`;

export const DivTextArea = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 15px 0;
  width: 100%;

  @media (max-width: 1024px) {
    width: 85%;
    align-items: center;
  }
`;

export const LabelTextArea = styled.p`
  color: #707070;
  font-size: 12px;
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  height: 135px;
  border: 0;
  border: 1px solid #707070;
  margin: 5px 0;
  background-color: transparent;
  resize: none;

  @media (max-width: 1024px) {
    width: 270px;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  width: 100%;
  padding: 5px 0;
  font-size: 10px;
`;

export const DivButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled.button`
  cursor: pointer;
  color: white;
  font-weight: bold;
  width: 100%;
  height: 40px;
  background: #23ba8d;
  border-radius: 30px;
  margin-bottom: 150px;
`;
