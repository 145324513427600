import styled from "styled-components";

export const Card = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
`;

export const DataWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

export const ScheduleBody = styled.div`
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: ${(props) => props.showMore ? "0px" : "5px"};
`;

export const LeftInfo = styled.div`
    background-color: ${(props) => props.telemedicina ? "#059CD9" : "#15B193"};
    width: 10%;
    max-width: 100px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    @media (max-width: 1090px) {
        width: 25%;
        padding: 5px;
        border-bottom-left-radius: ${(props) => props.showMore ? "0px" : "5px"};
    }
`;

export const LeftInfoDate = styled.text`
    width: 100%;    
    color: #fff;
    font-size: 14px;
    text-align: right;
    font-weight: 600;
    @media (max-width: 1090px) {
        text-align: center;
    }
`;

export const LeftInfoTime = styled.text`
    width: 100%;
    color: #fff;
    text-align: right;
    font-size: 12px;
    font-weight: 600;
    @media (max-width: 1090px) {
        text-align: center;
    }
`;

export const PatientInfoBody = styled.div`
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    @media (max-width: 1090px) {
        display: ${(props) => props.hideMobile ? "none" : "flex"};
        border-bottom: ${(props) => props.showMore ? "1px solid #DFE3E8" : "none"};
    }
`;
export const ButtonBody = styled.div`
    /* flex: 1; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
    @media (max-width: 1090px) {
        display: none;
    }
`;

export const PatientTitle = styled.text`
    color: #6F6F70;
    font-size: 12px;
    font-weight: 400;
`;

export const PatientValue = styled.text`
    color: #6F6F70;
    font-size: 12px;
    font-weight: 700;
`;

export const ConfirmButton = styled.button`
    color: #fff;
    border: 1px solid #2196F3;
    background-color: #2196F3;
    font-size: 14px;
    font-weight: 600;
    border-radius: 30px;
    padding: 9px 25px 9px 25px;
    width: 120px;

    @media (max-width: 1090px) {
        width: 100%;
    }
`;

export const CancelButton = styled.button`
    color: red;
    border: 1px solid red;
    background-color: #fff;
    font-size: 14px;
    font-weight: 600;
    border-radius: 30px;
    padding: 9px 25px 9px 25px;
    margin-left: 10px;
    width: 120px;

    &:hover {
        background-color: red;
        color: #fff;
    }

    @media (max-width: 1090px) {
        width: 100%;
        margin-left: 0px;
        margin-top: 10px;
    }
`;
export const ChangeDateButton = styled.button`
    color: #2196F3;
    border: 1px solid #2196F3;
    background-color: #fff;
    font-size: 14px;
    font-weight: 600;
    border-radius: 30px;
    padding: 9px 25px 9px 25px;
    margin-left: 10px;
    width: 120px;

    &:hover {
        background-color: #2196F3;
        color: #fff;
    }

    @media (max-width: 1090px) {
        width: 100%;
        margin-left: 0px;
        margin-top: 10px;
    }
`;

export const ShowMoreBox = styled.button`
    display: none;
    @media (max-width: 1090px) {
        width: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: ${(props) => props.showMore ? "1px solid #DFE3E8" : "none"};
    }
`;

export const ImageShowMore = styled.img`
  width: 50%;
  resize-mode: contain;
  max-width: 25px;
`;

export const ShowMore = styled.div`
    display: none;
    @media (max-width: 1090px) {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        padding: 15px;
        width: 100%;
    }
`;

export const MobileTextBox = styled.div`
    @media (max-width: 1090px) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
    }

    ${PatientTitle}{
        margin-right: 5px;
    }
`;

export const ShowMoreDivision = styled.div`
    border-bottom: 1px solid #DFE3E8;
    margin: 10px 0px 10px 0px;
    width: 100%;
`;