import {
  GET_TERMS_PROVIDER,
  SET_TERMS_PROVIDER,
  SET_TERMS_PROVIDER_STATUS,
  SET_TERMS_PROVIDER_STATUS_AND_TIME,
  GET_PATIENT_TERMS,
  SET_PATIENT_TERMS,
  REGISTER_PATIENT_TERMS,
  GET_TELECONSULTATION_TERMS,
  SET_TELECONSULTATION_TERMS,
  REGISTER_TELECONSULTATION_TERMS,
  SET_PATIENT_LEAD_TERMS,
  GET_PATIENT_LEAD_TERMS,
  POST_PATIENT_LEAD_TERMS,
  SET_COMMUNICATION_MODAL_OPEN,
  SET_PATIENT_LEAD_TERMS_FILTERED
} from "./action-types";

export const getTermsProvider = () => ({
  type: GET_TERMS_PROVIDER,
});

export const setTermsProvider = (payload) => ({
  type: SET_TERMS_PROVIDER,
  payload,
});

export const registerConsultationTerms = (payload) => ({
  type: REGISTER_TELECONSULTATION_TERMS,
  payload,
});

export const getTeleconsultationTerms = (payload) => ({
  type: GET_TELECONSULTATION_TERMS,
  payload,
});

export const setTeleconsultationTerms = (payload) => ({
  type: SET_TELECONSULTATION_TERMS,
  payload,
});

export const setTermsProviderStatus = (payload) => ({
  type: SET_TERMS_PROVIDER_STATUS,
  payload,
});

export const setTermsProviderStatusAndTime = (payload) => ({
  type: SET_TERMS_PROVIDER_STATUS_AND_TIME,
  payload,
});

export const getPatientTerms = () => ({
  type: GET_PATIENT_TERMS,
});

export const setPatientTerms = (payload) => ({
  type: SET_PATIENT_TERMS,
  payload,
});

export const registerViewTerms = (payload) => ({
  type: REGISTER_PATIENT_TERMS,
  payload,
});

export const setPatientLeadTerms = (payload) => ({
  type: SET_PATIENT_LEAD_TERMS,
  payload,
});

export const setPatientLeadTermsFiltered = (payload) => ({
  type: SET_PATIENT_LEAD_TERMS_FILTERED,
  payload,
});

export const getPatientLeadTerms = () => ({
  type: GET_PATIENT_LEAD_TERMS,
});

export const postPatientLeadTerms = (payload) => ({
  type: POST_PATIENT_LEAD_TERMS,
  payload,
});

export const setCommunicationModalOpen = (payload) => ({
  type: SET_COMMUNICATION_MODAL_OPEN,
  payload,
});