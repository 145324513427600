export const terms = {
  replaces: [{ from: "company", to: "Natura S/A" }],
  status: {
    DEFAULT: {
      title:
        "Compartilhamento de dados pessoais e de saúde",
      paragraphs: [
        "Você não está compatilhando seus dados com a empresa <strong>company</strong>",
        "<strong>Caso deseje compartilhar</strong>, você pode permitir o compartilhamento clicando no botão.",
      ],
    },
    ACCEPTED: {
      title:
        "Compartilhamento de dados pessoais e de saúde",
      paragraphs: [
        "Seus dados estão sendo compartilhados com a empresa <strong>company</strong>",
        "<strong>Caso não deseje compartilhar</strong>, você pode alterar o compartilhamento clicando no botão.",
      ],
    },
  },
  apply(text) {
    this.replaces.map((el) => (text = text.replace(el.from, el.to)));
    return text;
  },
};
