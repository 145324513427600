import { GET_FEATURE_FLAGS_REQUEST, SET_FEATURE_FLAGS } from "./action-types"

export const setFeatureFlags = (payload) => ({
  type: SET_FEATURE_FLAGS,
  payload,
});

export const getFeatureFlagsRequest = (payload) => ({
  type: GET_FEATURE_FLAGS_REQUEST,
  payload,
});
