import {
  GET_CATALOG_PLANS,
   SET_CATALOG_PLANS,
  } from './action-types';
  
  export const setCatalogPlans = (payload) => ({
    type: SET_CATALOG_PLANS,
    payload,
  });
    
  export const getCatalogPlans = (payload) => ({
    type: GET_CATALOG_PLANS,
    payload,
  });
    
