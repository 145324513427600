import { getRemoteConfig } from "firebase/remote-config";
import { firebaseConnection } from "../../firebase";

const remoteConfig = getRemoteConfig(firebaseConnection);

remoteConfig.settings.minimumFetchIntervalMillis = 7500;

remoteConfig.defaultConfig = {
  features_self_scheduling: {
    modal_side_scheduling: false,
  },
  service_vonage_enabled: {
    scheduling: true,
    immediate_care: true,
  },
  module_home_microfrontend_enabled: false,
  module_communication_enabled: false,
  terms_menu: [
    {
      title: '',
      subtitle: '',
      description: '',
      type: '',
    }
  ],
  privacy_warning: '',
  service_contract: '',
  terms_of_use: '',
  telemedicine_terms: '',
  teleconsulting_terms_enabled: false,
  catch_system_error: {
    login: {
      enabled: true,
      title: "",
      description: "",
      contact: {
        phone: "",
        link_chat_whatsapp: "",
      }
    },
    immediateCare: {
      enabled: true,
      title: "",
      description: "",
      contact: {
        phone: "",
        link_chat_whatsapp: "",
      }
    }
  },
};

export default remoteConfig;
