import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 3rem 0;
  
  @media(max-width: 768px) {
    padding: 10% 10% 10% 10%;
  }

  @media (max-width: 500px) {
    padding: 10% 0 10% 0;
  }
`;

export const ProgramBody = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  
  flex-wrap: wrap;
  overflow: hidden;

  justify-content: center;
  
  @media(max-width: 768px) {
    overflow-x: auto;
    flex-wrap: nowrap;
    padding: 10px 40px 10px 10px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 500px) {
    padding: 10px 0px 10px 0px;
  }
`;

export const NavigationDiv = styled.button`
  width: 5%;
  justify-content: center;
  font-size: 25px;
  
  display: none;

  @media(max-width: 768px) {
    display: flex;
  }
`;