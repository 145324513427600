import axios from "axios";
import {
	clearCredentials,
	getCredentials,
	hasToken,
} from "../utils/credentials";
import getDeviceInfo from "./deviceReport";

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;
const URL_CATALOGO = process.env.REACT_APP_API_CATALOGO;
const URL_HISTORY = process.env.REACT_APP_HISTORY_API;

const api = axios.create({
	baseURL: URL_CONECTA,
});

export const apiHealthData = axios.create({
	baseURL: URL_HISTORY,
});

export const apiCatalog = axios.create({
	baseURL: URL_CATALOGO,
});

api.interceptors.request.use(config => {
	try {
		config.headers["microfront"] = `true`;
		const { token, patientToken } = getCredentials();
		const hasCredentials = hasToken();

		if (hasCredentials) {
			config.headers["x-access-token"] = `Bearer ${token}`;
			config.headers["x-access-patient-token"] = `Bearer ${patientToken}`;
		}

		//Relatorio de acesso
		const deviceData = getDeviceInfo();
		if (deviceData) {
			config.headers["Device-Data"] = JSON.stringify(deviceData);
		}
	} catch (err) {
		clearCredentials();
	} finally {
		return config;
	}
});

export default api;
