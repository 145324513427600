import { SET_SPECIALIST, SET_SPECIALTY  } from "../actions/action-types";

const initialState = {
  specialistAll: [],
  specialties: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SPECIALIST:
      return {
        ...state,
        specialistAll: action.specialist,
      };
    case SET_SPECIALTY:
      return {
        ...state,
          specialties: action.specialties,
      };
    default:
      return state;
  }
};
