import {
   SET_CATALOG_PLANS
  } from "../actions/action-types";
  
  const initialState = {
    catalogPlans: [],
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case SET_CATALOG_PLANS:
        return {
          ...state,
          catalogPlans: action.payload,
        };

      default:
        return state;
    }
  };
  