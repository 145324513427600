import styled from "styled-components";

export const Body = styled.div`
display: flex;
justify-content: flex-start;
height: 92%;
width: 90%;
overflow: auto;
margin: 3% 5% 5% 5%;
flex-direction: column;
@media (max-width: 1024px) {
  overflow: visible;
  margin-top: 80px;
  padding-bottom: 35%;
}
`;

export const SubTitle = styled.p`
  margin-top: 8px;
  font-size: 14px;
  color: #707070;
`;