import apiPatient from "../../services/apiPatient";

export const getPatientToken = async (patientId) => {
  return await apiPatient
    .post("/profile/token", {
      patientId: patientId,
    })
    .then(async ({ data }) => {
      const { patientToken } = data;
      return patientToken;
    })
    .catch(() => {
      return "";
    });
};

export function* getDocumentsPatient(id, document) {
  try {
    const response = yield apiPatient.post(`/uploads/documents`, {
      id,
      document,
    });
    if (response.data.patient.fileDocuments.length > 0) {
      return {
        documents: response.data.patient.fileDocuments,
        existsDocuments: true,
      };
    } else {
      return {
        documents: null,
        existsDocuments: false,
      };
    }
  } catch {
    return {
      documents: null,
      existsDocuments: false,
    };
  }
}
