export default function getDeviceInfo () {
  let OS = window.navigator.platform
  const navigator = window.navigator.vendor == "" ? window.navigator.appCodeName : window.navigator.vendor
  const deviceType = detectMob() ? 'mobile' : 'desktop'
  const accessChannel = 'web'
  if(deviceType == 'mobile'){
    OS = OS.toLowerCase() == 'iphone' ? 'iOS' : 'Android'
  }
  const deviceData = {
    OS,
    navigator,
    deviceType,
    accessChannel
  }
  return deviceData
}

function detectMob() {
  const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
  ];

  return toMatch.some((toMatchItem) => {
      return window.navigator.userAgent.match(toMatchItem);
  });
}
