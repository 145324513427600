import React from "react"
import * as S from "./styles"
import checkIcon from '../../../public/img/check-icon.png'

const REACT_APP_PLATFORM_URL = process.env.REACT_APP_PLATFORM_URL
const REACT_APP_HOME_URL = process.env.REACT_APP_HOME_URL

const PaymentSuccess = () => {
    return (
        <S.Background>
            <S.Container>
                <S.Title>Compra efetuada com sucesso!</S.Title>
                <S.Icon src={checkIcon} />
                <S.Text>Este serviço já está disponível na nossa plataforma.<br/>Aproveite!</S.Text>
                <S.Button href={REACT_APP_PLATFORM_URL}>Acessar a plataforma</S.Button>
                <S.Link href={REACT_APP_HOME_URL}>Voltar para o início</S.Link>
            </S.Container>
        </S.Background>
    )
}

export default PaymentSuccess