import { call, put } from "redux-saga/effects";
import { show } from "../../services/alert";
import api from "../../services/apiCatalog";
import { setCatalogPlans } from "../actions/catalog.actions";

export function* getCatalogPlans() {
  try {
    const request = () => api.get(`/services/${process.env.REACT_APP_SERVICE_ID}`);
    const { data } = yield call(request);
   yield put(setCatalogPlans(data));

  } catch (error) {
    if (error?.response?.data?.message) show(error.response.data.message);
    else show('Não foi possível obter os dados dos planos');
  }
}