import axios from 'axios';
import { clearCredentials, getCredentials, hasToken } from "../utils/credentials";
const URL_APS = process.env.REACT_APP_APS_URL;

const api = axios.create({
  baseURL: `${URL_APS}/aps`,
  timeout: 180000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  }
});

api.interceptors.request.use((config) => {
  try {
    const { token, patientToken } = getCredentials()
    const hasCredentials = hasToken()

    if (hasCredentials) {
      config.headers["x-access-token"] = `Bearer ${token}`;
      config.headers["x-access-patient-token"] = `Bearer ${patientToken}`;
    }
  } catch (err) {
    console.error(err);
  } finally {
    return config;
  }
});

export default api;