import { call, put } from "redux-saga/effects";
import { setLoading } from "../actions/utils.actions";

import { setHistoryList } from "../actions/history.actions";
import { apiHealthData } from "../../services/api";

export function* getHistory({ payload }) {
	yield put(setLoading(true));

	const today = new Date();
	const endDate = new Date().toISOString().split("T")[0];
	const startDate = new Date(today.setFullYear(today.getFullYear() - 1))
		.toISOString()
		.split("T")[0];

	try {
		const request = () =>
			apiHealthData.get(
				`api/2/HealthHistory?DocumentType=${
					payload.documentType === undefined ? "CPF" : payload.documentType
				}&DocumentNumber=${payload.documentNumber}&StartDate=${
					payload.startDate ? payload.startDate : startDate
				}&EndDate=${payload.endDate ? payload.endDate : endDate}`,
				{
					headers: {
						Authorization: `Bearer ${payload.token}`,
					},
				}
			);
		const { data } = yield call(request);

		if (data) {
			return yield put(setHistoryList(data));
		}
	} catch (error) {
	} finally {
		yield put(setLoading(false));
	}
}

export function* getAssignUrl({ payload }) {
	yield put(setLoading(true));

	try {
		const request = () =>
			apiHealthData.post(`api/1/Document`, payload, {
				headers: {
					Authorization: `Bearer ${payload.token}`,
				},
			});
		const { data } = yield call(request);

		if (data) {
			return data?.document && window.open(data?.document);
		}
	} catch (error) {
	} finally {
		yield put(setLoading(false));
	}
}
