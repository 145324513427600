export function getPhoneMaskRule(rawPhoneStr) {
  // initial
  if (!rawPhoneStr)
    return {
      maskChar: "_",
      mask: "(99) 9999-9999",
    };

  const phoneStr = rawPhoneStr
    .replace(/_/g, "")
    .replace("(", "")
    .replace(")", "")
    .replace(/-/g, "")
    .replace(/ /g, "")
    .replace(/[a-z]+/g, "")
    .replace(/[A-Z]+/g, "");

  // incomplete
  if (!phoneStr || phoneStr.length < 10)
    return {
      maskChar: "_",
      mask: "(99) 9999-9999",
    };

  // optional last digit
  if (phoneStr.length === 10)
    return {
      maskChar: null,
      mask: "(99) 9999-99999",
    };

  // full 9 digits
  if (phoneStr.length > 10)
    return {
      maskChar: null,
      mask: "(99) 99999-9999",
    };

  return {
    maskChar: null,
    mask: "(99) 99999-9999",
  };
}
