import React, { useState } from "react";
import {
  Form,
  Inputs,
  Button,
  Title,
  TitlesRecovery,
  SubTitle,
  CustomInput,
} from "../styles";

import useYup from "@usereact/use-yup";
import validationSchema from "./validate";

import InputCustom from "../../../../components/input-custom";

import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../../../redux/actions/user.actions";

export default function Profile(props) {
  const userProps = useSelector((state) => state.userReducer.user);
  const dispatch = useDispatch();

  const [touched, setTouched] = useState(false);

  const [passwordRecovery, setPasswordRecovery] = useState({
    lastPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  let { errors } = useYup(passwordRecovery, validationSchema, {
    validateOnChange: true,
  });

  function isEmpty(obj) {
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }

    return true;
  }

  function handleSubmit(e) {
    e.preventDefault();
    // if (!user.preview) {
    //   errors.image = "Insira uma imagem";
    // }

    setTouched({
      lastPassword: true,
      newPassword: true,
      confirmNewPassword: true,
    });

    if (isEmpty(errors)) {
      dispatch(
        changePassword({
          newPassword: passwordRecovery.newPassword,
          oldPassword: passwordRecovery.lastPassword,
        })
      );
    }
  }

  function handleChange(e) {
    const { name, value } = e.currentTarget;
    setTouched({
      ...touched,
      [name]: true,
    });

    setPasswordRecovery((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  return (
    <Form onSubmit={handleSubmit}>
      <TitlesRecovery>
        <Title>Troca de senha</Title>
        <SubTitle>Altere suas informações pessoais.</SubTitle>
      </TitlesRecovery>

      <Inputs>
        <InputCustom
          width="680px"
          onChange={handleChange}
          value={passwordRecovery.lastPassword}
          name="lastPassword"
          passwordShow={true}
          placeholder="Senha atual"
          type="password"
          errorMessage={
            touched["lastPassword"] && errors && errors.lastPassword
          }
        />

        <InputCustom
          width="325px"
          onChange={handleChange}
          value={passwordRecovery.newPassword}
          name="newPassword"
          passwordShow={true}
          placeholder="Nova senha"
          type="password"
          errorMessage={touched["newPassword"] && errors && errors.newPassword}
          labelMessage={
            " Sua senha deve conter no mínimo 8 dígitos, 1 caractere maiúsculo, 1 minúsculo, 1 número e um caractere especial (@#$&*)"
          }
        />

        <CustomInput>
          <InputCustom
            width="325px"
            onChange={handleChange}
            value={passwordRecovery.confirmNewPassword}
            name="confirmNewPassword"
            passwordShow={true}
            placeholder="Confirmar nova senha"
            type="password"
            errorMessage={
              touched["confirmNewPassword"] &&
              errors &&
              errors.confirmNewPassword
            }
          />
        </CustomInput>

        <Button type="submit">Salvar</Button>
      </Inputs>
    </Form>
  );
}
