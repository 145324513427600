import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import Container from "../../container";
import {
  Main,
  ContainerSpecialist,
  SubTitleSchedule,
  DivTitle,
  TitleSchedule,
  Table,
  Pagination,
  DivSelect,
  ContainerModal,
  ButtonBack,
  IconBack,
  LabelBack,
  Contacts,
  TitleContact,
  ContactsMain,
  TableWrapper,
} from "./style";

import { useDispatch, useSelector } from "react-redux";

import {
  getAllDoctors,
  getAllSpecialties,
} from "../../redux/actions/specialist.actions";
import { getUnitAddress } from "./getUnits";
import { getContacts } from "./getContacts";
import Profile from "../home/profile";
import { openModalUser } from "../../redux/actions/user.actions";

function SpecialistDoctors() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [specialtySelect, setSpecialtySelect] = useState(null);
  const [visible, setVisible] = useState(false);
  const [specialistSelected, setSpecialistSelected] = useState(false);

  function changeSpecialist({ target }) {
    const specialtySelected = JSON.parse(target.value);
    setSpecialtySelect(specialtySelected);
    dispatch(getAllDoctors({ specialty: specialtySelected, page: page }));
  }

  const fetchSpecialistData = async (specialistId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_CATALOGO}/v2/specialists/${specialistId}/public`,
        {
          headers: {
            accept: "*/*",
            "X-Tenant-Id": "einstein",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        // console.log("data", JSON.stringify(data));
        setSpecialistSelected(data);
      } else {
        throw new Error("Erro ao obter dados do especialista.");
      }
    } catch (error) {
      console.error("Erro ao buscar dados do especialista:", error);
      show("Erro ao buscar dados do especialista.");
    }
  };

  function changeSpecialist({ target }) {
    const specialtySelected = JSON.parse(target.value);
    setSpecialtySelect(specialtySelected);
    dispatch(getAllDoctors({ specialty: specialtySelected, page: page }));
  }

  function specialistSelect(specialist) {
    // console.log("specialist id", specialist.id);

    fetchSpecialistData(specialist.id);

    setVisible(true);
  }

  function changePage(param) {
    let paginator = page;
  
    if (param === 1) {
      if (page > 0) {
        paginator = page - 1;
        setPage(page - 1);
      }
    } else {
      if (specialist.length > 8) {
        paginator = page + 1;
        setPage(page + 1);
      }
    }
  
    dispatch(getAllDoctors({ page: paginator, specialty: specialtySelect }));
  }

  const specialist = useSelector((state) => state.specialists.specialistAll);
  const specialties = useSelector((state) => state.specialists.specialties);
  const modalUser = useSelector((state) => state.userReducer.modalUser);

  useEffect(() => {
    dispatch(getAllSpecialties());
    dispatch(getAllDoctors({ page: page }));
  }, []);

  const getCrmFromProfessionalCodes = (professionalCodes) => {
    const crmCode = professionalCodes.find(
      (code) => code.type === "crm" && code.value
    );
    return crmCode ? crmCode.value : "";
  };

  return (
    <>
      <Modal
        closeTimeoutMS={500}
        overlayClassName="overlay-dialog"
        className="custom-dialog"
        isOpen={visible}
        onRequestClose={() => {
          setVisible(false);
        }}
        ariaHideApp={false}
      >
        <ContainerModal>
          <ButtonBack type="button" onClick={() => setVisible(false)}>
            <IconBack src="img/icons/back-icon.png"></IconBack>
            <LabelBack>Voltar</LabelBack>
          </ButtonBack>
          <ContactsMain>
            {specialistSelected && (
              <Contacts>
                <strong>CRM:</strong>{" "}
                {getCrmFromProfessionalCodes(
                  specialistSelected.professional_codes
                )}
                <br /><br />
              </Contacts>
            )}
            {specialistSelected &&
              specialistSelected.units.map((unit, key) => {
                // console.log("unit", JSON.stringify(unit));
                return (
                  <div key={key}>
                    <Contacts>
                      <strong>{unit.name}</strong>
                    </Contacts>
                    <Contacts>
                      <strong>Endereço:</strong>
                      <span>{" " + unit.full_address}</span>
                    </Contacts>
                    {unit.contacts.some(
                      (contact) => !contact.contact_type || !contact.value
                    )
                      ? null
                      : unit.contacts.map((contact, contactKey) => (
                          <div key={contactKey}>
                            {contact.contact_type.name !==
                              "Contato para agendamento" && (
                              <Contacts>
                                <strong>Tipo de Contato:</strong>
                                <span>{" " + contact.contact_type.name}</span>
                                <br />
                                <strong>Contato:</strong>
                                <span>{" " + contact.value.value}</span>
                              </Contacts>
                            )}
                          </div>
                        ))}
                    <br />
                  </div>
                );
              })}
          </ContactsMain>
        </ContainerModal>
      </Modal>
      <Container>
        <Main>
          <ContainerSpecialist>
            <DivTitle>
              <TitleSchedule>Médicos Einstein</TitleSchedule>
              <SubTitleSchedule>
                Consulte os nossos especialistas do Einstein Corporate
              </SubTitleSchedule>
            </DivTitle>
            <div>
              {specialties.length > 0 && (
                <DivSelect htmlFor="selectSpecialties">
                  <select id="selectSpecialties" onChange={changeSpecialist}>
                    {(specialties || []).map((specialty, index) => {
                      return (
                        <option
                          key={index}
                          label={`${specialty.name}`}
                          value={JSON.stringify(specialty.id)}
                        />
                      );
                    })}
                  </select>
                  <img src="img/arrow-down.png" />
                </DivSelect>
              )}
            </div>
            <TableWrapper>
              {specialist.length > 0 && (
                <Table>
                  <thead>
                    <tr>
                      <th>NOME</th>
                      <th>ESPECIALIDADES</th>
                      <th>CONTATO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {specialist?.map((item, index) => {
                      // console.log("item", item);
                      return (
                        <tr key={index}>
                          <td>{item.name.toUpperCase()}</td>
                          <td>
                            {" "}
                            {item.specialties.map((specialty, index) => (
                              <span key={index}>
                                {specialty.name} <br />
                              </span>
                            ))}
                          </td>
                          <td
                          className="clickable"
                            onClick={() => {
                              specialistSelect(item);
                            }}
                          >
                            VISUALIZAR CONTATO
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </TableWrapper>

            <Pagination>
              {specialist.length > 1 && (
                <div>
                  <button
                    disabled={page === 0}
                    id="anterior"
                    onClick={() => {
                      changePage(1);
                    }}
                  >
                    Anterior &nbsp;
                  </button>
                  <button
                    id="proximo"
                    onClick={() => {
                      changePage(2);
                    }}
                  >
                    Próximo &nbsp;
                  </button>
                </div>
              )}
            </Pagination>
          </ContainerSpecialist>
        </Main>
        <Profile isOpen={modalUser} onClose={openModalUser} />
      </Container>
    </>
  );
}
export default SpecialistDoctors;
