import { call, put } from "redux-saga/effects";
import api from "../../services/api";
import { setFeatureFlags } from "../actions/featureFlag.actions";

export function* getFeatureFlagsRequest() {
  try {
    const request = () => api.get(`/feature-flags`);
    const { data } = yield call(request);

    yield put(setFeatureFlags(data));

  } catch (error) {
    console.error(error);
  }
}