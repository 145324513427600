import styled, { css } from "styled-components"

export const Instructions = styled.div`
  background: #fafafa;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 0 50px;
  padding: 100px 0 0;

  @media (max-width: 1024px) {
    margin: 0;
    height: auto;
  }
`

export const InstructionsDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
`

export const LabelInstructions = styled.h1`
  margin: 20px 0;
`

export const InstructionsItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 340px;

  @media (max-width: 680px) {
    margin-bottom: 30px;
  }
`

export const DivImageInstruction = styled.div`
  position: relative;
  border-radius: 200px;
  width: 245px;
  height: 245px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  margin: 20px 0;

  div#layer {
    position: absolute;
    border-radius: 200px;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 1024px) {
    div#layer {
      position: static;
      height: auto;
    }
  }
`

export const ImageInstruction = styled.div`
  position: absolute;
  border-radius: 200px;
  background-image: url(${(props) => props.src});
  width: 100%;
  height: 100%;
`

export const NumberImageInstruction = styled.h1`
  color: #e0832c;
  font-size: 120px;
  z-index: 5;
`

export const TitleInstruction = styled.h4`
  width: 250px;
  margin: 10px 0;
  text-align: center;
  font-size: 22px;
`

export const TextInstruction = styled.p`
  width: 250px;
  margin: 10px 0 0;
  color: #707070;
  line-height: 2;
  text-align: justify;

  & + & {
    margin: 0;
  }
`
