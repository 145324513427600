import { call, select, put } from "redux-saga/effects";
import api from "../../services/apiPatient";
import { setPatientLeadTerms, setPatientLeadTermsFiltered, setPatientTerms, setTeleconsultationTerms } from '../actions/termsProvider.actions';

const filterByName = (payload, name) => {
  return payload?.terms?.find(
    (e) => e?.uniqueIdentifier === name
  )
};

export function* setTermsProviderSaga({ payload }) { }

export function* getTermsProviderSaga({ payload }) {
}

export function* getPatientTermsSaga() {
  const request = () => api.get("/terms");
  const { data } = yield call(request);
  yield put(setPatientTerms(data));
};

export function* registerViewTermsSaga({ payload }) {
  const request = () => api.post("/terms", { termName: payload });
  yield call(request);
}

export function* getTeleconsultationTermsSaga({ payload }) {
  let {
    idVida,
    idConvenio,
    idCliente,
    page,
    limit,
    sort,
    tipoTermoNome,
    idTipoTermo,
  } = payload;
  if (!idVida || (!idConvenio && !idCliente)) {
    return;
  }

  page || (page = 1);
  limit || (limit = 10);
  sort || (sort = 'desc');

  tipoTermoNome = tipoTermoNome ? `&tipoTermoNome=${tipoTermoNome}` : '';
  idTipoTermo = idTipoTermo ? `&idTipoTermo=${idTipoTermo}` : '';

  let params = `?idVida=${idVida}&page=${page}&limit=${limit}&sort=${sort}${tipoTermoNome}${idTipoTermo}`;

  if (idConvenio) {
    params += `&idConvenio=${idConvenio}`;
  } else if (idCliente) {
    params += `&idVida=${idVida}`;
  }

  if (!tipoTermoNome) {
    params += `&tipoTermoNome=tele-consulta`;
  }

  const request = () => api.get(`/termsProvider/consultation/${params}`);

  const { data } = yield call(request);
  localStorage.setItem('teleconsultationTerms', JSON.stringify(data));
  yield put(setTeleconsultationTerms(data));
}

export function* registerConsultationTermsSaga({ payload }) {
  const request = () => api.post("/termsProvider/acceptTerm", payload);
  yield call(request);
}

export function* getPatientLeadTerms() {
  try {
    const request = () => api.post("/terms/leads/consult?historyLimit=1", {
      termUniqueIdentifiers: [
        'termo-de-uso-da-teleconsulta',
        'comunicacao-orientacao-sobre-saude',
        'comunicacao-produtos-e-servicos-einstein',
        'comunicacao-pesquisa-de-satisfacao',
      ],
    });
    const { data } = yield call(request);

    yield put(setPatientLeadTermsFiltered({
      data: filterByName(data, 'comunicacao-orientacao-sobre-saude'),
      property: 'healthOrientation',
    }));

    yield put(setPatientLeadTermsFiltered({
      data: filterByName(data, 'comunicacao-produtos-e-servicos-einstein'),
      property: 'productsAndServices',
    }));

    yield put(setPatientLeadTermsFiltered({
      data: filterByName(data, 'comunicacao-pesquisa-de-satisfacao'),
      property: 'searchSatisfaction',
    }));
    
    yield put(setPatientLeadTermsFiltered({
      data: filterByName(data, 'termo-de-uso-da-teleconsulta'),
      property: 'teleConsultation',
    }));

    yield put(setPatientLeadTerms(data));
    return data;
  } catch (error) {
    return error;
  }
};

export function* postPatientLeadTerms({ payload }) {
  try {
    const userAgentString = navigator.userAgent;
    const mobileRegex = /Mobile|Android|iPhone|iPod|Windows Phone/i;
    const tabletRegex = /Tablet|iPad/i;
  
    let device = 'Desktop'; // Default
  
    if (mobileRegex.test(userAgentString)) {
        device = 'Mobile';
    } else if (tabletRegex.test(userAgentString)) {
        device = 'Tablet';
    }
  
    const request = () => api.post(`/terms/leads/accept/${payload.termId}`, {
      device: payload.device ?? device,
      status: payload.status,
    });
    const data = yield call(request);
    return data;    
  } catch (error) {
    return error;
  }
}