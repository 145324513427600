import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {createReducer} from '@reduxjs/toolkit';

import {setSaveUUIDTrackingEvent} from './actions';

const persistConfig = {
  key: 'tracking',
  version: 1,
  storage,
  whitelist: ['uuidv4'],
};

const initialState = {
  uuidv4: ''
};

const trackingReducer = createReducer(initialState, builder => {
  builder.addCase(setSaveUUIDTrackingEvent, (state, action) => ({
    ...state,
    uuidv4: action.payload,
  }));
});

const persistTracking = persistReducer(
  persistConfig,
  trackingReducer,
);

export default persistTracking;
