import React from "react";
import { useSelector } from "react-redux";

import CovidScreen from "./covid";
import AlertScreen from "./alert";
import ConfirmScreen from "./confirmation";
import { push } from "../../services/navigate";

export default function Screening() {
  const currentDisease = useSelector(
    (state) => state.utilsReducer.currentDisease
  );

  function initVideoCall() {
    return push("/orientacoes")
    // dispatch(call({ ...patient, titleDisease: currentDisease.titleDisease }));
  }

  if (currentDisease && currentDisease.covidScreen) {
    return <CovidScreen initVideoCall={initVideoCall} />;
  }

  if (currentDisease && currentDisease.flagDisease === 2) {
    return <AlertScreen disease={currentDisease} />;
  }

  if (currentDisease && currentDisease.flagDisease === 3) {
    return (
      <ConfirmScreen initVideoCall={initVideoCall} disease={currentDisease} />
    );
  }

  return (window.location.href = "/home");
}
