import React from "react";
import { Container, Iframe } from "./styles";

import { useSelector } from "react-redux";

export default function App() {
  const paymentUrl = useSelector((state) => state.buyReducer.paymentUrl);

  if (!paymentUrl) {
    return <></>;
  }

  return (
    <Container>
      <Iframe src={paymentUrl} />
    </Container>
  );
}
