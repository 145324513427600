import React, { useState, useEffect } from "react";
import {
  Container,
  Layer,
  Main,
  RowMain,
  InitCall,
  GoScheduleList,
  ScheduleInit,
} from "./styles";
import { setLabelWhite } from "../../../redux/actions/utils.actions";
import { openModalUser } from "../../../redux/actions/user.actions";

import {
  setScheduleType,
} from "../../../redux/actions/schedule.actions";

import { useSelector, useDispatch } from "react-redux";
import { FiCalendar } from "react-icons/fi";
import { push } from "../../../services/navigate";
import ReactTooltip from "react-tooltip";
import Modal from "../../../components/modal-schedule-type"

import Profile from "../../home/profile"

import {
  openModalPatient,
} from "../../../redux/actions/user.actions";

export default function App(props) {
  const user = useSelector((state) => state.userReducer.user);
  const dispatch = useDispatch();
  const scheduleType = useSelector((state) => state.schedule.scheduleType)
  const modalUser = useSelector((state) => state.userReducer.modalUser);
  const modal = useSelector((state) => state.userReducer.modalPatient);

  const telepsicologia = useSelector((state) => state.userReducer.telepsicologia);
  const homePage = useSelector((state) => state.userReducer.homePage);
  if(!telepsicologia){
    push(homePage)
  }

  useEffect(() => {
    dispatch(setLabelWhite(true));

    return () => dispatch(setLabelWhite(false));
  }, []);

  function _goToScheduleList() {
    push("/consultas-agendadas")
  }

  function _startScheduling() {
    dispatch(setScheduleType("TELEPSICOLOGIA"));
    dispatch(openModalPatient(true));
    push("/consultas-agendadas")
  }

  function _patientSelected(patient){
    console.log(patient)
  }

  return (
    <Container>
      <Layer />

      <Main>
        <Profile isOpen={modalUser} onClose={openModalUser} />
        <Modal
          isOpen={modal}
          onClose={() => {
            dispatch(openModalPatient(false));
          }}
          onSelectPatient={(patient) => _patientSelected(patient)}
        />
        <RowMain>
          <InitCall>
            <GoScheduleList
              onClick={() => _goToScheduleList()}
            >
              Consultas Agendadas
            </GoScheduleList>
            <ScheduleInit
              elegibility={user.checkElegibility}
              onClick={() => _startScheduling()}
              disabled={!user.checkElegibility}
            >
              <FiCalendar size={25} /> <p>Agendar</p>
            </ScheduleInit>
          </InitCall>
        </RowMain>
      </Main>

      <ReactTooltip />
    </Container>
  );
}
