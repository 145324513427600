import * as yup from "yup"

export default yup.object().shape({
  document: yup
    .string()
    .trim()
    .required("CPF ou Passaporte é obrigatório")
  ,
  password: yup
    .string()
    .trim()
    .required("Senha é obrigatória")
    .min(8, "Mínimo 8 caracteres"),
});
