import React, { useState, useEffect } from "react";
import {
  Form,
  Inputs,
  DivInput,
  ErrorMessage,
  ErrorImage,
  Select,
  Button,
  UploadImage,
  DivImage,
  ImageUser,
  InputNone,
  DivIcon,
  Icon,
  Titles,
  Title,
  SubTitle,
  ButtonsContainer,
} from "../styles";
import { sendUpdateProfilePicture } from "../../../../redux/actions/user.actions";

import Modal from "../../../../components/modal-right";
import useYup from "@usereact/use-yup";
import { brasileiroSchema, estrangeiroSchema } from "./validate";

import InputCustom from "../../../../components/input-custom";

import { useDispatch, useSelector } from "react-redux";
import { editUser } from "../../../../redux/actions/user.actions";
import { convertDate } from "../../../../utils/date";
import { push } from "../../../../services/navigate";
import { patientObjHasDocuments } from "../../../../utils/documentHelpers"
import { getPhoneMaskRule } from "../../../../utils/phoneMask"
import mime from "mime-types";

export default function Profile(props) {
  const userProps = useSelector((state) => state.userReducer.user);
  const patientSentDocuments = useSelector((state) => state.patientReducer.patientSentDocuments);
  const dispatch = useDispatch();

  const [touched, setTouched] = useState(false);
  const [phoneMaskRule, setPhoneMaskRule] = useState(getPhoneMaskRule(""));
  const [tryPhone, setTryPhone] = useState(false);

  const [user, setUser] = useState({
    name: userProps.name,
    lastName: userProps.lastName,
    phone: userProps.phone,
    email: userProps.email,
    document: userProps.document.replace(/([^0-9a-zA-Z])/gi, ''),
    documentType: userProps.documentType,
    rg: userProps.rg,
    birthDate: convertDate(userProps.birthDate),
    sex: userProps.sex,
    preview: userProps.image,
  });

  let { errors } = useYup(user, user.documentType === 'PASSAPORTE' ? estrangeiroSchema : brasileiroSchema, {
    validateOnChange: true,
  });

  useEffect(() => {
    if (userProps && userProps.phone) {
      setPhoneMaskRule(getPhoneMaskRule(user.phone));
    }
  }, [userProps, user]);

  function isEmpty(obj) {
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }

    return true;
  }

  function handleSubmit(e) {
    e.preventDefault();
    // if (!user.preview) {
    //   errors.image = "Insira uma imagem";
    // }

    if (isEmpty(errors)) {
      dispatch(editUser(user));
    }
  }

  function randomString() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    return characters
      .split("")
      .map((_) =>
        characters.charAt(Math.floor(Math.random() * characters.length))
      )
      .join("");
  }

  const handleImage = React.useCallback(
    (e) => {
      const image = e.target.files[0];
      const newStructureImageBucket = {
        documentImage: {
          lastModified: image.lastModified,
          lastModifiedDate: image.lastModifiedDate,
          name: image.name,
          size: image.size,
          type: image.type,
          webkitRelativePath: image.webkitRelativePath,
          url: URL.createObjectURL(image),
        },
        documentName: `${"PROFILE_PIC"}-${randomString()}.${mime.extension(
          image.type
        )}`,
        documentType: "PROFILE_PIC",
        idDocument: `${"PROFILE_PIC"}-${randomString()}.${mime.extension(
          image.type
        )}`,
        imageSource: {
          lastModified: image.lastModified,
          lastModifiedDate: image.lastModifiedDate,
          name: image.name,
          size: image.size,
          type: image.type,
          webkitRelativePath: image.webkitRelativePath,
          url: URL.createObjectURL(image),
        },
        size: image.size,
      };
      dispatch(sendUpdateProfilePicture([newStructureImageBucket]));
      const preview = URL.createObjectURL(image);
      setUser({
        ...user,
        preview,
        image,
      });
    },
    [dispatch]
  );

  function handleChange(e) {
    const { name, value } = e.currentTarget;
    setTouched({
      ...touched,
      [name]: true,
    });
    const reg = /^([a-zA-ZÀ-ú\s\d]+)$/gi
    if (!reg.test(value) && value.length > 0 && name !== 'birthDate') {
      return
    }

    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handlePhone(e) {
    const { name, value } = e.currentTarget;
    setTouched({
      ...touched,
      [name]: true,
    });

    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setPhoneMaskRule(value)
  }

  function uploadDocuments(e) {
    push('/documentos')
  }

  return (
    <Form onSubmit={handleSubmit}>
      <UploadImage>
        <DivImage htmlFor="imageuser">
          <InputNone
            onChange={handleImage}
            id="imageuser"
            type="file"
            accept="image/x-png,image/gif,image/jpeg,image/png"
          />
          <ImageUser
            src={user.preview || "img/default.png"}
            onError={(e) => (e.target.src = "img/default.png")}
          />
          <DivIcon>
            <Icon src="img/icons/upload.png" />
          </DivIcon>
          <ErrorImage>
            {touched["preview"] && errors && errors.image}
          </ErrorImage>
        </DivImage>
        <Titles>
          <Title>Meu Perfil</Title>
          <SubTitle>Altere suas informações pessoais.</SubTitle>
        </Titles>
      </UploadImage>

      <Inputs>
        <InputCustom
          width="325px"
          onChange={handleChange}
          value={user.name}
          name="name"
          placeholder="Nome"
          type="text"
          errorMessage={touched["name"] && errors && errors.name}
        />

        <InputCustom
          width="325px"
          onChange={handleChange}
          value={user.lastName}
          name="lastName"
          placeholder="Sobrenome"
          type="text"
          errorMessage={touched["lastName"] && errors && errors.lastName}
        />

        <InputCustom
          width={user.documentType === "PASSAPORTE" ? "680px" : "325px"}
          value={user.document}
          name="document"
          placeholder={user.documentType === "PASSAPORTE" ? "Passaporte" : "CPF"}
          type="text"
          mask={user.documentType === "PASSAPORTE" ? "" : "999.999.999-99"}
          readOnly={true}
          readOnlyColor={true}
          errorMessage={touched["document"] && errors && errors.document}
          labelMessage={"Esse campo não pode ser editado"}
        />

        {user.documentType !== "PASSAPORTE" && (
          <InputCustom
            width="325px"
            value={user.rg}
            name="rg"
            placeholder="RG"
            type="text"
            readOnlyColor={true}
            readOnly={true}
            errorMessage={touched["rg"] && errors && errors.rg}
            labelMessage={"Esse campo não pode ser editado"}
          />
        )}

        <InputCustom
          width="325px"
          onChange={handleChange}
          value={user.birthDate}
          name="birthDate"
          mask="99/99/9999"
          readOnlyColor={true}
          readOnly={true}
          placeholder="Data de Nascimento"
          errorMessage={touched["birthDate"] && errors && errors.birthDate}
        />

        <DivInput>
          {user.sex && <label>Sexo</label>}
          <Select name="sex" value={user.sex} onChange={handleChange}>
            <option disabled selected value>
              Sexo
            </option>
            <option value="M">Masculino</option>
            <option value="F">Feminino</option>
          </Select>
          <ErrorMessage>{touched["sex"] && errors && errors.sex}</ErrorMessage>
        </DivInput>

        <InputCustom
          width="325px"
          onChange={handlePhone}
          name="phone"
          placeholder={user.documentType === 'PASSAPORTE' ? 'Código do País + DDD + Telefone' : "DDD + Telefone"}
          type="text"
          mask={user.documentType === 'PASSAPORTE' ? '+999999999999999' : phoneMaskRule.mask}
          value={user.phone}
          errorMessage={touched["phone"] && errors && errors.phone}
          maskChar={phoneMaskRule.maskChar}
        />

        <InputCustom
          width="325px"
          onChange={handleChange}
          value={user.email}
          name="email"
          placeholder="E-mail"
          type="text"
          readOnly={true}
          readOnlyColor={true}
          labelMessage={"Esse campo não pode ser editado"}
          errorMessage={touched["email"] && errors && errors.email}
        />
        <ButtonsContainer>
          {!patientSentDocuments && (
            <Button onClick={uploadDocuments} marginRight={16}>
              Upload documentos
            </Button>
          )}
          <Button type="submit">Salvar</Button>
        </ButtonsContainer>
      </Inputs>
    </Form>
  );
}
