import axios from "axios";
import {
  clearCredentials,
  getCredentials,
  hasToken,
} from "../utils/credentials";

const URL_PATIENT = process.env.REACT_APP_TELEMEDICINA_EINSTEIN;

const api = axios.create({
  baseURL: `${URL_PATIENT}/patient`,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": "*",
    Accept: "application/json, text/plain, /",
    "Content-Type": "application/json;charset=utf-8",
  },
});

api.interceptors.request.use((config) => {
  try {
    const { token, patientToken } = getCredentials();
    const hasCredentials = hasToken();

    const patientTokenTemporary = config.headers["patient-token-temporary"];
    let newPatientToken = "";

    if (
      patientTokenTemporary?.length > 0 &&
      patientTokenTemporary !== undefined
    ) {
      newPatientToken = config.headers["patient-token-temporary"];
    } else {
      newPatientToken = patientToken;
    }

    if (hasCredentials) {
      config.headers["x-access-token"] = `Bearer ${token}`;
      config.headers["x-access-patient-token"] = `Bearer ${newPatientToken}`;
    }
  } catch (err) {
    console.error(err);
  } finally {
    return config;
  }
});

export default api;
