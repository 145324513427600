import styled from "styled-components";

import { motion } from "framer-motion";
import animations from "../../utils/animation";

export const Container = styled(motion.div).attrs(animations)`
  position: absolute;
  z-index: 999;
  width: 100%;
  top: 0;
  left: 0;
  height: 100vh;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;
