import React, { useEffect, useState } from "react";
import {
  SideBar,
  DivLogo,
  Logo,
  MenuList,
  MenuItem,
  MenuLabel,
} from "./styles";

import itemsMenu from "../../utils/items-side-bar";
import api from "../../services/api";

import { push } from "../../services/navigate";
import { useDispatch, useSelector } from "react-redux";
import {
  setPatient,
  setSelectedPatient,
} from "../../redux/actions/patient.actions";
import { verifyAuth } from "../../redux/actions/utils.actions";
import { TERMS_PROVIDER_STATUS } from "../../pages/home/select-patient/terms-provider/constants";
import { getElegibilityTermsProvider } from "../../services/termsProvider.service";
import { setTermsProvider } from "../../redux/actions/termsProvider.actions";
import { getRemoteConfigValue } from "../../hooks/RemoteConfig";

export default function App() {
  const dispatch = useDispatch();

  const [activeRoute, setActiveRoute] = useState("/home");
  const [flagScheduling, setFlagScheduling] = useState(false);
  const user = useSelector((state) => state.userReducer.user);
  const provider = useSelector((state) => state.userReducer.selectedProvider);
  const hasTelemedicina = useSelector(
    (state) => state.userReducer.telemedicina
  );
  const hasTelepsicologia = useSelector(
    (state) => state.userReducer.telepsicologia
  );
  const hasCorporateCard = useSelector(
    (state) => state.userReducer.corporateCard
  );
  const hasTermsProvider = useSelector((state) => state.termsProvider.status);
  const hasCorporateCardFeatureEnabled = useSelector(
    (state) => state.featureFlagsReducer.featureFlags.corporateCardEnabled
  );
  const hasTermsProviderFeatureEnabled = useSelector(
    (state) => state.featureFlagsReducer.featureFlags.termsProviderEnabled
  );
  const host = window.location.href;

  function _hasEligibility(product, scheduling) {
    if (scheduling) {
      if (!flagScheduling || provider.validar_tipo_elegibilidade != 0) {
        return false;
      }
    }

    switch (product) {
      case "TERMS_PROVIDER":
        return (
          hasTermsProvider &&
          hasTermsProvider !== TERMS_PROVIDER_STATUS.NOT_ELEGIBLE &&
          hasTermsProviderFeatureEnabled
        );
      case "TELEMEDICINA":
        return hasTelemedicina;
      case "TELEPSICOLOGIA":
        return hasTelepsicologia;
      case "CORPORATE_CARD":
        return hasCorporateCard && hasCorporateCardFeatureEnabled;
      case "SAUDE_ITAU":
        return localStorage.getItem("provider") === "fundacao-itau";
      case "ANY":
        return hasTelemedicina || hasTelepsicologia;
      default:
        return true;
    }
  }

  function getFlagScheduling() {
    api.get("/config-system").then((result) => {
      const flag = result.data.configSystem[0].scheduling;
      setFlagScheduling(flag);
    });
  }

  async function verifyElegibilityTermsProvider() {
    try {
      const isElegility = await getElegibilityTermsProvider();
      dispatch(setTermsProvider(isElegility));
    } catch (error) {
      console.log({ error });
    }
  }

  useEffect(() => {
    verifyElegibilityTermsProvider();
  }, [hasTermsProvider]);

  useEffect(() => {
    getFlagScheduling();
    verifyElegibilityTermsProvider();

    const activeRoute = host.split("/")[3];
    if (!activeRoute) {
      return setActiveRoute(`/home`);
    }

    setActiveRoute(`/${activeRoute}`);
  }, []);

  function changeRoute(infosRoute) {
    if (infosRoute.needEgilibility && !user.checkElegibility) {
      return;
    }
    dispatch(setSelectedPatient(false));
    dispatch(setPatient({}));
    push(infosRoute.route);
    setActiveRoute(infosRoute.route);
    dispatch(verifyAuth());
  }

  function messageToltip(infosRoute) {
    if (infosRoute.needEgilibility && !user.checkElegibility)
      return "Não foi possível checar a sua elegibilidade, favor verificar se você possui algum contrato!";
  }

  function goToHome() {
    push("/home");
    dispatch(setSelectedPatient(false));
  }

  function verifyProdAndDebug(itemMenu) {
    return (
      host.includes("telemedicina.einstein.br") && itemMenu.debug
    );
  }

  function verifyItemRemoteConfig(itemMenu) {
    if (verifyProdAndDebug(itemMenu)) return null;

    if (itemMenu?.remoteConfig) {
      if (!getRemoteConfigValue(itemMenu?.remoteConfig)) return null;
    }

    return true;
  }

  return (
    <SideBar>
      <DivLogo onClick={() => goToHome()}>
        <Logo src="/img/logo-blue.png" />
      </DivLogo>

      <MenuList>
        {itemsMenu.map((itemMenu, key) => {
          if (!verifyItemRemoteConfig(itemMenu))
            return null;
          else {
            return (
              <MenuItem
                key={key}
                visible={_hasEligibility(
                  itemMenu.product,
                  itemMenu.needScheduling
                )}
                active={itemMenu.route === activeRoute}
                onClick={() => changeRoute(itemMenu)}
                data-tip={messageToltip(itemMenu)}
              >
                {itemMenu.icon && itemMenu.icon}
                <MenuLabel active={itemMenu.route === activeRoute}>
                  {itemMenu.label}
                </MenuLabel>
              </MenuItem>
            );
          }
        })}
      </MenuList>
    </SideBar>
  );
}
