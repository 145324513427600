import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import PublicRoute from "./public.routes";
import PrivateRoute from "./private.routes";
import BuyRoute from "./buy.routes";
import PrivatePlanRoute from "./private-plan.routes";

// auth
import LoginV2 from "../auth/login-v2";
import Register from "../auth/register";
import SendLinkRecovery from "../auth/send-link";
import Logout from "../auth/logout";
import RecoveryPassword from "../auth/recovery-password";

// pages
import BuyB2C from "../pages/buy-b2c";
import Home from "../pages/home";
import Disease from "../pages/home/disease";
import TelePsicologia from "../pages/telepsicologia";
import ListDependent from "../pages/dependent/list-dependent";
import Landing from "../pages/landing/";
import WarningScreen from "../pages/warning-screen";
import Contact from "../pages/contact";
import ChangeCreditCard from "../pages/change-credit-card";
import CheckTerms from "../pages/check-terms";
import Screening from "../pages/screening";
import Schedule from "../pages/schedule";
import Scheduled from "../pages/appointments";
import History from "../pages/history";
import CorporateCard from "../pages/einstein-corporate";
import Cancel from "../pages/cancel-plan";
import Descount from "../pages/discount";
import UploadFiles from "../pages/upload-files";
import UploadDocuments from "../pages/upload-documents";
import Guidelines from "../pages/guidelines";
import SelectProvider from "../pages/select-provider";
import PaymentSuccess from "../pages/payment-success";
import SpecialistDoctors from "../pages/specialist-doctors";

import { setNavigator } from "../services/navigate";
import TermsProviderPage from "../pages/terms-provider";
import Privacy from "../pages/privacy";
import TeleconsultTerms from "../pages/teleconsulting-terms";
import Communications from "../pages/communications";
import getRemoteConfigValue from "../hooks/RemoteConfig/getRemoteConfigValue";

const LinkDescount = () => (
  <a href="http://localhost:36108/payment/samsung">SAMSUNG DESCONTO</a>
);

export default function App() {
  const moduleCommunicationsEnabled = getRemoteConfigValue(
    "module_communication_enabled"
  );

  return (
    <BrowserRouter ref={(nav) => setNavigator(nav)}>
      <Switch>
        <BuyRoute path="/comprar" component={BuyB2C} />
        <BuyRoute path="/comprar/:plan" component={BuyB2C} />

        <PublicRoute path="/link" exact component={LinkDescount} />

        <Route path="/cupom-samsung" exact component={Descount} />

        {/* <PublicRoute path="/login" component={Login} /> */}
        {/* <PublicRoute path="/login/:payment" component={Login} /> */}
        {/* <PublicRoute path="/login/v2" component={LoginV2} /> */}
        {/* <PublicRoute path="/login" component={LoginV2} /> */}
        <PublicRoute path="/cadastro" component={() => <Redirect to='/register' />} />
        <PublicRoute path="/cadastro/:plan" component={() => <Redirect to='/register' />} />
        <PublicRoute path="/enviar-link" exact component={SendLinkRecovery} />
        <PublicRoute path="/logout" component={Logout} />
        <PublicRoute
          path="/recuperar-senha"
          exact
          component={RecoveryPassword}
        />

        <Route path="/pagamento-confirmado" exact component={PaymentSuccess} />

        <PrivateRoute path="/home" exact component={Home} />
        <PrivateRoute path="/sintomas" exact component={Disease} />
        <PrivateRoute path="/teleconsulta/warning-screen" exact component={WarningScreen} />
        <PrivateRoute path="/telepsicologia" exact component={TelePsicologia} />
        <PrivateRoute path="/cancelar" exact component={Cancel} />
        <PrivateRoute path="/dependentes" exact component={ListDependent} />
        <PrivateRoute path="/fale-conosco" exact component={Contact} />
        {/* <PrivateRoute path="/termos" exact component={CheckTerms} /> */}
        <PrivateRoute path="/triagem" exact component={Screening} />
        <PrivateRoute path="/historico" exact component={History} />
        <PrivateRoute path="/agendamento" exact component={Schedule} />
        <PrivateRoute path="/consultas-agendadas" exact component={Scheduled} />
        <PrivateRoute path="/arquivos" exact component={UploadFiles} />
        <PrivateRoute path="/orientacoes" exact component={Guidelines} />
        <PrivateRoute path="/provedores" exact component={SelectProvider} />
        <PrivateRoute path="/documentos" exact component={UploadDocuments} />
        <PrivateRoute path="/especialistas-corporate" exact component={SpecialistDoctors} />
        <PrivateRoute
          path="/privacidade/:term?"
          exact
          component={Privacy}
        />
        <PrivateRoute
          path="/teleconsulta/termo"
          exact
          component={TeleconsultTerms}
        />
        <PrivateRoute
          path="/terms-provider"
          exact
          component={TermsProviderPage}
        />
        {moduleCommunicationsEnabled ? (
          <PrivateRoute
            path="/comunicacoes"
            exact
            component={Communications}
          />
        ) : null}
        {/* <PrivateRoute
          path="/einstein-corporate"
          exact
          component={CorporateCard}
        /> */}

        <PrivatePlanRoute
          path="/trocar-cartao"
          exact
          component={ChangeCreditCard}
        />

        {/* <Route path="/" exact component={Landing} /> */}
      </Switch>
    </BrowserRouter>
  );
}
