import React from "react"

import {
  Container,
  ButtonBack,
  DivButtons,
  LabelConfirm,
  ButtonCancel,
  ButtonConfirm,
} from "./styles"

import Modal from "../../../components/modal-confirm"

export default function App(props) {
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      handleClose={props.handleClose}
    >
      <Container>
        <LabelConfirm>
          Deseja iniciar o atendimento sem anexar nenhum arquivo?
        </LabelConfirm>

        <DivButtons>
          <ButtonCancel onClick={() => props.handleClose(false)}>
            Voltar
          </ButtonCancel>
          <ButtonConfirm
            onClick={() => {
              props.handleClose(true)
            }}
          >
            Avançar
          </ButtonConfirm>
        </DivButtons>
      </Container>
    </Modal>
  )
}
