import styled from "styled-components";

export const ButtonFill = styled("button")`
  height: 50px;
  width: 100%;
  color: ${(props) => (props.loading ? "transparent" : "#fff")};
  background-color: #004f92;
  margin-top: 1.5rem;
  border-radius: 25px;
  border: 25px;
  font-size: 18px;
  cursor: pointer;
  position: relative;
  pointer-events: ${(props) => (props.loading ? "none" : "fill")};
  min-height: 45px;

  span {
    font-weight: 500;
  }
  @media (max-width: 768px) {
    margin-top: 1.5rem;
  }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  &:after {
    display: ${(props) => (props.loading ? "block" : "none")};
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-top-color: transparent;
    animation: spinner 0.6s linear infinite;
    z-index: 1;
  }

  &:disabled {
    opacity: 0.5;
    background-color: #9b9b9b;
    cursor: no-drop;

    &:after {
      border: 2px solid #000;
      border-top-color: transparent;
    }
  }
`;

export const ButtonOutline = styled(ButtonFill)`
  background-color: transparent;
  color: ${(props) =>
    props.loading ? "transparent" : props.disabled ? "#777" : "#004f92"};
  border: 1px solid #004f92;

  &:after {
    border-color: #004f92;
    border-top-color: transparent;
  }

  &:disabled {
    opacity: 0.5;
    border-color: #000;
    background-color: #9b9b9b77;
  }
`;
