import React, { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Container,
  Main,
  Titles,
  Title,
  SubTitle,
  DiasesList,
  DiasesItem,
  DivImageDiases,
  DiasesLabel,
  ButtonBack,
  LabelBack,
  IconBack,
  AuxItem,
} from "./styles";
import SidBar from "../../../container";

import { useDispatch, useSelector } from "react-redux";

import { push } from "../../../services/navigate";
import { getDiseases, setDisease } from "../../../redux/actions/utils.actions";
import { postTrackingEvent } from "../../../core/Tracking/actions";

export default function App(props) {
  const dispatch = useDispatch();

  const diseases = useSelector((state) => state.utilsReducer.diseases);

  const patientSelected = JSON.parse(localStorage.getItem("@conecta:patient-selected"));

  useEffect(() => {
    dispatch(getDiseases());
  }, []);

  const goToScreening = React.useCallback(
    (disease) => {
      const uuid = uuidv4();
      localStorage.setItem("@correlationId", uuid);
      dispatch(
        postTrackingEvent([
          {
            correlationId: uuid,
            idempotencyKey: uuidv4(),
            payload: null,
            scope: "upa",
            terminal: false,
            timestamp: new Date(),
            type: "atendimentoImediato",
          },
          patientSelected,
        ])
      );
      dispatch(
        postTrackingEvent([
          {
            correlationId: uuid,
            idempotencyKey: uuidv4(),
            payload: {
              treatmentType: "TELE",
            },
            scope: "upa",
            terminal: false,
            timestamp: new Date(),
            type: "tipoAtendimentoEscolhido",
          },
          patientSelected,
        ])
      );
      dispatch(
        postTrackingEvent([
          {
            correlationId: uuid,
            idempotencyKey: uuidv4(),
            payload: {
              sympton: disease.titleDisease,
              symptonId: disease._id,
            },
            scope: "upa",
            terminal: false,
            timestamp: new Date(),
            type: "sintomaSelecionado",
          },
          patientSelected,
        ])
      );
      dispatch(
        postTrackingEvent([
          {
            correlationId: uuid,
            idempotencyKey: uuidv4(),
            payload: {
              selected: "TELE",
              recommended: "TELE",
              pickedInCareTransitionScreen: null,
            },
            scope: "upa",
            terminal: false,
            timestamp: new Date(),
            type: "triagemFinalizada",
          },
          patientSelected,
        ])
      );
      if (disease && disease.flagDisease !== 1) {
        dispatch(setDisease(disease));
        return push("/triagem");
      }
      dispatch(setDisease(disease));
      return push("/orientacoes");
    },
    [patientSelected, dispatch]
  );

  return (
    <SidBar>
    <Container>
      <Main>
        <ButtonBack
          type="button"
          onClick={() => {
            push('/home')
          }}
        >
          <IconBack src="img/icons/back-icon.png"></IconBack>
          <LabelBack>Voltar</LabelBack>
        </ButtonBack>

        <Titles>
          <Title>Motivo da consulta</Title>
          <SubTitle>
            Selecione abaixo o <b>motivo principal</b> da sua consulta:
          </SubTitle>
        </Titles>
        <DiasesList>
          {diseases.map((item, index) => {
            if (item.showFirst) {
              return (
                <AuxItem key={index}>
                  <DiasesItem onClick={() => goToScreening(item)}>
                    {item.image && <DivImageDiases src={item.image} />}
                  </DiasesItem>
                  <DiasesLabel>{item.titleDisease}</DiasesLabel>
                </AuxItem>
              );
            }
          })}
        </DiasesList>
        <DiasesList>
          {diseases.map((item, index) => {
            if (!item.showFirst) {
              return (
                <AuxItem key={index}>
                  <DiasesItem
                    onClick={() => goToScreening(item)}
                    another={true}
                  >
                    {item.image && <DivImageDiases src={item.image} />}
                  </DiasesItem>
                  <DiasesLabel>{item.titleDisease}</DiasesLabel>
                </AuxItem>
              );
            }
          })}
        </DiasesList>
      </Main>
    </Container>
    </SidBar>
  );
}
