import api from "./api";
import { STORAGE_TERMS_PROVIDER_KEY } from "../pages/home/select-patient/terms-provider/constants";

export async function postElegibilityTermsProvider(props) {
  const { status } = props || {};

  if ( !status ) return {};

  const payload = {
    status,
    lastAccepted: new Date().toISOString(),
  };

  try {
    const responsePost = await api.post('/terms-provider/elegibility', payload);
    const { data } = responsePost;
    return data;
  } catch (error) {
    console.log({ error: 'postElegibilityTermsProvider', errorMessage: error}); 
    return {};
  }
}

export async function getElegibilityTermsProvider() {
  try {
    const response = await api.get('/terms-provider/elegibility');
    const { data } = response; 

    if ( !data || !data.provider ) return {};

    return setLocalStorageTermsProvider(data);
  } catch (error) {
    console.log({ error: 'getElegibilityTermsProvider', errorMessage: error });
    return error;
  }
}

export async function getTermsProviderCurrentPage(props) {
  const { pageSelected } = props || {};
  console.log('getTermsProviderCurrentPage');
  try {
    const response = await api.get(`/terms-provider/elegibility/${pageSelected || ''}`);
    const { data } = response; 
    return setLocalStorageTermsProvider(data);
  } catch (error) {
    console.log({ error: 'getTermsProviderCurrentPage', errorMessage: error });
    return error;
  }
}

function setLocalStorageTermsProvider(data) {
  try {
    localStorage.setItem(STORAGE_TERMS_PROVIDER_KEY, JSON.stringify(data));
  } catch (error) {
    console.log({ error: 'setLocalStorageTermsProvider', errorMessage: error });
  }
  return data;
}