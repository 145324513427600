import {
  FIND_SLOTS,
  FIND_SPECIALTIES,
  FIND_DOCTORS_BY_SPECIALTY_ID,
  CONFIRM_SCHEDULING,
  FIND_PATIENT,
  FIND_PATIENT_TRUCLINIC,
  FIND_SLOT_BY_PATIENT,
  CANCEL_SCHEDULING,
  CANCEL_SCHEDULING_TRUCLINIC,
  SCHEDULE_CLEAR,
  CLEAR_SLOT_BY_PATIENT,
  SCHEDULED_SUCCESS,
  FIND_PRODUCTS,
  SUCCESS_SCHEDULED_CLEAR,
  APPOINTMENT_OLD,
  GET_FLAG_SCHEDULING,
  SET_SCHEDULE_TYPE,
  SET_DAY_SELECTED,
  SET_SELECTED_SLOT,
  SCHEDULE_TELEPSICO,
  SET_OLD_SLOT,
  GET_RESCHEDULE,
  SET_SCHEDULE_MODAL,
  SET_LOADING_SCHEDULE,
  CANCEL_SCHEDULING_SUCESS
} from "./action-types";

export const slotByMonth = (payload) => ({
  type: FIND_SLOTS,
  payload,
});

export const specialtiesByMonth = (payload) => ({
  type: FIND_SPECIALTIES,
  payload,
});

export const doctorsBySpecialtyId = (payload) => ({
  type: FIND_DOCTORS_BY_SPECIALTY_ID,
  payload,
});

export const confirmSchedulingSlot = (payload) => ({
  type: CONFIRM_SCHEDULING,
  payload,
});
export const checkPatient = (payload) => ({
  type: FIND_PATIENT,
  payload,
});
export const checkPatientTruclinic = (payload) => ({
  type: FIND_PATIENT_TRUCLINIC,
  payload,
});
export const slotByPatient = (payload) => ({
  type: FIND_SLOT_BY_PATIENT,
  payload,
});
export const cancelScheduling = (payload) => ({
  type: CANCEL_SCHEDULING,
  payload,
});
export const cancelSchedulingSuccess = (payload) => ({
  type: CANCEL_SCHEDULING_SUCESS,
  payload,
});
export const cancelSchedulingTruclinic = (payload) => ({
  type: CANCEL_SCHEDULING_TRUCLINIC,
  payload,
});
export const clearSchedule = () => ({
  type: SCHEDULE_CLEAR,
});
export const clearSlotByPatient = () => ({
  type: CLEAR_SLOT_BY_PATIENT,
});
export const successScheduled = (payload) => ({
  type: SCHEDULED_SUCCESS,
  payload,
});
export const findProducts = () => ({
  type: FIND_PRODUCTS,
});
export const clearSuccessScheduled = () => ({
  type: SUCCESS_SCHEDULED_CLEAR,
});
export const appointmentOld = (payload) => ({
  type: APPOINTMENT_OLD,
  payload,
});
export const getScheduling = () => ({
  type: GET_FLAG_SCHEDULING,
});

export const setScheduleType = (payload) => ({
  type: SET_SCHEDULE_TYPE,
  payload,
});

export const setDaySelected = (payload) => ({
  type: SET_DAY_SELECTED,
  payload,
});

export const setSelectedSlot = (payload) => ({
  type: SET_SELECTED_SLOT,
  payload,
});

export const scheduleTelepsico = (payload) => ({
  type: SCHEDULE_TELEPSICO,
  payload,
});
export const setOldSlot = (payload) => ({
  type: SET_OLD_SLOT,
  payload,
});
export const getReschedule = (payload) => ({
  type: GET_RESCHEDULE,
  payload,
});

export const setScheduleModal = (payload) => ({
  type: SET_SCHEDULE_MODAL,
  payload,
})

export const setLoadingSchedule = (value) => ({
  type: SET_LOADING_SCHEDULE,
  payload: value,
})
