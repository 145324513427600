import React from "react";
import Container from "../../container";

import Main from "./main";

export default function App() {
  return (
    <Container>
      <Main />
    </Container>
  );
}
