import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/pt-br";
import "semantic-ui-react";
import { show } from "../../services/alert";

import {
	slotByPatient,
	cancelScheduling,
	cancelSchedulingTruclinic,
	setScheduleModal,
} from "../../redux/actions/schedule.actions";

import { push } from "../../services/navigate";
import api from "../../services/api";
import {
	differenceInHours,
	differenceInMinutes,
	format,
	parseISO,
} from "date-fns";
import { FiCalendar } from "react-icons/fi";
import Modal from "../../components/modal-schedule-type";
import Loading from "../../components/loading";

import {
	Body,
	DivTitle,
	TitleSchedule,
	SubTitleSchedule,
	Div,
	HeaderScheduled,
	NoSchedules,
	NewSchedule,
	NewScheduleDiv,
	NewScheduleButton,
	IconText,
	NewScheduleMobile,
} from "./styles";

import "./style.css";

import { ScheduleCard } from "../../components/schedule-card/index";

import { useSelector, useDispatch } from "react-redux";
import Container from "../../container";
import {
	setScheduleType,
	setOldSlot,
} from "../../redux/actions/schedule.actions";
import { openModalPatient } from "../../redux/actions/user.actions";
import {
	setPatient,
	setSelectedPatient,
} from "../../redux/actions/patient.actions";
import Confirm from "./modal-confirm";
import { setSelectedProgram } from "../../redux/actions/telepsico.actions";
import { setLoading } from "../../redux/actions/utils.actions";
import { dateToISoString, getRangeDateFromToday } from "../../utils/date";
import { getRemoteConfigValue } from "../../hooks/RemoteConfig";

function DateTimeFormInline() {
	const moduleHomeMicrofrontendEnabled = getRemoteConfigValue(
		"module_home_microfrontend_enabled"
	);
	const serviceVonageEnabled = getRemoteConfigValue("service_vonage_enabled");
	const featuresSelfScheduling = getRemoteConfigValue(
		"features_self_scheduling"
	);

	const dispatch = useDispatch();

	const modal = useSelector(state => state.userReducer.modalPatient);
	const appointments = useSelector(state => state.schedule.scheduled);
	const openScheduleModal = useSelector(
		state => state.schedule.openScheduleModal
	);
	const loadingSchedule = useSelector(state => state.schedule.loadingSchedule);
	const user = useSelector(state => state.userReducer.user);
	const dependents = useSelector(state => state.dependentsReducer.dependents);
	const telemedicina = useSelector(state => state.userReducer.telemedicina);
	const telepsicologia = useSelector(state => state.userReducer.telepsicologia);
	const provider = useSelector(state => state.userReducer.selectedProvider);

	const [modalPatient, setModalPatient] = useState(false);
	const [selectedSlot, setSelectedSlot] = useState(null);
	const [flagScheduling, setFlagScheduling] = useState(false);
	const [dateSlot, setDateSlot] = useState(false);
	const [hourSlot, setHourSlot] = useState(false);
	const [telepsico] = useState(false);

	function getFlagScheduling() {
		api.get("/config-system").then(result => {
			const flag = result.data.configSystem[0].scheduling;
			setFlagScheduling(flag);
		});
	}

	useEffect(() => {
		if (user && !user.checkElegibility) {
			return push("/home");
		}

		getFlagScheduling();
		const { today, endDate } = getRangeDateFromToday(30);
		dispatch(
			slotByPatient({
				document: user.document,
				initialDate: dateToISoString(today),
				finalDate: dateToISoString(endDate),
				email: user.email,
			})
		);
	}, []);

	const onClickAccessConsult = slot => {
		if (!slot.roomUrl) {
			show("Sala indisponível");
		} else {
			window.open(slot.roomUrl, "_blank");
		}
	};

	const handleOpen = slot => {
		let dateFormating = parseISO(slot.startAt);
		const dateFormat = format(dateFormating, "dd/MM/yyyy");
		const hourFormat = format(dateFormating, "HH:mm");

		setDateSlot(dateFormat);
		setHourSlot(hourFormat);
		setSelectedSlot(slot);
		setModalPatient(true);
	};

	const _onChangeDate = slot => {
		let patient;
		const slotPatient = slot.Patient;
		//check schedule's patient
		if (slotPatient.document == user.document.replace(/([^0-9a-zA-Z])/gi, "")) {
			patient = user;
		} else {
			dependents.map(dep => {
				if (
					dep.document &&
					dep.document !== user.document.replace(/([^0-9a-zA-Z])/gi, "")
				) {
					if (dep.document == slotPatient.document) {
						patient = {
							...dep,
							empresas: [provider],
						};
					}
				} else {
					if (
						dep.birthDate == slotPatient.birthDate.split(" ")[0] &&
						dep.firstName == slotPatient.name &&
						dep.lastName == slotPatient.lastName
					) {
						patient = {
							...dep,
							empresas: [provider],
						};
					}
				}
			});
		}

		if (!patient) {
			return show("Não foi possível identificar o paciente, tente novamente.");
		}
		if (!_enableReschedule(slot)) {
			return show("Não é possível reagendar consultas com menos de 24 horas");
		}
		dispatch(setScheduleType(slot.scheduleType));
		dispatch(setOldSlot(slot));
		dispatch(setPatient(patient));
		dispatch(setSelectedPatient(true));
		dispatch(setSelectedProgram({ title: slot.telepsicoProgram }));
		push("/agendamento");
	};

	const _enableStart = slot => {
		return differenceInMinutes(parseISO(slot.startAt), new Date()) >= -10;
	};

	const _enableReschedule = slot => {
		return (
			differenceInHours(parseISO(slot.startAt), new Date()) > 24 ||
			slot.firstScheduleTelepsico
		);
	};

	const handleClose = confirm => {
		if (confirm) {
			if (serviceVonageEnabled.scheduling) {
				dispatch(
					cancelScheduling({
						scheduleId: selectedSlot.id,
						channel: selectedSlot.channel,
					})
				);
			} else {
				dispatch(cancelSchedulingTruclinic(selectedSlot.id));
			}
			setModalPatient(false);
		} else {
			setModalPatient(false);
		}
	};

	function _startScheduling() {
		if (telepsicologia && telemedicina) dispatch(setScheduleType(null));
		else if (telemedicina && !telepsicologia) {
			dispatch(setScheduleType("TELEMEDICINA"));
		} else if (telepsicologia && !telemedicina) {
			dispatch(setScheduleType("TELEPSICOLOGIA"));
		}

		if (moduleHomeMicrofrontendEnabled) {
			localStorage.setItem(
				"@home:modal-selected-patient",
				JSON.stringify({
					visibleModal: true,
					typeModal: "modal_schedule",
				})
			);
		} else {
			dispatch(openModalPatient(true));
		}
	}

	function _patientSelected(patient) {
		console.log(patient);
	}

	const _isTelemedicinaSlot = slot => {
		return slot.scheduleType === "TELEMEDICINA";
	};

	function handleScheduleByFlag() {
		if (featuresSelfScheduling.modal_side_scheduling) {
			dispatch(setScheduleModal(!openScheduleModal));
		} else {
			_startScheduling();
		}
	}

	return (
		<Container>
			<Modal
				isOpen={modal}
				onClose={() => {
					dispatch(openModalPatient(false));
				}}
				onSelectPatient={patient => _patientSelected(patient)}
			/>
			<Div>
				<DivTitle>
					<TitleSchedule>Consultas agendadas</TitleSchedule>
					<SubTitleSchedule>Gerencie seus agendamentos</SubTitleSchedule>
				</DivTitle>
				{appointments.length > 0 && (
					<NewScheduleDiv>
						<NewScheduleButton
							appointment
							onClick={() => handleScheduleByFlag()}
						>
							<IconText>
								<FiCalendar />
							</IconText>
							Novo Agendamento
						</NewScheduleButton>
					</NewScheduleDiv>
				)}
				<DivTitle>
					<TitleSchedule>Agendamentos</TitleSchedule>
				</DivTitle>

				<Body>
					{appointments.length === 0 && (
						<HeaderScheduled>
							<NoSchedules>
								Você não possui nenhum agendamento.
								<br />
								Clique abaixo para iniciar.
							</NoSchedules>

							<NewSchedule
								hideMobile={true}
								onClick={() => handleScheduleByFlag()}
							>
								<FiCalendar />
								Novo Agendamento
							</NewSchedule>
						</HeaderScheduled>
					)}

					{appointments &&
						appointments.map((slot, key) => (
							<ScheduleCard
								name={slot.name}
								key={key}
								telemedicina={_isTelemedicinaSlot(slot)}
								date={moment(slot.startAt).format("DD/MM")}
								time={moment(slot.startAt).format("HH:mm")}
								consulta={
									_isTelemedicinaSlot(slot) ? "Espontânea" : slot.sessionName
								}
								especialidade={
									slot.scheduleType === "TELEMEDICINA"
										? slot.Specialty.name
										: slot.specialty
								}
								profissional={
									slot.doctorRefName ? slot.doctorRefName : slot.doctor
								}
								onStart={() => onClickAccessConsult(slot)}
								onCancel={() => handleOpen(slot)}
								onChangeDate={() => _onChangeDate(slot)}
								enableStart={_enableStart(slot)}
								enableReschedule={_enableReschedule(slot)}
								type={slot.type}
								address={slot.address || ""}
							/>
						))}
				</Body>

				<Confirm
					isOpen={modalPatient}
					dateSlot={dateSlot}
					hourSlot={hourSlot}
					handleClose={handleClose}
					onClose={() => setModalPatient(false)}
				/>
			</Div>
			<NewScheduleMobile>
				<NewSchedule onClick={() => handleScheduleByFlag()}>
					<FiCalendar />
					Novo Agendamento
				</NewSchedule>
			</NewScheduleMobile>
			{loadingSchedule && <Loading />}
		</Container>
	);
}
export default DateTimeFormInline;
