import React, { useEffect } from "react";
import Container from "../../container";

import SelectPatient from "./select-patient";
import { useSelector } from "react-redux";
import { setSelectedPatient } from "../../redux/actions/patient.actions";
import { push } from "../../services/navigate";
import { getRemoteConfigValue } from "../../hooks/RemoteConfig";
import CommunicationsModals from "../communications/modals";

export default function App() {
  const moduleHomeMicrofrontendEnabled = getRemoteConfigValue(
    "module_home_microfrontend_enabled"
  );
  const moduleCommunicationsEnabled = getRemoteConfigValue(
    "module_communication_enabled"
  );
  const telemedicina = useSelector((state) => state.userReducer.telemedicina);
  const homePage = useSelector((state) => state.userReducer.homePage);
  if (!telemedicina) {
    push(homePage);
  }

  useEffect(() => {
    localStorage.removeItem("@change_plan");
    localStorage.removeItem("@current_plan");
    localStorage.removeItem("@CHANGE_NAME_terms_provider");
    localStorage.removeItem("conecta:docs");
    localStorage.removeItem("conecta:exams");
  }, []);

  return (
    <Container>
      {moduleCommunicationsEnabled ? <CommunicationsModals /> : null}
      {!moduleHomeMicrofrontendEnabled && (
        <SelectPatient setSelectedPatient={setSelectedPatient} />
      )}
    </Container>
  );
}
