import React from "react";
import "./styles.css";

export default function Covid(props) {
  return (
    <div class="round">
      <input
        onChange={{}}
        checked={props.checked}
        type="checkbox"
        id="checkbox"
      />
      <label for="checkbox"></label>
    </div>
  );
}
