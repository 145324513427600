export function convertDate(data) {
  if (data) {
    let arrayData = data.split("-");
    data = `${arrayData[2]}/${arrayData[1]}/${arrayData[0]}`;
  }

  return data;
}

export function generateDate(data) {
  if (data) {
    let arrayData = data.split("/");
    data = `${arrayData[2]}-${arrayData[1]}-${arrayData[0]}`;
  }

  return data;
}

export function getRangeDateFromToday(days) {
  const dateNow = new Date();
  const rangeDateEnd = new Date();
  rangeDateEnd.setDate(rangeDateEnd.getDate() + days)
  return {
    today: dateNow,
    endDate: rangeDateEnd,
  };
}

export function dateToISoString(date) {
  return date.toISOString().split("T")[0];
}
