import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import Loading from "../components/loading";
import { verifyAuth, buyAuth, setLogged } from "../redux/actions/utils.actions";
import { useDispatch, useSelector } from "react-redux";

export default function PrivateRoute(props) {
  const isLoaded = useSelector((state) => state.utilsReducer.loaded);
  const dispatch = useDispatch();
  const location = props.location
  const token = location.search.split('?token=')[1] ? location.search.split('?token=')[1] : false
  if (token) {
    localStorage.setItem('@ios_app', true)
  } else {
    localStorage.removeItem('@ios_app')
  }

  useEffect(() => {
    dispatch(buyAuth());
  }, []);

  function returnPrivateRoute() {
    return <Route {...props} />;
  }

  return !isLoaded ? <Loading /> : returnPrivateRoute();
}
