import styled, { css } from "styled-components";

const active = css`
  background-color: #2196f3;
  color: #fff;
`;

export const ScheduleDay = styled.div`
  display: flex;
  background-color: #ffffff ;
  border-radius: 8px;
  margin-top: 20px;
`;
