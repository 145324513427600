import React, { useState, useEffect } from "react";
import "moment/locale/pt-br";
import "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { getTelepsicoPrograms } from "../../../redux/actions/telepsico.actions"
import ProgramCard from "../../../components/scheduleTelepsico/select-program"
import Schedule from "../../../components/scheduleTelepsico"
function Main() {
    const dispatch = useDispatch();
    const selectedProgram = useSelector((state) => state.telepsico.selectedProgram);
    const oldSlot = useSelector((state) => state.schedule.oldSlot);

    return(
        <>
            {!selectedProgram && !oldSlot ? <ProgramCard/> : <Schedule/>}
        </>
    )
}
export default Main;
