import React from "react";

import { Main, Link, Title, Titles, SubTitle, Close } from "./styles";

import { IoIosCloseCircle } from "react-icons/io";
import { AiFillEye } from "react-icons/ai";

import { generateDocumentAssign } from "../../../redux/actions/utils.actions";

import Modal from "../../../components/modal";
import { useDispatch } from "react-redux";
import { getCredentials } from "../../../utils/credentials";

export default function App(props) {
	const dispatch = useDispatch();

	function openDocument(document) {
		dispatch(
			generateDocumentAssign({
				token: getCredentials().patientToken,
				filename: document.filename,
				location: document.location,
			})
		);
	}

	return (
		<Modal isOpen={props.open}>
			<Main>
				<Close onClick={props.onClose}>
					<IoIosCloseCircle size={30} />
				</Close>
				<Titles>
					<Title>Pronto Atendimento Virtual</Title>
					<SubTitle>Data: {props.history.date}</SubTitle>
					<SubTitle>Hora: {props.history.hour}</SubTitle>
					<SubTitle>Paciente: {props.history.namePatient}</SubTitle>
					<SubTitle>Médico: {props.history.nameDoctor}</SubTitle>
				</Titles>
				{(props.history.documents || []).map((item, key) => {
					return (
						<Link key={key} onClick={() => openDocument(item)}>
							<p>{item.classification || item.name}</p>
							<AiFillEye size="20px" />
						</Link>
					);
				})}
			</Main>
		</Modal>
	);
}
