import { all, takeEvery } from 'redux-saga/effects';
import {
  LOGIN_REQUEST,
  DEPENDENTS_GETALL_REQUEST,
  ADD_DEPENDENT,
  VERIFY_AUTH,
  EDIT_DEPENDENT,
  EDIT_USER,
  REGISTER_USER,
  CALL,
  SEND_LINK_RECOVERY,
  RECOVERY_PASSWORD,
  GET_PLANS,
  GET_PLANS_BY_CATEGORY,
  REGISTER_PAYMENT,
  SET_NEW_CREDIT_CARD,
  SEND_EMAIL,
  RESEND_EMAIL,
  GET_ASSIGN,
  FIND_SLOTS,
  FIND_SPECIALTIES,
  FIND_PATIENT,
  FIND_PATIENT_TRUCLINIC,
  FIND_DOCTORS_BY_SPECIALTY_ID,
  BUY_AUTH,
  CHECK_TERMS,
  GENERATE_BUY_TOKEN,
  CHANGE_PLAN,
  GET_DISEASE,
  FIND_SLOT_BY_PATIENT,
  OPEN_MODAL_REQUEST,
  CHANGE_PASSWORD,
  CANCEL_SCHEDULING,
  SEND_CANCEL_REQUEST,
  GET_HISTORY,
  FIND_PRODUCTS,
  GET_FLAG_SCHEDULING,
  CHECK_CARD_NUMBER,
  CHECK_PHONE_NUMBER,
  CHECK_PIN,
  UPLOAD_FILES,
  UPLOAD_DOCUMENTS,
  PATIENT_SELECTED,
  NEXT_DOCS_UPLOAD_STEP,
  UNDO_DOCS_UPLOAD_STEP,
  SET_SELECTED_DOC_TYPE,
  SELECT_DOC_STEP_FILE,
  IGNORE_UPLOAD_DOCS,
  FETCH_CURRENT_PRIVATE_PLAN,
  REMOVE_DEPENDENT,
  GET_TELEPSICO_PROGRAMS,
  GET_AVAILABLE_DATE,
  USER_SET_PROVIDER,
  SCHEDULE_TELEPSICO,
  CANCEL_SCHEDULING_TRUCLINIC,
  GET_RESCHEDULE,
  GET_AVAILABLE_DATE_DOCTOR,
  VALIDATE_VOUCHER,
  SET_VOUCHER,
  GET_CORPORATE_CARD_REQUEST,
  GET_TERMS_PROVIDER,
  GET_FEATURE_FLAGS_REQUEST,
  SET_CATALOG_PLANS,
  GET_CATALOG_PLANS,
  UPLOAD_EXAMS,
  UPLOAD_UPDATE_PROFILE_PICTURE,
  GET_PATIENT_TERMS,
  REGISTER_PATIENT_TERMS,
  GET_TELECONSULTATION_TERMS,
  REGISTER_TELECONSULTATION_TERMS,
  GET_PATIENT_LEAD_TERMS,
  SET_PATIENT_LEAD_TERMS_FILTERED,
  POST_PATIENT_LEAD_TERMS,
  FIND_SPECIALIST_DOCTORS,
  FIND_SPECIALTY
} from "../actions/action-types";

import {
  login,
  editUser,
  register,
  sendLinkRecovery,
  recoveryPassword,
  changePassword,
  findPhoneNumber,
  findPin,
  sendUploadFiles,
  sendUploadDocuments,
  sendUpdateProfilePicture,
  patientSelected,
  currentPrivatePlanRequested,
  setProvider,
  sendUploadExams,
} from "./user";

import { getAll } from './dependents';
import {
  checkAuth,
  sendEmail,
  reSendEmail,
  buyAuth,
  checkTerms,
  generateBuyToken,
  getDiseases,
  openModalRequest,
  sendCancelRequest,
  goToNextUploadStep,
  undoDocUploadStep,
  uploadDocTypeSelected,
  currentDocumentStepFileSelected,
  uploadDocsIgnored,
} from './utils';

import { callVideoConference } from './call';

import {
  findSlots,
  findSpecialties,
  findDoctorsBySpecialtyId,
  findPatient,
  createSchedulingTruclinic,
  findSLotByPatient,
  cancelScheduling,
  cancelSchedulingTruclinic,
  findProducts,
  configSystem,
  scheduleTelepsico,
  reschedule,
} from "./schedule";

import { getHistory, getAssignUrl } from './history';

import {
  getPlansApi,
  getPlansCategoryApi,
  registerPayment,
  getCurrentPlan,
  verifyVoucher,
} from './buy';
import { setVoucher } from '../actions/buy.actions';

import {getAllDoctors, getAllSpecialties } from './specialistDoctor'

import { listTelepsicoPrograms, listDate, listDateByDoctor } from "./telepsico"
import { getPatientTermsSaga, getTermsProviderSaga, registerViewTermsSaga, getTeleconsultationTermsSaga, registerConsultationTermsSaga, getPatientLeadTerms, postPatientLeadTerms } from './termsProvider.saga';
import { getCorporateCardRequest } from './corporateCard';
import { getFeatureFlagsRequest } from './featureFlags.saga';
import { getCatalogPlans } from './catalog';
import trackingSagas from '../../core/Tracking/sagas'
import homeSagas from '../../core/Home/sagas'
import scheduleSagas from '../../core/Scheduling/sagas'
import { setPatientLeadTermsFiltered } from '../actions/termsProvider.actions';


export default function* rootSaga() {
  yield all([
    ...trackingSagas,
    ...homeSagas,
    ...scheduleSagas,
    takeEvery(CALL, callVideoConference),
    takeEvery(LOGIN_REQUEST, login),
    takeEvery(REGISTER_USER, register),
    takeEvery(DEPENDENTS_GETALL_REQUEST, getAll),
    takeEvery(SEND_LINK_RECOVERY, sendLinkRecovery),
    takeEvery(RECOVERY_PASSWORD, recoveryPassword),
    takeEvery(VERIFY_AUTH, checkAuth),
    takeEvery(BUY_AUTH, buyAuth),
    takeEvery(CHECK_TERMS, checkTerms),
    takeEvery(GENERATE_BUY_TOKEN, generateBuyToken),
    takeEvery(EDIT_USER, editUser),
    takeEvery(GET_PLANS, getPlansApi),
    takeEvery(GET_PLANS_BY_CATEGORY, getPlansCategoryApi),
    takeEvery(REGISTER_PAYMENT, registerPayment),
    takeEvery(SEND_EMAIL, sendEmail),
    takeEvery(RESEND_EMAIL, reSendEmail),
    takeEvery(FIND_SLOTS, findSlots),
    takeEvery(FIND_SPECIALTIES, findSpecialties),
    takeEvery(FIND_PATIENT, findPatient),
    takeEvery(FIND_PATIENT_TRUCLINIC, createSchedulingTruclinic),
    takeEvery(CANCEL_SCHEDULING_TRUCLINIC, cancelSchedulingTruclinic),
    takeEvery(FIND_DOCTORS_BY_SPECIALTY_ID, findDoctorsBySpecialtyId),
    takeEvery(CHANGE_PLAN, getCurrentPlan),
    takeEvery(GET_DISEASE, getDiseases),
    takeEvery(FIND_SLOT_BY_PATIENT, findSLotByPatient),
    takeEvery(OPEN_MODAL_REQUEST, openModalRequest),
    takeEvery(CHANGE_PASSWORD, changePassword),
    takeEvery(CANCEL_SCHEDULING, cancelScheduling),
    takeEvery(SEND_CANCEL_REQUEST, sendCancelRequest),
    takeEvery(GET_HISTORY, getHistory),
    takeEvery(GET_ASSIGN, getAssignUrl),
    takeEvery(FIND_PRODUCTS, findProducts),
    takeEvery(GET_FLAG_SCHEDULING, configSystem),
    takeEvery(CHECK_PHONE_NUMBER, findPhoneNumber),
    takeEvery(CHECK_PIN, findPin),
    takeEvery(UPLOAD_FILES, sendUploadFiles),
    takeEvery(UPLOAD_DOCUMENTS, sendUploadDocuments),
    takeEvery(UPLOAD_UPDATE_PROFILE_PICTURE, sendUpdateProfilePicture),
    takeEvery(UPLOAD_EXAMS, sendUploadExams),
    takeEvery(PATIENT_SELECTED, patientSelected),
    takeEvery(NEXT_DOCS_UPLOAD_STEP, goToNextUploadStep),
    takeEvery(UNDO_DOCS_UPLOAD_STEP, undoDocUploadStep),
    takeEvery(SET_SELECTED_DOC_TYPE, uploadDocTypeSelected),
    takeEvery(SELECT_DOC_STEP_FILE, currentDocumentStepFileSelected),
    takeEvery(IGNORE_UPLOAD_DOCS, uploadDocsIgnored),
    takeEvery(FETCH_CURRENT_PRIVATE_PLAN, currentPrivatePlanRequested),
    takeEvery(GET_TELEPSICO_PROGRAMS, listTelepsicoPrograms),
    takeEvery(GET_AVAILABLE_DATE, listDate),
    takeEvery(GET_AVAILABLE_DATE_DOCTOR, listDateByDoctor),
    takeEvery(USER_SET_PROVIDER, setProvider),
    takeEvery(SCHEDULE_TELEPSICO, scheduleTelepsico),
    takeEvery(GET_RESCHEDULE, reschedule),
    takeEvery(VALIDATE_VOUCHER, verifyVoucher),
    takeEvery(SET_VOUCHER, setVoucher),
    takeEvery(GET_TERMS_PROVIDER, getTermsProviderSaga),
    takeEvery(GET_CORPORATE_CARD_REQUEST, getCorporateCardRequest),
    takeEvery(GET_FEATURE_FLAGS_REQUEST, getFeatureFlagsRequest),
    takeEvery(GET_CATALOG_PLANS, getCatalogPlans),
    takeEvery(GET_PATIENT_TERMS, getPatientTermsSaga),
    takeEvery(REGISTER_PATIENT_TERMS, registerViewTermsSaga),
    takeEvery(GET_TELECONSULTATION_TERMS, getTeleconsultationTermsSaga),
    takeEvery(REGISTER_TELECONSULTATION_TERMS, registerConsultationTermsSaga),
    takeEvery(GET_PATIENT_LEAD_TERMS, getPatientLeadTerms),
    takeEvery(SET_PATIENT_LEAD_TERMS_FILTERED, setPatientLeadTermsFiltered),
    takeEvery(POST_PATIENT_LEAD_TERMS, postPatientLeadTerms),
    takeEvery(FIND_SPECIALIST_DOCTORS, getAllDoctors),
    takeEvery(FIND_SPECIALTY, getAllSpecialties),
  ]);
}
