import React, { useState, useEffect } from "react";
import {
  Main,
  Titles,
  Title,
  SubTitle,
  Form,
  DivTextArea,
  LabelTextArea,
  TextArea,
  ErrorMessage,
  DivButton,
  Button,
} from "./styles";

import useYup from "@usereact/use-yup";
import {brasileiroSchema, estrangeiroSchema} from "./validate";
import Input from "../../components/input-custom";
import Container from "../../container";

import { sendCancelRequest } from "../../redux/actions/utils.actions";
import { useDispatch, useSelector } from "react-redux";

export default function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer.user);

  const [maskPhone, setMaskPhone] = useState("");
  
  const [touched, setTouched] = useState(false);
  const [values, setValues] = useState({
    document: "",
    phone: "",
    email: "",
    name: "",
    message: "",
    documentType: ""
  });

  let { errors } = useYup(values, values.documentType === "CPF" ? brasileiroSchema : estrangeiroSchema, {
    validateOnChange: true,
  });

  useEffect(() => {
    if (user && user.email) {
      setValues({
        document: user.document,
        phone: user.phone,
        email: user.email,
        name: user.name,
        documentType: user.documentType
      });
    }

    if (user && user.phone && user.phone.length === 15) {
      setMaskPhone(user.documentType === "CPF" ? "(99) 99999-9999" : "+999999999999999");
    }
  }, []);

  function isEmpty(obj) {
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }

    return true;
  }

  function handleSubmit(e) {
    e.preventDefault();
    setTouched({
      document: true,
      phone: true,
      email: true,
      name: true,
      message: true,
    });

    if (isEmpty(errors)) {
      dispatch(sendCancelRequest(values));
    }
  }

  function handleChange(e) {
    const { name, value } = e.currentTarget;
    setTouched({
      ...touched,
      [name]: value,
    });
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function clearMask(e) {
    setMaskPhone("");
  }

  function setMask(e) {
    let phone = e.currentTarget.value;
    phone = phone
      .replace(/_/g, "")
      .replace("(", "")
      .replace(")", "")
      .replace(/-/g, "")
      .replace(/ /g, "")
      .replace(/[a-z]+/g, "")
      .replace(/[A-Z]+/g, "");
    setMaskPhone("");

    if (phone.length >= 11) {
      setMaskPhone(user.documentType === "CPF" ? "(99) 99999-9999" : "+999999999999999");
    } else {
      setMaskPhone(user.documentType === "CPF" ? "(99) 9999-9999" : "+999999999999999");
    }
    if (phone.length <= 9) setMaskPhone("");
  }

  function handlePhone(e) {
    const { name, value } = e.currentTarget;
    setTouched({
      ...touched,
      [name]: true,
    });

    
    let phone = value
    .replace(/_/g, "")
    .replace("(", "")
    .replace(")", "")
    .replace("+", "")
    .replace(/-/g, "")
    .replace(/ /g, "")
    .replace(/[a-z]+/g, "")
    .replace(/[A-Z]+/g, "");
    
    setValues((prevState) => ({
      ...prevState,
      [name]: phone,
    }));
  }

  return (
    <Container>
      <Main>
        <Titles>
          <Title>Formulário de cancelamento ou exclusão de dependentes</Title>
          <SubTitle>
            Confirme os dados abaixo e em até 02 dias úteis a equipe do Einstein
            Conecta entrará em contato para realizar o seu cancelamento
          </SubTitle>
        </Titles>

        <Form onSubmit={handleSubmit}>
          <Input
            width="450px"
            onChange={handleChange}
            name="name"
            placeholder="Nome"
            type="text"
            value={values.name}
            errorMessage={touched["name"] && errors && errors.name}
            readOnlyColor={true}
            readOnly={true}
            labelMessage={"Esse campo não pode ser editado"}
          />

          <Input
            width="450px"
            onChange={handleChange}
            name="document"
            placeholder={user.documentType === "CPF" ? "CPF" : "Passaporte"}
            type="text"
            mask={user.documentType === "CPF" ? "999.999.999-99" : "*****************************"}
            maskChar= {""}
            value={values.document}
            errorMessage={touched["document"] && errors && errors.document}
            readOnlyColor={true}
            readOnly={true}
            labelMessage={"Esse campo não pode ser editado"}
          />

          <Input
            maxLength={11}
            width="450px"
            onChange={handlePhone}
            onClick={clearMask}
            onSelect={clearMask}
            onBlur={setMask}
            name="phone"
            placeholder={user.documentType === "CPF" ? "DDD + Telefone" : "Código do País + DDD + Telefone"}
            type="text"
            mask={maskPhone}
            maskChar = ""
            value={values.phone}
            errorMessage={touched["phone"] && errors && errors.phone}
            readOnlyColor={true}
            readOnly={true}
            labelMessage={"Esse campo não pode ser editado"}
          />

          <Input
            width="450px"
            onChange={handleChange}
            name="email"
            placeholder="E-mail"
            type="text"
            value={values.email}
            errorMessage={touched["email"] && errors && errors.email}
            readOnlyColor={true}
            readOnly={true}
            labelMessage={"Esse campo não pode ser editado"}
          />

          <DivTextArea>
            <LabelTextArea>Motivo do cancelamento</LabelTextArea>
            <TextArea
              onChange={handleChange}
              name="message"
              type="text"
              value={values.message}
            />
            <ErrorMessage>
              {touched["message"] && errors && errors.message}
            </ErrorMessage>
          </DivTextArea>

          <DivButton>
            <Button type="submit">Solicitar cancelamento</Button>
          </DivButton>
        </Form>
      </Main>
    </Container>
  );
}
