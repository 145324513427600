import React from "react";
import { Container, Logo, Message, Button, Wrap, Title, Subtitle, ButtonClose, WrapRight } from "./styles";

import { useDispatch } from "react-redux";

export default function App({ style, options, message, close }) {
  const dispatch = useDispatch();

  function reSendEmailFC() {
    dispatch(options.buttonAction)
  }

  return (
    <Container style={style}>
      {options.button && <Title type={options.type}>{'Sua conta não foi ativada'}</Title>}
      {options.button &&<Subtitle type={options.type}>{'Ative sua conta, clicando no link que foi encaminhado para o seu e-mail.'}</Subtitle>}
      <WrapRight>
        {options.button && <Button onClick={reSendEmailFC}>Reenviar Link</Button> }
        {options.button &&<Button onClick={close}>Fechar</Button>}
      </WrapRight>
      <Wrap>
        {!options.button &&<Message type={options.type}>{message}</Message>}
        {!options.button && <ButtonClose onClick={close}>X</ButtonClose>}
      </Wrap>
    </Container>
  );
}
