import styled, { css, createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: none;
        border: 0;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    }
    html{
      height: 100vh;
      scroll-behavior: smooth;

    }
    button{
      cursor: pointer;
      border: 0;
      background: transparent;
      &:hover{
        opacity: 0.8;
        transition: 0.2s
      }
    }
    a{
      text-decoration: none;
    }

    select {
       font-size: 14px;
        padding: 0 10px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: transparent;
        color: #000;
        outline: none;
    }
    body {
      overflow-x: hidden
    }
`;

export const Container = styled.div``;

export const User = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
  min-width: 190px;
  height: 55px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Message = styled.p`
  color: ${(props) => (props.white ? "#fff" : "#000")};
  text-align: right;
  font-size: 14px;
  margin: 0 15px;
`;

export const ImageUser = styled.img`
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 36px;
`;

export const Options = styled.div`
  position: absolute;
  width: 171px;
  top: 80px;
  right: 25px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  z-index: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 0 0 0;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const OptionItem = styled.button`
  cursor: pointer;
  width: 75%;
  color: #d1d1d1;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 8px;
  border-bottom: 0.5px solid #808080;
  margin: 10px 0;
`;

export const ContainerForm = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Form = styled.form`
  margin: auto;
  box-shadow: 0px 0px 16px 0px rgba(227, 227, 227, 1);
  width: 300px;
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  text-align: center;

  input {
    margin: 20px 0;
    border: 0;
    border-bottom: 1px solid #000;
    padding: 7px 0;

    &:focus {
      outline: none;
    }
  }

  p {
    margin: 0;
    color: #707070;
  }

  button {
    margin: 10px 0;
    border: 0;
    background: #25428a;
    color: white;
    padding: 10px 0px;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 3px;
  }
`;

export const ModalConfirm = styled.div`
  width: 400px;
  height: 270px;
  background: #fff;
  border-radius: 3px;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
  }
`;

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px 0 0;
`;

export const Button = styled.button`
  width: 160px;
  height: 40px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;

  ${(props) => props.contained && contained}
  ${(props) => props.outline && outline}

  @media (max-width: 1024px) {
    margin: 0 20px;
    min-width: 100px;
  }
`;

const contained = css`
  background: #e0832c;
  color: #fff;
`;

const outline = css`
  border: 1px solid #e0832c;
  color: #e0832c;
`;
