import { createAction } from '@reduxjs/toolkit';

const prefix = 'tracking';

export const postTrackingEvent = createAction(
  `${prefix}/postTrackingEvent`
);

export const setSaveUUIDTrackingEvent = createAction(
  `${prefix}/setSaveUUIDTrackingEvent`
);
