import axios from "axios";
import { getCredentials } from "../utils/credentials";

const { REACT_APP_MINHA_CONTA_MANAGEMENT } = process.env

const apiMinhaConta = axios.create({
  baseURL: `${REACT_APP_MINHA_CONTA_MANAGEMENT}`,
});

apiMinhaConta.interceptors.request.use(config => {

  const { patientToken } = getCredentials();

  config.headers.Authorization = `Bearer ${patientToken}`

  return config

})

export { apiMinhaConta }
