import { call, put } from "redux-saga/effects";
import { setLoading } from "../actions/utils.actions";
import { show } from "../../services/alert";
import { setSelectedPatient } from "../actions/patient.actions";
import { push } from "../../services/navigate";
import { getDesktopData } from "../../utils/getDesktopData";
import api from "../../services/api";
import apiPatient from "../../services/apiPatient";

export const deleteVonageFinishCall = async (sessionId, patientToken) => {
  return await apiPatient
    .post(
      "/vonage/finishCall",
      {
        sessionId: sessionId,
      },
      {
        headers: {
          "patient-token-temporary": patientToken,
        },
      }
    )
    .then(async ({ data }) => {
      const { message } = data;
      console.log({ message });
      show("Realizado o cancelamento do acionamento. Tente novamente!");
      return true;
    })
    .catch(() => {
      show("Erro ao cancelar acionamento");
      return false;
    });
};

export const getVonageSessionStatus = async (patientToken, idPatient) => {
  const sessionsOldPatient = await JSON.parse(
    localStorage.getItem("@vonage_session_status")
  );

  if (sessionsOldPatient !== null) {
    if (sessionsOldPatient?.sessionId !== undefined) {
      localStorage.removeItem("@vonage_session_status");
    }
  }

  const sessionsCurrentPatient = await JSON.parse(
    localStorage.getItem("@vonage_session_status")
  );

  let verifySession = {
    sessionId: "",
    url: "",
  };

  if (sessionsCurrentPatient !== null) {
    const filterSession = sessionsCurrentPatient.filter(
      (value) => value.idPatient === idPatient
    )[0];
    verifySession = {
      sessionId: filterSession !== undefined ? filterSession.sessionId : "",
      url: filterSession !== undefined ? filterSession.url : "",
    };
  }

  return await apiPatient
    .post(
      "/vonage/getSession",
      {
        sessionId: verifySession.sessionId,
      },
      {
        headers: {
          "patient-token-temporary": patientToken,
        },
      }
    )
    .then(async ({ data }) => {
      const { session } = data;
      if (session.active) {
        const isSafari =
          /constructor/i.test(window.HTMLElement) ||
          (function (p) {
            return p.toString() === "[object SafariRemoteNotification]";
          })(
            !window["safari"] ||
            (typeof safari !== "undefined" && safari.pushNotification)
          );

        if (isSafari) {
          window.open(verifySession.url, "_self");
        } else {
          if (getDesktopData().deviceType.toLocaleLowerCase() === "mobile") {
            window.open(verifySession.url, "_self");
          } else {
            window.open(verifySession.url, "_blank");
          }
        }

        return true;
      } else {
        const filterSession = sessionsCurrentPatient.filter(
          (value) => value.sessionId !== verifySession.sessionId
        );
        let newSessionsPatients = [];
        if (sessionsCurrentPatient !== null) {
          filterSession.map((value) => {
            newSessionsPatients.push(value);
          });
        }
        localStorage.setItem(
          "@vonage_session_status",
          JSON.stringify(newSessionsPatients)
        );
        return false;
      }
    })
    .catch(() => {
      return false;
    });
};

export const getUserProductsRequest = async (patientToken) => {
  return await apiPatient
    .post(
      "/elegibility",
      {
        products: [
          process.env.REACT_APP_TELEMEDICINA_PRODUCT_ID,
          process.env.REACT_APP_CLINICAS_PRODUCT_ID,
          process.env.REACT_APP_PA_PRODUCT_ID,
          process.env.REACT_APP_CORPORATE_PRODUCT_ID,
          process.env.REACT_APP_APS_DIGITAL_PRODUCT_ID,
          process.env.REACT_APP_ONSITE_PRODUCT_ID,
        ],
      },
      {
        headers: {
          "patient-token-temporary": patientToken,
        },
      }
    )
    .then(async ({ data }) => {
      return data;
    })
    .catch(() => {
      show("Erro ao buscar os produtos do paciente");
      return [];
    });
};

export const createSessionVonage = async (
  patient,
  provider,
  disease,
  userProducts,
  patientToken,
  examsDocuments
) => {
  return await apiPatient
    .post(
      "/vonage/createNewSession",
      {
        birthDate: patient.birthDate,
        convenio: {
          id: provider.id,
          id_vida: provider.id_vida,
          id_convenio: provider.id,
          data_importacao: provider.data_importacao,
          data_val_de: provider.data_val_de,
          data_vigencia: provider.data_vigencia,
          status: provider.status,
          document_titular: null,
          subgrupo: provider.subgrupo,
          imagem: provider.imagem,
          descricao: provider.descricao,
          identification: provider.identification,
          seguradora: provider.seguradora,
          base_de_vidas_pe: provider.base_de_vidas_pe,
          video_conferencia_status: provider.video_conferencia_status,
          validar_tipo_elegibilidade: provider.validar_tipo_elegibilidade,
          id_cliente: provider.id_cliente,
          origin: "portal",
          canRemove: false,
          maxDeps: 0,
          addDependentAllowed: false,
        },
        deviceData: getDesktopData(),
        docsReferences: examsDocuments,
        email: patient.email,
        idMotivoTriagem: disease._id,
        motivoTriagem: disease.titleDisease,
        name: patient?.firstName
          ? `${patient?.firstName} ${patient?.lastName}`
          : patient?.name,
        questionario: [],
        role: "publisher",
        userProducts: userProducts,
        userType: "patient",
        sessionOrigin: "WEB - Pronto Atendimento"
      },
      {
        headers: {
          "patient-token-temporary": patientToken,
        },
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      if(error?.response?.data?.message === "product not allowed"){
        show("Paciente não elegível");
      } else {
        show("Erro ao criar sessão");
      }
      throw new Error(error);
    });
};

export const createSessionTruclinic = async (
  patient,
  provider,
  disease,
  userProducts,
  patientToken,
  examsDocuments
) => {
  return await apiPatient
    .post(
      "/truclinic/createNewSession",
      {
        birthDate: patient.birthDate,
        convenio: {
          id: provider.id,
          id_vida: provider.id_vida,
          id_convenio: provider.id,
          data_importacao: provider.data_importacao,
          data_val_de: provider.data_val_de,
          data_vigencia: provider.data_vigencia,
          status: provider.status,
          document_titular: null,
          subgrupo: provider.subgrupo,
          imagem: provider.imagem,
          descricao: provider.descricao,
          identification: provider.identification,
          seguradora: provider.seguradora,
          base_de_vidas_pe: provider.base_de_vidas_pe,
          video_conferencia_status: provider.video_conferencia_status,
          validar_tipo_elegibilidade: provider.validar_tipo_elegibilidade,
          id_cliente: provider.id_cliente,
          origin: "portal",
          canRemove: false,
          maxDeps: 0,
          addDependentAllowed: false,
        },
        deviceData: getDesktopData(),
        docsReferences: examsDocuments,
        email: patient.email,
        idMotivoTriagem: disease._id,
        motivoTriagem: disease.titleDisease,
        name: patient.firstName
          ? `${patient.firstName} ${patient.lastName}`
          : patient.name,
        questionario: [],
        role: "publisher",
        userProducts: userProducts,
        userType: "patient",
      },
      {
        headers: {
          "patient-token-temporary": patientToken,
        },
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      if (
        error.response?.data?.message !== undefined &&
        error.response.data?.message !== null &&
        error.response.data?.message?.length > 0
      ) {
        throw new Error(error.response.data?.message);
      } else {
        throw new Error("Erro ao criar sessão");
      }
    });
};

export const createUrlSessionVonage = async (name, session, patientToken) => {
  return await apiPatient
    .post(
      `/vonage/newUrl`,
      {
        sessionId: session,
        attendantData: {
          name: name,
          role: "patient",
        },
      },
      {
        headers: {
          "patient-token-temporary": patientToken,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch(async () => {
      show("Erro ao gerar URL da sessão");
      throw new Error(error);
    });
};

export function* callVideoConference({ payload }) {
  let patient = {};
  let disease = {};
  let docsReferences = {};

  if (payload && payload.dataReducer) {
    patient = payload.dataReducer.patient;
    disease = payload.dataReducer.disease.titleDisease;
    docsReferences = payload.docsReferences;
  } else {
    patient = payload;
    disease = payload.titleDisease;
  }

  yield put(setLoading(true));

  const provider = JSON.parse(localStorage.getItem("@conecta:selected-provider"));

  let dataRequest = {};

  if (patient.holderId) {
    if (patient.idVida && patient.idVida > 0 && !patient._id) {
      dataRequest = {
        idVida: payload.idVida
          ? payload.idVida
          : payload.dataReducer.patient.idVida,
        provider: provider,
      };
    } else {
      dataRequest = {
        dependentId: payload._id
          ? payload._id
          : payload.dataReducer.patient._id,
        provider: provider,
      };
    }
  } else {
    dataRequest = {
      provider: provider,
    };
  }

  try {
    const request = () =>
      api.post("/truclinic", {
        ...dataRequest,
        docsReferences: docsReferences,
        titleDisease: disease,
      });
    const { data } = yield call(request);

    yield push("/home");
    yield put(setSelectedPatient(false));

    if (data && data.visitUrl) {
      window.location.href = data.visitUrl;
    }
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      show(error.response.data.message);
    }
  } finally {
    yield put(setLoading(false));
  }
}
