import {
    FIND_SPECIALIST_DOCTORS,
    SET_SPECIALIST,
    FIND_SPECIALTY,
    SET_SPECIALTY
  } from "./action-types";
  
  export const getAllDoctors = (payload) => ({
    type: FIND_SPECIALIST_DOCTORS,
    payload
  });

  export const setSpecialist = (specialist) => ({
    type: SET_SPECIALIST,
    specialist
  });

  export const getAllSpecialties = () => ({
    type: FIND_SPECIALTY
  });
  export const setSpecialties = (specialties) => ({
    type: SET_SPECIALTY,
    specialties
  });