import React from "react";
import SideBar from "../components/side-bar";
import MenuMobile from "../components/menu-mobile";
import { useSelector } from "react-redux";
import { Container, Main } from "./styles";
import { getRemoteConfigValue } from "../hooks/RemoteConfig";
export default function App(props) {
  const moduleHomeMicrofrontendEnabled = getRemoteConfigValue(
    "module_home_microfrontend_enabled"
  );
  const openScheduleModal = useSelector(
    (state) => state.schedule.openScheduleModal
  );
  return (
    <Container>
      <SideBar />
      {!openScheduleModal ? <MenuMobile /> : ""}
      <Main
        isHomeMicro={
          moduleHomeMicrofrontendEnabled
            ? window.location.pathname !== "/home"
            : false
        }
      >
        {props.children}
      </Main>
    </Container>
  );
}
