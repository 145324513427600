import styled, { css } from "styled-components";

export const Differentials = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 0 50px;
  padding: 100px 0 0;
`;

export const LabelDifferentials = styled.h1`
  margin: 20px 0;
`;

export const DifferentialsDiv = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    width: 80%;
  }
`;

export const DifferentialsItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: calc(33% - 60px);
  height: 240px;
  border-radius: 200px;
  margin: 30px;

  @media (max-width: 1024px) {
    width: 100%;
    margin: 10px 0;
  }
`;

export const DifferentialsImage = styled.img``;

export const DifferentialsText = styled.div`
  margin: 10px 0 0;
  width: 210px;
  line-height: 1.5;
  font-size: 18px;
  text-align: center;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;
