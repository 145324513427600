import styled from "styled-components";

import { motion } from "framer-motion";
import animations from "../../../utils/animation";

export const SelectPatientBackgroundColor = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffff;
`;

export const Container = styled.div`
  background-color: 100%;
  background-image: url("img/bg-provedores.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  width: 85%;
  height: 100%;

  @media (max-width: 1024px) {
    display: flex;
    width: 100%;  
    height: 100vh;
    justify-content: center;
    align-items: center;
  }
`;

export const Layer = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media (max-width: 1024px) {
    bottom: 0;

    background: linear-gradient(
      to top,
      rgba(0, 0, 0) 0%,
      rgba(0, 0, 0) 20%,
      rgba(0, 0, 0, 0.7) 40%,
      rgba(0, 0, 0, 0.3) 60%,
      rgba(0, 0, 0, 0.2) 80%,
      rgba(0, 0, 0, 0) 100%
    );
  }
`;

export const User = styled.div`
  position: absolute;
  width: 190px;
  height: 55px;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Message = styled.p`
  color: #ddd;
  text-align: right;
  font-size: 14px;
`;

export const ImageUser = styled.img`
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 36px;
`;

export const Options = styled.div`
  position: absolute;
  width: 171px;
  height: 150px;
  top: 80px;
  right: 25px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const OptionItem = styled.button`
  cursor: pointer;
  width: 75%;
  color: #d1d1d1;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 8px;
  border-bottom: 0.5px solid #808080;
  margin: 10px 0;
`;

export const Main = styled.div`
  position: absolute;
  bottom: 0;
  /* height: 250px; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1024px) {
    justify-content: flex-end;
    bottom: 20px;
    width: 80%;
    min-width: 80%;
    height: 85%;
  }
`;

export const AlertGroup = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  content-align: left;
  itens-align: left;
  text-align: left;

  @media (max-width: 1024px) {
    position: absolute;
    top: 40px;
    width: auto;

    button {
      font-size: 10px;
    }
  }
`;

export const Alert = styled.div`
  width: 850px;
  min-height: 55px;
  padding: 20px;
  border-radius: 3px;
  background: #000000bb;

  display: flex;
  align-items: center;
  flex-direction: column;
  /* 
  justify-content: space-between;
  */
  margin-top: ${(props) => props.marginTop || "0"}px;

  button {
    color: #ededed;
    height: 31px;
    background: #2196f3;
    border-radius: 4px;
    padding: 7px 12px;
    white-space: nowrap;
  }

  @media (max-width: 1024px) {
    width: auto;
    button {
      font-size: 10px;
    }
  }
`;

export const AlertMessage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* align-items: flex-end; */
  gap: 20px;

  && > p {
    color: #ededed;
    font-size: 13px;
  }

  @media (max-width: 1024px) {
    && > p {
      font-size: 11px;
    }
  }
`;

export const AlertMessageAlignItemsBottom = styled(AlertMessage)`
  align-items: flex-end !important;
`;

export const DirectionColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px auto;

  && > h3 {
    color: #ffffff;
    font-size: 14px;
    margin-bottom: 7px;
  }
  && > p {
    color: #ededed;
    font-size: 12px;
  }

  @media (max-width: 1024px) {
    h3 {
      font-size: 12px;
    }
    && > p {
      font-size: 11px;
    }
  }
`;

export const RowMain = styled.div`
  width: 850px;
  border-radius: 4px;
  margin: 0px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: column-reverse;
  }
`;

export const InitCall = styled.div`
  border-radius: 3px;
  width: 850px;
  height: 121px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 0px;

  @media (max-width: 1024px) {
    width: 100%;
    height: 110px;
    background: transparent;
  }
`;

export const ButtonInit = styled.button`
  cursor: pointer;
  /* cursor: ${(props) => !props.elegibility && "not-allowed"}; */
  width: 100%;
  height: 72px;
  background: #2196f3;
  color: #fff;
  font-weight: bold;
  border-radius: 4px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const ScheduleInit = styled.button`
  cursor: ${(props) => !props.elegibility && "not-allowed"};
  width: 100%;
  height: 72px;
  background: #2196f3;
  color: #fff;
  font-weight: bold;
  display: ${(props) =>
    (!props.flagScheduling && "none") ||
    (props.notElegibilityInternal && "none") ||
    "flex"};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
  margin-left: 5px;

  @media (max-width: 1024px) {
    width: 30%;
  }
`;

export const ButtonTextSpan = styled.span`
  padding: 0 0 0 10px;
`;

export const IconAccepted = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-check-circle"
  >
    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
    <polyline points="22 4 12 14.01 9 11.01"></polyline>
  </svg>
);

export const IconReject = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-alert-circle"
  >
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="12" y1="8" x2="12" y2="12"></line>
    <line x1="12" y1="16" x2="12.01" y2="16"></line>
  </svg>
);
