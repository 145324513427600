import axios from "axios";
import { clearCredentials, getCredentials, hasToken } from "../utils/credentials";
import getDeviceInfo from './deviceReport'

const URL_CATALOG = process.env.REACT_APP_CATALOGO_API;

const catalogApi = axios.create({
  baseURL: URL_CATALOG,
});

export default catalogApi;
