import {
  SET_USER,
  LOGIN_REQUEST,
  EDIT_USER,
  MODAL_USER,
  REGISTER_USER,
  RECOVERY_PASSWORD,
  SEND_LINK_RECOVERY,
  CHANGE_PASSWORD,
  CHECK_CARD_NUMBER,
  CHECK_PHONE_NUMBER,
  CHECK_PIN,
  PHONE_NUMBER,
  MODAL_PIN,
  MODAL_VERIFICATION,
  MODAL_PATIENT,
  UPLOAD_FILES,
  UPLOAD_DOCUMENTS,
  SET_FILES,
  NEXT_DOCS_UPLOAD_STEP,
  SET_CURRENT_DOCS_UPLOAD_FILE,
  UNDO_DOCS_UPLOAD_STEP,
  SET_CURRENT_PRIVATE_PLAN,
  FETCH_CURRENT_PRIVATE_PLAN,
  USER_SET_PROVIDER,
  USER_SET_TELEPSICOLOGIA,
  USER_SET_TELEMEDICINA,
  SET_HOME_PAGE,
  USER_SET_CORPORATE_CARD,
  UPLOAD_EXAMS,
  UPLOAD_UPDATE_PROFILE_PICTURE,
} from "./action-types";

export const login = (payload) => ({
  type: LOGIN_REQUEST,
  payload,
})

export const changePassword = (payload) => ({
  type: CHANGE_PASSWORD,
  payload,
})

export const registerUser = (payload) => ({
  type: REGISTER_USER,
  payload,
})

export const setUser = (payload) => ({
  type: SET_USER,
  user: payload,
})

export const editUser = (payload) => ({
  type: EDIT_USER,
  payload,
})

export const openModalUser = (bool) => ({
  type: MODAL_USER,
  payload: bool,
})

export const sendLinkRecovery = (payload) => ({
  type: SEND_LINK_RECOVERY,
  payload,
})

export const recoveryPassword = (payload) => ({
  type: RECOVERY_PASSWORD,
  payload,
})

export const findPhoneNumber = (payload) => ({
  type: CHECK_PHONE_NUMBER,
  payload,
})

export const findPin = (payload) => ({
  type: CHECK_PIN,
  payload,
})

export const setPhone = (payload) => ({
  type: PHONE_NUMBER,
  payload,
})

export const openModalVerification = (bool) => ({
  type: MODAL_VERIFICATION,
  payload: bool,
})

export const openModalPin = (bool) => ({
  type: MODAL_PIN,
  payload: bool,
})

export const openModalPatient = (bool) => ({
  type: MODAL_PATIENT,
  payload: bool,
})

export const sendUploadFiles = (payload) => ({
  type: UPLOAD_FILES,
  payload,
})

export const sendUploadDocuments = (payload) => ({
  type: UPLOAD_DOCUMENTS,
  payload,
})

export const sendUpdateProfilePicture = (payload) => ({
  type: UPLOAD_UPDATE_PROFILE_PICTURE,
  payload,
})

export const sendUploadExams = (payload) => ({
  type: UPLOAD_EXAMS,
  payload,
})

export const setFiles = (payload) => ({
  type: SET_FILES,
  payload,
})

export const nextDocsUploadStep = (payload) => ({
  type: NEXT_DOCS_UPLOAD_STEP,
  payload,
});

export const undoDocsUploadStep = (payload) => ({
  type: UNDO_DOCS_UPLOAD_STEP,
  payload,
});

export const setCurrentDocsUploadFile = (payload) => ({
  type: SET_CURRENT_DOCS_UPLOAD_FILE,
  payload,
});

export const setDocsUploadStep = (payload) => ({
  type: UNDO_DOCS_UPLOAD_STEP,
  payload,
});

export const setCurrentPrivatePlan = (payload) => ({
  type: SET_CURRENT_PRIVATE_PLAN,
  payload,
});

export const fetchCurrentPrivatePlan = (payload) => ({
  type: FETCH_CURRENT_PRIVATE_PLAN,
  payload,
});

export const selectProvider = (payload) => ({
  type: USER_SET_PROVIDER,
  payload,
});

export const userSetTelemedicina = (payload) => ({
  type: USER_SET_TELEMEDICINA,
  payload,
});
export const userSetCorporateCard = (payload) => ({
  type: USER_SET_CORPORATE_CARD,
  payload,
});
export const userSetTelepsicologia = (payload) => ({
  type: USER_SET_TELEPSICOLOGIA,
  payload,
});

export const setHomePage = (payload) => ({
  type: SET_HOME_PAGE,
  payload,
});
