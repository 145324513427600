import React, { useState, useEffect } from "react";
import {
  Container,
  Main,
  Titles,
  Title,
  SubTitle,
  DivLogo,
  Logo,
  Question,
  QuestionTitle,
  QuestionOptions,
  Option,
  Infos,
  Texts,
  Buttons,
  Button,
  UlCustom,
} from "./styles";

import CheckBox from "../../../components/checkbox";
import { push } from "../../../services/navigate";
import { show } from "../../../services/alert";

export default function Covid(props) {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answer, setAnswer] = useState("not-selected");

  const [screenAlert, setScreenAlert] = useState(false);

  const questions = [
    {
      text: "Você apresenta febre/sensação febril ou tosse persistente?",
      rejectAction: function () {
        setScreenAlert(this.screenAlert);
      },
      screenAlert: {
        title: "Não é necessária nenhuma medida adicional",
        text:
          "Em pacientes assintomáticos ou com sintomas leves, não está indicada a coleta de exame para coronavírus, mesmo que você tenha tido contato com casos confirmados, sintomáticos ou assintomáticos.",
        listItems: [
          "Recomendamos:",
          "- Vigilância infecciosa (atenção a novos sintomas).",
          "- Medir a temperatura 3-4x/dia.",
          "- Manter boa higiene das mãos: lavar as mãos e/ ou utilizar álcool gel com frequência. ",
          "- Evitar mexer nos olhos, nariz e boca em locais públicos.  ",
          "- Cobrir a boca ao tossir e espirrar com um lenço de papel. Jogá-lo fora após o uso e lavar as mãos.",
          "- Manter boa hidratação e alimentação leve, evitando ambientes com poeira ou fumaça",
        ],
        rejectAction: () => push("/sintomas"),
      },
    },
    {
      text:
        "Você está se sentindo mal a ponto de precisar parar de fazer suas atividades do dia a dia?",
      rejectAction: function () {
        setScreenAlert(this.screenAlert);
      },
      screenAlert: {
        title: "Não é necessária nenhuma medida adicional",
        text:
          "Em pacientes assintomáticos ou com sintomas leves, não está indicada a coleta de exame para coronavírus, mesmo que você tenha tido contato com casos confirmados, sintomáticos ou assintomáticos.",
        listItems: [
          "Recomendamos:",
          "- Vigilância infecciosa (atenção a novos sintomas).",
          "- Medir a temperatura 3-4x/dia.",
          "- Manter boa higiene das mãos: lavar as mãos e/ ou utilizar álcool gel com frequência. ",
          "- Evitar mexer nos olhos, nariz e boca em locais públicos.  ",
          "- Cobrir a boca ao tossir e espirrar com um lenço de papel. Jogá-lo fora após o uso e lavar as mãos.",
          "- Manter boa hidratação e alimentação leve, evitando ambientes com poeira ou fumaça",
        ],
        rejectAction: () => push("/sintomas"),
      },
    },
  ];

  function nextStep() {
    if (answer === "not-selected") {
      return show("Selecione alguma opção");
    }

    if (answer === "refused") {
      return questions[currentQuestion].rejectAction();
    }

    if (currentQuestion + 1 >= questions.length) {
      return props.initVideoCall();
    }

    setAnswer("not-selected");
    setCurrentQuestion(currentQuestion + 1);
  }

  return (
    <Container>
      <DivLogo>
        <Logo src="img/logo-blue.png" />
      </DivLogo>

      <Main>
        {screenAlert && (
          <>
            <Infos>
              <Titles margin="0 0 20px">
                <Title>{screenAlert.title}</Title>
              </Titles>

              <Texts>
                <p>{screenAlert.text}</p>
              </Texts>

              {screenAlert.listItems && screenAlert.listItems.length > 0 && (
                <UlCustom>
                  {screenAlert.listItems.map((item) => (
                    <li>{item}</li>
                  ))}
                </UlCustom>
              )}

              <Buttons>
                <Button outline onClick={screenAlert.rejectAction}>
                  Obrigado, minha dúvida foi esclarecida
                </Button>
                <Button contained onClick={props.initVideoCall}>
                  Desejo prosseguir mesmo assim
                </Button>
              </Buttons>
            </Infos>
          </>
        )}

        {!screenAlert && (
          <>
            <Titles>
              <Title>Para continuar seu atendimento, responda:</Title>
              <SubTitle>
                Pergunta {currentQuestion + 1} de {questions.length}
              </SubTitle>
            </Titles>

            <Question>
              <QuestionTitle>{questions[currentQuestion].text}</QuestionTitle>
              <QuestionOptions>
                <Option onClick={() => setAnswer("accepted")}>
                  <CheckBox checked={answer === "accepted"} />
                  <p>Sim</p>
                </Option>

                <Option onClick={() => setAnswer("refused")}>
                  <CheckBox checked={answer === "refused"} />
                  <p>Não</p>
                </Option>
              </QuestionOptions>

              <Buttons>
                <Button outline onClick={nextStep}>
                  Cancelar
                </Button>
                <Button contained onClick={nextStep}>
                  Avançar
                </Button>
              </Buttons>
            </Question>
          </>
        )}
      </Main>
    </Container>
  );
}
