import styled from "styled-components";

export const Container = styled.div`
  padding: 24px;
  max-width: 100%;
  background-color: #ffffff;
  height: 100%;

  @media (max-width: 960px) {
    margin-top: 55px;
  }
`;
