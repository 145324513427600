import React, { useState } from "react";
import { Step, StepGroup, Separator, Container, StepIcon } from "./styles";
import { Title } from "../styles";

export const Steps = ({ steps, currentStep }) => {
  return (
    <Container>
      <Title>{steps[currentStep].title}</Title>
      <StepGroup>
        {steps.map((step, idx) => (
          <React.Fragment key={idx}>
            <Step done={currentStep >= idx}>
              <StepIcon as={step.icon} done={currentStep >= idx} />
            </Step>
            {idx < steps.length - 1 && <Separator active={currentStep > idx} />}
          </React.Fragment>
        ))}
      </StepGroup>
    </Container>
  );
};
