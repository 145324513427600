import styled from "styled-components";

export const Container = styled.div`
  border-radius: 4px;
  background: #2e2e2e;
  display: flex;
  justify-content: center;
  // align-items: center;
  flex-direction: column;
  margin: 20px 0 20px 0;
  padding: 20px 30px;
  box-shadow: 0px 0px 24px 10px rgba(214, 214, 214, 0.8);
  z-index: 80;
`;

export const WrapRight = styled.div`
  display: flex;
  width: 60%;
  justify-content: space-between;
  align-self: flex-end;
  flex-direction: row;

  @media(max-width: 515px) {
    flex-direction: column;
  }

`;

export const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  flex-direction: row;

`;

export const Logo = styled.img`
  width: 40px;
  height: 40px;

  max-width: 40px;
  max-height: 40px;
  margin: 0 10px 0 0;
`;

export const Message = styled.p`
  margin: 0;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
`;

export const Subtitle = styled.p`
  margin: 10px 0;
  font-size: 14px;
  color: #fff9;
`;
export const Title = styled.p`
  margin: 0;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
`;

export const Button = styled.button`
  cursor: pointer;
  margin: 10px 10px 0 10px; 
  padding: 0px 8px;
  color: #fff;
  border-radius: 3px;
  font-size: 14px;
  ${(props) => props.outline && " border: 1px solid #fff"};
  // font-weight: bold;
  text-transform: uppercase;

  @media(max-width: 515px) {
    text-align: right;
    margin: 10px 0px 8px 0; 
  }
`;

export const ButtonClose = styled.button`
  cursor: pointer;
  margin: 0 10px;
  color: #fff;
  border-radius: 3px;
  font-size: 14px;
  ${(props) => props.outline && " border: 1px solid #fff"};
  // font-weight: bold;
  text-transform: uppercase;
`;
