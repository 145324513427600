import * as yup from "yup";
import creditCardType from "credit-card-type";
import { isCardNumberValid } from "./validateCreditCard";

yup.addMethod(yup.string, "creditCardType", function (errorMessage) {
    return this.test(`test-card-type`, errorMessage, function (value) {
        const { path, createError } = this;

        const currentFlags = ['visa', 'mastercard', 'elo', 'diners-club', 'american-express'];

        if (isCardNumberValid(value)) {
            var cardType = creditCardType(value);
            if (cardType.length !== 0) {
                var isNumberValid = currentFlags.includes(cardType[0].type);
            }
          }

        return (
            isNumberValid ||
            createError({ path, message: errorMessage })
        );
    });
});

yup.addMethod(yup.string, "validateMonth", function (errorMessage) {
    return this.test(`test-valid-month`, errorMessage, function (value) {
        const { path, createError } = this;

        return (
            value <= 12 && value > 0 ||
            createError({ path, message: errorMessage })
        );
    });
});

yup.addMethod(yup.string, "validateYear", function (errorMessage) {
    return this.test(`test-valid-year`, errorMessage, function (value) {
        const { path, createError } = this;

        const currentYear = new Date().getFullYear().toString().substr(2);

        return (
            value >= currentYear ||
            createError({ path, message: errorMessage })
        );
    });
});

export const creditCardSchema = yup.object().shape({
    cardNumber: yup
        .string()
        .min(13, 'No mínimo 16 caracteres')
        .max(16, 'No máximo 16 carcteres')
        .creditCardType('Este cartão é inválido')
        .required('O número do cartão é obrigatório'),
    expirationMonth: yup
        .string()
        .min(2, 'No mínimo 2 caracteres')
        .max(2, 'No máximo 2 caracteres')
        .validateMonth('O mês é inválido')
        .required('O mês é obrigatório'),
    expirationYear: yup
        .string()
        .min(2, 'No mínimo 2 caracteres')
        .max(2, 'No máximo 2 caracteres')
        .validateYear('O ano é inválido')
        .required('O ano é obrigatório'),
});