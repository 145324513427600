import styled from "styled-components";

export const Container = styled.div``;

export const Main = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin: 70px 0 0 70px;

  @media (max-width: 1024px) {
    margin: 80px 0 0 30px;
  }
`;

export const Titles = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 60%;
`;

export const Title = styled.h2`
  margin-bottom: 5px;
  font-size: 15px;
`;

export const SubTitle = styled.p`
  color: #707070;
`;

export const DivImage = styled.label`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;
`;

export const OwnerImage = styled.img`
  width: 66px;
  height: 66px;
  border-radius: 48px;
  margin: 0 10px;
`;

export const ManagerDependents = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
`;

export const TitleDependents = styled.h2`
  font-size: 18px;
`;

export const AddDependents = styled.button`
  cursor: pointer;
  width: 162px;
  height: 35px;
  color: #fff;
  font-weight: bold;
  background: #2196f3;
  border-radius: 4px;
  margin: 0 15px;
`;

export const ListDependents = styled.div`
  display: flex;
  flex-direction: flex;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const LabelAdd = styled.p`
  font-size: 18px;
  color: #707070;
  margin: 0 20px;
  width: 100px;
  font-weight: lighter;
  line-height: 1.5;
`;

export const DependentItem = styled.div`
  box-shadow: 0px 0px 5px 2px rgba(214, 214, 214, 1);
  width: 300px;
  height: 135px;
  margin: 10px;
  background: #fff;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 425px) {
    width: 85%;
  }

  @media (max-width: 1024px) {
    width: 270px;
  }
`;

export const AddDependent = styled.button`
  /* cursor: ${(props) => props.notElegibilityExternal && "not-allowed"}; */
  /* pointer-events: ${(props) => props.notElegibilityExternal && "none"};; */
  box-shadow: 0px 0px 5px 2px rgba(214, 214, 214, 1);
  width: 200px;
  height: 135px;
  margin: 10px;
  background: #fff;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor:pointer;

  @media (max-width: 425px) {
    width: 85%;
  }

  @media (max-width: 1024px) {
    width: 270px;
    margin: 10px 10px 100px 10px;
  }
`;

export const DependentEdit = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: ${(props) => props.position ? "35px" : "10px"};
`;

export const DependentAdd = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const DependentImage = styled.img`
  width: 66px;
  height: 66px;
  border-radius: 48px;
  margin: 0 10px;
`;

export const DependentRemove = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
`;
