import styled from "styled-components";

export const Title = styled.h2`
  font-size: 18px;
  font-family: Montserrat, system-ui;
  font-weight: 600;
  font-style: normal;
  line-spacing: 0px;
  color: #000;
  margin-bottom: 32px;
  text-align: left;
`;
