import {
  Main,
  Titles,
  Title,
  SubTitle,
  DivImage,
  OwnerImage,
  ManagerDependents,
  TitleDependents,
  ListDependents,
  DependentItem,
  DependentImage,
} from "./styles";

import Container from "../../../container";

import { useSelector } from "react-redux";

import ModalConfirm from "./modal-confirm";

export default function App() {
  let provider = localStorage.getItem("@conecta:selected-provider");
  provider = JSON.parse(provider);

  const user = useSelector((state) => state.userReducer.user);
  const dependentsProps = useSelector(
    (state) => state.dependentsReducer.dependents
  );




  return (
    <Container>
      <ModalConfirm />
      <Main>
        <Titles>
          <Title>Dependentes</Title>
          <SubTitle>Gerencie seus dependentes.</SubTitle>
        </Titles>

        <DivImage>
          <OwnerImage src={user.image || "img/default.png"} />
          <Titles>
            <Title>{`${user.name || ""}  ${user.lastName || ""}`}</Title>
            <SubTitle>Titular</SubTitle>
          </Titles>
        </DivImage>

        <ManagerDependents>
          <TitleDependents>Gerenciar dependentes</TitleDependents>
        </ManagerDependents>

        <ListDependents>
          {dependentsProps.length > 0
            ? dependentsProps.map((dependent, index) => {
                if (
                  dependent.document !==
                  user.document.replace(/([^0-9a-zA-Z])/gi, "")
                )
                  return (
                    <DependentItem key={index}>
                      <DependentImage
                        src={
                          (dependent.image && dependent.image) ||
                          "img/default.png"
                        }
                        onError={(e) => (e.target.src = "img/default.png")}
                      />
                      <Titles>
                        <Title>
                          {dependent.lastName
                            ? `${dependent.name} ${dependent.lastName}`
                            : dependent.name}
                        </Title>
                        <SubTitle>Dependente</SubTitle>
                      </Titles>
                    </DependentItem>
                  );
              })
            : null}
        </ListDependents>
      </Main>

    </Container>
  );
}
