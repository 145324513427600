import styled, { css } from "styled-components";

export const Container = styled.div``;

export const Main = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px 0;
  min-width: 500px;
  min-height: 400px;
  background: #f0f0f0;
  border-radius: 2px;
  margin-top: 100px;
  @media (max-width: 1024px) {
    min-width: 100%;
  }
`;

export const Close = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  cursor: pointer;
`;

export const Titles = styled.div`
  width: 90%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 0 30px;

  @media (max-width: 1024px) {
    align-items: center;
  }
`;

export const Title = styled.h2`
  margin-bottom: 5px;
  font-size: 18px;
`;

export const SubTitle = styled.p`
  color: #707070;
`;

export const Link = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  width: 90%;
  background: #fff;
  margin: 10px 0;

  p {
    margin: 0;
    color: #4a688f;
    font-weight: bold;
  }
`;
