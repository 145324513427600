import React from "react";
import {
  FiVideo,
  FiMail,
  FiUsers,
  FiCalendar,
  FiClock,
  FiShield,
  FiCreditCard,
} from "react-icons/fi";
import { MdSecurity, MdNotificationsNone } from "react-icons/md";
import {
  GiBrain,
} from "react-icons/gi";

export default [
  {
    icon: <FiVideo />,
    label: "Telemedicina",
    route: "/home",
    product: "TELEMEDICINA"
  },
  {
    icon: <GiBrain />,
    product: "TELEPSICOLOGIA",
    label: "Telepsicologia",
    route: "/telepsicologia",
    needEgilibility: true,
    needEligibilityTelepsicologia: true,
  },
  {
    icon: <FiCalendar />,
    label: "Agenda",
    route: "/consultas-agendadas",
    needEgilibility: true,
    needScheduling: true,
    product: "ANY"
  },
  {
    icon: <FiClock />,
    label: "Histórico",
    route: "/historico",
    needEgilibility: false,
  },
  // {
  //   icon: <FiCreditCard />,
  //   label: "Einstein Corporate",
  //   route: "/einstein-corporate",
  //   needEgilibility: true,
  //   product: "CORPORATE_CARD"
  // },
  {
    icon: <FiUsers />,
    label: "Dependentes",
    route: "/dependentes",
  },
  {
    icon: <MdSecurity />,
    label: "Privacidade",
    route: "/privacidade",
    remoteConfig: 'teleconsulting_terms_enabled',
  },
  {
    icon: <MdNotificationsNone />,
    label: "Comunicações",
    route: "/comunicacoes",
    remoteConfig: 'module_communication_enabled',
  },
  {
    icon: <FiMail />,
    label: "Contato",
    route: "/fale-conosco",
  },
  {
    // TODO: create a dynamic menu from redux/dispach
    icon: <FiShield />,
    label: "Privacidade Natura",
    route: "/terms-provider",
    needEgilibility: true,
    product: 'TERMS_PROVIDER',
  },
  {
    icon: <FiUsers />,
    label: "Médicos Einstein",
    route: "/especialistas-corporate",
    needEgilibility: true,
    product: "SAUDE_ITAU",
  },
];
