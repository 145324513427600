import ReactGA from 'react-ga';

const history = {};

export function setNavigator(nav) {
  if (nav) {
    // ReactGA.initialize(process.env.REACT_APP_ANALYTICS_UID);
    history.push = nav.history.push;
    history.goBack = nav.history.goBack;
  }
}

export function push(routeName, params) {
  if (params === undefined) {
    // ReactGA.pageview(routeName);
    return history.push(routeName);
  }
  const query = Object.entries(params).map((entry) => `${entry[0]}=${encodeURIComponent(entry[1])}`).join('&')
  // ReactGA.pageview(`${routeName}?${query}`);
  return history.push(`${routeName}?${query}`)
}

export function goBack(routeName, params) {
  // ReactGA.pageview(routeName);
  return history.goBack(routeName);
}
