import React from "react";
import Modal from "react-modal";

import LoadingModal from "../loading-modal";

import { useSelector } from "react-redux";
import './styles.css';

export default function App(props) {
  const loadingModal = useSelector((state) => state.utilsReducer.loadingModal);

  return (
    <Modal
      closeTimeoutMS={500}
      overlayClassName="overlay-dialog"
      className={props.className || "custom-dialog"}
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
    >
      {props.children}
      {loadingModal && <LoadingModal />}
    </Modal>
  );
}
