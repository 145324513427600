import React, { useEffect } from "react"
import { Route, Redirect } from "react-router-dom"
import Loading from "../components/loading"
import { verifyAuth } from "../redux/actions/utils.actions"
import { useDispatch, useSelector } from "react-redux"

export default function PrivatePlanRoute(props) {
  const isLoaded = useSelector((state) => state.utilsReducer.loaded)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(verifyAuth())
  }, [dispatch])

  function returnPrivateRoute() {
    let provider = localStorage.getItem("@conecta:selected-provider")
    provider = JSON.parse(provider);

    if (provider.id_convenio === process.env.REACT_APP_ID_AGREEMENT_PARTICULAR) {
      if (provider.subgrupo !== 'PLANO AVULSO B2C')
        return <Route {...props} />
    }
    
    return <Redirect to="/home" />
  }

  return !isLoaded ? <Loading /> : returnPrivateRoute()
}