import styled from "styled-components";

export const Card = styled.aside`
  box-shadow: 0px 8px 24px rgba(43, 61, 69, 0.16);
  border-radius: 16px;
  width: 336px;
  padding: 24px;

  & > p {
    margin-bottom: 8px;
  }

  @media (max-width: 960px) {
    margin-top: 16px;
  }
`;

export const SeeMoreContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  & > a {
    color: #1774de;
    font-weight: 600;
    font-size: 16px;
  }
`;
