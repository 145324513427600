import * as yup from "yup";
import moment from "moment";
import { cpf as cpfTest } from "cpf-cnpj-validator";

export const brasileiroSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("E-mail é obrigatório")
    .email("Digite um e-mail valido"),
  name: yup
    .string()
    .trim()
    .required("Nome é obrigatório")
    .min(3, "Nome mínimo 3 caracteres"),
  lastName: yup
    .string()
    .trim()
    .required("Sobrenome é obrigatório")
    .min(3, "Sobrenome mínimo 3 caracteres"),
  birthDate: yup
    .string()
    .required("Data de nascimento é obrigatória")
    .test("date-test", "Data inválida", function (value) {
      if (value) {
        let date = value.replace(/_/g, "");
        if (date.length < 10) {
          return false;
        }
        date = moment(value, "DD/MM/YYYY");
        if (!date.isValid()) {
          return false;
        }

        function calcAge(birth) {
          const auxBirth = new Date(birth);
          const diference = Date.now() - auxBirth.getTime();
          const age = new Date(diference);

          return age.getUTCFullYear() - 1970;
        }

        const age = calcAge(date);

        if (age <= 17 || age > 120) {
          return false;
        }

        return date;
      }
    }),
  sex: yup.string().required("Sexo é obrigatório"),
  nationality: yup.string().required("Nacionalidade é obrigatório"),
  document: yup
    .string()
    .trim()
    .test("cpf-test", "Documento inválido", function (value) {
      if (!value) return false;

      const cpf = value.replace(/[^0-9]+/g, "");
      if (!cpfTest.isValid(cpf)) {
        return false;
      } else {
        return true;
      }
    }),
  cep: yup
    .string()
    .trim()
    .required("Cep é obrigatório")
    .test("Cep-test", "CEP inválido", function (value) {
      if (value) {
        const cep = value.replace(/[^0-9]+/g, "");
        if (cep.length < 8) {
          return false;
        } else {
          return true;
        }
      }
    }),
  address: yup.string().trim().required("Endereço é obrigatório"),
  stateAddress: yup.string().trim().required("Estado é obrigatório"),
  numberAddress: yup.string().trim().required("Informe um número"),
  cityAddress: yup.string().trim().required("Cidade é obrigatória"),
});

export const addressBrasileiroSchema = yup.object().shape({
  cep: yup
    .string()
    .trim()
    .required("Cep é obrigatório")
    .test("Cep-test", "CEP inválido", function (value) {
      if (value) {
        const cep = value.replace(/[^0-9]+/g, "");
        if (cep.length < 8) {
          return false;
        } else {
          return true;
        }
      }
    }),
  address: yup.string().trim().required("Endereço é obrigatório"),
  stateAddress: yup.string().trim().required("Estado é obrigatório"),
  numberAddress: yup.string().trim().required("Informe um número"),
  cityAddress: yup.string().trim().required("Cidade é obrigatória"),
});

// Estrangeiro

export const estrangeiroSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("E-mail é obrigatório")
    .email("Digite um e-mail valido"),
  name: yup
    .string()
    .trim()
    .required("Nome é obrigatório")
    .min(3, "Nome mínimo 3 caracteres"),
  lastName: yup
    .string()
    .trim()
    .required("Sobrenome é obrigatório")
    .min(3, "Sobrenome mínimo 3 caracteres"),
  birthDate: yup
    .string()
    .required("Data de nascimento é obrigatória")
    .test("date-test", "Data inválida", function (value) {
      if (value) {
        let date = value.replace(/_/g, "");
        if (date.length < 10) {
          return false;
        }
        date = moment(value, "DD/MM/YYYY");
        if (!date.isValid()) {
          return false;
        }

        function calcAge(birth) {
          const auxBirth = new Date(birth);
          const diference = Date.now() - auxBirth.getTime();
          const age = new Date(diference);

          return age.getUTCFullYear() - 1970;
        }

        const age = calcAge(date);

        if (age <= 17 || age > 120) {
          return false;
        }

        return date;
      }
    }),
  sex: yup.string().required("Sexo é obrigatório"),
  nationality: yup.string().required("Nacionalidade é obrigatório"),
  document: yup.string().required("Documento é obrigatório"),
  cep: yup
    .string()
    .trim()
    .required("Cep é obrigatório")
    .test("Cep-test", "CEP inválido", function (value) {
      if (value) {
        const cep = value.replace(/[^0-9]+/g, "");
        if (cep.length < 3) {
          return false;
        } else {
          return true;
        }
      }
    }),
  address: yup.string().trim().required("Endereço é obrigatório"),
  stateAddress: yup.string().trim().required("Estado é obrigatório"),
  numberAddress: yup.string().trim().required("Informe um número"),
  cityAddress: yup.string().trim().required("Cidade é obrigatória"),
});

export const addressEstrangeiroSchema = yup.object().shape({
  cep: yup
    .string()
    .trim()
    .required("Cep é obrigatório")
    .test("Cep-test", "CEP inválido", function (value) {
      if (value) {
        const cep = value.replace(/[^0-9]+/g, "");
        if (cep.length < 3) {
          return false;
        } else {
          return true;
        }
      }
    }),
  address: yup.string().trim().required("Endereço é obrigatório"),
  stateAddress: yup.string().trim().required("Estado é obrigatório"),
  numberAddress: yup.string().trim().required("Informe um número"),
  cityAddress: yup.string().trim().required("Cidade é obrigatória"),
});
