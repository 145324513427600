import React from "react";
import {
  MainContainer,
  Main,
  Content,
  Title,
  SubTitle,
  DivImage,
  DivButton,
  Button,
} from "./styles";

import Container from "../../container";
import ErrorPageSvg from "../../assets/images/404-error-page.svg"
import PhoneSvg from "../../assets/icons/phone.svg"
import WhatsAppSvg from "../../assets/icons/whatsapp.svg"

import { getRemoteConfigValue } from "../../hooks/RemoteConfig";

import { formatTitle, formatDescription, formatPhone } from "./helper";

export default function App() {
  const [phone, setPhone] = React.useState("");
  const [linkChatWhatsapp, setLinkChatWhatsapp] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");

  const handlePhoneButton = (number) => {
    const cleanNumber = number.replace(/[^\d]/g, "");
    const formatNumber = cleanNumber.replace(
      /(\d{2})(\d{4})(\d{4})/,
      "$1-$2-$3"
    );
    window.location.href = `tel:+55-${formatNumber}`; // tel:+55-12-1234-1234
  };

  const handleWhatsAppButton = (linkChatWhatsapp) => {
    window.open(linkChatWhatsapp, "_blank");
  };

  React.useEffect(() => {
    const catchSystemError = getRemoteConfigValue("catch_system_error");

    setTitle(catchSystemError?.immediateCare?.title);
    setDescription(catchSystemError?.immediateCare?.description);
    setPhone(catchSystemError?.immediateCare?.contact?.phone);
    setLinkChatWhatsapp(catchSystemError?.immediateCare?.contact?.link_chat_whatsapp);

    if (catchSystemError?.immediateCare?.enabled === false) {
      window.history.back();
    }
  }, []);

  return (
    <Container>
      <MainContainer>
        <Main>
          <Content>
            <Title loading={!title}>{formatTitle(title)}</Title>
            <DivImage>
              <img src={ErrorPageSvg} alt="view-off" />
            </DivImage>

            <SubTitle
              loading={!description}
              dangerouslySetInnerHTML={{ __html: formatDescription(description) }}
            />

            <DivButton>
              <Button
                id={"btn-teleconsulta-warning-screen-phone"}
                loading={!phone}
                borderColor={"#0E4F92"}
                textColor={"#0E4F92"}
                backgroundColor={"#FFF"}
                onClick={() => handlePhoneButton(phone)}
              >
                {phone && (
                  <>
                    <img src={PhoneSvg} alt="view-off" />
                    {formatPhone(phone)}
                  </>
                )}
              </Button>
              <Button
                id={"btn-teleconsulta-warning-screen-whatsapp"}
                loading={!linkChatWhatsapp}
                backgroundColor={"#0E4F92"}
                onClick={() => handleWhatsAppButton(linkChatWhatsapp)}
              >
                {linkChatWhatsapp && (
                  <>
                    <img src={WhatsAppSvg} alt="view-off" />
                    WhatsApp
                  </>
                )}
              </Button>
            </DivButton>
          </Content>
        </Main>
      </MainContainer>
    </Container>
  );
}
