import React, { useState } from "react";
import {
  Card,
  ScheduleBody,
  LeftInfo,
  LeftInfoDate,
  LeftInfoTime,
  PatientInfoBody,
  PatientTitle,
  PatientValue,
  ConfirmButton,
  CancelButton,
  ButtonBody,
  ChangeDateButton,
  ShowMoreBox,
  ImageShowMore,
  ShowMore,
  MobileTextBox,
  ShowMoreDivision,
  DataWrapper,
} from "./styles";

export const ScheduleCard = ({
  telemedicina,
  date,
  time,
  consulta,
  especialidade,
  profissional,
  onStart,
  onCancel,
  enableStart,
  type,
  address = '',
  name
}) => {
  const [showMore, setShowMore] = useState(false);
  const _showMore = () => {
    setShowMore(!showMore);
  };

  function handleScheduleType(type) {
    if (type === "TELEMEDICINE") return "Consulta Telemedicina";
    if (type === "specialist") return `Consulta ${especialidade}`;
    if (type === "consulting") return `${profissional}`;
    if (type === "immunization") return "Imunizacão";
    if (type === "exam") return "Exame";
  }

  function handleProducedureTitle(type) {
    if (type === "TELEMEDICINE") return "Tipo de consulta";
    if (type === "specialist") return `Profissional`;
    if (type === "immunization") return "Vacina";
    if (type === "consulting") return 'Consulta';
    if (type === "exam") return "Exame";
  }

  function handleProcedureType(type) {
    if (type === "TELEMEDICINE") return "Telemedicina";
    if (type === "specialist") return profissional;
    if (type === "immunization") return profissional;
    if (type === "consulting") return address;
    if (type === "exam") return profissional;
  }

  return (
    <Card>
      <ScheduleBody showMore={showMore}>
        <LeftInfo showMore={showMore} telemedicina={telemedicina}>
          <LeftInfoDate>{date}</LeftInfoDate>
          <LeftInfoTime>{time}</LeftInfoTime>
        </LeftInfo>
        <DataWrapper>
          <PatientInfoBody hideMobile={true}>
            <PatientValue>{name}</PatientValue>
            <PatientValue>{handleScheduleType(type)}</PatientValue>
          </PatientInfoBody>
          <PatientInfoBody hideMobile={true}>
            <PatientTitle>{handleProducedureTitle(type)}</PatientTitle>
            <PatientValue>{handleProcedureType(type)}</PatientValue>
          </PatientInfoBody>
        </DataWrapper>
        <ButtonBody>
          {enableStart && type === "TELEMEDICINE" && (
            <ConfirmButton onClick={() => onStart()}>Ingressar</ConfirmButton>
          )}
          <CancelButton onClick={() => onCancel()}>Cancelar</CancelButton>
        </ButtonBody>
        <ShowMoreBox showMore={showMore} onClick={() => _showMore()}>
          {showMore ? (
            <ImageShowMore src="/img/show-less.png" />
          ) : (
            <ImageShowMore src="/img/show-more.png" />
          )}
        </ShowMoreBox>
      </ScheduleBody>
      {showMore && (
        <ShowMore>
          <MobileTextBox>
            <PatientValue>{handleScheduleType(type)}</PatientValue>
          </MobileTextBox>
          <MobileTextBox>
            <PatientTitle>{`${handleProducedureTitle(type)}:`} </PatientTitle>
            <PatientValue>{handleProcedureType(type)}</PatientValue>
          </MobileTextBox>
          <ShowMoreDivision />
          {enableStart && type === "TELEMEDICINE" && (
            <ConfirmButton onClick={() => onStart()}>Ingressar</ConfirmButton>
          )}
          <CancelButton onClick={() => onCancel()}>Cancelar</CancelButton>
        </ShowMore>
      )}
    </Card>
  );
};
