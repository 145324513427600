import * as yup from "yup";

export default yup.object().shape({
  lastPassword: yup
    .string()
    .trim()
    .required("Senha é obrigatória")
    .min(8, "Mínimo 8 caracteres")
    .test("password-text", "Senha fraca", function (value) {
      return value.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])[0-9a-zA-Z$*&@#]{8,}$/
      );
    }),
  newPassword: yup
    .string()
    .trim()
    .required("Senha é obrigatória")
    .min(8, "Mínimo 8 caracteres")
    .test("password-text", "Senha fraca", function (value) {
      return value.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])[0-9a-zA-Z$*&@#]{8,}$/
      );
    }),
  confirmNewPassword: yup
    .string()
    .trim()
    .required("Confirme sua senha")
    .test("confirm-password", "Senhas divergentes", function (value) {
      if (this.parent && this.parent.newPassword !== value) {
        return false;
      } else {
        return true;
      }
    }),
});
