import React from "react";
import { v4 as uuidv4 } from "uuid";

import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Main,
  Titles,
  Title,
  SubTitle,
  DivLogo,
  Logo,
  Infos,
  Texts,
  Buttons,
  Button,
  ButtonBack,
  LabelBack,
  IconBack,
} from "./styles";
import { getDesktopData } from "../../utils/getDesktopData";
import { postTrackingEvent } from "../../core/Tracking/actions";
import { getPatientToken } from "../../redux/sagas/patient";
import {
  getUserProductsRequest,
  getVonageSessionStatus,
  createSessionVonage,
  createUrlSessionVonage,
  deleteVonageFinishCall,
  createSessionTruclinic,
} from "../../redux/sagas/call";
import { push } from "../../services/navigate";
import { getRemoteConfigValue } from "../../hooks/RemoteConfig";
import { setLoading } from "../../redux/actions/utils.actions";
import { show } from "../../services/alert";
import { postPatientLeadTerms } from "../../redux/actions/termsProvider.actions";

export default function Covid(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer.user);
  let provider = JSON.parse(localStorage.getItem("@conecta:selected-provider"));
  const patient = useSelector((state) => state.patientReducer.patient);
  const patientSelected = JSON.parse(localStorage.getItem("@conecta:patient-selected"));
  const serviceVonageEnabled = getRemoteConfigValue("service_vonage_enabled");
  const teleconsultingTermsEnabled = getRemoteConfigValue(
    "teleconsulting_terms_enabled"
  );
  const catchSystemError = getRemoteConfigValue(
    "catch_system_error"
  );
  const correlationIdStorage = localStorage.getItem("@correlationId");
  const decisionTreeStorage = JSON.parse(
    localStorage.getItem("@decision_tree")
  );
  const numberCard = JSON.parse(
    localStorage.getItem("@selected_provider_number_card")
  );
  const examsDocuments = JSON.parse(localStorage.getItem("conecta:exams"));
  const [checked, setChecked] = React.useState(false);

  const TERMS_STATUS = {
    ACCEPTED: "ACCEPTED",
    REJECTED: "REJECTED",
    INITIAL: "INITIAL",
  };

  const leadTerms = useSelector(
    (state) => state.termsProvider.patientLeadTermsFiltered
  );

  const filterLeadId = (propertyName) => {
    return leadTerms[propertyName]?.id || 0;
  };

  const teleconsultingTermId = filterLeadId("teleConsultation");

  function goToUploads() {
    localStorage.removeItem("conecta:docs");
    window.location.href = '/arquivos'
  }

  function goToHome() {
    window.location.href = '/home'
  }

  const changeCreateSessionTruclinic = React.useCallback(async () => {
    dispatch(setLoading(true));
    const patientToken = await getPatientToken(patientSelected._id);
    const userProducts = await getUserProductsRequest(patientToken);
    if (numberCard?.length > 0) {
      const newProvider = {
        ...provider,
        identification: numberCard,
      };
      provider = newProvider;
    }
    await createSessionTruclinic(
      patientSelected,
      provider,
      decisionTreeStorage,
      userProducts,
      patientToken,
      examsDocuments
    )
      .then(async (response) => {
        const arraySessions = await JSON.parse(
          localStorage.getItem("@conecta:immediateCare")
        );
        let newArraySessions = [];
        if (arraySessions !== null) {
          const removeSessionCurrent = arraySessions.filter(
            (session) => session.document !== patientSelected.document
          );
          newArraySessions.push(...removeSessionCurrent, {
            url: response.url,
            document: patientSelected.document,
          });
        } else {
          localStorage.setItem(
            "@conecta:immediateCare",
            JSON.stringify([
              {
                url: response.url,
                document: patientSelected.document,
              },
            ])
          );
        }
        localStorage.setItem(
          "@conecta:immediateCare",
          JSON.stringify(newArraySessions)
        );
        window.open(response.url, "_self");
        dispatch(setLoading(false));
      })
      .catch(async (error) => {
        const errorResponse = String(error).replace("Error: ", "");
        const arraySessions = await JSON.parse(
          localStorage.getItem("@conecta:immediateCare")
        );
        if (arraySessions !== null) {
          let getSessionCurrent = arraySessions.filter(
            (session) => session.document === patientSelected.document
          )[0];
          if (
            getSessionCurrent !== undefined &&
            getSessionCurrent !== null &&
            getSessionCurrent?.url?.length !== 0
          ) {
            const isSafari =
              /constructor/i.test(window.HTMLElement) ||
              (function (p) {
                return p.toString() === "[object SafariRemoteNotification]";
              })(
                !window["safari"] ||
                (typeof safari !== "undefined" && safari.pushNotification)
              );

            if (isSafari) {
              window.open(getSessionCurrent.url, "_self");
            } else {
              if (
                getDesktopData().deviceType.toLocaleLowerCase() === "mobile"
              ) {
                window.open(getSessionCurrent.url, "_self");
              } else {
                window.open(getSessionCurrent.url, "_blank");
              }
            }

            push("/home");
            localStorage.removeItem("conecta:exams");
            dispatch(setLoading(false));
          } else {
            show(errorResponse);
            dispatch(setLoading(false));
          }
        } else {
          show(errorResponse);
          dispatch(setLoading(false));
        }
      });
  }, []);

  const changeCreateNewSessionVonage = React.useCallback(async () => {
    dispatch(setLoading(true));
    const isDependent = patientSelected._id !== user._id;
    const idPatient = isDependent
      ? patientSelected._id
      : patientSelected.idPatientConecta;
    const patientToken = await getPatientToken(idPatient);
    const userProducts = await getUserProductsRequest(patientToken);
    if (numberCard?.length > 0) {
      const newProvider = {
        ...provider,
        identification: numberCard,
      };
      provider = newProvider;
    }
    try {
      const sessionVonageState = await getVonageSessionStatus(
        patientToken,
        idPatient
      );
      console.log({ sessionVonageState })
      if (!sessionVonageState) {
        await createSessionVonage(
          patientSelected,
          provider,
          decisionTreeStorage,
          userProducts,
          patientToken,
          examsDocuments
        )
          .then(async (responseSession) => {
            dispatch(
              postTrackingEvent([
                {
                  correlationId: correlationIdStorage,
                  idempotencyKey: uuidv4(),
                  payload: {
                    sessionId: responseSession.session,
                    convenios: provider,
                    type: "TELE",
                  },
                  scope: "upa",
                  terminal: false,
                  timestamp: new Date(),
                  type: "atendimentoIniciado",
                },
                patientSelected,
              ])
            );
            await createUrlSessionVonage(
              patientSelected.name,
              responseSession.session,
              patientToken
            )
              .then((responseUrl) => {
                if (responseUrl) {
                  const sessionsPatients = JSON.parse(
                    localStorage.getItem("@vonage_session_status")
                  );
                  const telemedicineImmediate = {
                    sessionId: responseSession.session,
                    url: responseUrl.url,
                    idPatient: idPatient,
                  };
                  let newSessionsPatients = [];

                  if (sessionsPatients !== null) {
                    sessionsPatients.map((value) => {
                      newSessionsPatients.push(value);
                    });
                  }

                  newSessionsPatients.push(telemedicineImmediate);

                  localStorage.removeItem("@vonage_session_status");

                  localStorage.setItem(
                    "@vonage_session_status",
                    JSON.stringify(newSessionsPatients)
                  );

                  const isSafari =
                    /constructor/i.test(window.HTMLElement) ||
                    (function (p) {
                      return (
                        p.toString() === "[object SafariRemoteNotification]"
                      );
                    })(
                      !window["safari"] ||
                      (typeof safari !== "undefined" &&
                        safari.pushNotification)
                    );

                  if (isSafari) {
                    window.open(responseUrl.url, "_self");
                  } else {
                    if (
                      getDesktopData().deviceType.toLocaleLowerCase() ===
                      "mobile"
                    ) {
                      window.open(responseUrl.url, "_self");
                    } else {
                      window.open(responseUrl.url, "_blank");
                    }
                  }

                  dispatch(setLoading(false));
                  localStorage.removeItem("conecta:exams");
                  return true;
                }
                return false;
              })
              .catch(async () => {
                await deleteVonageFinishCall(
                  responseSession.session,
                  patientToken
                );
                dispatch(setLoading(false));
              });
          })
          .catch(() => {
            dispatch(setLoading(false));
          });
      } else {
        dispatch(setLoading(false));
      }
    } catch {
      dispatch(setLoading(false));
    }
  }, []);

  const handleSession = async () => {
    if (catchSystemError.immediateCare.enabled) {
      window.location.href = '/teleconsulta/warning-screen'
      return;
    }

    if (!checked) {
      return show("É necessário aceitar os termos de uso.");
    }

    if (teleconsultingTermsEnabled) {
      dispatch(
        postPatientLeadTerms({
          termId: teleconsultingTermId,
          status: TERMS_STATUS.ACCEPTED,
        })
      );
    }

    if (serviceVonageEnabled.immediate_care) {
      await changeCreateNewSessionVonage();
    } else {
      await changeCreateSessionTruclinic();
    }
  };

  return (
    <Container>
      <DivLogo onClick={() => goToHome()}>
        <Logo src="img/logo-blue.png" />
      </DivLogo>

      <Main>
        <ButtonBack type="button" onClick={goToHome}>
          <IconBack src="img/icons/back-icon.png"></IconBack>
          <LabelBack>Voltar</LabelBack>
        </ButtonBack>

        <Titles>
          <Title>Sua consulta está prestes a começar</Title>
          <SubTitle>Dicas para uma melhor experiência:</SubTitle>
        </Titles>

        <Infos>
          <Texts>
            <img src="img/sem-som.png" />
            <p>
              Procure um local silencioso, privativo e com boa iluminação. Se
              possível, use fones de ouvido.
            </p>
          </Texts>
          <Texts>
            <img src="img/wifi.png" />
            <p>
              Prefira conexão Wi-Fi e tente ficar próximo ao roteador de
              internet. 3G/4G também funcionam, mas verifique se está com um bom
              sinal da antena. Evite se deslocar durante a consulta.
            </p>
          </Texts>
          <Texts>
            <img src="img/carteirinha.png" />
            <p>
              Antes de começar, tenha em mãos um documento com foto para sua
              correta identificação. Ele será solicitado logo no primeiro
              momento.
            </p>
          </Texts>
          <Texts>
            Você deseja fazer upload de alguma foto ou documento antes da
            consulta?
          </Texts>
          <div style={{ padding: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <input style={{
              marginRight: 5,
            }} type="checkbox" id="termo" value={checked} onClick={() => setChecked(!checked)} />
            <label style={{
              color: '#666666',
              flexWrap: 'wrap',
              fontSize: 10,
            }}>Ao iniciar o atendimento, você concorda com o <label style={{
              color: '#0274D2',

            }} onClick={() => { window.location.href = '/teleconsulta/termo' }}>Termo de uso obrigatório da Teleconsulta.</label></label>
          </div>
          <Buttons>
            <Button outline onClick={() => goToUploads()}>
              Fazer upload
            </Button>
            <Button contained width="250px" onClick={() => handleSession()}>
              Ir para a consulta
            </Button>
          </Buttons>
        </Infos>
      </Main>
    </Container>
  );
}
