import styled from "styled-components"

export const Container = styled.div`
  width: 460px;
  max-height: 300px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  padding: 50px 10px;

  @media (max-width: 1024px) {
    margin-top: 30px;
    /* height: 60vh; */
    max-height: 60vh;
  }
`

export const ButtonBack = styled.button`
  cursor: pointer;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background: transparent;
`

export const IconBack = styled.img`
  width: 15px;
  height: 15px;
`

export const DivInput = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: flex-start;
`

export const DivInputCustom = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
`

export const Input = styled.input`
  border: 2px solid #000;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  font-size: 16;
  margin-top: 10px;
`

export const DivButtons = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`

export const ButtonConfirm = styled.div`
  width: 225px;
  height: 35px;
  border-radius: 20px;
  padding: 7px;
  text-align: center;
  font-size: 12px;
  color: white;
  background-color: #2196f3;
  cursor: pointer;
`
export const ButtonText = styled.div`
  width: 100%;
  height: 30px;
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #2196f3;
  cursor: pointer;
`
export const ButtonTextResend = styled.div`
  width: 100%;
  height: 30px;
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #7ec548;
  cursor: pointer;
`

export const Title = styled.div`
  display: flex;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 10px;
  color: black;
`
export const SubTitle = styled.div`
  display: flex;
  text-align: center;
  font-size: 14px;
  color: #707070;
  padding-bottom: 10px;
`
