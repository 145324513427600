import { TERMS_PROVIDER_STATUS } from "../../pages/home/select-patient/terms-provider/constants";
import {
  SET_TERMS_PROVIDER,
  SET_TERMS_PROVIDER_STATUS,
  SET_TERMS_PROVIDER_STATUS_AND_TIME,
  GET_TERMS_PROVIDER,
  SET_PATIENT_TERMS,
  SET_TELECONSULTATION_TERMS,
  SET_PATIENT_LEAD_TERMS,
  SET_COMMUNICATION_MODAL_OPEN,
  SET_PATIENT_LEAD_TERMS_FILTERED,
} from "../actions/action-types";

const initialState = {
  provider: "",
  status: TERMS_PROVIDER_STATUS.NOT_ELEGIBLE,
  lastAccepted: "",
  termUrl: "",
  teleconsultationTerms: null,
  meta: {
    pages: 0,
    totalPages: 0,
    data: [],
  },
  patientTerms: {},
  patientLeadTerms: {
    lead: {
      birthDate: "",
      document: "",
      id: 0,
      name: "",
    },
    terms: [],
  },
  patientLeadTermsFiltered: {
    healthOrientation: null,
    productsAndServices: null,
    searchSatisfaction: null,
    teleConsultation: null,
  },
  communicationModalOpen: false,
};

export default (state = initialState, action) => {
  const setLocalStorage = (value) =>
    localStorage.setItem(
      "@selected_terms_provider_settings",
      JSON.stringify(value)
    );
  const getLocalStorage = () =>
    JSON.parse(
      localStorage.getItem("@selected_terms_provider_settings") || "{}"
    );
  let data = {
    ...state,
  };
  switch (action.type) {
    case SET_TERMS_PROVIDER_STATUS:
      data = {
        ...state,
        status: action.payload,
      };
      setLocalStorage(data);
      return data;
    case SET_TERMS_PROVIDER_STATUS_AND_TIME:
      const { status: terms_status, lastAccepted: terms_lastAccepted } =
        action.payload;
      data = {
        ...state,
        status: terms_status,
        lastAccepted: terms_lastAccepted,
      };
      setLocalStorage(data);
      return data;
    case SET_TERMS_PROVIDER:
      const { provider, status, lastAccepted, termUrl, meta } = action.payload;
      data = {
        ...state,
        provider,
        status,
        lastAccepted,
        termUrl,
        meta,
      };
      setLocalStorage(data);
      return data;
    case GET_TERMS_PROVIDER:
      return getLocalStorage(data);
    case SET_PATIENT_TERMS:
      return {
        ...state,
        patientTerms: action.payload,
      };
    case SET_TELECONSULTATION_TERMS:
      return {
        ...state,
        teleconsultationTerms: action.payload,
      };
    case SET_PATIENT_LEAD_TERMS:
      return {
        ...state,
        patientLeadTerms: action.payload,
      };
    case SET_PATIENT_LEAD_TERMS_FILTERED:
      return {
        ...state,
        patientLeadTermsFiltered: {
          ...state.patientLeadTermsFiltered,
          [action.payload.property]: action.payload.data,
        }
      };
    case SET_COMMUNICATION_MODAL_OPEN:
      return {
        ...state,
        communicationModalOpen: Boolean(action.payload),
      };

    default:
      return state;
  }
};
