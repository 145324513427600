import styled, { css } from "styled-components";
import { motion } from "framer-motion";

export const Step = styled(motion.div).attrs((props) => ({
  animate: {
    backgroundColor: props.done
      ? props.theme.colors.primary
      : props.theme.colors.background,
  },
  transition: {
    delay: 0.1,
    duration: 0.5,
  },
}))`
  border-radius: 1.7em;
  width: 2.8em;
  height: 2.8em;
  align-items: center;
  justify-content: center;
  text-align: justify;
  display: flex;

  @media (max-width: 400px) {
    width: 2em;
    height: 2em;
    border-radius: 1em
  }
`;

export const Separator = styled(motion.div).attrs((props) => ({
  animate: {
    backgroundColor: props.active
      ? props.theme.colors.primary
      : props.theme.colors.background,
  },
  transition: {
    duration: 0.3,
  },
}))`
  display: flex;
  height: 1.5px;
  margin-left: 4px;
  margin-right: 4px;
  width: 4em;

  @media (max-width: 400px) {
    width: 2em;
  }
`;

export const StepGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
`;

export const StepUnitContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
`;

export const StepIcon = styled.div`
  fill: ${(props) => (props.done ? "white" : "rgb(191, 191, 191)")};
  font-size: 1.8em;
  transition: all 0.8s cubic-bezier(0.37, 0.13, 0.58, 1);
  transition-delay: 0.1s;

  @media (max-width: 400px) {
    font-size: 1em;
  }
`;
