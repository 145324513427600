import styled, { css } from "styled-components";

import { motion } from "framer-motion";
import animations from "../../../../utils/animation";

export const Container = styled.div`
  width: 90%;
  max-height: 90vh;
  overflow: auto;
  position: relative;
  background: #fff;

  @media (max-width: 1024px) {
    margin: 50px 0;
  }
`;

export const TopicsDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1024px) {
    margin: 0 15px;
  }
`;

export const TopicItem = styled.div`
  width: 90%;
  margin: 10px 0;
  padding: 40px 0;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;

  &:hover {
    opacity: 0.7;
    transition: 0.3s;
    cursor: pointer;
  }

  @media (max-width: 1024px) {
    width: 80%;
    padding: 20px 5px;
  }
`;

export const TopicHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

export const TopicBody = styled(motion.div).attrs(animations)`
  margin: 20px 0;
`;

export const TopicText = styled.h4``;

export const TopicIcon = styled.img`
  width: 20px;
  height: 20px;
  margin: 0 10px;
`;

export const UlCustom = styled.ul`
  list-style: none;
`;

export const LiCustom = styled.li`
  margin: 30px 0;
  padding-left: 20px;
`;

export const IconClose = styled.div`
  position: fixed;
  top: 15px;
  right: 15px;
  cursor: pointer;
`;
