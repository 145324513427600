import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { createReducer } from "@reduxjs/toolkit";

import { encryptTransform } from "redux-persist-transform-encrypt";

import { setDependents, setPatient } from "./actions";

import { REACT_APP_CRYPTO_STORE_HOME_MICROFRONTEND } from "../../configuration/env";

const persistConfig = {
  key: "conecta:home-microfrontend/home",
  version: 1,
  storage,
  transforms: [
    encryptTransform({
      secretKey: REACT_APP_CRYPTO_STORE_HOME_MICROFRONTEND,
      onError: function (error) {},
    }),
  ],
  whitelist: [],
};

const initialState = null;

const homeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setPatient, (state, action) => ({
      ...state,
      ...action.payload,
    }))
    .addCase(setDependents, (state, action) => ({
      ...state,
      ...action.payload,
    }));
});

const persistHome = persistReducer(persistConfig, homeReducer);

export default persistHome;
