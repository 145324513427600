import { GET_CORPORATE_CARD_REQUEST, SET_CORPORATE_CARD_DATA } from "./action-types"

export const setCorporateCardData = (payload) => ({
  type: SET_CORPORATE_CARD_DATA,
  payload,
});

export const getCorporateCardRequest = (payload) => ({
  type: GET_CORPORATE_CARD_REQUEST,
  payload,
});
