function parseString(value) {
  if (isArrayString(value)) {
    return JSON.parse(value);
  }

  if (isObjectString(value)) {
    return JSON.parse(value);
  }

  if (value === "false" || value === "true") {
    return JSON.parse(value);
  }

  if (isNumericString(value)) {
    return JSON.parse(value);
  }

  return value;
}

function isArrayString(str) {
  return /^\[.*\]$/.test(str);
}

function isObjectString(str) {
  return /^\{.*\}$/.test(str);
}

function isNumericString(str) {
  return /^\d+$/.test(str);
}

export default parseString;