import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "../../container";
import * as S from "../../components/cards-terms/styles";
import moment from "moment";
import { postPatientLeadTerms } from "../../redux/actions/termsProvider.actions";
moment.locale("pt-br");

const TERMS_STATUS = {
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
  INITIAL: "INITIAL",
};

export default function Communications() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer.user);
  const [lastDate, setLastDate] = React.useState(new Date());
  const leadTerms = useSelector(
    (state) => state.termsProvider.patientLeadTermsFiltered
  );
  const [leadTermsDates, setLeadTermsDates] = React.useState({
    healthOrientation: null,
    productsAndServices: null,
    searchSatisfaction: null,
  });
  const [leadTermsStatus, setLeadTermsStatus] = React.useState({
    healthOrientation: null,
    productsAndServices: null,
    searchSatisfaction: null,
  });
  const [leadTermsIds, setLeadTermsIds] = React.useState({
    healthOrientation: 0,
    productsAndServices: 0,
    searchSatisfaction: 0,
  });

  const filterLeadDate = (propertyName) => {
    return leadTerms[propertyName]?.current?.interactionDate || null;
  };

  const filterLeadStatus = (propertyName) => {
    return leadTerms[propertyName]?.current?.status || null;
  };

  const filterLeadId = (propertyName) => {
    return leadTerms[propertyName]?.id || 0;
  };

  const mostRecentDate = async () => {
    const healthOrientationDate = filterLeadDate("healthOrientation");
    const productsAndServicesDate = filterLeadDate("productsAndServices");
    const searchSatisfactionDate = filterLeadDate("searchSatisfaction");

    const healthOrientationStatus = filterLeadStatus("healthOrientation");
    const productsAndServicesStatus = filterLeadStatus("productsAndServices");
    const searchSatisfactionStatus = filterLeadStatus("searchSatisfaction");

    const healthOrientationId = filterLeadId("healthOrientation");
    const productsAndServicesId = filterLeadId("productsAndServices");
    const searchSatisfactionId = filterLeadId("searchSatisfaction");

    const dates = {
      healthOrientation: healthOrientationDate,
      productsAndServices: productsAndServicesDate,
      searchSatisfaction: searchSatisfactionDate,
    };
    const status = {
      healthOrientation: healthOrientationStatus,
      productsAndServices: productsAndServicesStatus,
      searchSatisfaction: searchSatisfactionStatus,
    };
    const ids = {
      healthOrientation: healthOrientationId,
      productsAndServices: productsAndServicesId,
      searchSatisfaction: searchSatisfactionId,
    };

    setLeadTermsDates(dates);
    setLeadTermsStatus(status);
    setLeadTermsIds(ids);

    const ifHasNoIds = Object.keys(leadTermsIds).find(
      (key) => leadTermsIds[key] === 0
    );

    if (ifHasNoIds) return;


    const showBannerIntervalRule = () => {
      const terms = [
        {
          current: {
            status: healthOrientationStatus,
            interactionDate: healthOrientationDate
          }
        },
        {
          current: {
            status: productsAndServicesStatus,
            interactionDate: productsAndServicesDate
          }
        },
        {
          current: {
            status: searchSatisfactionStatus,
            interactionDate: searchSatisfactionDate
          }
        }];

      const filteredTerms = terms?.filter((term) => term?.current?.status);

      if (!filteredTerms?.length) return false;

      const interactionDates = filteredTerms
        .filter((term) => term?.current?.interactionDate)
        .map((term) => new Date(term.current.interactionDate))
        .filter((date) => !isNaN(date.getTime()));

      if (!interactionDates.length) return true;

      const mostRecentDate = new Date(Math.max.apply(null, interactionDates));

      const today = new Date();
      const diffTime = Math.abs(today.getTime() - mostRecentDate.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      const statuses = new Set(filteredTerms.map((term) => term.current.status));

      return (
        (statuses.has('ACCEPTED') && diffDays >= 360) || diffDays >= 180
      );
    }

    setModalOpen(showBannerIntervalRule());
  };

  useEffect(() => {
    (async () => await mostRecentDate())();
  }, [leadTerms]);

  const TermSwitch = ({ title, description, name }) => {
    const [enabled, setEnabled] = useState(false);

    const handleToggle = () => {
      const statusDescription =
        leadTermsStatus[name] === TERMS_STATUS.ACCEPTED
          ? TERMS_STATUS.REJECTED
          : TERMS_STATUS.ACCEPTED;
      setLeadTermsStatus((prev) => {
        return {
          ...prev,
          [name]: statusDescription,
        };
      });
      dispatch(
        postPatientLeadTerms({
          termId: leadTermsIds[name],
          status: statusDescription,
        })
      );
    };

    useEffect(() => {
      const statusBoolean =
        leadTermsStatus[name] === TERMS_STATUS.ACCEPTED ? false : true;
      setEnabled(!statusBoolean);
    }, [leadTermsStatus]);

    return (
      <S.CommunicationTermWrapper>
        <S.Info>
          <h3>{title}</h3>
          <p>{description}</p>
        </S.Info>
        <S.SwitchContainer>
          <S.SwitchLabel>
            <S.Input
              type="checkbox"
              checked={enabled}
              onChange={handleToggle}
            />
            <S.Slider className="slider">
              <S.SliderBefore />
            </S.Slider>
          </S.SwitchLabel>
        </S.SwitchContainer>
      </S.CommunicationTermWrapper>
    );
  };

  return (
    <>
      <Container>
        <S.CommunicationContainer>
          <S.Header>
            <h1>Comunicações</h1>
          </S.Header>

          <S.ContainerContent className="title">
            <S.ContainerTerm>
              <S.Text>
                <strong style={{ fontSize: 20 }}>
                  Consentimento para comunicações
                </strong>
              </S.Text>
            </S.ContainerTerm>
          </S.ContainerContent>

          <S.CardUserInfo>
            <S.UserData>
              <span>Titular da conta</span>
              <h3>{`${user.name} ${user.lastName}`}</h3>
            </S.UserData>

            <S.UserTimeViewed>
              Editado em: {" "}
              <span>{moment(lastDate).format(`DD/MM/YYYY [às] HH:mm`)}</span>
            </S.UserTimeViewed>
          </S.CardUserInfo>

          <S.Row>
            <S.Column>
              <S.ContainerContent className="read-term">
                <S.ContainerTerm>
                  <S.Text>
                    <span>
                      Escolha quais tipos de informações deseja que o Einstein
                      envie para você.
                    </span>
                  </S.Text>

                  <TermSwitch
                    name="healthOrientation"
                    title="Orientações sobre sua saúde"
                    description="Informações e orientações relevantes relacionadas à sua saúde, por meio de canais de comunicação físicos e digitais."

                  />
                  <TermSwitch
                    name="productsAndServices"
                    title="Produtos e serviços Einstein"
                    description="Informações sobre produtos e/ou serviços fornecidos pelo Einstein, por meio de canais de comunicação físicos e digitais."
                  />

                  <TermSwitch
                    name="searchSatisfaction"
                    title="Pesquisa de satisfação"
                    description="Pesquisas de avaliação da satisfação e para nos ajudar a melhorar a prestação dos nossos serviços."
                  />
                </S.ContainerTerm>
              </S.ContainerContent>
            </S.Column>
            <S.ColumnWidthCardUser />
          </S.Row>
        </S.CommunicationContainer>
      </Container>
    </>
  );
}
