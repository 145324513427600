import React, { useCallback, useEffect, useState } from "react";
import { Container, Body, ProgramBody, NavigationDiv } from "./style"
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai"
import ProgramCard from "../program-card"
import { useDispatch, useSelector } from "react-redux";
import { setSelectedProgram } from "../../../../redux/actions/telepsico.actions"

export default function Main(props) {
  const dispatch = useDispatch();
  const programs = useSelector((state) => state.telepsico.list);

  const [sliderPosition, setSliderPosition] = useState(0);
  const [programFocus, setProgramFocus] = useState([]);
  const [showMoreCard, setShowMoreCard] = useState([]);

  const [testFocus, setTestFocus] = useState(false);

  useEffect(() => {
    function getProgramLength() {
      const isFocus = [];
      const showMoreList = [];

      for(let counter = 0; counter < programs.length; counter ++) {
        isFocus.push(false);
        showMoreList.push(false);
      }

      setProgramFocus(isFocus);
      setShowMoreCard(showMoreList);
    }

    getProgramLength();
  }, [programs]);

  const _changeShowMoreCard = useCallback((showMoreIndex) => {
    const newShowMoreCard = [];

    for(let counter = 0; counter < showMoreCard.length; counter ++) {
      if(showMoreIndex !== counter) {
        newShowMoreCard.push(false);
      } else {
        if(showMoreCard[counter]) {
          newShowMoreCard.push(false);
        } else {
          newShowMoreCard.push(true)
        }
      }
    }

    setShowMoreCard(newShowMoreCard)
  }, [showMoreCard, setShowMoreCard]);

  const _changeFocusCard = useCallback((cardIndex) => {
    const newProgramFocus = [];

    for(let index = 0; index < programFocus.length; index ++) {
      if(cardIndex != index) {
        newProgramFocus.push(false);
        continue
      }

      if(programFocus[index]) {
        newProgramFocus.push(false)
      } else {
        newProgramFocus.push(true)
      }
    }

    setProgramFocus(newProgramFocus)
  }, [programFocus, setProgramFocus]);

  const slider = document.getElementById("items");
  let isDown = false;
  let startX;
  let scrollLeft; 

  if (slider) {
    slider.addEventListener('mousedown', (e) => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mousemove', (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 1; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    });
  }

  const _scrollStart = () => {
    if (slider) {
      if(sliderPosition > 0) {
        setSliderPosition(sliderPosition - 200);
      }
      
      const options = {
        left: sliderPosition,
        right: slider.scrollWidth,
        behavior: 'smooth'
      }

      slider.scrollTo(options);
    }
  }

  const _scrollEnd = () => {
    if (slider) {

      if(sliderPosition < slider.scrollWidth) {
        setSliderPosition(sliderPosition + 200);
      }

      const options = {
        left: sliderPosition,
        right: slider.scrollWidth,
        behavior: 'smooth'
      }

      slider.scrollTo(options);
    }
  }

  const _selectProgram = (program) => {
    dispatch(setSelectedProgram(program));
  }


  return (
    <Container>
      {programs && programs.length > 0 && (
        <Body>
          <NavigationDiv onClick={() => _scrollStart()}>
            <AiOutlineLeft />
          </NavigationDiv>
          <ProgramBody id="items">
            {programs && programs.map((program, index) => (
                <ProgramCard
                  key={program._id}
                  title={program.title}
                  iconSrc={program.iconSrc}
                  period={program.period}
                  showMore={showMoreCard[index]}
                  showMoreIndex={index}
                  _changeShowMoreCard={_changeShowMoreCard}
                  _changeFocus={_changeFocusCard}
                  isFocus={programFocus[index]}
                  programFocusIndex={index}
                  periodDescription={program.periodDescription}
                  onConfirm={() => _selectProgram(program)}
                  description={program.description} />
      ))}
            
          </ProgramBody>
          <NavigationDiv onClick={() => _scrollEnd()}>
            <AiOutlineRight />
          </NavigationDiv>
        </Body>
      )}
      {programs && programs.length === 0 && (
        <Body>Não existem programas de telepsicologia disponíveis no momento, tente novamente mais tarde.</Body>
      )}
    </Container>
  );
}