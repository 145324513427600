import styled from "styled-components";

export const Container = styled.div`
  min-width: 800px;

  @media (max-width: 1024px) {
    width: 100%;
    min-width: 100%;
  }
`;

export const Titles = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: 1024px) {
    width: 100%;
    align-items: center;
    text-align: center;
  }
`;

export const Title = styled.h2`
  margin-bottom: 5px;
  font-size: 18px;
`;

export const SubTitle = styled.p`
  color: #707070;
`;

export const LabelLoading = styled.p`
  margin: auto;
`;

export const Main = styled.div`
  @media (max-width: 1024px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0;
  }
`;

export const Plans = styled.div`
  background: url(${(props) => props.src});
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 0 50px;

  @media (max-width: 1024px) {
    height: auto;
    min-height: 100vh;
  }
`;

export const PlansLabel = styled.h1`
  margin: 20px 0;
  color: #fff;
`;

export const PlansDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 30px 0 40px;
`;

export const PlansInstructions = styled.h1`
  margin: 20px 0;
`;

export const Plan = styled.div`
  margin: 10px 20px;
  position: relative;
  box-shadow: 0px 0px 9px 0px rgba(150, 150, 150, 1);
  width: 325px;
  height: 570px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
`;

export const TitlePlan = styled.h2`
  font-size: 25px;
  text-align: center;
`;

export const SubTitlePlan = styled.p`
  font-size: 14px;
  color: #707070;
  margin-bottom: ${props => props.namePlan === 'Consulta Avulsa' ? '43px' : '20px'};
  text-align: center;
`;

export const OldPrice = styled.div`
  margin: 0 0 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  color: #707070;

  b {
    text-decoration: line-through red;

    margin-left: 5px;
    font-size: 18px;
    color: #707070;
  }
`;

export const NewPrice = styled.div`
  display: flex;
  justify-content: center;
`;

export const Price = styled.p`
  font-weight: bold;
  font-size: 35px;
  color: #000;
`;

export const DetailsPrice = styled.div`
  margin: 7px 5px 0;
  width: 30px;
  display: flex;
  flex-direction: column;
  p {
    font-weight: bold;
  }
  span {
    font-weight: lighter;
    color: #707070;
    margin-left: 5px;
    font-size: 12px;
    width: 80px;
  }
`;

export const CardHeading = styled.div`
  display: flex;
  flex-direction: column;
  height: 180px;
  
`;

export const ListAdvantagesPlan = styled.div`
  margin: 10px 0;
  width: 80%;
`;

export const Advantage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  margin: 12px 0;
`;

export const IconAdvantage = styled.img`
  width: 15px;
`;

export const LabelAdvantage = styled.p`
  color: #707070;
  margin-left: 5px;
  font-size: 15px;
`;
export const LabelDisclaimer = styled.p`
  color: #707070;
  margin: 15px 43px 0 38px;
  font-size: 10px;
  font-weight: bold;
`;

export const ButtonPlan = styled.button`
  width: 220px;
  height: 48px;
  background-color: #e0832c;
  color: white;
  border-radius: 25px;
  font-weight: bolder;
  cursor: pointer;

  bottom: 50px;
  margin-top: ${props => props.namePlan === 'Consulta Avulsa' ? '105px' : '20px'};
`;
