import styled, { css } from "styled-components";
import Mask from "react-input-mask";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 1024px) {
    height: 100vh;
    overflow: auto;
    padding-top: 60px;
  }
`;

export const Header = styled.div`
  margin: 20px auto 0;
  width: 85%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #f1f1f1;

  @media (max-width: 1024px) {
    width: 100%;
    padding: 0 20px;
    align-items: flex-start;
    margin: 35px auto 0;
    flex-direction: column;
  }
`;

export const Options = styled.div`
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 20px 0 0;
  }
`;

export const OptionsButton = styled.button`
  color: #fff;
  font-weight: bold;
  padding: 10px 25px;
  width: 170px;
  font-size: 12px;

  background: ${(props) => (props.active ? "#2196F3" : "#949494")};
  ${(props) => props.left && left};
  ${(props) => props.right && right};

  @media (max-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => (props.center ? "45%" : "35%")};
    padding: 0;
    font-size: 10px;
    height: 30px;
  }
`;

const left = css`
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
`;

const right = css`
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
`;

export const Form = styled.form`
  position: relative;
  margin: 20px auto 0;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #f1f1f1;
`;

export const ButtonBack = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: transparent;
`;

export const LabelBack = styled.p`
  margin-left: 10px;
  color: #707070;
`;

export const IconBack = styled.img`
  width: 20px;
  height: 20px;
`;

export const UploadImage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DivImage = styled.label`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;
`;

export const DivIcon = styled.div`
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  background: #2196f3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Icon = styled.img`
  width: 15px;
  height: 15px;
`;

export const InputNone = styled.input`
  display: none;
`;

export const ImageUser = styled.img`
  width: 76px;
  height: 76px;
  border-radius: 48px;
`;

export const Titles = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin: 0 20px;
`;

export const TitlesRecovery = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin: 20px 0;
`;

export const Title = styled.h2`
  margin-bottom: 5px;
  font-size: 18px;
`;

export const SubTitle = styled.p`
  color: #707070;
`;

export const DivLogo = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
`;

export const Inputs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 680px;
  margin: 0 0 10px;

  @media (max-width: 1024px) {
    width: 100%;
    justify-content: center;
  }
`;

export const DivInput = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: ${(props) => (props.width ? props.width : "325px")};
  height: 60px;

  label {
    width: 100%;
    text-align: left;
    font-size: 10px;
    height: 12px;
  }

  @media (max-width: 1024px) {
    width: 350px;
  }

  @media (max-width: 425px) {
    width: 270px;
  }
`;

export const Input = styled.input`
  border: 0;
  border-bottom: 1px solid #000;
  background-color: transparent;
  width: 100%;
  padding: 7px 0;
  color: ${(props) => (props.readOnlyColor ? "#707070" : "#000")};
`;

export const InputMask = styled(Mask)`
  border: 0;
  border-bottom: 1px solid #000;
  background-color: transparent;
  width: 100%;
  padding: 7px 0;
  color: ${(props) => (props.readOnlyColor ? "#707070" : "#000")};
`;

export const Select = styled.select`
  border: 0;
  border-bottom: 1px solid #000;
  background-color: transparent;
  width: 100%;
  padding: 7px 0;
`;

export const ErrorMessage = styled.p`
  color: ${(props) => (props.label ? "#707070" : "red")};
  width: 100%;
  padding: 5px 0;
  font-size: 10px;
`;

export const ErrorImage = styled.p`
  position: absolute;
  left: 5px;
  bottom: -25px;
  color: red;
  width: 100%;
  padding: 5px 0;
  font-size: 10px;
`;

export const Button = styled.button`
  width: 150px;
  height: 45px;
  background-color: #2196f3;
  color: white;
  border-radius: 25px;
  font-weight: bolder;
  cursor: pointer;
  margin-top: 40px;
  margin-right: ${props => props.marginRight || 0}px;
  padding: 0px 10px;

  @media (max-width: 1024px) {
    width: 80%;
    margin: 16px auto 0px;
    max-width: 200px;
  }
`;

export const InputsPassword = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  min-width: 600px;
  margin: 0 0 10px;
`;

export const CustomInput = styled.div`
  @media (max-width: 1024px) {
    margin: 30px 0 0;
  }
`;

export const UploadMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

export const DivImageUpload = styled.div`

  img{
    width: 50px;
    height: 50px;
    margin: 20px 0;
  }

`;

export const UploadArea = styled.label`
width: 218px;
height: 175px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  input#inputFile{
    display: none;
  }
`;

export const Uploaded = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;


  @media (max-width: 1024px) {
    width: 100%;
    margin: 20px 0 0;
  }
`;

export const Files = styled.div`
  width: 100%;
  max-height: 400px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;


export const ButtonUpload = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 154px;
  height: 30px;
  background-color: #2196f3;
  color: white;
  border-radius: 25px;
  font-weight: bolder;
  cursor: pointer;
  margin: 10px 0 0;

  @media (max-width: 1024px) {
    width: 92%;
  }
`;

export const ButtonSaveUpload = styled.button`
  width: 150px;
  height: 35px;
  background-color: #2196f3;
  color: white;
  border-radius: 25px;
  font-weight: bolder;
  cursor: pointer;
  margin: 30px 0 0;

  @media (max-width: 1024px) {
    margin: 40px 0 180px;
    width: 92%;
  }
`;

export const ItemFile = styled.div`
  width: 100%;
  border-bottom: 1px solid #c9c9c9;
  height: 40px;
  padding: 10px 0;
  margin: 5px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  img{
    cursor: pointer;
    width: 15px;
    height: 15px;
  }

  @media (max-width: 1024px) {
    margin: 20px 0 0;
  }
`;

export const FirstFile = styled.div`

display: flex;
  flex-direction: row;
  
  label{
    color: #707070;
    font-size: 13px;
  }

  img{
    margin: 0 10px;
    width: 20px;
  }

`

export const ButtonsContainer = styled.div`
  width: 100%;
  flex-direction: row; 
  display: flex; 
  justify-content: flex-end;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`