import { Link } from "react-router-dom";

import SideCardPrivacy from "../side-card-privacy";
import CardPrivacy from "../card-privacy";

import * as S from "./styles";

export default function CardsTerms({ terms, onSelectTerm }) {
  const use_terms = terms.find((term) => term.type === "terms_of_use");

  function openTermDocument() {
    onSelectTerm(use_terms)
    window.location.href = `/privacidade/${use_terms.title.replace(/\s/g, "-").toLowerCase()}`;
  }

  return (
    <>
      <S.Header>
        <h1>Privacidade</h1>
      </S.Header>

      <S.ContainerContent>
        <S.ContainerTerm>
          <S.Text>
            <div>
              Confira nosso {' '} <S.Link onClick={openTermDocument}> termo de uso </S.Link>,  {' '} 
              pois ele se aplica ao SEU acesso e forma de uso das informações,
              materiais e funcionalidades contidas no {' '}
              <strong>“Einstein Conecta”</strong> e em suas páginas on-line.
              Confira também abaixo todos nossos outros termos disponíveis.
            </div>
          </S.Text>

          {terms.map((term, index) => (
            <CardPrivacy
              onSelectTerm={(v) => onSelectTerm(v)}
              key={index}
              term={term}
            />
          ))}
        </S.ContainerTerm>

        <SideCardPrivacy />
      </S.ContainerContent>
    </>
  );
}
