import apiPatient from "../../services/apiPatient";
import { takeEvery } from "redux-saga/effects";
import { postTrackingEvent } from "./actions";
import { getDesktopData } from "../../utils/getDesktopData";

function* postTrackingEventSaga(action) {
  let patient = action.payload[1];
  const firstName = patient.name.split(" ")[0];
  const lastName =
    patient?.lastName !== undefined && patient?.lastName?.length > 0
      ? patient?.lastName
      : patient?.name
          .split(" ")
          .filter((_, index) => index !== 0)
          .join(" ");
  const user = {
    ...patient,
    firstName,
    lastName,
  };

  const events = [
    {
      ...action.payload[0],
      meta: {
        user,
        userProducts: ["TELEMEDICINA"],
      },
      device: getDesktopData().deviceType,
    },
  ];

  yield apiPatient
    .post(`/analytics/patient-journey`, {
      events,
    })
    .catch((error) => {
      show("Falha ao registrar evento.");
    });
}

const trackingSagas = [takeEvery(postTrackingEvent, postTrackingEventSaga)];

export default trackingSagas;
