import {
  SET_USER,
  MODAL_USER,
  MODAL_PATIENT,
  MODAL_VERIFICATION,
  MODAL_CARD_NUMBER,
  MODAL_PIN,
  PHONE_NUMBER,
  SET_FILES,
  SET_CURRENT_PRIVATE_PLAN,
  USER_SET_PROVIDER,
  USER_SET_TELEPSICOLOGIA,
  USER_SET_TELEMEDICINA,
  SET_HOME_PAGE,
  USER_SET_CORPORATE_CARD,
} from "../actions/action-types";

const initialState = {
  user: {},
  modalUser: false,
  modalPatient: false,
  modalVerification: false,
  modalCardNumber: false,
  modalPin: false,
  phone: "",
  files: [],
  currentPrivatePlan: null,
  selectedProvider: {},
  telepsicologia: false,
  telemedicina: false,
  corporateCard: false,
  homePage: "/home",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      localStorage.setItem("@conecta:patient-holder", JSON.stringify(action.user));
      return {
        ...state,
        user: action.user,
      };
    case MODAL_USER:
      return {
        ...state,
        modalUser: action.payload,
      };
    case MODAL_PATIENT:
      return {
        ...state,
        modalPatient: action.payload,
      };
    case MODAL_VERIFICATION:
      return {
        ...state,
        modalVerification: action.payload,
      };
    case MODAL_CARD_NUMBER:
      return {
        ...state,
        modalCardNumber: action.payload,
      };
    case MODAL_PIN:
      return {
        ...state,
        modalPin: action.payload,
      };
    case PHONE_NUMBER:
      return {
        ...state,
        phone: action.payload,
      };
    case SET_FILES:
      return {
        ...state,
        files: action.payload,
      };
    case SET_CURRENT_PRIVATE_PLAN:
      return {
        ...state,
        currentPrivatePlan: action.payload,
      };

    case USER_SET_PROVIDER:
      return {
        ...state,
        selectedProvider: action.payload,
      };
    case USER_SET_TELEPSICOLOGIA:
      return {
        ...state,
        telepsicologia: action.payload,
      };
    case USER_SET_TELEMEDICINA:
      return {
        ...state,
        telemedicina: action.payload,
      };
    case USER_SET_CORPORATE_CARD:
      return {
        ...state,
        corporateCard: action.payload,
      };
    case SET_HOME_PAGE:
      return {
        ...state,
        homePage: action.payload,
      };
    default:
      return state;
  }
};
