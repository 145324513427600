import React, { useState, useEffect } from "react";
import {
    Body,
    SubTitle,
} from "./styles";

import {
    DivTitle,
    TitleSchedule,
} from "../styles"

import SliderPrograms from "./slider-programs"

import { getAvailableDates, getTelepsicoPrograms } from "../../../redux/actions/telepsico.actions"
import { useDispatch, useSelector } from "react-redux";

export default function Main(props) {
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(getTelepsicoPrograms());
    }, []);

    return(
        <Body>
            <DivTitle>
                <TitleSchedule>Agendamento da Telepsicologia</TitleSchedule>
                <SubTitle>Selecione o programa que melhor se adeque às suas necessidades<br/> (Caso tenha dúvida, não se preocupe, <b>durante a primeira sessão</b> o profissional te orientará da melhor forma)</SubTitle>
            </DivTitle>
            <SliderPrograms />
        </Body>
    )
}