export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const SCHEDULE_NOT_FOUND_SLOT = "SCHEDULE_NOT_FOUND_SLOT"
export const UPLOAD_UPDATE_PROFILE_PICTURE = "UPLOAD_UPDATE_PROFILE_PICTURE"
export const SCHEDULE_SUCCESS_SLOT = "SCHEDULE_SUCCESS_SLOT"
export const CHECK_AUTH_REQUEST = "CHECK_AUTH_REQUEST"
export const SET_USER = "SET_USER"
export const SET_PATIENT = "SET_PATIENT"
export const PATIENT_SENT_DOCUMENTS = "PATIENT_SENT_DOCUMENTS"
export const SET_DEPENDENTS = "SET_DEPENDENTS"
export const SET_DEPENDENTS_RULES = "SET_DEPENDENTS_RULES"
export const SET_LOADING_MODAL = "SET_LOADING_MODAL"
export const SET_LOADING = "SET_LOADING"
export const ADD_DEPENDENT = "ADD_DEPENDENT"
export const MODAL_DEPENDENT = "MODAL_DEPENDENT"
export const SET_LOADED = "SET_LOADED"
export const SET_LOGGED = "SET_LOGGED"
export const VERIFY_AUTH = "VERIFY_AUTH"
export const EDIT_DEPENDENT = "EDIT_DEPENDENT"
export const EDIT_USER = "EDIT_USER"
export const MODAL_USER = "MODAL_USER"
export const REGISTER_USER = "REGISTER_USER"
export const UPLOAD_EXAMS = "UPLOAD_EXAMS"
export const CALL = "CALL"
export const SET_PLAN = "SET_PLAN"
export const SET_DATA_USER = "SET_DATA_USER"
export const PATIENT_SELECTED = "PATIENT_SELECTED"
export const SEND_LINK_RECOVERY = "SEND_LINK_RECOVERY"
export const RECOVERY_PASSWORD = "RECOVERY_PASSWORD"
export const GET_PLANS = "GET_PLANS"
export const GET_PLANS_BY_CATEGORY = "GET_PLANS_BY_CATEGORY"
export const SET_PLANS = "SET_PLANS"
export const SET_PLANS_CATEGORY = "SET_PLANS_CATEGORY"
export const SET_LABEL_WHITE = "SET_LABEL_WHITE"
export const REGISTER_PAYMENT = "REGISTER_PAYMENT"
export const SET_URL_PAYMENT = "SET_URL_PAYMENT"
export const SET_DISEASE = "SET_DISEASE"
export const SEND_EMAIL = "SEND_EMAIL"
export const RESEND_EMAIL = "RESEND_EMAIL"
export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP"
export const SCHEDULE_SUCESS = "SCHEDULE_SUCESS"
export const SPECIALTY_SUCESS = "SPECIALTY_SUCESS"
export const DOCTOR_SUCESS = "DOCTOR_SUCESS"
export const CONFIRM_SCHEDULING_SUCESS = "CONFIRM_SCHEDULING_SUCESS"
export const FIND_SLOTS = "FIND_SLOTS"
export const FIND_SPECIALTIES = "FIND_SPECIALTIES"
export const FIND_DOCTORS_BY_SPECIALTY_ID = "FIND_DOCTORS_BY_SPECIALTY_ID"
export const CONFIRM_SCHEDULING = "CONFIRM_SCHEDULING"
export const FIND_PATIENT = "FIND_PATIENT"
export const FIND_PATIENT_TRUCLINIC = "FIND_PATIENT_TRUCLINIC"
export const DEPENDENTS_GETALL_REQUEST = "DEPENDENTS_GETALL_REQUEST"
export const BUY_AUTH = "BUY_AUTH"
export const CHECK_TERMS = "CHECK_TERMS"
export const GENERATE_BUY_TOKEN = "GENERATE_BUY_TOKEN"
export const SET_MENU_VISIBLE = "SET_MENU_VISIBLE"
export const CHANGE_PLAN = "CHANGE_PLAN"
export const GET_DISEASE = "GET_DISEASE"
export const SET_LIST_DISEASES = "SET_LIST_DISEASES"
export const SET_VISIBLE_OPTIONS = "SET_VISIBLE_OPTIONS"
export const OPEN_MODAL_REQUEST = "OPEN_MODAL_REQUEST"
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const SEND_CANCEL_REQUEST = "SEND_CANCEL_REQUEST"
export const MODAL_PLAN_B2B = "MODAL_PLAN_B2B"
export const FIND_SLOT_BY_PATIENT = "FIND_SLOT_BY_PATIENT"
export const APPOINTMENTS_BY_PATIENT = "APPOINTMENTS_BY_PATIENT"
export const CANCEL_SCHEDULING = "CANCEL_SCHEDULING"
export const CANCEL_SCHEDULING_TRUCLINIC = "CANCEL_SCHEDULING_TRUCLINIC"
export const CANCEL_SCHEDULING_SUCESS = "CANCEL_SCHEDULING_SUCESS"
export const SCHEDULE_CLEAR = "SCHEDULE_CLEAR"
export const CLEAR_SLOT_BY_PATIENT = "CLEAR_SLOT_BY_PATIENT"
export const GET_HISTORY = "GET_HISTORY"
export const SET_HISTORY_LIST = "SET_HISTORY_LIST"
export const SCHEDULED_SUCCESS = "SCHEDULED_SUCCESS"
export const FIND_PRODUCTS = "FIND_PRODUCTS"
export const PRODUCTS_SUCCESS = "PRODUCTS_SUCCESS"
export const SUCCESS_SCHEDULED_CLEAR = "SUCCESS_SCHEDULED_CLEAR"
export const GET_ASSIGN = "GET_ASSIGN"
export const APPOINTMENT_OLD = "APPOINTMENT_OLD"
export const GET_FLAG_SCHEDULING = "GET_FLAG_SCHEDULING"
export const CHECK_CARD_NUMBER = "CHECK_CARD_NUMBER"
export const CHECK_PHONE_NUMBER = "CHECK_PHONE_NUMBER"
export const CHECK_PIN = "CHECK_PIN"
export const PHONE_NUMBER = "PHONE_NUMBER"
export const MODAL_VERIFICATION = "MODAL_VERIFICATION"
export const MODAL_CARD_NUMBER = "MODAL_CARD_NUMBER"
export const MODAL_PIN = "MODAL_PIN"
export const MODAL_PATIENT = "MODAL_PATIENT"
export const UPLOAD_FILES = "UPLOAD_FILES"
export const SET_FILES = "SET_FILES"
export const UPLOAD_DOCUMENTS = "UPLOAD_DOCUMENTS"
export const NEXT_DOCS_UPLOAD_STEP = "NEXT_DOCS_UPLOAD_STEP"
export const UNDO_DOCS_UPLOAD_STEP = "UNDO_DOCS_UPLOAD_STEP"
export const SET_CURRENT_DOCS_UPLOAD_FILE = "SET_CURRENT_DOCS_UPLOAD_FILE"
export const SET_DOCS_UPLOAD_STEP = "SET_DOCS_UPLOAD_STEP"
export const SET_DOCS_UPLOAD_STEPS = "SET_DOCS_UPLOAD_STEPS"
export const SET_DOCS_UPLOAD_FILES = "SET_DOCS_UPLOAD_FILES"
export const SET_SELECTED_DOC_TYPE = "SET_SELECTED_DOC_TYPE"
export const SKIP_PROFILE_PIC_UPLOAD_STEP = "SKIP_PROFILE_PIC_UPLOAD_STEP"
export const SELECT_DOC_STEP_FILE = "SELECT_DOC_STEP_FILE"
export const IGNORE_UPLOAD_DOCS = "IGNORE_UPLOAD_DOCS"
export const RESET_UPLOAD_DOCS = "RESET_UPLOAD_DOCS"
export const SET_CURRENT_PRIVATE_PLAN = "SET_CURRENT_PRIVATE_PLAN"
export const FETCH_CURRENT_PRIVATE_PLAN = "FETCH_CURRENT_PRIVATE_PLAN"
export const REMOVE_DEPENDENT = "REMOVE_DEPENDENT"
export const SET_MODAL_CONFIRM = "SET_MODAL_CONFIRM"
export const MODAL_CONFIRM_ACCEPTED = "MODAL_CONFIRM_ACCEPTED"
export const MODAL_CONFIRM_DISMISSED = "MODAL_CONFIRM_DISMISSED"
export const SET_SCHEDULE_TYPE = "SET_SCHEDULE_TYPE"
export const SET_DAY_SELECTED = "SET_DAY_SELECTED"
export const GET_TELEPSICO_PROGRAMS = "GET_TELEPSICO_PROGRAMS"
export const TELEPSICO_PROGRAMS_SUCCESS = "TELEPSICO_PROGRAMS_SUCCESS"
export const USER_SET_PROVIDER = "USER_SET_PROVIDER"
export const USER_SET_TELEPSICOLOGIA = "USER_SET_TELEPSICOLOGIA"
export const USER_SET_TELEMEDICINA = "USER_SET_TELEMEDICINA"
export const SET_HOME_PAGE = "SET_HOME_PAGE"
export const SET_SELECTED_PROGRAM = "SET_SELECTED_PROGRAM"
export const GET_AVAILABLE_DATE = "GET_AVAILABLE_DATE"
export const GET_AVAILABLE_DATE_SUCCESS = "GET_AVAILABLE_DATE_SUCCESS"
export const SET_TRIADE = "SET_TRIADE"
export const SET_SELECTED_SLOT = "SET_SELECTED_SLOT"
export const SET_OLD_SLOT = "SET_OLD_SLOT"
export const SCHEDULE_TELEPSICO = "SCHEDULE_TELEPSICO"
export const GET_RESCHEDULE = "GET_RESCHEDULE"
export const GET_AVAILABLE_DATE_DOCTOR = "GET_AVAILABLE_DATE_DOCTOR"
export const VALIDATE_VOUCHER = 'VALIDATE_VOUCHER';
export const SET_VOUCHER = 'SET_VOUCHER';
export const SET_SCHEDULE_MODAL = 'SET_SCHEDULE_MODAL';
export const USER_SET_CORPORATE_CARD = 'USER_SET_CORPORATE_CARD';
export const SET_CORPORATE_CARD_DATA = 'SET_CORPORATE_CARD_DATA';
export const GET_CORPORATE_CARD_REQUEST = 'GET_CORPORATE_CARD_REQUEST';
export const SET_TERMS_PROVIDER = 'SET_TERMS_PROVIDER';
export const SET_TERMS_PROVIDER_STATUS = 'SET_TERMS_PROVIDER_STATUS';
export const SET_TERMS_PROVIDER_STATUS_AND_TIME = 'SET_TERMS_PROVIDER_STATUS_AND_TIME';
export const GET_TERMS_PROVIDER = 'GET_TERMS_PROVIDER';
export const SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS';
export const GET_FEATURE_FLAGS_REQUEST = 'GET_FEATURE_FLAGS_REQUEST';
export const GET_CATALOG_PLANS = 'GET_CATALOG_PLANS';
export const SET_CATALOG_PLANS = 'SET_CATALOG_PLANS'
export const SET_LOADING_SCHEDULE = 'SET_LOADING_SCHEDULE';
export const GET_PATIENT_TERMS = 'GET_PATIENT_TERMS';
export const SET_PATIENT_TERMS = 'SET_PATIENT_TERMS';
export const REGISTER_PATIENT_TERMS = 'REGISTER_PATIENT_TERMS';
export const GET_TELECONSULTATION_TERMS = 'GET_TELECONSULTATION_TERMS';
export const SET_TELECONSULTATION_TERMS = 'SET_TELECONSULTATION_TERMS';
export const REGISTER_TELECONSULTATION_TERMS = 'REGISTER_TELECONSULTATION_TERMS';
export const SET_PATIENT_LEAD_TERMS = 'SET_PATIENT_LEAD_TERMS';
export const GET_PATIENT_LEAD_TERMS = 'GET_PATIENT_LEAD_TERMS';
export const POST_PATIENT_LEAD_TERMS = 'POST_PATIENT_LEAD_TERMS';
export const SET_COMMUNICATION_MODAL_OPEN = 'SET_COMMUNICATION_MODAL_OPEN';
export const SET_PATIENT_LEAD_TERMS_FILTERED = 'SET_PATIENT_LEAD_TERMS_FILTERED';
export const FIND_SPECIALIST_DOCTORS = 'FIND_SPECIALIST_DOCTORS';
export const SET_SPECIALIST = 'SET_SPECIALIST';
export const FIND_SPECIALTY = 'FIND_SPECIALTY';
export const SET_SPECIALTY = 'SET_SPECIALTY';
export const USER_SET_CORPORATE = 'USER_SET_CORPORATE';