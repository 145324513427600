import styled, { css } from "styled-components";

const active = css`
  background-color: #2196f3;
  color: #fff;
`;

export const Card = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Infos = styled.div`
  width: 333px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  @media (max-width: 1024px) {
    width:100%;
  }
`;

export const Text = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 13px 0px 13px 0px;
  align-items: center;
  flex-direction: column;
  border-bottom: ${(props) => props.hidden ? "0px" : "0.5px solid #F0F0F0"};
`;

export const Title = styled.div`
  display: flex;
  width: 100%;
  font-size: 12px;
  color: #707070;
`;
export const SubTitle = styled.div`
  display: flex;
  width: 100%;
  font-size: 14px;
  color: #000;
  font-weight: bold;
`;

export const Icon = styled.div`
  font-size: 47px;
  font-weight: bold;
  margin-bottom: 20px;
`;

export const DivButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin-top: 40px;
  @media (max-width: 1024px) {
    width: 100%;
    position: fixed;
    height: 10%;
    bottom: 0;
  }
`;
export const Button = styled.button`
  width: 221px;
  height: 47px;
  border-radius: 36px;
  margin: 0 5px;

  ${(props) => props.blue && blue}
  ${(props) => props.gray && gray}

  @media (max-width: 1024px) {
    margin: 10px;
    width: 60%;
    height: 30px;
  }
`;

const blue = css`
  background-color: #2196f3;
  color: #ffffff;
`;

const gray = css`
  border: 1px solid #2196f3;
  color: #2196f3;
`;

export const DivTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  margin-left: 5%;
  @media (max-width: 1024px) {
    margin-top: 95px;
  }
`;
export const Form = styled.div`
  display: flex;
  width: 333px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;

  @media(max-width: 1024px) {
    width: 100%;

    justify-content: center;
  }
`;

export const TitleSchedule = styled.div`
  width: 250px;
  font-weight: bold;
  font-size: ${(props) => (props.title ? "16px" : "14px")};
  margin: 0 0 5px;
`;
