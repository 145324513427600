import styled from "styled-components";

import { motion } from "framer-motion";
import animations from "../../utils/animation";

export const Container = styled(motion.div).attrs(animations)`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;

  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

export const ImageForm = styled.div`
  background-image: url("${(props) => props.src}");
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  width: 85%;
  height: 100%;

  display: flex;
  justify-content: center;

  h1 {
    font-size: 30px;
    padding-top: 30px;
    margin-left: 120px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Providers = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
`;

export const ItemProvider = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    color: #707979;
    margin: 5px 0;
    font-weight: bold;
  }
`;

export const ImageProvider = styled.div`
  box-shadow: ${(props) => props.active && "0px 0px 2px 1px #4eacd4;"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 100px;
    height: 100px;
  }
`;

export const DivButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 150px;
  height: 30px;
  border-radius: 15px;
  margin-top: 8px 0 6px;
  line-height: 25px;
  font-size: 14px;
  color: #fff;
  background-color: #2196f3;
  cursor: pointer;
  margin: 20px 0;

  &:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
`;

export const NoEligibilityButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 40px;
  color: #fff;
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0px;
  border-radius: 8px;
  background: #1c7cf9;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
`;

export const Main = styled.form`
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 300;
  background: #fcfcfc;

  padding-top: 102px;

  @media (min-width: 1024px) {
    max-width: 375px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    background-color: #fff;
    padding-top: 24px;
  }
`;

export const DivLogo = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;
export const DivBody = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  padding: 0 20px;
  max-height: 100vh;
`;

export const Logo = styled.img`
  max-width: 40px;
  margin-left: 20px;
`;

export const Labels = styled.div`
  width: 100%;
  padding: 5px;
  /* padding-top: 31px; */

  h2 {
    font-weight: bold;
  }

  h3 {
    color: #000;
    font-family: "Work Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }

  p {
    color: #707070;
    margin: 27px 0;
    font-size: 16px;
    color: #484b52;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
`;

export const ButtonBack = styled.button`
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  padding: 24px 20px 20px 42px;
  background: transparent;
  height: 56px;
`;

export const LabelBack = styled.p`
  margin-left: 16px;
  color: #707070;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

export const LabelItem = styled.p`
  margin-left: 10px;
`;

export const IconBack = styled.svg`
  width: 16px;
`;

export const ProviderDescriptionContainer = styled.div`
  text-align: center;
  height: 3.8em;

  p {
    font-weight: bold;
  }
`;

export const Divider = styled.hr`
  width: 327px;
  height: 1px;
  background: #dce0e8;
  margin: 27px 0;
`;

export const HelpSection = styled.div`
  width: 100%;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  .card {
    &-title {
      margin: 0 0 20px 0;
      color: #484b52;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
    &-holder {
      border-radius: 8px;
      background: #f8fafa;
      display: flex;
      padding: 16px;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      height: 73px;
      margin-bottom: 11px;

      &-text {
        text-align: left;
        display: flex;
        flex-direction: column;
        b {
          margin-bottom: 4px;
        }
        p {
          color: #848994;
          font-family: Inter;
          font-size: 14px;
        }
      }
    }
  }
`;

export const Spacer = styled.div`
  display: block;
  height: 31px;
`;
