import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 600px;
  height: 100%;
  min-height: 300px;
  max-height: 500px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;

  @media (max-width: 1024px) {
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 25px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  align-items: none;
  -webkit-align-items: none;

  @media (max-width: 1024px) {
  }
`;

export const ButtonClose = styled.button`
  cursor: pointer;
  float: right;
  background: transparent;
`;

export const SvgCloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-x"
  >
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </svg>
);

export const ButtonCloseContainer = styled.div`
  text-align: right;
`;

export const ContentCentralize = styled.div`
  flex: 1; 
  display: flex; 
  flex-direction: row; 
  align-items: center; 
  align-content: center;
`;

export const ContentParagraph = styled(ContentCentralize)`
  flex-direction: column; 

  h3 {
    font-size: 19px;
    max-width: 300px;
    line-height: 1.5rem;
  }

  p {
    font-size: 14px;
    max-width: 450px;
    line-height: 1.2rem;
  }

  a {
    color: #004f92;
  }

`;

export const Image = styled.img`
  margin: 20px;
  width: 170px;
`;

// note: 'quebrar em duas linhas com diminuir a resolucao'
export const ButtonsGrpup = styled.div`
  flex: block; 
  display: flex; 
  flex-direction: row; 
  gap: 10px;

  button {
    font-size: 14px;
  }

  @media (max-width: 1024px) {
    flex-direction: column; 
    gap: 2px;
  }
`;
