import styled from "styled-components";

const Container = styled.div`
    padding-right: 32px;
    padding-left: 32px;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: 768px) {
    width: 750px;
    }
    @media (min-width: 992px) {
    width: 970px;
    }

    @media (min-width: 1200px) {
    width: 1170px;
    }
`;


export const HeaderSite = styled.header`
  position: fixed;
  width: 100%;
  background-color: white;
  z-index: 10;
  top: 0;
  left: 0;
`;

export const HeaderContainer = styled(Container)`
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 63px;
  .label {
    font-weight: 600;
    color: #004f92;
    font-size: 17px;
    border-left: solid 1px #004f9269;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 1rem;
    margin-left: 1rem;
  }
  svg {
    height: 23px;
  }

  .logo-header {
    max-height: 35px;
  }

  @media (min-width: 768px) {
    justify-content: flex-start;
    width: 100%;
  }
`;

export const GradientLine = styled.div`
  background-image: linear-gradient(
    to right,
    #ecb561 0%,
    #de656b 30%,
    #4595c4 60%,
    #3ad5ca 100%
  );
  height: 8px;
`;

export const ImgLogo = styled.img`
    cursor: pointer;
`;