import { array } from "yup";
import {
  SCHEDULE_SUCESS,
  SPECIALTY_SUCESS,
  DOCTOR_SUCESS,
  CONFIRM_SCHEDULING_SUCESS,
  APPOINTMENTS_BY_PATIENT,
  CANCEL_SCHEDULING_SUCESS,
  SCHEDULE_CLEAR,
  CLEAR_SLOT_BY_PATIENT,
  SCHEDULED_SUCCESS,
  PRODUCTS_SUCCESS,
  SUCCESS_SCHEDULED_CLEAR,
  APPOINTMENT_OLD,
  SET_SCHEDULE_TYPE,
  SET_DAY_SELECTED,
  SET_SELECTED_SLOT,
  SCHEDULE_TELEPSICO,
  SET_OLD_SLOT,
  GET_RESCHEDULE,
  SET_SCHEDULE_MODAL,
  SET_LOADING_SCHEDULE,
  SCHEDULE_NOT_FOUND_SLOT,
  SCHEDULE_SUCCESS_SLOT
} from "../actions/action-types";

const initialState = {
  scheduleResult: [],
  specialty: [],
  doctors: [],
  slotStateId: null,
  scheduled: [],
  successScheduled: false,
  product: null,
  appointmentOld: [],
  scheduleType: null,
  daySelected: null,
  selectedSlot: false,
  oldSlot: false,
  openScheduleModal: false,
  loadingSchedule: false,
  notFoundSlots: false,
  successSlots: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SCHEDULE_SUCESS:
      return {
        ...state,
        scheduleResult: action.payload,
      };
    case SCHEDULE_NOT_FOUND_SLOT:
      return {
        ...state,
        notFoundSlots: action.payload,
      };
    case SCHEDULE_SUCCESS_SLOT:
      return {
        ...state,
        successSlots: action.payload,
      };
    case SCHEDULE_CLEAR:
      return {
        ...state,
        scheduleResult: [],
      };
    case APPOINTMENTS_BY_PATIENT:
      return {
        ...state,
        scheduled: action.payload,
      };
    case CLEAR_SLOT_BY_PATIENT:
      return {
        ...state,
        scheduled: [],
      };
    case SPECIALTY_SUCESS:
      return {
        ...state,
        specialty: action.payload,
      };
    case DOCTOR_SUCESS:
      return {
        ...state,
        doctors: action.payload,
      };
    case CONFIRM_SCHEDULING_SUCESS:
      return {
        ...state,
        slotStateId: action.payload,
      };
    case CANCEL_SCHEDULING_SUCESS:
      return {
        ...state,
        slotStateId: action.payload,
      };
    case SCHEDULED_SUCCESS:
      return {
        ...state,
        successScheduled: action.payload,
      };
    case PRODUCTS_SUCCESS:
      return {
        ...state,
        product: action.payload,
      };
    case SUCCESS_SCHEDULED_CLEAR:
      return {
        ...state,
        successScheduled: null,
      };
    case APPOINTMENT_OLD:
      return {
        ...state,
        appointmentOld: action.payload,
      };
    case SET_SCHEDULE_TYPE:
      return {
        ...state,
        scheduleType: action.payload,
      };
    case SET_DAY_SELECTED:
      return {
        ...state,
        daySelected: action.payload,
      };
    case SET_SELECTED_SLOT:
      return {
        ...state,
        selectedSlot: action.payload,
      };
    case SET_OLD_SLOT:
      return {
        ...state,
        oldSlot: action.payload,
      };
    case SCHEDULE_TELEPSICO:
      return state;
    case GET_RESCHEDULE:
      return state;
    case SET_SCHEDULE_MODAL:
      return {
        ...state,
        openScheduleModal: action.payload,
      };
    case SET_LOADING_SCHEDULE:
      return {
        ...state,
        loadingSchedule: action.payload,
      };
    default:
      return state;
  }
};
