import * as yup from "yup"
import moment from "moment"
import { cpf as cpfTest } from "cpf-cnpj-validator"

export const brasileiroSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("Nome é obrigatório")
    .min(3, "Nome mínimo 3 caracteres"),
  lastName: yup
    .string()
    .trim()
    .required("Sobrenome é obrigatório")
    .min(3, "Sobrenome mínimo 3 caracteres"),
  birthDate: yup
    .string()
    .trim()
    .required("Data de nascimento é obrigatória")
    .test("date-test", "Data inválida", function (value) {
      if (value) {
        let date = value.replace(/_/g, "")
        if (date.length < 10) {
          return false
        }
        date = moment(value, "DD/MM/YYYY")
        if (!date.isValid()) {
          return false
        }

        function calcAge(birth) {
          const auxBirth = new Date(birth)
          const diference = Date.now() - auxBirth.getTime()
          const age = new Date(diference)

          return age.getUTCFullYear() - 1970
        }

        const age = calcAge(date)

        if (age < 0) {
          return false
        }

        return date
      }
    }),
  sex: yup.string().trim().required("Sexo é obrigatório"),
  document: yup
    .string()
    .trim()
    .required("CPF é obrigatório")
    .test("cpf-test", "CPF Inválido", function (value) {
      if (this.parent.documentType === "PASSPORTE") {
        return true
      }

      if (value) {
        const cpf = value.replace(/[^0-9]+/g, "")
        if (!cpfTest.isValid(cpf)) {
          return false
        } else {
          return true
        }
      }
    }),
  email: yup
    .string()
    .trim()
    .required("E-mail é obrigatório")
    .test("email-test", "E-mail inválido", function (value) {
      const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

      if (!emailRegexp.test(value)) {
        return false
      } else {
        return true
      }
    }),
  phone: yup
    .string()
    .trim()
    .required("Telefone é obrigatório")
    .test("phone-test", "Telefone inválido", function (value) {
      if (value) {
        let phone = value.replace(/_/g, "")
        if (phone.length < 10) {
          return false
        } else {
          return true
        }
      }
    }),
})



// ESTRANGEIRO



export const estrangeiroSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("Nome é obrigatório")
    .min(3, "Nome mínimo 3 caracteres"),
  lastName: yup
    .string()
    .trim()
    .required("Sobrenome é obrigatório")
    .min(3, "Sobrenome mínimo 3 caracteres"),
  birthDate: yup
    .string()
    .trim()
    .required("Data de nascimento é obrigatória")
    .test("date-test", "Data inválida", function (value) {
      if (value) {
        let date = value.replace(/_/g, "")
        if (date.length < 10) {
          return false
        }
        date = moment(value, "DD/MM/YYYY")
        if (!date.isValid()) {
          return false
        }

        function calcAge(birth) {
          const auxBirth = new Date(birth)
          const diference = Date.now() - auxBirth.getTime()
          const age = new Date(diference)

          return age.getUTCFullYear() - 1970
        }

        const age = calcAge(date)

        if (age < 0 || age > 120) {
          return false
        }

        return date
      }
    }),
  sex: yup.string().trim().required("Sexo é obrigatório"),
  document: yup
    .string()
    .required("Documento é obrigatório"),
  email: yup
    .string()
    .trim()
    .required("E-mail é obrigatório")
    .test("email-test", "E-mail inválido", function (value) {
      const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

      if (!emailRegexp.test(value)) {
        return false
      } else {
        return true
      }
    }),
  phone: yup
    .string()
    .trim()
    .required("Telefone é obrigatório")
    .test("phone-test", "Telefone inválido", function (value) {
      if (value) {
        let phone = value.replace(/_/g, "")
        if (phone.length < 10) {
          return false
        } else {
          return true
        }
      }
    }),
})

