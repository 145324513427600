import { createAction } from "@reduxjs/toolkit";

const prefix = "conecta:home-microfrontend/home";

export const setDependents = createAction(`${prefix}/setDependents`);

export const setNavigation = createAction(`${prefix}/setNavigation`);

export const setPatient = createAction(`${prefix}/setPatient`);

export const setModal = createAction(`${prefix}/setModal`);

export const getHomeStore = createAction(`${prefix}/getHomeStateStore`);
