import styled, { css } from "styled-components";

export const Container = styled.div``;

export const Body = styled.div`
  width: 100%;
  min-width: 1120px;
  z-index: 5;

  @media (max-width: 1024px) {
    min-width: 320px;
  }
`;
