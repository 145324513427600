import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import DayPicker from "react-day-picker/DayPicker";

import "./styles.css";

import MomentLocaleUtils from "react-day-picker/moment";

import "moment/locale/pt-br";
import { format, parseISO } from "date-fns";
import {
  setDaySelected,
  slotByMonth,
} from "../../redux/actions/schedule.actions";
import { getAvailableDates } from "../../redux/actions/telepsico.actions";

import { ScheduleDay } from "./styles";
import { DateUtils } from "react-day-picker";

function Calendar() {
  const dispatch = useDispatch();

  const day = useSelector((state) => state.schedule.daySelected);
  const days = useSelector((state) => state.telepsico.dateRange);
  const triade = useSelector((state) => state.telepsico.triade)

  function handleClickDay(dayselected, { disabled }) {
    if (!disabled) {
      dispatch(setDaySelected(dayselected));
      //if triade return slots by doctor
      if(triade){
        dispatch(slotByMonth({ date: dayselected, doctorId: triade.registroProfissional }))
      }else{
        dispatch(slotByMonth({ date: dayselected }));
      }
    }
  }

  function isDayDisabled(day) {
    return !(days || []).some((obj) =>
      DateUtils.isSameDay(day, parseISO(obj.date))
    );
  }

  useEffect(() => {
    // if(triade){
    //   dispatch(getAvailableDatesByDoctor());
    // }else{
    //   dispatch(getAvailableDates());
    // }
  }, []);

  return (
    <ScheduleDay>
      <DayPicker
        localeUtils={MomentLocaleUtils}
        locale={"pt-br"}
        onDayClick={handleClickDay}
        disabledDays={isDayDisabled}
        selectedDays={day}
      />
    </ScheduleDay>
  );
}
export default Calendar;
