import styled from "styled-components";

import { motion } from "framer-motion";
import animations from "../../../utils/animation";

export const Topics = styled.div`
  background: #fafafa;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 0 50px;
  padding: 100px 0 0;
`;

export const LabelTopics = styled.h1`
  margin: 20px 0;
`;

export const TopicsDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1024px) {
    margin: 0 15px;
  }
`;

export const TopicItem = styled.div`
  width: 1200px;
  margin: 10px 0;
  padding: 40px 0;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;

  &:hover {
    opacity: 0.7;
    transition: 0.3s;
    cursor: pointer;
  }

  @media (max-width: 1024px) {
    width: 100%;
    padding: 40px 5px;
  }
`;

export const TopicHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

export const TopicBody = styled(motion.div).attrs(animations)`
  margin: 20px 0;
`;

export const TopicText = styled.h4``;

export const TopicIcon = styled.img`
  width: 20px;
  height: 20px;
  margin: 0 10px;
`;

export const MoreTopics = styled.p`
  cursor: pointer;
  margin: 50px 0 0;
  font-weight: bold;
  font-size: 18px
`;