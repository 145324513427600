import {
  SET_PLAN,
  SET_DATA_USER,
  SET_PLANS,
  SET_PLANS_CATEGORY,
  SET_URL_PAYMENT,
  SET_VOUCHER,
} from '../actions/action-types';

const initialState = {
  currentPlan: {},
  dataUser: {},
  plans: null,
  plansCategory: null,
  paymentUrl: null,
  voucherValues: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_URL_PAYMENT:
      return {
        ...state,
        paymentUrl: action.payload,
      };
    case SET_PLAN:
      return {
        ...state,
        currentPlan: action.payload,
      };
    case SET_DATA_USER:
      return {
        ...state,
        dataUser: action.payload,
      };
    case SET_PLANS:
      return {
        ...state,
        plans: action.payload,
      };
    case SET_PLANS_CATEGORY:
      return {
        ...state,
        plansCategory: action.payload,
      };
    case SET_VOUCHER:
      return {
        ...state,
        voucherValues: action.payload,
      };
    default:
      return state;
  }
};
