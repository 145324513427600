import styled from "styled-components";
import Mask from "react-input-mask";

export const Container = styled.div`
  width: 700px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const Titles = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: 1024px) {
    width: 80%;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
  }
`;

export const Title = styled.h2`
  margin-bottom: 5px;
  font-size: 18px;
`;

export const SubTitle = styled.p`
  color: #707070;
`;

export const Form = styled.form``;

export const Inputs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 25px 0;

  @media (max-width: 1024px) {
    margin: 10px 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

export const Select = styled.select`
  border: 0;
  border-bottom: 1px solid #000;
  background-color: transparent;
  width: 100%;
  padding: 7px 0;
`;

export const DivButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;

  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

export const Button = styled.button`
  width: 150px;
  height: 45px;
  background-color: #e0832c;
  color: white;
  border-radius: 25px;
  font-weight: bolder;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 5px;
`;

export const CheckResponsible = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 1024px) {
    width: 80%;
  }
`;

export const Label = styled.label`
  margin-top: 8px;

  input {
    margin-right: 10px;
  }
`;

export const DivInput = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: ${(props) => (props.width ? props.width : "285px")};
  height: 60px;

  label {
    width: 100%;
    text-align: left;
    font-size: 10px;
    height: 12px;
  }

  @media (max-width: 1024px) {
    width: 350px;
  }

  @media (max-width: 425px) {
    width: 270px;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  width: 100%;
  padding: 5px 0;
  font-size: 10px;
`;
