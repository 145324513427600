import React, { useState, useEffect } from "react";
import "moment/locale/pt-br";
import "semantic-ui-react";
import "./styles.css";
import { useSelector, useDispatch } from "react-redux";
import Container from "../../container";
import Telemedicina from "./telemedicina/index";
import TelePsicologia from "./telepsicologia/";
import { setDaySelected } from "../../redux/actions/schedule.actions";
import { push } from "../../services/navigate";

function Main() {
  const dispatch = useDispatch();
  const scheduleType = useSelector((state) => state.schedule.scheduleType);

  if(!scheduleType){
    push('/consultas-agendadas')
  }

  useEffect(() => {
    dispatch(setDaySelected(null));
  }, []);

  return (
    <Container>
      {scheduleType === "TELEMEDICINA" ? <Telemedicina /> : <TelePsicologia />}
    </Container>
  );
}
export default Main;
