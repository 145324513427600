import styled from "styled-components";

export const Container = styled.div``;

export const Main = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin: 70px 0 0 70px;

  @media (max-width: 1024px) {
    align-items: center;
    margin: 70px 0 0;
  }
`;

export const ContactsMain = styled.div`
  display: flex;
  width: 95%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 1024px) {
    align-items: center;
    margin: 70px 0 0;
  }
`;

export const Contacts = styled.div`
  display: block;
  align-items: center;
  justify-content: left;
  flex-direction: row;

  font-size: 14px;
  color: #707070;
  margin-bottom: 12px;

  @media (max-width: 1024px) {
    margin-bottom: 12px;
  }
`;

export const Title = styled.h4`
  margin-bottom: 5px;
`;

export const ContainerSpecialist = styled.div`
  margin-bottom: 30px;

  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

export const DivTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleSchedule = styled.h4`
  width: 250px;
  font-weight: bold;
  font-size: 16px;
  margin: 0 0 5px;
`;

export const SubTitleSchedule = styled.p`
  display: flex;
  font-size: 14px;
  color: #909090;
`;

export const Table = styled.table`
  width: 92%;
  margin-top: 20px;
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

  th,
  td {
    padding: 10px 20px;
    border: 1px solid #000;
    flex-grow: 1;
    vertical-align: middle; /* Adicionado para alinhamento vertical */
  }

  thead tr {
    background-color: #015a9c;
    color: #ffffff;
    text-align: left;
  }

  tbody tr {
    border-bottom: 1px solid #ffffff;
  }

  tbody tr:nth-of-type(even) {
    background-color: #dddd;
  }

  tbody tr:last-of-type {
    border-bottom: 2px solid #006098;
  }

  .clickable {
    cursor: pointer;
  }
  
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const TableWrapper = styled.div`
  @media (max-width: 1024px) {
    max-width: 70%;
    overflow-x: scroll;
  }
`;

export const Pagination = styled.div`
  display: flex;
  margin: 5px;
  justify-content: left;
`;

export const DivSelect = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #bababa;
  border-radius: 5px;
  background: #fff;
  margin: 10px 0 0;
  height: 30px;
  width: 350px;
  position: relative;
  cursor: pointer;

  select {
    cursor: pointer;
    width: 100%;
    z-index: 100;
    -webkit-appearance: none;
  }

  img {
    cursor: pointer;
    height: 10px;
    position: absolute;
    right: 10px;
  }

  @media (max-width: 1024px) {
    width: 250px;

    select {
      width: 200px;
    }
  }
`;

export const ContainerModal = styled.div`
  width: 600px;
  max-height: auto;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;

  padding: 20px 10px;
  @media (max-width: 1024px) {
    margin-top: 30px;
    height: auto;
    width: 90vw;
  }
`;

export const ButtonBack = styled.button`
  cursor: pointer;
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background: transparent;
  margin-bottom: 20px;
`;

export const LabelBack = styled.p`
  margin-left: 10px;
  color: #707070;
`;

export const IconBack = styled.img`
  width: 20px;
  height: 20px;
`;

export const TitleContact = styled.div`
  display: flex;
  font-size: 14px;
  color: #707070;
  margin-top: 5px;
  text-align: left;
  padding: 10px;
  vertical-align: middle;
`;

export const Strong = styled.strong`
  font-weight: bold;
  margin-right: 5px;
`;
