const uploadDocumentsLocalStorage = async (file, docs) => {
  if (docs !== null && docs !== undefined) {
    const result = docs.filter((doc) => doc.documentType !== file.documentType);
    let arrayFile = [];
    result.map(docs => arrayFile.push(docs))
    arrayFile.push(file);
    localStorage.setItem("conecta:docs", JSON.stringify(arrayFile));
  } else {
    localStorage.setItem("conecta:docs", JSON.stringify([file]));
  }
};

export default uploadDocumentsLocalStorage;
