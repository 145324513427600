import React, { useState } from 'react';
import { LabelFloat, ErrorMessage, DivInput, Input, Label, ShowPassword } from './styles';

import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

export default function InputCP({
  onChange = () => {},
  onPaste = () => {},
  onBlur = () => {},
  onClick = () => {},
  onSelect = () => {},
  name = '',
  placeholder = '',
  type = 'Text',
  errorMessage,
  labelMessage,
  width = '330px',
  mask = '',
  readOnly = false,
  readOnlyColor = false,
  value = '',
  visible = true,
  margin = 0,
  height = 0,
  passwordShow = false,
  maxLength = false,
  maskChar = "_",
  className,
  isCreditCardNumber = false,
  isCreditCardDate = false,
}) {
  const [field, setField] = useState(false);
  function changeField() {
    setField(!field);
  }
  return !visible ? null : (
    <DivInput height={height} margin={margin}>
      <LabelFloat>
        <Input
          className={className}
          maxLength={maxLength}
          onClick={onClick}
          width={width}
          onSelect={onSelect}
          onBlur={onBlur}
          onChange={onChange}
          name={name}
          type={!field ? type : 'text'}
          placeholder=" "
          mask={mask}
          readOnly={readOnly}
          readOnlyColor={readOnlyColor}
          value={value}
          onPaste={onPaste}
          maskChar={maskChar}
          isCreditCardNumber={isCreditCardNumber}
          isCreditCardDate={isCreditCardDate}
        />
        <Label>{placeholder}</Label>
      </LabelFloat>
      {passwordShow && (
        <ShowPassword onClick={changeField}>
          {field ? <AiFillEye size="15px" /> : <AiFillEyeInvisible size="15px" />}
        </ShowPassword>
      )}

      {errorMessage && <ErrorMessage width={width}>{errorMessage}</ErrorMessage>}
      {labelMessage && <ErrorMessage label>{labelMessage}</ErrorMessage>}
    </DivInput>
  );
}
