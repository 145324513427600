import styled from "styled-components";

export const ContainerCard = styled.div`
  border: 1px solid #d0dade;
  border-radius: 16px;
  padding: 24px 24px 4px;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`;

export const CardTitle = styled.h2`
  font-size: 16px;
  margin-bottom: 8px;
`;

export const CardDescription = styled.p`
  font-size: 16px;
  margin-bottom: 24px;
`;

export const CardLink = styled.div`
  display: flex;
  justify-content: flex-end;

  & > a, button {
    color: #1774de;
    font-weight: 600;
    font-size: 16px;
  }
`;
