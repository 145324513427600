import React from "react";
import { ButtonFill, ButtonOutline } from "./styles";

const Button = (props) => {
  const {
    disabled,
    children,
    loading,
    onClick,
    variation = "fill",
    ...rest
  } = props;

  const ButtonComponent = variation === "fill" ? ButtonFill : ButtonOutline;

  return (
    <ButtonComponent
      disabled={disabled}
      loading={loading}
      onClick={onClick}
      {...rest}
    >
      {children}
    </ButtonComponent>
  );
};

export default Button;
