import styled from "styled-components";
import Mask from "react-input-mask";

import { motion } from "framer-motion";
import animations from "../../utils/animation";

export const Container = styled(motion.div).attrs(animations)`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  flex-direction: row;
  background-color: #f1f1f1;
  overflow: auto;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 0;
  }
`;

export const DivLogo = styled.div`
  cursor: pointer;
  position: absolute;
  top: 30px;
  left: 30px;

  @media (max-width: 1180px) {
    display: none;
  }
`;

export const DivLogoWhite = styled.div`
  display: none;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 30px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0)
  );

  @media (max-width: 1180px) {
    display: flex;
  }
`;

export const Logo = styled.img`
  max-width: 40px;
`;

export const Main = styled.form`
  min-width: 600px;
  width: 45%;
  height: 85%;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1024px) {
    min-width: 100%;
    width: 100%;
    margin: 20px 0 0;
  }
`;

export const Titles = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: 1024px) {
    align-items: center;
  }
`;

export const Title = styled.h2`
  margin-bottom: 5px;
  font-size: 18px;
`;

export const SubTitle = styled.p`
  color: #707070;
`;

export const Inputs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 620px;
  margin: 25px 0 75px;

  @media (max-width: 1024px) {
    justify-content: center;
    min-width: 100%;
    width: 100%;
    margin: 0;
  }
`;

export const DivInput = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: ${(props) => (props.width ? props.width : "285px")};
  height: 60px;

  label {
    width: 100%;
    text-align: left;
    font-size: 10px;
    height: 12px;
  }

  @media (max-width: 1024px) {
    width: 350px;
  }

  @media (max-width: 425px) {
    width: 270px;
  }
`;

export const Select = styled.select`
  border: 0;
  border-bottom: 1px solid #000;
  background-color: transparent;
  width: 100%;
  padding: 7px 0;
`;

export const AlertMessage = styled.p`
  width: 100%;
  padding: 5px 0;
  font-size: 10px;
`;

export const ErrorMessage = styled.p`
  color: red;
  width: 100%;
  padding: 5px 0;
  font-size: 10px;
`;

export const Button = styled.button`
  width: 150px;
  height: 45px;
  min-height: 45px;
  background-color: #e0832c;
  color: white;
  border-radius: 25px;
  font-weight: bolder;
  cursor: pointer;
  margin: 30px 0 50px;
`;

export const DivTerms = styled.div`
  width: 80%;
`;

export const Terms = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;

  p {
    margin-left: 5px;
    font-size: 14px;
    color: #707070;
  }

  input[type="checkbox"] {
    border: 1px solid black;
  }
`;
