import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Main,
  DivLogo,
  Logo,
  ImageForm,
  Providers,
  ItemProvider,
  Labels,
  Button,
  DivBody,
  DivButton,
  ButtonBack,
  IconBack,
  LabelBack,
  ImageProvider,
  ProviderDescriptionContainer,
  Divider,
  HelpSection,
  NoEligibilityButton,
  Spacer,
} from "./styles";

import { selectProvider } from "../../redux/actions/user.actions";
import { consultHealthPlan } from "../../redux/sagas/provider";
import { push } from "../../services/navigate";
import {
  generateBuyToken,
  setLoading,
  setMenuVisible,
} from "../../redux/actions/utils.actions";
import LinesEllipsis from "react-lines-ellipsis";
import { clearCredentials } from "../../utils/credentials";
import { LOGIN_ROUTE } from "../../routes/routes.contants";

export default function App() {
  const user = useSelector((state) => state.userReducer.user);

  const [activeProvider, setActiveProvider] = useState(false);
  const [particularAgreements, setParticularAgreements] = useState(false);

  const providersExt = user.empresasExterno;
  const providersInt = user.empresas;

  const [formattedCompanies, setFormattedCompanies] = useState([]);
  const [formattedCompaniesExternal, setFormattedCompaniesExternal] = useState(
    []
  );

  const dispatch = useDispatch();

  const consultImagesCompanies = React.useCallback(async () => {
    dispatch(setLoading(true));
    const { companies, companiesExterno } = await consultHealthPlan(user);
    setFormattedCompaniesExternal(companiesExterno);
    setFormattedCompanies(companies);
    if (companies) {
      companies.map((prov) => {
        if (
          prov.id_convenio === process.env.REACT_APP_ID_AGREEMENT_PARTICULAR
        ) {
          setParticularAgreements(true);
        }
      });
    }
    dispatch(setMenuVisible(false));
    dispatch(setLoading(false));
  }, [dispatch, particularAgreements, user]);

  useEffect(() => {
    consultImagesCompanies();
  }, [consultImagesCompanies]);

  function setProvider(provider, key) {
    setActiveProvider({ ...provider, key });
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!activeProvider || !activeProvider.key) return;
    if (activeProvider.key === 301) {
      return dispatch(generateBuyToken());
    }
    localStorage.setItem(
      "@conecta:selected-provider",
      JSON.stringify(activeProvider)
    );

    dispatch(selectProvider(activeProvider));

    window.location.href = "/home";
  }

  function handleNoElegibility() {
    localStorage.setItem("@conecta:topbar-redirect-scroll", "#plans"),
      push("/");
  }

  return (
    <Container>
      <Main>
        <DivLogo onClick={() => push("/")}>
          <Logo src="img/logo-blue.png" />
        </DivLogo>

        <ButtonBack
          onClick={() => {
            clearCredentials();
            window.location.href = LOGIN_ROUTE;
          }}
        >
          <IconBack
            svg
            width="12"
            height="20"
            viewBox="0 0 12 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5 1L1.5 10.002L10.495 19"
              stroke="#1774DE
"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </IconBack>
          <LabelBack>Voltar</LabelBack>
        </ButtonBack>

        {user && user.checkElegibility && (
          <>
            <DivBody>
              <Labels>
                <h3>Escolha seu provedor</h3>
                <p>
                  Identificamos a sua elegibilidade. Selecione o provedor para
                  prosseguir com o atendimento:
                </p>
              </Labels>

              <Providers>
                {(formattedCompanies || []).map((providerInt, key) => (
                  <ItemProvider
                    key={key}
                    onClick={() => setProvider(providerInt, key + 112)}
                  >
                    <ImageProvider active={activeProvider.key === key + 112}>
                      <img
                        src={providerInt.imagem || "img/default_image.png"}
                        onError={(e) =>
                          (e.target.src = "img/default_image.png")
                        }
                      />
                    </ImageProvider>
                    <ProviderDescriptionContainer>
                      <LinesEllipsis
                        text={providerInt.descricao}
                        basedOn="letters"
                        maxLine="2"
                        ellipsis="..."
                      ></LinesEllipsis>
                    </ProviderDescriptionContainer>
                  </ItemProvider>
                ))}
              </Providers>

              {formattedCompaniesExternal &&
                formattedCompaniesExternal.length > 0 && (
                  <>
                    <Labels>
                      <p>
                        Você também pode utilizar ou contratar um dos provedores
                        abaixo:
                      </p>
                    </Labels>
                    <Providers>
                      {(formattedCompaniesExternal || []).map(
                        (provider, key) => (
                          <ItemProvider
                            key={key}
                            onClick={() => setProvider(provider, key + 1)}
                          >
                            <ImageProvider
                              active={activeProvider.key === key + 1}
                            >
                              <img
                                src={provider.imagem || "img/default_image.png"}
                                onError={(e) =>
                                  (e.target.src = "img/default_image.png")
                                }
                              />
                            </ImageProvider>

                            <ProviderDescriptionContainer>
                              <LinesEllipsis
                                text={provider.descricao}
                                basedOn="letters"
                                maxLine="2"
                                ellipsis="..."
                              ></LinesEllipsis>
                            </ProviderDescriptionContainer>
                          </ItemProvider>
                        )
                      )}
                    </Providers>
                  </>
                )}
              {!particularAgreements && (
                <>
                  <Labels>
                    <p>
                      Você ainda pode contratar um plano particular para seguir
                      com o atendimento:
                    </p>
                  </Labels>
                  <Providers>
                    <ItemProvider onClick={() => setProvider({}, 301)}>
                      <ImageProvider active={activeProvider.key === 301}>
                        <img src={"img/einstein.jpeg"} />
                      </ImageProvider>
                      <ProviderDescriptionContainer>
                        <LinesEllipsis
                          text="Atendimento particular"
                          basedOn="letters"
                          maxLine="2"
                          ellipsis="..."
                        ></LinesEllipsis>
                      </ProviderDescriptionContainer>
                    </ItemProvider>
                  </Providers>
                </>
              )}
            </DivBody>
            <DivButton>
              <Button
                onClick={handleSubmit}
                active={activeProvider && activeProvider.key}
              >
                Continuar
              </Button>
            </DivButton>
          </>
        )}
        {user && !user.checkElegibility && (
          <DivBody>
            <Spacer />
            <Labels>
              <h3>Seja atendido agora por um médico da Telemedicina</h3>
              <p>Conheça nossos modelos de contratação</p>
            </Labels>
            <DivButton>
              <NoEligibilityButton onClick={() => handleNoElegibility()}>
                Continuar
              </NoEligibilityButton>
            </DivButton>
            <Divider />
            <HelpSection>
              <p className="card-title">Precisa de ajuda?</p>
              <div
                className="card-holder"
                onClick={() => (window.location.href = "tel:+551121517690")}
                style={{ cursor: "pointer" }}
              >
                <div className="card-holder-icon">
                  <svg
                    width="41"
                    height="40"
                    viewBox="0 0 41 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      width="40"
                      height="40"
                      rx="20"
                      fill="#DFECFB"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M26.085 25.8021L26.8005 25.0867C27.2886 24.5985 27.2886 23.8071 26.8005 23.3189L25.2027 21.7211C24.8901 21.4086 24.4662 21.233 24.0242 21.233C23.5821 21.233 23.1582 21.4086 22.8457 21.7211L21.5931 22.9737C19.7118 22.2721 18.2278 20.7881 17.5262 18.9068L18.7788 17.6543C19.4296 17.0034 19.4296 15.9481 18.7788 15.2972L17.181 13.6995C16.9466 13.465 16.6286 13.3333 16.2971 13.3333C15.9656 13.3333 15.6477 13.465 15.4132 13.6995L14.6978 14.4149C14.1449 14.9678 13.8339 15.7175 13.8333 16.4995C13.8309 19.1967 14.9013 21.7841 16.8086 23.6913C18.7158 25.5986 21.3032 26.669 24.0004 26.6667C24.7824 26.666 25.5321 26.3551 26.085 25.8021Z"
                      stroke="#1774DE"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="card-holder-text">
                  <b>Telefone</b>
                  <p>+55 11 2151-7690</p>
                </div>
              </div>
              <div
                className="card-holder"
                onClick={() =>
                  window.open("https://wa.me/551136202550", "_blank")
                }
                style={{ cursor: "pointer" }}
              >
                <div className="card-holder-icon">
                  <svg
                    width="41"
                    height="40"
                    viewBox="0 0 41 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      width="40"
                      height="40"
                      rx="20"
                      fill="#DFECFB"
                    />
                    <path
                      d="M18.7384 21.7592C20.3759 23.3975 22.45 24.4408 23.6842 23.2125L23.9834 22.9133C24.3825 22.515 24.3267 21.855 23.8634 21.5333C23.5717 21.33 23.2584 21.1117 22.9117 20.8683C22.5534 20.6167 22.0617 20.6558 21.7509 20.9642L21.4125 21.3C20.9934 21.0342 20.5784 20.6933 20.1934 20.3083L20.1917 20.3067C19.8067 19.9217 19.4659 19.5067 19.2 19.0875L19.5359 18.7492C19.845 18.4383 19.8834 17.9467 19.6317 17.5883C19.3875 17.2417 19.1692 16.9283 18.9667 16.6367C18.645 16.1742 17.985 16.1183 17.5867 16.5167L17.2875 16.8158C16.0592 18.05 17.1025 20.1233 18.74 21.7617M25.67 14.8017C24.2984 13.4292 22.4742 12.6725 20.5309 12.6717C16.525 12.6717 13.2659 15.9292 13.265 19.9333C13.2634 21.2075 13.5975 22.46 14.2342 23.5642L13.2034 27.3275L17.055 26.3175C18.1209 26.8975 19.3142 27.2017 20.5275 27.2017H20.5309C24.535 27.2017 27.7942 23.9433 27.7959 19.9392C27.7967 17.9992 27.0417 16.175 25.67 14.8017Z"
                      stroke="#1774DE"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="card-holder-text">
                  <b>Whatsapp</b>
                  <p>+55 11 3620-2550</p>
                </div>
              </div>
            </HelpSection>
          </DivBody>
        )}
      </Main>
      <ImageForm src="img/bg-provedores.png">
        <h1>IDENTIFICAÇÃO PARA ATENDIMENTO</h1>
      </ImageForm>
    </Container>
  );
}
