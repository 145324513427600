import React from "react";
// import { format } from "date-fns";
// import { v4 as uuidv4 } from "uuid";
import ReactMarkdown from "react-markdown";
// import { useDispatch, useSelector } from "react-redux";
// import locale from 'date-fns/locale/pt-BR'
// import { setLoading } from "../../redux/actions/utils.actions";
import { getRemoteConfigValue } from "../../hooks/RemoteConfig";
// import { registerConsultationTerms } from "../../redux/actions/termsProvider.actions";

import Container from "../../container";
// import { show } from "../../services/alert";
// import {
//   getUserProductsRequest,
//   getVonageSessionStatus,
//   createSessionVonage,
//   createUrlSessionVonage,
//   deleteVonageFinishCall,
//   createSessionTruclinic,
// } from "../../redux/sagas/call";
// import { getPatientToken } from "../../redux/sagas/patient";
// import { getDesktopData } from "../../utils/getDesktopData";
// import { postTrackingEvent } from "../../core/Tracking/actions";
// import { goBack, push } from "../../services/navigate";
// import ModalTerm from './modalTerm'
import * as S from "../../components/cards-terms/styles";
import * as Style from "./styles";

export default function TeleconsultTerms() {
  // const [openModal, setOpenModal] = React.useState(false);
  // const dispatch = useDispatch();
  const markdown = getRemoteConfigValue('telemedicine_term')
  // const user = useSelector((state) => state.userReducer.user);
  // const teleconsultingTerms = JSON.parse(localStorage.getItem('teleconsultationTerms')) || null;
  // const visualizationDate = format(
  //   new Date(teleconsultingTerms?.lastAccepted || new Date()),
  //   `dd/MM/yyyy 'às' HH'h'mm`,
  //   { locale }
  // );

  // React.useEffect(() => {
  //   if (!teleconsultingTerms) {
  //     show("Não foi possível carregar os termos de teleconsulta, por favor, tente novamente.");
  //     setTimeout(() => {
  //       goBack();
  //     }, 5000)
  //   }
  //   return;
  // }, [])

  // const serviceVonageEnabled = getRemoteConfigValue("service_vonage_enabled");

  // const correlationIdStorage = localStorage.getItem("@correlationId");
  // const patientSelected = JSON.parse(localStorage.getItem("@conecta:patient-selected"));
  // const decisionTreeStorage = JSON.parse(
  //   localStorage.getItem("@decision_tree")
  // );
  // let provider = JSON.parse(localStorage.getItem("@conecta:selected-provider"));
  // const numberCard = JSON.parse(
  //   localStorage.getItem("@selected_provider_number_card")
  // );

  // const examsDocuments = JSON.parse(localStorage.getItem("conecta:exams"));

  // const changeCreateSessionTruclinic = React.useCallback(async () => {
  //   dispatch(setLoading(true));
  //   const patientToken = await getPatientToken(patientSelected._id);
  //   const userProducts = await getUserProductsRequest(patientToken);
  //   if (numberCard?.length > 0) {
  //     const newProvider = {
  //       ...provider,
  //       identification: numberCard,
  //     };
  //     provider = newProvider;
  //   }
  //   await createSessionTruclinic(
  //     patientSelected,
  //     provider,
  //     decisionTreeStorage,
  //     userProducts,
  //     patientToken,
  //     examsDocuments
  //   )
  //     .then(async (response) => {
  //       const arraySessions = await JSON.parse(
  //         localStorage.getItem("@conecta:immediateCare")
  //       );
  //       let newArraySessions = [];
  //       if (arraySessions !== null) {
  //         const removeSessionCurrent = arraySessions.filter(
  //           (session) => session.document !== patientSelected.document
  //         );
  //         newArraySessions.push(...removeSessionCurrent, {
  //           url: response.url,
  //           document: patientSelected.document,
  //         });
  //       } else {
  //         localStorage.setItem(
  //           "@conecta:immediateCare",
  //           JSON.stringify([
  //             {
  //               url: response.url,
  //               document: patientSelected.document,
  //             },
  //           ])
  //         );
  //       }
  //       localStorage.setItem(
  //         "@conecta:immediateCare",
  //         JSON.stringify(newArraySessions)
  //       );
  //       window.open(response.url, "_self");
  //       dispatch(setLoading(false));
  //     })
  //     .catch(async (error) => {
  //       const errorResponse = String(error).replace("Error: ", "");
  //       const arraySessions = await JSON.parse(
  //         localStorage.getItem("@conecta:immediateCare")
  //       );
  //       if (arraySessions !== null) {
  //         let getSessionCurrent = arraySessions.filter(
  //           (session) => session.document === patientSelected.document
  //         )[0];
  //         if (
  //           getSessionCurrent !== undefined &&
  //           getSessionCurrent !== null &&
  //           getSessionCurrent?.url?.length !== 0
  //         ) {
  //           const isSafari =
  //             /constructor/i.test(window.HTMLElement) ||
  //             (function (p) {
  //               return p.toString() === "[object SafariRemoteNotification]";
  //             })(
  //               !window["safari"] ||
  //               (typeof safari !== "undefined" && safari.pushNotification)
  //             );

  //           if (isSafari) {
  //             window.open(getSessionCurrent.url, "_self");
  //           } else {
  //             if (
  //               getDesktopData().deviceType.toLocaleLowerCase() === "mobile"
  //             ) {
  //               window.open(getSessionCurrent.url, "_self");
  //             } else {
  //               window.open(getSessionCurrent.url, "_blank");
  //             }
  //           }

  //           push("/home");
  //           localStorage.removeItem("conecta:exams");
  //           dispatch(setLoading(false));
  //         } else {
  //           show(errorResponse);
  //           dispatch(setLoading(false));
  //         }
  //       } else {
  //         show(errorResponse);
  //         dispatch(setLoading(false));
  //       }
  //     });
  // }, []);

  // const changeCreateNewSessionVonage = React.useCallback(async () => {
  //   dispatch(setLoading(true));
  //   const isDependent = patientSelected._id !== user._id;
  //   const idPatient = isDependent
  //     ? patientSelected._id
  //     : patientSelected.idPatientConecta;
  //   const patientToken = await getPatientToken(idPatient);
  //   const userProducts = await getUserProductsRequest(patientToken);
  //   if (numberCard?.length > 0) {
  //     const newProvider = {
  //       ...provider,
  //       identification: numberCard,
  //     };
  //     provider = newProvider;
  //   }
  //   try {
  //     const sessionVonageState = await getVonageSessionStatus(
  //       patientToken,
  //       idPatient
  //     );
  //     if (!sessionVonageState) {
  //       await createSessionVonage(
  //         patientSelected,
  //         provider,
  //         decisionTreeStorage,
  //         userProducts,
  //         patientToken,
  //         examsDocuments
  //       )
  //         .then(async (responseSession) => {
  //           dispatch(
  //             postTrackingEvent([
  //               {
  //                 correlationId: correlationIdStorage,
  //                 idempotencyKey: uuidv4(),
  //                 payload: {
  //                   sessionId: responseSession.session,
  //                   convenios: provider,
  //                   type: "TELE",
  //                 },
  //                 scope: "upa",
  //                 terminal: false,
  //                 timestamp: new Date(),
  //                 type: "atendimentoIniciado",
  //               },
  //               patientSelected,
  //             ])
  //           );
  //           await createUrlSessionVonage(
  //             patientSelected.name,
  //             responseSession.session,
  //             patientToken
  //           )
  //             .then((responseUrl) => {
  //               if (responseUrl) {
  //                 const sessionsPatients = JSON.parse(
  //                   localStorage.getItem("@vonage_session_status")
  //                 );
  //                 const telemedicineImmediate = {
  //                   sessionId: responseSession.session,
  //                   url: responseUrl.url,
  //                   idPatient: idPatient,
  //                 };
  //                 let newSessionsPatients = [];

  //                 if (sessionsPatients !== null) {
  //                   sessionsPatients.map((value) => {
  //                     newSessionsPatients.push(value);
  //                   });
  //                 }

  //                 newSessionsPatients.push(telemedicineImmediate);

  //                 localStorage.removeItem("@vonage_session_status");

  //                 localStorage.setItem(
  //                   "@vonage_session_status",
  //                   JSON.stringify(newSessionsPatients)
  //                 );

  //                 const isSafari =
  //                   /constructor/i.test(window.HTMLElement) ||
  //                   (function (p) {
  //                     return (
  //                       p.toString() === "[object SafariRemoteNotification]"
  //                     );
  //                   })(
  //                     !window["safari"] ||
  //                     (typeof safari !== "undefined" &&
  //                       safari.pushNotification)
  //                   );

  //                 if (isSafari) {
  //                   window.open(responseUrl.url, "_self");
  //                 } else {
  //                   if (
  //                     getDesktopData().deviceType.toLocaleLowerCase() ===
  //                     "mobile"
  //                   ) {
  //                     window.open(responseUrl.url, "_self");
  //                   } else {
  //                     window.open(responseUrl.url, "_blank");
  //                   }
  //                 }

  //                 dispatch(setLoading(false));
  //                 localStorage.removeItem("conecta:exams");
  //                 return true;
  //               }
  //               return false;
  //             })
  //             .catch(async () => {
  //               await deleteVonageFinishCall(
  //                 responseSession.session,
  //                 patientToken
  //               );
  //               dispatch(setLoading(false));
  //             });
  //         })
  //         .catch(() => {
  //           dispatch(setLoading(false));
  //         });
  //     } else {
  //       dispatch(setLoading(false));
  //     }
  //   } catch {
  //     dispatch(setLoading(false));
  //   }
  // }, []);

  // const handleSession = React.useCallback(async () => {
  //   const params = {
  //     idVida: Number(teleconsultingTerms.idVida),
  //     idConvenio: Number(teleconsultingTerms.idConvenio),
  //     idTermo: Number(teleconsultingTerms.idTermo),
  //     termoAceite: true,
  //     dataTermoAceite: new Date().toISOString(),
  //     ipMaquinaTermoAceite: 'IP',
  //     dispositivoPlataforma: window.navigator.userAgent,
  //     descriptionService: 'teleconsulta',
  //   };

  //   dispatch(registerConsultationTerms(params))

  //   if (serviceVonageEnabled.immediate_care) {
  //     await changeCreateNewSessionVonage().then(async () => {
  //       await push("/home");
  //     });
  //   } else {
  //     await changeCreateSessionTruclinic();
  //   }
  // }, []);

  // const rejectSession = React.useCallback(async () => {
  //   setOpenModal(true);
  //   const params = {
  //     idVida: Number(teleconsultingTerms.idVida),
  //     idConvenio: Number(teleconsultingTerms.idConvenio),
  //     idTermo: Number(teleconsultingTerms.idTermo),
  //     termoAceite: false,
  //     dataTermoAceite: new Date().toISOString(),
  //     ipMaquinaTermoAceite: 'IP',
  //     dispositivoPlataforma: window.navigator.userAgent,
  //     descriptionService: 'teleconsulta',
  //   };

  //   dispatch(registerConsultationTerms(params))

  //   await push('/home')
  // }, []);


  return (
    <Container>
      <Style.Container>
        <S.Header>
          <h1>Termo da teleconsulta</h1>
        </S.Header>
        <S.ContainerContent className="read-term">
          <S.ContainerTerm>
            <S.Text>
              <strong style={{ fontSize: 20 }}>
                Termo de Consentimento e Tratamento de Dados para Realização de Teleconsulta
              </strong>
            </S.Text>
            <ReactMarkdown>{markdown}</ReactMarkdown>
          </S.ContainerTerm>

          {/* <S.CardUserInfo>
            <S.UserData>
              <span>Titular da conta</span>
              <h3>{`${user.name} ${user.lastName}`}</h3>
            </S.UserData>
            {
              teleconsultingTerms?.lastAccepted ? (
                <S.UserTimeViewed>
                  Visualizado em: <span>{visualizationDate}</span>
                </S.UserTimeViewed>
              )
                : null
            }
          </S.CardUserInfo> */}
        </S.ContainerContent>
        {/* <Style.Buttons>
          <Style.ButtonCancel onClick={() => setOpenModal(true)}>
            Não aceitar e sair
          </Style.ButtonCancel>
          <Style.Button onClick={() => handleSession()} >
            Aceitar e acessar consulta
          </Style.Button>
        </Style.Buttons> */}
      </Style.Container>
      {/* <ModalTerm open={openModal} onClose={() => setOpenModal(false)} onExit={() => rejectSession()} /> */}
    </Container>
  );
}
