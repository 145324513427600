import { combineReducers } from "redux";

import userReducer from "./user.reducer";
import dependentsReducer from "./dependents.reducer";
import patientReducer from "./patient.reducer";
import utilsReducer from "./utils.reducer";
import buyReducer from "./buy.reducer";
import historyReducer from "./history.reducer";
import schedule from "./schedule.reducer";
import telepsico from "./telepsico.reducer";
import corporateCardRecuder from "./corporateCard.reducer";
import termsProvider from "./termsProvider.reducer";
import featureFlagsReducer from "./featureFlag.reducer";
import catalogReducer from "./catalog.reducer";
import persistTracking from "../../core/Tracking/reducer";
import persistHome from "../../core/Home/reducer";
import specialists from "./specialists.reducer";

export default combineReducers({
  corporateCardRecuder,
  userReducer,
  dependentsReducer,
  utilsReducer,
  patientReducer,
  historyReducer,
  buyReducer,
  schedule,
  telepsico,
  termsProvider,
  featureFlagsReducer,
  catalogReducer,
  persistTracking,
  home: persistHome,
  specialists
});
