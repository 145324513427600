import { takeEvery, put } from "redux-saga/effects";
import { getScheduleStore } from "./actions";
import { push } from "../../services/navigate";
import { setOldSlot } from "../../redux/actions/schedule.actions";
import {
  setPatient as setPatientB2C,
  setSelectedPatient,
} from "../../redux/actions/patient.actions";
import { setSelectedProgram } from "../../redux/actions/telepsico.actions";
import { setScheduleType } from "../../redux/actions/schedule.actions";

function* schedulingFulfilledSaga() {
  const schedule = JSON.parse(
    localStorage.getItem("@home:modal-selected-patient")
  );
  if (schedule !== null) {
    if (schedule.nextSchedule) {
      const patient = JSON.parse(
        localStorage.getItem("@conecta:patient-selected")
      );
      yield put(setScheduleType("TELEMEDICINA"));
      yield put(setPatientB2C(patient));
      yield put(setSelectedPatient(true));
      yield put(setSelectedProgram(false));
      yield put(setOldSlot(false));
      push("/agendamento");
      localStorage.setItem(
        "@home:modal-selected-patient",
        JSON.stringify({
          visibleModal: false,
          nextSchedule: false,
          typeModal: "",
        })
      );
    }
  }
}

const schedulingSagas = [
  takeEvery(getScheduleStore.match, schedulingFulfilledSaga),
];

export default schedulingSagas;
