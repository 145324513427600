import React, { useState } from "react";

import {
    Body,
    Title,
    ProgramIcon,
    ProgramPeriod,
    ShowMore,
    Icon,
    BackDiv,
    PeriodDescription,
    ShowMoreDivision,
    DivisionDiv,
    Description,
    ButtonDiv,
    Button,
} from "./styles";

import { BsPlusCircle } from "react-icons/bs";
import { AiOutlineLeft } from "react-icons/ai";

export default function ProgramCard(props) {
    return(
        <Body onClick={() => {
            props._changeShowMoreCard(props.showMoreIndex)
            props._changeFocus(props.programFocusIndex)
        }} showMore={props.showMore} isFocus={props.isFocus}>
            <Title showMore={props.showMore}>{props.title}</Title>
            <BackDiv showMore={props.showMore} onClick={() => props._changeShowMoreCard(props.showMore)}>
                <AiOutlineLeft />
            </BackDiv>
            <ProgramIcon showMore={props.showMore} src={props.iconSrc}/>
            <ProgramPeriod showMore={props.showMore}>{props.period}</ProgramPeriod>
            <PeriodDescription showMore={props.showMore}>
                {props.periodDescription && props.periodDescription.map((period) => (
                    <div>{period.description}</div>
                ))}
            </PeriodDescription>
            <DivisionDiv showMore={props.showMore}>
                <ShowMoreDivision/>
            </DivisionDiv>
            <ShowMore onClick={() => props._changeShowMoreCard(props.showMore)} showMore={props.showMore}>
                <Icon>
                    <BsPlusCircle/>
                </Icon>
            </ShowMore>
            <Description showMore={props.showMore}>{props.description}</Description>
            <ButtonDiv showMore={props.showMore}>
                <Button onClick={props.onConfirm}>Selecione</Button>
            </ButtonDiv>
        </Body>
    )
}