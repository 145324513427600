import * as yup from "yup";
import { cpf as cpfTest } from "cpf-cnpj-validator";

export const brasileiroSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("Nome é obrigatório")
    .min(3, "Nome mínimo 3 caracteres"),

  email: yup
    .string()
    .trim()
    .required("E-mail é obrigatório")
    .test("email-test", "E-mail inválido", function (value) {
      const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

      if (!emailRegexp.test(value)) {
        return false;
      } else {
        return true;
      }
    }),
  document: yup
    .string()
    .trim()
    .test("cpf-test", "Documento inválido", function (value) {
      if (!value) return false;

      const cpf = value.replace(/[^0-9]+/g, "");
      if (!cpfTest.isValid(cpf)) {
        return false;
      } else {
        return true;
      }
    }),
  phone: yup
    .string()
    .trim()
    .required("Telefone é obrigatório")
    .test("phone-test", "Telefone inválido", function (value) {
      if (value) {
        let phone = value.replace(/_/g, "");
        if (phone.length < 10) {
          return false;
        } else {
          return true;
        }
      }
    }),
  message: yup.string().trim().required("Mensagem é obrigatória"),
});





// Estrangeiro


export const estrangeiroSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("Nome é obrigatório")
    .min(3, "Nome mínimo 3 caracteres"),

  email: yup
    .string()
    .trim()
    .required("E-mail é obrigatório")
    .test("email-test", "E-mail inválido", function (value) {
      const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

      if (!emailRegexp.test(value)) {
        return false;
      } else {
        return true;
      }
    }),
  document: yup
  .string()
  .required('Documento é obrigatório'),
  phone: yup
    .string()
    .trim()
    .required("Telefone é obrigatório")
    .test("phone-test", "Telefone inválido", function (value) {
      if (value) {
        let phone = value.replace(/_/g, "");
        if (phone.length < 10) {
          return false;
        } else {
          return true;
        }
      }
    }),
  message: yup.string().trim().required("Mensagem é obrigatória"),
});




