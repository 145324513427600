import React, { useEffect, useState } from "react";
import { push } from "../../../../services/navigate";
import {
  Alert,
  AlertMessageAlignItemsBottom,
  DirectionColumn,
  ButtonTextSpan,
  IconAccepted,
  IconReject,
} from "../styles";
import { TERMS_PROVIDER_STATUS } from "./constants";
import { terms } from "./terms/natura.terms";
import { terms as termsDefault } from "./terms/natura.terms";

export default function AlertTermsProvider(props) {
	const { status: termsProviderStatus } = props;
	const goToTermsProviderPage = () => push('/terms-provider');
  return (
    <Alert>
      <AlertMessageAlignItemsBottom>
        <DirectionColumn>
          <TermTextByStatus status={termsProviderStatus} />
        </DirectionColumn>
        <button onClick={goToTermsProviderPage}>
          {termsProviderStatus === TERMS_PROVIDER_STATUS.ACCEPTED ? <IconAccepted/> : <IconReject/>}
          <ButtonTextSpan>
            Editar            
          </ButtonTextSpan>
        </button>
      </AlertMessageAlignItemsBottom>
    </Alert>
  );
}

export function TermTextByStatus(props) {
  const { status } = props;
	const [textProvider, setTextProvider] = useState(termsDefault.status.DEFAULT);

	function Title({ children: { title } }) {
		return <h3 dangerouslySetInnerHTML={{ __html: terms.apply(title)}} />;
	}

	function Paragraphs({ children: { paragraphs } }) {
		if (!Array.isArray(paragraphs)) return null;
		return paragraphs.map(paragraph => <p dangerouslySetInnerHTML={{ __html: terms.apply(paragraph)}} />);
	}

	useEffect(() => {
		// TODO: load diff companies/providers
		setTextProvider(terms.status[status] || terms.status.DEFAULT);
	}, [status]);

  return (
    <React.Fragment>
			<Title>{textProvider}</Title>
			<Paragraphs>{textProvider}</Paragraphs>
    </React.Fragment>
  )
}
