import {
  SET_DEPENDENTS,
  DEPENDENTS_GETALL_REQUEST,
  ADD_DEPENDENT,
  EDIT_DEPENDENT,
  REMOVE_DEPENDENT
} from "./action-types";

export const getAllDependents = (payload) => ({
  type: DEPENDENTS_GETALL_REQUEST,
  payload,
});

export const addDependent = (payload) => ({
  type: ADD_DEPENDENT,
  payload,
});

export const setDependents = (dependents) => ({
  type: SET_DEPENDENTS,
  payload: dependents,
});

export const eraseDependent = (payload) => ({
  type: REMOVE_DEPENDENT,
  payload,
});

export const setDependentsRules = (payload) => ({
  type: "SET_DEPENDENTS_RULES",
  payload
})
