import styled, { css } from "styled-components";

const active = css`
  background-color: #2196f3;
  color: #fff;
`;

export const AvailableHours = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: auto;
  background-color: #ffffff;
  border-radius: 10px;
  @media (max-width: 500px) {
    overflow-y: visible;
    margin: 0px;
  }
`;

export const HourButton = styled.div`
  width: 20%;
  height: 30px;
  border-radius: 20px;
  padding: 5px;
  margin: 2.5%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  ${(props) => props.active && active}
  &:hover {
    background-color: #2196f3;
    color: #fff;
    transition: 0.2s;
  }
`;
