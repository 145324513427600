import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-bottom: 70px;
  padding: 0 25px;

  ${props => props.isBuyPage && css`
    padding-bottom: 40px;
  `}

  h4 {
    text-align: center;
    ${props => props.isBuyPage && css`
      display: none;
    `}
  }
`;

export const ImageContainer = styled.div`
  margin-top: 20px;
  width: 275px;
  display: flex;
  justify-content: space-evenly;
`;
