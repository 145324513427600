import { select, call, put, all } from "redux-saga/effects";
import { setLoading } from "../actions/utils.actions";
import api from "../../services/api";
import { show } from "../../services/alert";
import {
  TELEPSICO_PROGRAMS_SUCCESS,
  GET_AVAILABLE_DATE_SUCCESS,
  SET_OLD_SLOT,
} from "../actions/action-types";
import { goBack, push } from "../../services/navigate";

const getPatient = state => state.patientReducer.patient;

export function* listTelepsicoPrograms() {
  yield put(setLoading(true));
  try {
    const patient = yield select(getPatient);
    const request = () => api.post("/telepsychology", patient);
    const response = yield call(request);
    if(response.status === 200){
      const { triade, activeProgram } = response.data;
      //if user have activeProgram but don't have triade return to schedule list with message
      if((!triade && activeProgram) || (triade && !activeProgram)){
        show("É necessário realizar a primeira consulta de telepsicologia para agendar as próximas");
        return push('/consultas-agendadas');  
      }else{
        yield all([
          put({ type: TELEPSICO_PROGRAMS_SUCCESS, payload: response.data }), 
          put({ type: SET_OLD_SLOT, payload: false })
        ])
      }
    }
  } catch (error) {
    if(error.response && error.response.data){
      show(error.response.data.message);
      return push('/consultas-agendadas');
    }
    show("Tente novamente");
  }finally{
    yield put(setLoading(false));
  }
}

export function* listDate() {
  yield put(setLoading(true));
  try {
    const request = () => api.post("/schedule/find-slot-avaible", { type: "TELEPSICOLOGIA" });
    const response = yield call(request);

    if(response.data.hasError) {
      throw new Error(response.data.message);
    }
    
    if (response.status === 200) {
      yield put({ type: GET_AVAILABLE_DATE_SUCCESS, payload: response.data.data.rangeDates });
    }
  } catch (error) {
    show(error.message);
  } finally {
    yield put(setLoading(false));
  }
}

export function* listDateByDoctor() {
  yield put(setLoading(true));
  const scheduleType  = yield select(state => state.schedule.scheduleType);
  const patient  = yield select(state => state.patientReducer.patient);
  
  try {
    const data = {
      patient: patient,
      scheduleType: scheduleType
    }
    const request = () => api.post("/schedule/slot-availability-by-doctor", data);
    const response = yield call(request);
    if (response.status === 200) {
      if(response.data && response.data.data){
        yield put({ type: GET_AVAILABLE_DATE_SUCCESS, payload: response.data.data.rangeDates });
      }else{
        yield put({ type: GET_AVAILABLE_DATE_SUCCESS, payload: [] });
        show('Não foram encontrados dias disponíveis para o médico informado')
      }
    }
  } catch (error) {
    show("Tente novamente");
  } finally {
    yield put(setLoading(false));
  }
}