import React, { useState, useEffect } from "react";

import {
  Body,
  ScheduleDay,
  TitleSchedule,
  DivButtons,
  Button,
  DivTitle,
  DivCalendar,
  BoxDiv,
  DivHours,
} from "./styles";

import "./styles.css"

import Calendar from "../../components/calendar";
import Hours from "../../components/hour";
import { useSelector, useDispatch } from "react-redux";
import { getAvailableDates, getAvailableDatesByDoctor } from "../../redux/actions/telepsico.actions"
import { scheduleTelepsico, getReschedule, checkPatient, setDaySelected, clearSuccessScheduled, setSelectedSlot } from "../../redux/actions/schedule.actions"
import { push } from "../../services/navigate"
import { show } from "../../services/alert";
import Confirm from "../../components/confirm-infos";
import ConfirmInfos from "../confirm-schedule";


function ScheduleTelepsico() {
  const dispatch = useDispatch();
  const day = useSelector((state) => state.schedule.daySelected);
  const triade = useSelector((state) => state.telepsico.triade);
  const oldSlot = useSelector((state) => state.schedule.oldSlot);
  const patient = useSelector((state) => state.patientReducer.patient);
  const selectedSlot = useSelector((state) => state.schedule.selectedSlot);
  const selectedProvider = useSelector((state) => state.userReducer.selectedProvider);
  const successScheduled = useSelector(
    (state) => state.schedule.successScheduled
  );
  const [confirmSchedule, setConfirmSchedule] = useState(false);

  function goToScheduled() {
    push("/consultas-agendadas")
  }

  const _calendarDivHeight = () => {
    return document.getElementById("calendar") ? document.getElementById("calendar").clientHeight : 0;
  }

  const _calendarDivWidth = () => {
    if (!day) {
      return 0;
    }
    return document.getElementById("calendar") ? document.getElementById("calendar").clientWidth : 0;
  }

  function _schedule() {
    if (!selectedSlot) {
      show('Selecione um horário para agendar')
    } else { 
      //first schedule
      if (!triade && !oldSlot) {
        dispatch(scheduleTelepsico())
      }
      //reschedule
      if (oldSlot) {
        dispatch(getReschedule())
      }
      //new schedule
      if (triade && !oldSlot) {
        dispatch(checkPatient({ patient: { ...patient, empresas: [selectedProvider] }, slot: { slotId: selectedSlot.id } }));
      }
    }
  }

  function _goBack() {
    if (confirmSchedule) {
      setConfirmSchedule(false);
      dispatch(setDaySelected(null));
    } else {
      goToScheduled();
    }
  }

  function _next() {
    if (!selectedSlot) {
      show('Selecione um horário para agendar')
    } else {
      if (confirmSchedule) {
        _schedule();
      } else {
        setConfirmSchedule(true);
      }
    }
  }

  useEffect(() => {
    dispatch(clearSuccessScheduled());
    //clear selected slots
    dispatch(setSelectedSlot(false))
    if (triade) {
      dispatch(getAvailableDatesByDoctor());
    } else {
      dispatch(getAvailableDates());
    }
  }, []);

  return (
    <Body>
      <DivTitle>
        <TitleSchedule>Agendamento Telepsicologia</TitleSchedule>
      </DivTitle>
      {!confirmSchedule && (
        <ScheduleDay>
          <BoxDiv>
            <DivCalendar id="calendar">
              <TitleSchedule>Selecione a data</TitleSchedule>
              <Calendar />
            </DivCalendar>
          </BoxDiv>
          {day && (
            <BoxDiv>
              <DivCalendar>
                <TitleSchedule id="hours" marginBottom={20}>Selecione um horário{ }</TitleSchedule>
                <DivHours width={_calendarDivWidth()} height={_calendarDivHeight() - 36}>
                  <Hours />
                </DivHours>
              </DivCalendar>
            </BoxDiv>
          )}
        </ScheduleDay>)}
      {confirmSchedule && !successScheduled && (
        <ScheduleDay>
          <Confirm />
        </ScheduleDay>
      )}
      {confirmSchedule && successScheduled && (
        <ScheduleDay>
          <ConfirmInfos />
        </ScheduleDay>
      )}
      {!successScheduled && (
        <DivButtons>
          <Button gray onClick={() => _goBack()}>
            Voltar
          </Button>
          <Button blue onClick={() => _next()}>
            Confirmar
          </Button>
        </DivButtons>
      )}
    </Body>
  );
}
export default ScheduleTelepsico;
