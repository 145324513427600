import styled from "styled-components";

export const Main = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin: 70px 0 0 70px;

    @media (max-width: 1024px) {
        align-items: center;
        margin: 100px 0 0;
    }
`;

export const Title = styled.h4`
    margin-bottom: 20px;
`;

export const SubTitle = styled.p`
    color: #707070;
    width: 443px;
    margin: 20px 0;
    line-height: 1.7;
    text-align: justify;

    @media (max-width: 1024px) {
        width: 270px;
    }
`;

export const DivButton = styled.div`
    width: 100%;
    display: flex;
    justify-content: left;

    @media (max-width: 1024px) {
        width: 270px;
        display: flex;
        flex-direction: column;        
    }
`;

export const Button = styled.button`
    cursor: pointer;
    color: white;
    width: 300px;
    font-weight: bold;
    height: 40px;
    background: #2196f3;
    border-radius: 30px;

    @media (max-width: 1024px) {
        width: 100%;
        margin: 0 0 20px;
    }
`;

export const ButtonBackHome = styled.button`
    cursor: pointer;
    color: #2196f3;
    width: 300px;
    height: 40px;
    font-weight: bold;
    background: #fff;
    margin-left: 2rem;

    border-radius: 30px;
    border: 2px solid #2196f3;

    @media (max-width: 1024px) {
        width: 100%;
        margin: 0;
    }
`;

export const CreditCardTemplate = styled.div`
    background: #8acdff;
    height: 10rem;
    width: 20rem;
    padding: 15px;

    border-radius: 15px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;
`;

export const CreditCardNumber = styled.p`
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
`;

export const Image = styled.img`
    position: absolute;
    right: 15px;
    bottom: 15px;
`;