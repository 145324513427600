import { useEffect } from "react";
import { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";

const guidelineBaseWidth = 100;
const guidelineBaseHeight = 100;

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function horizontalScale(size, width) { return (width / guidelineBaseWidth) * size };
function verticalScale(size, height) { return  (height / guidelineBaseHeight) * size };
function moderateScale(size, windowDimensions) {
  let factor;

  if (windowDimensions.width < 425)
    factor = 0.005;
  else if (windowDimensions.width < 768)
    factor = 0.025;
  else
    factor = 0.04;


  const cardIsVertical = windowDimensions.height > windowDimensions.width;
  let scale;

  if (cardIsVertical) scale = verticalScale(size, windowDimensions.height);
  else scale = horizontalScale(size, windowDimensions.width);

  scale = size + (scale - size) * factor;

  return scale.toFixed(4) + 'px';
}

export const Main = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin: 70px 0 0 70px;

  @media (max-width: 1024px) {
    margin: 80px 0 0 30px;
  }
`;

export const Titles = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 60%;
`;

export const Title = styled.h2`
  margin-bottom: 5px;
  font-size: 15px;
`;

export const SubTitle = styled.p`
  color: #707070;
`;

export const CardContainer = styled.div`
  perspective: 800px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: calc(100vw - 280px);
  height: 70vh;
  display: ${props => props.isVisible ? 'flex' : 'none'};
  
  @media (max-width: 1024px) { 
    width: calc(100% - 40px);
  }
`;

export const CardBackgroundImage = styled.img`
  object-fit: cover;
  position: absolute;
  width: ${props => moderateScale(310, props.windowDimensions)};
  height: ${props => moderateScale(220, props.windowDimensions)};
  transition: transform 0.8s;
  backface-visibility: hidden;
  transform: ${props => props.isVisible ? (props.isFrontImage ? 'rotateX(0deg)' : 'rotateX(360deg)') : 'rotateX(180deg)'};
`;

export const CardFieldsContainer = styled.div`
  position: absolute;
  width: ${props => moderateScale(310, props.windowDimensions)};
  height: ${props => moderateScale(220, props.windowDimensions)};
  padding-left: ${props => moderateScale(9, props.windowDimensions)};
  padding-right: ${props => moderateScale(45, props.windowDimensions)};
  transition: transform 0.8s;
  backface-visibility: hidden;
  transform: ${props => props.isVisible ? 'rotateX(0deg)' : 'rotateX(180deg)'};
`;

export const CardName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: ${props => moderateScale(12, props.windowDimensions)};
  display: block;
  font-size: ${props => moderateScale(10, props.windowDimensions)};
  position: relative;
  top: ${props => moderateScale(85, props.windowDimensions)};
  font-weight: bold;
`;

export const CardNumber = styled.p`
  display: block;
  font-size: ${props => moderateScale(10, props.windowDimensions)};
  position: absolute;
  bottom: ${props => moderateScale(41, props.windowDimensions)};
  color: #004e8f;
  font-weight: bold;
`;

export const CardCompanyName = styled.p`
  display: block;
  font-size: ${props => moderateScale(6, props.windowDimensions)};
`;

export const CardDataTable = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  top: ${props => moderateScale(100, props.windowDimensions)};
  width: 100%;
`;

export const CardDataColumn = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const CardDataRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CardDataName = styled.span`
  font-size: ${props => moderateScale(6.5, props.windowDimensions)};
  margin-right: ${props => moderateScale(3, props.windowDimensions)};
  color: #004e8f;
  font-weight: bold;
`;

export const CardDataValue = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: ${props => moderateScale(6.5, props.windowDimensions)};
  font-weight: bold;
`;

export const RotateCardContainer = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${props => moderateScale(9, props.windowDimensions)};
  color: #707070;
  font-weight: bold;
  left: ${props => moderateScale(200, props.windowDimensions)};

  @media (max-width: 600px) { 
    top: 30%;
    left: 0;
  }
`;

export const RotateCardIcon = styled.img`
  width: ${props => moderateScale(25, props.windowDimensions)};
  height: ${props => moderateScale(25, props.windowDimensions)};
  transform: ${props => props.cardFrontIsVisible ? 'rotateY(0deg)' : 'rotateY(180deg)'};
`;