import Compressor from "compressorjs";

var REGEXP_IMAGE_TYPE = /^image\/.+$/;

export const isImageType = (value) => {
  return REGEXP_IMAGE_TYPE.test(value);
}

export const compressImage = (file) =>
  new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.7,
      mimeType: "auto",
      success(result) {
        resolve(result);
      },
      error(err) {
        reject(err);
      },
    });
  });

export default compressImage