import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setLoading, verifyAuth } from "../../redux/actions/utils.actions";

import { push } from '../../services/navigate';
import api from "../../services/api";

import paymentLogos from '../../utils/icons-payment';

import Container from "../../container";
import NewCreditCardModal from './new-credit-card';

import {
  Main,
  Title,
  SubTitle,
  DivButton,
  Button,
  ButtonBackHome,
  CreditCardTemplate,
  CreditCardNumber,
  Image,
} from "./styles";

export default function ChangeCreditCardInfo() {
  const dispatch = useDispatch();

  const currentPrivatePlan = useSelector(
    (state) => state.userReducer.currentPrivatePlan
  );

  const [open, setOpen] = useState(false);
  const [cardInfo, setCardInfo] = useState({
    lastNumber: '',
    name: '',
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (confirm) => {
    if (confirm === 'ok') {
      getCreditCardInfo();
    }
    setOpen(false);
  };

  const getCreditCardInfo = useCallback(async () => {
    dispatch(setLoading(true));
    const response = await api.post('/payment/getCreditCard', {
      productId: currentPrivatePlan.ProductId,
    })

    const lastNumberLength = response.data.lastNumber.toString().length;
    const currentLastNumber = response.data.lastNumber;

    if (lastNumberLength < 4 && currentLastNumber !== 0) {
      let newLastNumber = response.data.lastNumber;
      for (let i = 0; i < 4 - lastNumberLength; i++)
        newLastNumber = '0' + newLastNumber;

      response.data.lastNumber = newLastNumber;
    }

    setCardInfo(response.data);
    dispatch(setLoading(false));
  }, [currentPrivatePlan]);

  useEffect(() => {
    if (currentPrivatePlan !== null) {
      getCreditCardInfo();
    }
  }, [currentPrivatePlan]);

  return (
    <>
      { open && <NewCreditCardModal open={open} handleClose={handleClose} />}
      <Container>
        <Main>
          <Title>Troca de cartão de crédito</Title>

          <CreditCardTemplate>
            <CreditCardNumber>
              {cardInfo.lastNumber > 0
                ? `**** **** **** ${cardInfo.lastNumber}`
                : 'Nº indisponível no momento'
              }
            </CreditCardNumber>
            {paymentLogos.map(logo => {
              if (cardInfo.name === logo.label)
                return <Image key={logo.id} src={logo.icon} width={45} alt={logo.label} />
            })}
          </CreditCardTemplate>

          <SubTitle>
            Após realizar a troca do cartão de crédito, todas as recorrências
            referentes ao plano contratado serão cobradas deste novo cartão, tem
            certeza que deseja realizar a troca?
          </SubTitle>

          <DivButton>
            <Button onClick={() => {
              handleClickOpen();
            }}>
              Sim, desejo continuar com a troca
            </Button>
            <ButtonBackHome onClick={() => {
              push('/home');
              dispatch(verifyAuth());
            }}>
              Não, desejo cancelar a troca
            </ButtonBackHome>
          </DivButton>
        </Main>
      </Container>
    </>
  );
}
