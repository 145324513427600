import React from 'react';
import { Container, ImageContainer } from './styles';
import paymentIcons from '../../utils/icons-payment';

export default function ShowsCreditCardsInline({ isBuyPage = false }) {
  return (
    <Container isBuyPage={isBuyPage}>
      <h4>
        Apenas pagamento no cartão de crédito. Bandeiras aceitas:
      </h4>
      <ImageContainer>
        {paymentIcons.map(icon => {
          return <img key={icon.id} src={icon.image} width={45} />;
        })}
      </ImageContainer>
    </Container>
  );
}