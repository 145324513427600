import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";

import { Provider } from "react-redux";
import { Store } from "./redux/store";

import { positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "./components/alert";

// require("dotenv-flow").config();
localStorage.setItem("clientId", "conecta-app");
localStorage.setItem("@client", "B2C");

const options = {
  position: positions.TOP_CENTER,
  timeout: 50000,
  offset: "30px",
};

// if (process.env.NODE_ENV === "development") {
//   import("./ReactotronConfig").then(() => console.log("Reactotron Configured"));
// }

// function Index() {
//   // <React.StrictMode>
//     <h2>
//       teste
//     </h2>
//     {/* <Provider store={Store}>
//       <AlertProvider
//         containerStyle={{ margin: 0, zIndex: 500 }}
//         template={AlertTemplate}
//         {...options}
//       >
//         <App />
//       </AlertProvider>
//     </Provider> */}
//   {/* </React.StrictMode>, */}
//   {/* document.getElementById("root") */}
// }

// export default Index;

export default function Index(props) {
  return (
    <React.StrictMode>
      <Provider store={Store}>
        <AlertProvider
          containerStyle={{ margin: 0, zIndex: 500 }}
          template={AlertTemplate}
          {...options}
        >
          <App />
        </AlertProvider>
      </Provider>
    </React.StrictMode>
  );
}


