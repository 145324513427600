import {
  SET_PLAN,
  SET_DATA_USER,
  GET_PLANS,
  GET_PLANS_BY_CATEGORY,
  SET_PLANS,
  SET_PLANS_CATEGORY,
  REGISTER_PAYMENT,
  SET_URL_PAYMENT,
  CHANGE_PLAN,
  VALIDATE_VOUCHER,
  SET_VOUCHER,
} from './action-types';

export const changePlan = (payload) => ({
  type: CHANGE_PLAN,
  payload,
});

export const setCurrentPlan = (payload) => ({
  type: SET_PLAN,
  payload,
});

export const setUrlPayment = (payload) => ({
  type: SET_URL_PAYMENT,
  payload,
});

export const setDataUser = (payload) => ({
  type: SET_DATA_USER,
  payload,
});

export const getPlans = (payload) => ({
  type: GET_PLANS,
  payload,
});

export const getPlansByCategory = (payload) => ({
  type: GET_PLANS_BY_CATEGORY,
  payload,
});

export const setPlans = (payload) => ({
  type: SET_PLANS,
  payload,
});

export const setPlansCategory = (payload) => ({
  type: SET_PLANS_CATEGORY,
  payload,
});

export const registerPayment = (payload) => ({
  type: REGISTER_PAYMENT,
  payload,
});

export const validateVoucher = (payload) => ({
  type: VALIDATE_VOUCHER,
  payload,
});

export const setVoucher = (payload) => ({
  type: SET_VOUCHER,
  payload,
});
