import {
  SET_PATIENT,
  PATIENT_SELECTED,
  PATIENT_SENT_DOCUMENTS,
} from "./action-types";

export const setPatient = (patient) => {
  let formattedPatient = null;
  if (
    patient[0] !== undefined &&
    patient[0] !== null &&
    patient[0].length > 0
  ) {
    formattedPatient = patient[0];
  } else {
    formattedPatient = patient;
  }
  return {
    type: SET_PATIENT,
    payload: formattedPatient,
  };
};

export const setSelectedPatient = (bool) => ({
  type: PATIENT_SELECTED,
  payload: bool,
});

export const setPatientSentDocuments = (payload) => ({
  type: PATIENT_SENT_DOCUMENTS,
  payload: payload,
});
