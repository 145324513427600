import { Link } from "react-router-dom";

import * as S from "./styles";

export default function CardPrivacy({ term, onSelectTerm }) {
  return (
    <S.ContainerCard>
      <S.CardTitle>{term.title}</S.CardTitle>
      <S.CardDescription>{term.description}</S.CardDescription>
      <S.CardLink>
        <button onClick={() => {
          onSelectTerm(term);
          window.location.href = `/privacidade/${term.title.replace(/\s/g, "-").toLowerCase()}`;
        }}>
          Ver mais
        </button>
      </S.CardLink>
    </S.ContainerCard>
  );
}
