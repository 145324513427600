import React, { useState, useEffect } from 'react';
import {
  Container,
  Main,
  Plans,
  Plan,
  Titles,
  Title,
  SubTitle,
  TitlePlan,
  SubTitlePlan,
  OldPrice,
  NewPrice,
  ListAdvantages,
  ListAdvantagesPlan,
  LabelDisclaimer,
  CardHeading,
  Advantage,
  PlansDiv,
  IconAdvantage,
  LabelAdvantage,
  ButtonPlan,
  Price,
  DetailsPrice,
  LabelLoading,
} from './styles';

import { push } from '../../../services/navigate';
import plans from './plans';
import { show } from '../../../services/alert';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPlan, getPlans } from '../../../redux/actions/buy.actions';
import { LOGIN_MF_PAYMENT_ROUTE } from '../../../routes/routes.contants';
import { useCookies } from 'react-cookie';
import GetCatalogoPlanId from '../../../utils/getCatalogoPlanId';

export default function App(props) {
  const dispatch = useDispatch();
  const plansAPI = useSelector((state) => state.buyReducer.plans);
  const [cookies, setCookie] = useCookies(["name"]);

  const [divChangePlan, setDivChangePlan] = useState(false);
  const changePlan = JSON.parse(localStorage.getItem('@change_plan'));

  useEffect(() => {
    if (changePlan || !divChangePlan) {
      setDivChangePlan(changePlan);
    }
    dispatch(getPlans());
  }, [changePlan, dispatch, divChangePlan]);

  function setPlan(plan) {
    if (plan.single && changePlan && !changePlan.TypeProduct.toLowerCase().includes('avulso')) {
      return show('Impossivel alterar');
    }
    props.handleNext();
    if (plan) {
      localStorage.setItem('@current_plan', JSON.stringify(plan));
      dispatch(setCurrentPlan(plan));
      setCookie("@product_id", GetCatalogoPlanId(plan.id), {
        path: "/",
      });
      setCookie("@service_id", process.env.REACT_APP_SERVICE_ID, {
        path: "/",
      });
      setCookie("@source_platform", "CONECTA_B2C_ed25519", { path: "/" });
      localStorage.setItem("buy-flag", "true");

      push(LOGIN_MF_PAYMENT_ROUTE);
      // push(`/comprar/${plan.namePlan}`);
      // window.location.reload();
    }
  }

  return (
    <Container>
      <Main>
        <Titles>
          <Title>Selecione o seu contrato</Title>
          <SubTitle>Escolha o contrato de sua preferência.</SubTitle>
        </Titles>

        <Plans>
          {!plansAPI && <LabelLoading>Carregando...</LabelLoading>}
          <PlansDiv>
            {plansAPI &&
              plansAPI.length > 0 &&
              plansAPI.map((planAPI) => {
                let plan = plans[planAPI.category];
                if (plan) {
                  return (
                    <Plan key={plan.namePlan}>
                      <CardHeading>
                        <TitlePlan>{plan.namePlan}</TitlePlan>
                        <SubTitlePlan namePlan={plan.namePlan}>{plan.typePlan}</SubTitlePlan>
                        {plan.oldPrice && (
                          <OldPrice>
                            de <b>R$ {plan.oldPrice},00</b>
                          </OldPrice>
                        )}

                        <NewPrice>
                          <Price>R$ {planAPI.Amount}</Price>
                          <DetailsPrice>
                            <span>{plan.labelTypePlan}</span>
                          </DetailsPrice>
                        </NewPrice>
                      </CardHeading>
                      <ListAdvantagesPlan>
                        {plan && plan.advantages.map((advantage) => (
                          <Advantage>
                            <IconAdvantage src="/img/tick.png" />
                            <LabelAdvantage>{advantage}</LabelAdvantage>
                          </Advantage>
                        ))}
                      </ListAdvantagesPlan>
                      <ButtonPlan
                        namePlan={plan.namePlan}
                        onClick={() => setPlan({ ...plan, ...planAPI })}
                      >
                        Contrate agora
                      </ButtonPlan>
                      {plan.disclaimer && <LabelDisclaimer>{plan.disclaimer}</LabelDisclaimer>}
                    </Plan>
                  );
                }
              })}
          </PlansDiv>
        </Plans>
      </Main>
    </Container>
  );
}
