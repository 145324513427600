import styled from "styled-components";

import { motion } from "framer-motion";
import animations from "../../utils/animation";

export const Container = styled.div`
  background: #fff;
  flex: 1;
  max-width: 500px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 5px;
  padding: 2%;

  @media (max-width: 1024px) {
    padding: 5%;
    width: 100%;
  }
`;
export const ContainerPatient = styled.div`
  width: 400px;
  max-height: 70vh;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;

  padding: 20px 10px;

  @media (max-width: 1024px) {
    margin-top: 30px;
    max-height: 70vh;
  }
`;

export const ButtonBack = styled.button`
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: transparent;
`;

export const LabelBack = styled.p`
  margin-left: 10px;
  color: #707070;
`;

export const IconBack = styled.img`
  width: 20px;
  height: 20px;
`;

export const ListPatient = styled(motion.div).attrs(animations)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
  overflow: auto;
  @media (max-width: 1024px) {
    flex-direction: column;
    height: 100%;
  }
`;

export const PatientSection = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 10px 5px;
  padding: 0 0 5px;
  border-bottom: 1px solid #ddd;
  width: 90%;
  min-width: 300px;

  @media (max-width: 1024px) {
    width: 100%;
    min-width: 100%;
    margin: 10px 0;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
  }

  &:hover {
    transition: 0.3s;
    opacity: 0.7;
  }
`;

export const ButtoAddPatient = styled.button`
  min-width: 46px;
  width: 46px;
  height: 46px;
  margin: 5px;

  border-radius: 46px;
  background: #2196f3;
`;

export const ImgButtonAddPatient = styled.img`
  width: 22px;
  height: 22px;
`;

export const ImagePatient = styled.img`
  min-width: 46px;
  width: 46px;
  height: 46px;
  border-radius: 46px;
  margin: 5px;
`;

export const NamePatient = styled.div`
  color: #000;
  font-size: 12px;
  margin: 5px;

  @media (max-width: 1024px) {
    font-size: 14px;
    font-weight: bold;
    margin: 5px;
  }
`;

export const DivArrow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DivType = styled.button`
  width: 100%;
  padding: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const Title = styled.text`
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: bold;
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 5px;
`;

export const SubTitle = styled.text`
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
`;

export const Icon = styled.text`
  font-size: 34px;
  font-weight: bold;
`;

export const Division = styled.div`
  border-top: 1px solid #DEDDDD;
`;