import styled, { css } from "styled-components";

const active = css`
  background-color: #2196f3;
  color: #fff;
`;

export const Body = styled.div`
  display: flex;
  justify-content: center;
  height: 92%;
  width: 90%;
  overflow: auto;
  margin: 3% 5% 5% 5%;
  flex-direction: column;
  @media (max-width: 1024px) {
    overflow: visible;
    margin-top: 80px;
    padding-bottom: 35%;
  }
`;
export const DivHours = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: ${(props) => props.height + "px"};
  width: ${(props) => props.width + "px"};
  /* @media (max-width: 500px) {
    height: 100%;
  } */
`;

export const DivCalendar = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 8px;
  margin: 30px;
  @media (max-width: 1024px) {
    margin: 0px;
    padding: 0px;
  }
`;

export const BoxDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GridSchedule = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 100vh;
  @media (max-width: 800px) {
    overflow: auto;
  }
`;

export const ScheduleDay = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  & + div{
    margin-top: -100px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: flex-start
  }
`;

export const ScheduleHours = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-left: 70px;
  @media (max-width: 1090px) {
    width: 100%;
    margin-left: 0px;
    // padding:20px;
  }
`;

export const DivTitle = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;
export const Title = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

export const TitleSchedule = styled.h4`
  font-weight: bold;
  flex: 1;
  font-size: 16px;
  margin-bottom: ${(props) => props.marginBottom ? props.marginBottom + "px" : "0px"};
  @media (max-width: 1090px) {
    margin-top: 20px;
  }
`;

export const Div = styled.div`
  display: flex;
  justify-content: center;
  height: 70vh;
  align-items: center;

`;

export const DivButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10%;
  @media (max-width: 800px) {
    width: 90%;
    padding: 5%;
    position: fixed;
    height: 10%;
    bottom: 0;
    background-image: linear-gradient(to bottom, rgba(241, 241, 241, 0.7), rgba(241, 241, 241, 1));
  }
`;
export const Button = styled.button`
  width: 221px;
  height: 47px;
  border-radius: 36px;
  margin: 0 5px;

  ${(props) => props.blue && blue}
  ${(props) => props.gray && gray}


  @media (max-width: 1024px) {
    margin: 10px;
    width: 40%;
    height: 30px;
  }
  @media (min-width: 800px) {
    &:hover{
      background-color: #2196f3;
      color: #fff;
      transition: 0.2s
    }
  }
`;

const blue = css`
  background-color: #2196f3;
  color: #ffffff;
`;

const gray = css`
  border: 1px solid #2196F3;
  color: #2196F3;
`;