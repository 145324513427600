import {  GET_TELEPSICO_PROGRAMS, 
          TELEPSICO_PROGRAMS_SUCCESS,
          SET_SELECTED_PROGRAM, 
          GET_AVAILABLE_DATE, 
          GET_AVAILABLE_DATE_SUCCESS,
          SET_TRIADE,
          GET_AVAILABLE_DATE_DOCTOR
} from "../actions/action-types";

const initialState = {
  list: [],
  selectedProgram: false,
  dateRange: [],
  triade: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TELEPSICO_PROGRAMS:
      return state;
    case TELEPSICO_PROGRAMS_SUCCESS:
      return {
        ...state,
        list: action.payload.programs,
        triade: action.payload.triade,
        selectedProgram: action.payload.activeProgram
      };
    case SET_SELECTED_PROGRAM:
      return {
        ...state,
        selectedProgram: action.payload
      }
    case GET_AVAILABLE_DATE:
      return state;
    case GET_AVAILABLE_DATE_DOCTOR:
      return state;
    case GET_AVAILABLE_DATE_SUCCESS:
        return {
          ...state,
          dateRange: action.payload
        }
    case SET_TRIADE: 
      return {
        ...state,
        triade: action.payload
      }
    default:
      return state;
  }
};
