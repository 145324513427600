import styled from "styled-components";

export const Container = styled.div`
  padding: 24px;
  max-width: 100%;
  background-color: #ffffff;
  height: 100%;
  margin-bottom: 55px;
  flex-direction: column;
  display: flex;

  @media (max-width: 960px) {
    margin-top: 55px;
    margin-bottom: 105px;
  }
`;

export const Buttons = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: white;
  @media (max-width: 767px) {
    height: 120px;
    flex-direction: column;
  }
`;

export const ButtonCancel = styled.button`
  width: 301px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #F53748;
  color: #F53748;
  border-radius: 40px;

  @media (max-width: 767px) {
    min-width: 100px;
    width: 250px;
  }
`;

export const Button = styled.button`
 width: 301px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0274D2 0% 0% no-repeat padding-box;
  border-radius: 40px;
  color: white;

  @media (max-width: 767px) {
    min-width: 100px;
    width: 250px;
  }
`;