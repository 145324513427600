import { Link } from "react-router-dom";

import TermImagePrivacy from "../../../public/img/privacy.png";

import * as S from "./styles";

export default function SideCardPrivacy() {
  return (
    <S.Card>
      <p>
        <strong>
          Portal de privacidade e proteção de dados Einstein - LGPD
        </strong>
      </p>

      <p>Entenda como coletamos, usamos e gerenciamos os seus dados.</p>

      <img src={TermImagePrivacy} />

      <S.SeeMoreContainer>
        <Link to="//www.einstein.br/privacidade-protecao-dados" target="_blank">
          Saiba mais
        </Link>
      </S.SeeMoreContainer>
    </S.Card>
  );
}
